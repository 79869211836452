import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreateBrand from './CreateBrand'
import UpdateBrand from './UpdateBrand'

export default function ListBrand() {

  const [listBrand, setListBrand] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadBrands()
  }, [])
  

  const loadBrands = async() => {
      try {
          const {data} = await Axios.get('/brands')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListBrand(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-6 col-lg-8 col-12 mb-3'>
        <div className='d-flex gap-2 mb-2'>
          <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> ADD NEW</button>
        </div>
        {listBrand && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
                { field: 'name', headerName: 'Brand', width: 150},
                { field: 'action', headerName: 'Action', width: 100,filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listBrand}
          />
        }
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateBrand/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateBrand data = {selected}/>}
        />

      </div>
    </div>
    )
}
