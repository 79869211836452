import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useAuth } from '../../../context/auth';
import { useGdate } from '../../../context/date';

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Text, SelectInput} from '../../../components/Inputs/InputFields'
import { MenuItem } from '@mui/material';

import {Check, Clear, Visibility, Help} from '@mui/icons-material/'


export default function ListEmployee() {
  const navigate = useNavigate()

  const [auth] = useAuth()
  const [gdate] = useGdate()

  const type = ['hours', 'day'] 

  const [listEmpAttendances, setListEmpAttendances] = useState('')

  const [paymentType, setPaymentType] = useState()
  const [value, setValue] = useState('')
  const [date, setDate] = useState(gdate?.date)

  const [selectedRows, setSelectedRows] = useState([])

  const [visible, setVisible] = useState(false)
  const [payInfoModel, setPayInfoModel] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadEmpAttendances()
  },[])

  useEffect(() => {
    loadEmpAttendances(paymentType)
    if (paymentType === 'hours') {
      setValue(8)
    } else if (paymentType === 'day') {
      setValue(1)
    }
  }, [paymentType])
  

  const loadEmpAttendances = async() => {
      try {
        const {data} = await Axios.post('/employees/attendance', [{role: { $gte: auth?.employee?.role } , "payment.type": paymentType}, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}}])
        if(data?.error){
            toast.error(data.error)
        } else {
            setListEmpAttendances(data?.filter((i) => i?.role > 0))
        }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  var getDaysArray = (startDate, endDate) => {
    for (var a=[], d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate()+1) )
      { 
        a.push(new Date(d).toISOString()); 
      }
    return a;
  };

  const dateList = getDaysArray(gdate?.reportStartDate,gdate?.reportEndDate);

  const dates = dateList?.map((i) => {
    return {field: `date ${moment(i).format("YYYY-MM-DD")}`, headerName: `${moment(i).format("MM-DD")}`, width: 55, renderCell:(p) => p.row.attendances?.find((a) => a?.date === moment(i).format("YYYY-MM-DD")) ? <Check color='success'/> : <Clear color='error'/>, filterable: false, sortable: false }
  })

  const rowSelection = (data) => {
    setSelectedRows(data)
  }

  const handleMarkAttendance = async() => {
    try {
      await setSubmitDisable(true)
      if (selectedRows.length > 0) {
        const {data} = await Axios.post(`/empattendance`, selectedRows?.map((i) => {
          switch (paymentType) {
            case 'hours':
              return {employee: i?._id, date, hours: value, payment: i?.payment, salary: Number(i?.payment?.amount * value).toFixed(2)}
            case 'day':
              return {employee: i?._id, date, day: value, payment: i?.payment, salary: Number(i?.payment?.amount * value).toFixed(2)}
            case 'month':
              return {employee: i?._id, date, month: value, payment: i?.payment, salary: Number(i?.payment?.amount * value).toFixed(2)}
            default:
              return {employee: i?._id, date, payment: i?.payment, salary: 0}
          }
        }))
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          if (paymentType && value > 0) {
            var allEntries = []
            await selectedRows?.map((i) => {
              allEntries.push(
                {date, type: `${(i?.payment?.amount * value) >= 0 ? 'credit' : 'debit'}`, empStatus: {status: false, employee: i?._id}, detail: {description: `${i?.name} Salary Arrears ${value} ${i?.payment?.type}`}, amount: Number(i?.payment?.amount * value).toFixed(2), accounting: '6515831c1a89e4fc7010012a', refId : `epa-${moment(date).format("YYYYMMDDHH")}-${i?._id}-${i?.payment?.amount}`},
                {date, type: `${(i?.payment?.amount * value) >= 0 ? 'credit' : 'debit'}`, empStatus: {status: false, employee: i?._id}, detail: {description: `${i?.name} Salary ${value} ${i?.payment?.type}`}, amount: Number(i?.payment?.amount * value).toFixed(2), accounting: '64cb525518dca625f12ce5bf', refId : `epa-${moment(date).format("YYYYMMDDHH")}-${i?._id}-${i?.payment?.amount}`}
              )
            })
            const {data} = await Axios.post(`/accdatabulk`, allEntries)
            if (data?.error) {
              toast.error(data.error)
              setSubmitDisable(false)
            } else {
              toast.success(`(${data?.insertedCount / 2}) Attendances Marked with Salary`)
              window.location.reload()
            }
          } else {
              toast.success(`${data?.insertedCount} Only Attendances Marked`)
              window.location.reload()
          }
  
        }
      } else {
        toast.error("Atleast 1 Employee should be selected")
        setSubmitDisable(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='row mb-2'>
          <div className='col-md-3 col-sm-6 col-12'>
            <Text
                label = 'Date'
                type = 'date'
                value = {date}
                onChange={(e) => setDate(e.target.value)}
                readOnly = 'true'
              />
          </div>

          <div className='col-md-3 col-sm-6 col-12'>
            <div className='d-flex align-items-center gap-2'>
              <SelectInput
                label = "Salary Type"
                value = {paymentType}
                onChange = {(e) => {
                  setPaymentType(e.target.value)
                }}
                items = {
                  type?.map((i) => (
                    <MenuItem value={i}>{i}</MenuItem>
                  ))
                }
              />
              <Help fontSize='small' onClick={() => {setPayInfoModel(true)}}/>
            </div>
          </div>

          <div className='col-md-3 col-sm-6 col-12'>
            {paymentType && <Text
              label="hours / day"
              value = {value}
              onChange={(e) => setValue(e.target.value)}
              focus = 'true'
            />}
          </div>

          <div className='col-md-3 col-sm-6 col-12'>
            <button className = 'btn btn-outline-success w-100' disabled={submitDisable} onClick={() => {
              if (paymentType) {
                handleMarkAttendance()
              } else {
                setVisible(true)
              }
            }}><Check/> ATTENDANCE</button>
          </div>
        </div>

        {/* <p className='text-center'>{gdate?.reportStartDate} - {gdate?.reportEndDate}</p> */}

        {listEmpAttendances && 
          <MUITable
            title = {'Employee Attendances | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
            id = {(row) => row._id}
            columns = {[
                { field: 'name', headerName: 'Name', width: 180},
                { field: 'paymentType', headerName: 'Pay Type', width:80, renderCell:(i) => i.row.payment?.type?.toUpperCase(), valueGetter:(i) => i.row.payment?.type},
                { field: 'present', headerName: 'Present', width:70, filterable: false, sortable: false, renderCell:(i) => {
                  switch (i.row.payment?.type) {
                    case 'hours':
                      return i.row.attendances?.reduce((a,v) => a = a + v?.hours, 0)
                      break;
                    case 'day':
                      return i.row.attendances?.reduce((a,v) => a = a + v?.day, 0)
                      break;
                    case 'month':
                      return i.row.attendances?.reduce((a,v) => a = a + v?.month, 0)
                      break;
                    default:
                      return '?'
                  }
                }},
                { field: 'salary', headerName: 'Salary', headerAlign: 'right', width:90, align:"right", renderCell:(i) => new Intl.NumberFormat().format(i.row.attendances?.reduce((a,v) => a = a + v?.salary, 0)), filterable: false, sortable: false},
                { field: 'salaryArrears', headerName: 'Salary Arrears', headerAlign: 'right', width:125, align:"right", renderCell:(i) => new Intl.NumberFormat().format(i.row.salaryArrears[0]?.balanceArrears), filterable: false, sortable: false},
                { field: 'action', headerName: 'View', width: 60, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      navigate(`/dashboard/user/employee/attendance/${i.row?._id}`)
                    }}
                  />
                </div>
                },
                ...dates,
            ]}
            rows = {listEmpAttendances}
            checkbox = {true}
            rowSelection = {rowSelection}
          />
        }
      </div>
      
      <ModalBasic
        open = {payInfoModel}
        onClose = {()=> {
        setPayInfoModel(false)
        }}
        content = {
          <div>
            <h6 className='text-center fw-bold'>1. Mark Only Attendance</h6>
            <div>
              Follow Steps:
              <ol>
                <li>Select checkbox of Employees</li>
                <li>Then Click on "ATTENDACE" button</li>
                <li>A Popup will Show</li>
                <li>Click on 'SUBMIT' Button</li>
              </ol>
            </div>
            <hr/>
            <h6 className='text-center fw-bold'>2. Mark Attendance with Payment</h6>
            <div>
              Follow Steps:
              <ol>
                <li>Select Salary Type</li>
                <li>Then Select checkbox of Employees</li>
                <li>Lastly Click on "ATTENDACE" button</li>
              </ol>
              <div className='d-flex justify-content-center gap-3'>
                <ul>
                  <li>Half Day: 0.5</li>
                  <li>Full Day: 1</li>
                </ul>

                <ul>
                  <li>Hours: 1-24h</li>
                </ul>
              </div>

              <p className='text-danger'>Employees with Salary Type "MONTH" should be marked with Double Entry</p>

            </div>
        </div>
        }
      />

      <ModalBasic
        open = {visible}
        onClose = {()=> {
        setVisible(false)
        }}
        content = {
          <div className='d-flex justify-content-between align-items-center'>
            <p>Only Attendance will be Marked</p>
            <button className = 'btn btn-success' onClick={handleMarkAttendance} disabled={submitDisable}>Submit
            </button>
        </div>
        }
      />
    </div>
  )
}


