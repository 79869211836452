import React, {useRef, useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useGdate } from '../../../context/date'
import { useDk } from '../../../context/dk'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Print} from '@mui/icons-material/'

import { SelectInput } from '../../../components/Inputs/InputFields'
import { Autocomplete, TextField, MenuItem } from '@mui/material'

import CreateGovOrder from './CreateGovOrder'
import UpdateGovOrder from './UpdateGovOrder'

export default function ListGovOrders() {

  const [gdate] = useGdate()
  const [dk, setDk] = useDk()

  const [listGovCustomers, setListGovCustomers] = useState('')
  const [listGovOrders, setListGovOrders] = useState('')
  const [listAccData, setListAccData] = useState('')

  const [inputCustomer, setInputCustomer] = useState()
  const [customer, setCustomer] = useState()

  const [status, setStatus] = useState('')
  const [filterRows, setFilterRows] = useState('')

  const [selectedRows, setSelectedRows] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visible, setVisible] = useState(false)

  const [onPrintVisible, setOnPrintVisible] = useState(true)

  useEffect(() => {
    loadGovCustomers()
    loadGovOrders()
    loadAccData()
  }, [])

  useEffect(() => {
    listGovOrders && setFilterRows(listGovOrders?.filter((i) => i?.customer?._id === customer?._id && i?.status === status ))
  }, [customer, status])
  

  const loadGovCustomers = async() => {
    try {
        const {data} = await Axios.get('/customers')
        if (data?.error) {
            toast.error(data.error)
        } else {
            setListGovCustomers(data?.filter((i) => i?.type === 'government'))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadGovOrders = async() => {
      try {
          const {data} = await Axios.post('/govOrders', {receiveDate: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
          if(data?.error){
              toast.error(data.error)
          } else {
              setListGovOrders(data)
              setFilterRows(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadAccData = async() => {
    try {
        const {data} = await Axios.post('/accdatas/filter', {accounting: '63e31abdf029ceab39dcd283', "detail.govOrder": {$exists : true}})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListAccData(data)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
}

  const rowSelection = (data) => {
    setSelectedRows(data)
  }

  const statusStyle = (status) => {
    switch (status) {
      case "submit":
        return "text-primary"
      case "cheque":
        return "text-success"
      default:
        return ""
    }
  }

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `Governemnt Orders - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
      onBeforeGetContent: async() => {
          await setDk({...dk, darkmode : false})
          await setOnPrintVisible(false)
      },
      onAfterPrint: () => {
          const data = localStorage.getItem("darkmode");
          if (data) {
              const parsed = JSON.parse(data);
              setDk({...dk, darkmode: parsed});
          }
          setOnPrintVisible(true)
      },
  })


  return (
    <div className='row justify-content-center p-3' ref={componentRef}>
    <h5 className='text-center'>Government Orders</h5>
    <dd className='text-center mb-2'>{gdate?.reportStartDate} To {gdate?.reportEndDate}</dd>
      <div className='col-12'>
        {onPrintVisible && <div className='row justify-content-between mb-2'>
          <div className='col-lg-4 col-md-6 col-sm-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            <button className = 'btn btn-warning w-50' onClick={() => {printData()}}><Print/></button>
          </div>

          <div className='col-lg-8 col-sm-12 d-flex justify-content-end gap-2 mb-2'>
            {listGovCustomers && <Autocomplete
              size='small'
              fullWidth
              value={customer?._id}
              onChange={(event, value) => {
                  setCustomer(value)
              }}
              inputValue={inputCustomer}
              onInputChange={(event, newInputValue) => {
                  setInputCustomer(newInputValue)
              }}
              options={listGovCustomers}
              renderInput={(params) => <TextField {...params} label="Customer"/>}
              getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
              noOptionsText = "No Government Customer Found"
            />}

            <SelectInput
              className = 'mb-0'
              label = "Mark"
              value = {status}
              onChange = {(e) => setStatus(e.target.value)}
              items = {
                  [{status: 'receive', mark:"Submission"}, {status: 'submit', mark:"Release"}].map((i) => (
                  <MenuItem value={i?.status}>{i?.mark}</MenuItem>
                  ))
              }
            />

            <button className = 'btn btn-outline-success' disabled={customer && status && selectedRows?.length > 0 ? false : true} onClick={() => {setVisible(true)}}>Update</button>
          </div>
        </div>}

        {listGovOrders && listAccData && 
          <MUITable
          title = {'Government Orders | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
            id = {(row) => row._id}
            columns = {[
                { field: 'customer', headerName: 'Customer', width: 180, renderCell: (i) => i.row.customer?.name, valueGetter: (i) => i.row.customer?.name},
                { field: 'govOrder', headerName: 'Order No', width: 125},
                { field: 'amount', headerName: 'G.O Amount', width: 150, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'currency', currency: "LKR"}).format(i.row.amount)},
                { field: 'amountadded', headerName: 'Sales Amount', width: 150, renderCell: (i) => <div className={listAccData?.filter((a) => a?.detail?.govOrder?._id === i.row._id)?.reduce((a,v) => a = a + v?.amount, 0)?.toFixed(2) === i.row.amount?.toFixed(2) ? 'text-success' : 'text-danger'}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: "LKR"}).format(listAccData?.filter((a) => a?.detail?.govOrder?._id === i.row._id)?.reduce((a,v) => a = a + v?.amount, 0))}</div>, filterable: false, sortable: false},
                { field: 'status', headerName: 'Status', width: 100, renderCell: (i) => <div className={statusStyle(i.row.status)}>{i.row.status?.toUpperCase()}</div>, valueGetter: (i) => i.row.status},
                { field: 'receiveDate', headerName: 'Received Date', width: 140},
                { field: 'submitDate', headerName: 'Submited Date', width: 140},
                { field: 'chequeDate', headerName: 'Cheque Released', width: 140}
            ]}
            rows = {filterRows}
            checkbox = {true}
            rowSelection = {rowSelection}
          />
        }
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateGovOrder/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateGovOrder data = {selectedRows} customer={customer?._id} status={status}/>}
        />

      </div>
    </div>
    )
}
