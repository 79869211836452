import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import MUITable from '../../components/table/MUITable'

export default function Stocks() {
    const [listProducts, setListProducts] = useState('')

    useEffect(() => {
        loadProducts()
    },[])

    const loadProducts = async() => {
        try {
            const {data} = await Axios.post(`/warehousestocks/group-byproductcode`)
            if(data?.error){
                toast.error(data.error)
            } else {
                setListProducts(data?.filter((i) => i?.totalStock < 20))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='mt-3'>
            <div className='d-flex justify-content-center align-items-baseline gap-2'>
                <h5>Low Stocks</h5>
                <dd className='text-danger'>less than 20 Qty</dd>
            </div>
            {listProducts && <MUITable
                id = {(row) => row._id}
                headerSize = {40}
                rowSize = {40}
                fontSize = {14}
                columns = {[
                    { field: 'productcode', headerName: 'Product Code', width: 120, renderCell: (i) => `${i.row?.productCode?.productCode}`, valueGetter: (i) => `${i.row?.productCode?.productCode}`},
                    { field: 'brand', headerName: 'Brand', width: 150, renderCell: (i) => `${i.row?.productCode?.product?.brand?.name}`, valueGetter: (i) => `${i.row?.productCode?.product?.brand?.name}`},
                    { field: 'product', headerName: 'Product', width: 250, renderCell: (i) => `${i.row?.productCode?.product?.name}`, valueGetter: (i) => `${i.row?.productCode?.product?.name}`},
                    { field: 'totalStock', headerName: 'Total Stock', headerAlign:'center', width: 120, renderCell: (i) => (new Intl.NumberFormat().format(i.row.totalStock) + ' ' + i.row?.productCode?.product?.unit), valueGetter: (i) => i.row?.totalStock},
                ]}
                rows = {listProducts}
            />}
      </div>
    )
}
