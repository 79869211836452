import React, { useState, useEffect } from 'react'
import { useAuth } from '../context/auth';

export default function EmpDashboard() {
  const [auth] = useAuth();

  const [roleString, setRoleString] = useState('Employee')

  useEffect(() => {
    setRoleString(role())
  },[auth?.employee])

  const role = () => {
    switch (auth?.employee?.role) {
      case 0:
        return 'Admin'
        break
      case 1:
        return 'User'
        break
      case 2:
        return 'Employee'
        break
      default:
        return 'Employee'
    }
  }
  return (
    <div className='row justify-content-center' >
      <div className='shadow p-4 pb-4 col-lg-6 col-sm-12' style={{borderRadius: '5px', backgroundImage: 'rgba(100,100,100,0.5)', backdropFilter: 'blur(10px)'}}>
          <div className='col text-center pb-2'>
            <h1 className='text-center'>Successfully Logged in as {roleString}</h1>
            <p className='fs-5'>{auth?.employee?.email}</p>
          </div>
          <div className='row justify-content-center'>
            <div className='col-4'>
              <p className='fs-5'>Name:</p>
            </div>
            <div className='col-8'>
              <p className='text-capitalize fs-5'>{auth?.employee?.name}</p>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-4'>
              <p className='fs-5'>Address:</p>
            </div>
            <div className='col-8'>
              <p className='fs-5'>{auth?.employee?.address}</p>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-4'>
              <p className='fs-5'>NIC No:</p>
            </div>
            <div className='col-8'>
              <p className='fs-5'>{auth?.employee?.nic}</p>
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='col-4'>
              <p className='fs-5'>Phone No:</p>
            </div>
            <div className='col-8'>
              <p className='fs-5'>{auth?.employee?.phoneNumber}</p>
            </div>
          </div>


      </div>
    </div>
  )
}
