import React, {useState,useEffect, useRef} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'
import {useReactToPrint} from 'react-to-print'

import { useGdate } from '../../context/date'
import { useDk } from '../../context/dk'

import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow,} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'

import {SelectInput} from '../../components/Inputs/InputFields'
import { MenuItem } from '@mui/material';

import { Print } from '@mui/icons-material/'

export default function AllLoads() {

  const [gdate] = useGdate()
  const [dk, setDk] = useDk()

  const [listTank, setListTank] = useState('')
  const [filteredTanks, setFilteredTanks] = useState([])
  const [depthList, setDepthList] = useState('')
  const [listLoads, setListLoads] = useState([])
  
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    calculateDays() < 32 && loadTank()
    calculateDays() < 32 && loadDepth()
    calculateDays() < 32 && loadStockLoad()
  }, [gdate])

  const loadTank = async() => {
    try {
      const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: "63e65efc2f840202604fbb7f"})
      if (data?.error){
        toast.error(data?.error)
      } else {
        setListTank(data)
        setFilteredTanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadDepth = async() => {
    try {
        const {data} = await Axios.post('/product-depths', {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
        if(data?.error){
            toast.error(data.error)
        } else {     
          setDepthList(data)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
}

  const loadStockLoad = async() => {
    try {
      const {data} = await Axios.post(`/stockloads-bydate`, {"purchase.purchaseDate": {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
      if(data?.error){
          toast.error(data.error)
      } else {
        setListLoads(data)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  var getDaysArray = (startDate, endDate) => {
    for (var a=[], d = new Date(startDate); d <= new Date(endDate); d.setDate(d.getDate()+1) )
      { 
        a.push(new Date(d).toISOString()); 
      }
    return a;
  };

  const dateList = getDaysArray(gdate?.reportStartDate, gdate?.reportEndDate);

  const calculateDays = () => {
    return ((new Date(gdate?.reportEndDate) - new Date(gdate?.reportStartDate)) / (1000 * 3600 * 24))
  }

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `Purchase Loads - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
      onBeforeGetContent: async() => {
          await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
          const data = localStorage.getItem("darkmode");
          if (data) {
              const parsed = JSON.parse(data);
              setDk({...dk, darkmode: parsed});
          }
      },
  })

  console.log(listLoads)
  return (
    <div className='justify-content-center'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xl-4 col-lg-6 col-md-8 col-12 d-flex gap-2 mb-2'>
            <SelectInput
              label = "Filter Tanks"
              value = {selectedValue}
              onChange = {(e) => {
                setSelectedValue(e.target.value)
                setFilteredTanks((preVal) => [...preVal, e.target.value])
              }}
              items = {
                listTank && listTank?.map((i) => (
                  <MenuItem value={i}>{i?.productCode?.product?.name}</MenuItem>
                ))
              }
            />
            <button className = 'btn btn-link' onClick={() => {setFilteredTanks([])}}>Reset</button>
            <button className = 'btn btn-warning' onClick={() => {printData()}}><Print/></button>
          </div>
        </div>

      <div className='justify-content-center pt-3' ref= {componentRef}>
        <div className='d-flex justify-content-center align-items-baseline gap-2'>
          <h5 className='text-capitalize'>Purchase Load List</h5>
          <dd>{gdate?.reportStartDate} - {gdate?.reportEndDate}</dd>
        </div>

        {calculateDays() < 32 ? <div className='col-12'>
          <Table className='d-flex justify-content-center' size='small' style={{overflowX: 'auto'}}>
            {/* DATE COLUMN */}
            <div>
              <TableHead>
                  <TableRow className='border'>
                      <StyledTableCell className='text-center'>-</StyledTableCell>
                  </TableRow>
                  <TableRow className='border'>
                      <StyledTableCell className='text-center' style={{minWidth: 100}}>Date</StyledTableCell>
                  </TableRow>
              </TableHead>

              <TableBody className='border'>
                {dateList && dateList?.map((i) =>
                <TableRow key={i}>
                  <TableCell style={{fontSize: 12}}>{moment(i).format("YYYY-MM-DD")}</TableCell>
                </TableRow>
                )}
              </TableBody>
            </div>

            {filteredTanks && filteredTanks?.map((t) => 
              <div>
                <TableHead>
                    <TableRow className='border'>
                        <StyledTableCell className='text-center' colSpan={3} style={{minWidth: 180}}>{t?.productCode?.product?.name}</StyledTableCell>
                    </TableRow>
                    <TableRow className='border'>
                        <StyledTableCell>Depth</StyledTableCell>
                        <StyledTableCell className='text-end'>Volume</StyledTableCell>
                        <StyledTableCell>Load</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody className='border'>
                  {dateList && dateList?.map((i) =>
                  <TableRow key={i}>
                    <TableCell style = {{fontSize: 12}}>{depthList && depthList?.find((l) => l?.date === moment(i).format("YYYY-MM-DD") && l?.product?._id === t?.productCode?.product?._id) ? depthList?.find((l) => l?.date === moment(i).format("YYYY-MM-DD") && l?.product?._id === t?.productCode?.product?._id)?.dep : '-'}</TableCell>
                    <TableCell style = {{fontSize: 12}} className='text-end'>{depthList && depthList?.find((l) => l?.date === moment(i).format("YYYY-MM-DD") && l?.product?._id === t?.productCode?.product?._id) ? depthList?.find((l) => l?.date === moment(i).format("YYYY-MM-DD") && l?.product?._id === t?.productCode?.product?._id)?.volume : '-'} L</TableCell>
                    <TableCell style = {{fontSize: 12}}>{new Intl.NumberFormat().format( (listLoads && listLoads?.find((l) => l?._id?.wareHouseStock === t?._id && l?._id?.date === moment(i).format("YYYY-MM-DD")) ? listLoads?.find((l) => l?._id?.wareHouseStock === t?._id && l?._id?.date === moment(i).format("YYYY-MM-DD"))?.TotalQuantity : 0 ) / t?.productCode?.product?.type?.loadLiter ) }</TableCell>
                  </TableRow>
                  )}
                  <TableRow key="total">
                    <TableCell></TableCell>
                    <TableCell style = {{fontSize: 12}} className='text-end'>Total</TableCell>
                    <TableCell style = {{fontSize: 12}}>{(listLoads?.filter((l) => l?._id?.wareHouseStock === t?._id)?.reduce((a,v) => a = a + v?.TotalQuantity, 0) / t?.productCode?.product?.type?.loadLiter)?.toFixed(0) }</TableCell>
                  </TableRow>
                </TableBody>
              </div>
            )}
          </Table>
        </div>
        :
        <h5 className='text-center'>Maximum of 1 month can be load <br/> So, Please select maximum date range of 1 month</h5>
        }
      </div>
    </div>
  )
}
