import React, {useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast';

import {useAuth} from '../context/auth'

import {MenuItem} from '@mui/material/'
import {Text, SelectInput} from '../components/Inputs/InputFields'

import {Check, Clear} from '@mui/icons-material';

import { accCategories, accountings, categories, employee, fuelTypes, warehouses } from '../Data/InitialSetupData';

import FileUpload from '../components/FIleUpload/FileUpload'
import Stepper from '../components/Stepper/Stepper'

import { Image } from 'antd';
import POS from '../Assets/images/POS.png'
import Invoice from '../Assets/images/Invoice.png'
import InvoiceNoName from '../Assets/images/invoice-noName.png'

export default function Setup() {

    const [auth, setAuth] = useAuth()

    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [mail, setMail] = useState('')
    const [logo, setLogo] = useState()

    const [currencyStart] = useState('Rs.')
    const [currencyEnd] = useState('LKR')

    const [nameOnReceipt, setNameOnReceipt] = useState(false)
    const [billReceipt, setBillReceipt] = useState('pos')

    const [stepCount, setStepCount] = useState(0)

    const [submitDisable, setSubmitDisable] = useState(false)

    const getImageData = (data) => {
        if(data?.file) {
          setLogo(data)
        } else {
          setLogo()
        }
    }

    const handleAllRequest = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.post(`/register`, employee)
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                await setAuth({...auth, token: data?.token, employee: data?.employee})
                const data1 = await Axios.post(`/settings`, {_id: '6440fc36e4c208d8ead76411', name, phoneNumber, address, mail, logo, currencyStart, currencyEnd, nameOnReceipt, billReceipt})
                if (data1?.data?.error) {
                    toast.error(data1?.data?.error)
                    setSubmitDisable(false)
                } else {
                    await Axios.all([
                        Axios.post(`/acccategory-bulk`, accCategories),
                        Axios.post(`/accounting-bulk`, accountings), 
                        Axios.post(`/category-bulk`, categories), 
                        Axios.post(`/fueltype-bulk`, fuelTypes), 
                        Axios.post(`/warehouse-bulk`, warehouses), 
                      ])
                      .then(Axios.spread((accCategory, accounting, category, fuelType, warehouse) => {
                        if (accCategory?.error || accounting?.error || category?.error || fuelType?.error || warehouse?.error) {
                            toast.error("Something went wrong, Please contact Support")
                            setSubmitDisable(false)
                        } else {
                            toast.success("Successfully System Initialized")
                            window.location.reload()
                        }
                      }));
                }
            }
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <div className='m-5'>
            <h1 className='text-center text-capitalize' style={{fontFamily: "Sofia"}}>Welcome to Gas Station System</h1>
            <h3 className='text-center text-capitalize' style={{fontFamily: "Trirong"}}>Initialize your Setup</h3>

            <Stepper stepCount={stepCount}/>

            {stepCount === 0 && <div className='row justify-content-center'>
                <div className='col-xl-4 col-md-6 col-12 p-3'>
                    <Text
                        label="Business Name (Optional)"
                        value = {name}
                        onChange={(e) => setName(e.target.value)}
                        end = 'Gas Station'
                    />
                    <Text
                        label="Phone Number (Optional)"
                        type ="Number"
                        value = {phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)} 
                        end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                    />
                    <Text
                        label="Address (Optional)"
                        value = {address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <Text
                        label="Mail Address (Optional)"
                        type="email"
                        value = {mail}
                        onChange={(e) => setMail(e.target.value)}
                        end = {mail.trim() ? (mail.includes('@') ? <Check color='success' /> : <Clear color='error'/>) : ''}
                    />

                    <FileUpload submit = {getImageData} fileType= "Image" hidePreview = {true}/>
                    {logo?.file ? 
                        <div className='col-12'>
                            <img
                                className='w-100'
                                src={logo?.file}
                                alt='Logo Load Failed Reload'
                            />
                            <button className='btn btn-danger mt-2 w-100' onClick={() => {setLogo('')}}>REMOVE LOGO</button>
                        </div>
                        :
                        <p className='text-center fs-6'>Set Organization Logo</p>
                    }
                </div>
            </div>}

            {stepCount === 1 && <div className='row justify-content-center'>
                <div className='col-xl-4 col-md-6 col-12 p-3'>
                    <SelectInput
                        label = "Bill Receipt"
                        value = {billReceipt}
                        onChange = {(e) => {
                            setBillReceipt(e.target.value)
                        }}
                        items = {
                            ['invoice', 'pos'].map((i) => (
                                <MenuItem value={i}>{i?.toUpperCase()}</MenuItem>
                            ))
                        }
                    />
                    {billReceipt === 'invoice' && <SelectInput
                        label = "Name on Receipt"
                        value = {nameOnReceipt}
                        onChange = {(e) => {
                            setNameOnReceipt(e.target.value)
                        }}
                        items = {
                            [{item:'TRUE', value: true}, {item:'FALSE', value: false}].map((i) => (
                                <MenuItem value={i?.value}>{i?.item}</MenuItem>
                            ))
                        }
                    />}
                    <div className='row justify-content-center p-3'>
                        {billReceipt === 'invoice' ?
                            <Image
                                width={'100%'}
                                src={nameOnReceipt ?  Invoice : InvoiceNoName}
                                alt='Bill-Receipt'
                            />
                            :
                            <Image
                                width={'70%'}
                                src={POS}
                                alt='Bill-Receipt'
                            />
                        }
                    </div>
                </div>
            </div>}

            {stepCount === 2 && <div className='row justify-content-center'>
                <div className='col-xl-4 col-md-6 col-12 p-3'>
                    <dd className='text-center'>Login Username</dd>
                    <h5 className='text-center'>admin</h5>

                    <dd className='text-center'>Password</dd>
                    <h5 className='text-center'>admin@123</h5>
                </div>
            </div>}

            <div className='row justify-content-center'>
                <div className='col-xl-4 col-md-6 col-12 p-3'>
                    <div className='d-flex justify-content-center'>
                        <button className='btn btn-outline-primary' disabled={submitDisable} onClick={(e) => {
                            if (stepCount === 2) {
                                return handleAllRequest(e)
                            } else {
                                setStepCount((prev) => prev+1)
                            }
                        }}>
                            {stepCount === 2 ? 'COMPLETE' : 'NEXT'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
