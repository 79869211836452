import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

import {useSettings} from '../../../context/settings'
import {useGdate} from '../../../context/date'

import { SelectInput, Text } from '../../../components/Inputs/InputFields'
import {TextField, Autocomplete, Button, MenuItem, Paper} from '@mui/material';
import {Clear} from '@mui/icons-material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCartPlus, faBarcode} from '@fortawesome/free-solid-svg-icons'

import MUITable from '../../../components/table/MUITable'

import Scanner from '../../../components/Scan-Qr/Scanner'
import FileUpload from '../../../components/FIleUpload/FileUpload'
export default function PurchaseForm() {
  const [settings] = useSettings()
  const [gdate] = useGdate()

  // LOAD DATA
  const [listWareHouses, setListWareHouses] = useState('')
  const [listWarehouseStocks, setListWarehouseStocks] = useState('')
  const [listSuppliers, setListSuppliers] = useState('')
  const [listBanks, setListBanks] = useState('')

  const [invoiceNo, setInvoiceNo] = useState('')
  const [wareHouse, setWareHouse] = useState('')

  const [inputValue, setInputValue] = useState('') 
  const [selectProductStock, setSelectProductStock] = useState('')

  const [inputSupplier, setInputSupplier] = useState('')
  const [selectedSupplier, setSelectedSupplier] = useState()

  const [bank, setBank] = useState()

  const [quantity, setQuantity] = useState()
  const [itemPrice, setItemPrice] = useState('')

  const [purchaseDate] = useState(gdate?.date)
  const [items, setItems] = useState([])
  const [subTotal, setSubTotal] = useState()
  const [total, setTotal] = useState()
  const [invoice, setInvoice] = useState()

  const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank'}, {_id:'64acdf13722d07654e219f89', name: 'Credit'}])
  const [pay, setPay] = useState('')

  const [scannerVisible, setScannerVisible] = useState(false)
  const productFocusRef = useRef()

  const [submitDisable, setSubmitDisable] = useState(false)


  //Refresh Events
  useEffect(() => {
    loadWareHouses()
    loadSupplier()
    loadBanks()
    setQuantity(1)
    setItemPrice('0')
  },[])

  useEffect(() => {
    loadWarehouseStocks()
    setInputValue('')
    setSelectProductStock('')
  },[wareHouse])

  useEffect(() => {
    if (selectProductStock?.productCode?.productCode !== inputValue.split('|')[0].trim()) {
        listWarehouseStocks && setSelectProductStock(listWarehouseStocks?.find((i) => i.productCode?.productCode === inputValue) ? listWarehouseStocks?.find((i) => i.productCode?.productCode === inputValue) : '')
    }
  }, [inputValue])

  useEffect(() => {
    selectProductStock && quantity && setItemPrice(Number(selectProductStock?.productCode?.cost * quantity)?.toFixed(2))
  }, [selectProductStock, quantity])

  useEffect(() => {
    setSubTotal(items?.reduce((t,i) => t = t + Number(i?.itemPrice), 0)?.toFixed(2))
    setTotal(items?.reduce((t,i) => t = t + Number(i?.itemPrice) , 0)?.toFixed(2))
  },[items])

  useEffect(() => {
    setBank()
  },[pay])


  const loadWareHouses = async() => {
    try {
      const {data} = await Axios.get('/warehouses')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWareHouses(data)
        setWareHouse('63f48039710db4fab3a62abb')
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSupplier = async() => {
    try {
      const {data} = await Axios.get(`/suppliers`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.get(`/bank-accounts`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadWarehouseStocks = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks/filtercategory`, {_id: {"$ne" : '63e65efc2f840202604fbb7f'}})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWarehouseStocks(data.filter((i) => i?.wareHouse?._id === wareHouse))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getBarcodeData = (data) => {
    setInputValue(data.result.decodedText)
  }

  const getPDFData = (data) => {
    if(data?.file) {
      setInvoice(data)
    } else {
      setInvoice()
    }
  }

  const productAdd = async(e) => {
    e.preventDefault()
    try {
      if (itemPrice > 0) {
        await setItems([...items, {productStock: selectProductStock, quantity, itemPrice}])
        setQuantity(1)
        setInputValue('')
        setSelectProductStock('')
        setItemPrice('')
        productFocusRef?.current?.firstChild?.focus();
      } else {
        toast.error("Final Price Required")
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const acc2 = (pay) => {
    if (pay?._id === '64acdf13722d07654e219f89') {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), type: `${total >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail:{description: `Purchase INV No: ${invoiceNo} : ${selectedSupplier?.name}`, supplier: selectedSupplier?._id}, amount: Number(total).toFixed(2) , accounting: '64acdf13722d07654e219f89'}
    } else {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), type: `${total >= 0 ? "debit" : "credit"}`, empStatus: {status: false}, detail:{description: `Purchase INV No: ${invoiceNo} : ${selectedSupplier?.name}`, bank}, amount: `${total >= 0 ? Number(-total).toFixed(2) : Number(total).toFixed(2)  }` , accounting: pay?._id}
    }
  }

  const orderSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (pay === '63e31a93f029ceab39dcd281' && !bank) {
        toast.error("Bank Account Required");
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/createpurchase`, {invoiceNo, supplier: selectedSupplier?._id, purchaseDate, items, total, payment: pay?._id, invoice, type: 'inventory'})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const data1 = await Axios.post(`/accdata`, {date: moment(purchaseDate).format("YYYY-MM-DD"), type : `${total >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail:{description: `Purchase INV No: ${invoiceNo} : ${selectedSupplier?.name}`}, amount: Number(total).toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
          const data2 = await Axios.post(`/accdata`, acc2(pay))
          if(data1?.data?.error || data2?.data?.error){
            toast.error(data1?.data?.error || data2?.data?.error)
            setSubmitDisable(false)
          } else {
            toast.success("Purchase Recorded Sucessfully")
            window.location.reload()
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong!")
    }
  }
  
  // UI COMPONENTS
  return (
    <div className='row'>
      {/* COLUMN 1 */}
      <div className='col-xxl-8 col-12'>
        {/* WAREHOUSE */}
        <div className='col-12 mb-2'>
          <SelectInput
            label = "Ware House"
            value = {wareHouse}
            onChange = {(e) => {
              setWareHouse(e.target.value)
            }}
            items = {
              listWareHouses && listWareHouses.map((i) => (
                <MenuItem value={i._id}>{i.name}</MenuItem>
              ))
            }
          />
        </div>

        {/* PRODUCTS */}
        <form className='row mb-2' onSubmit={productAdd}>
          <div className='col-md-6 col-12 mb-2'>
            {scannerVisible ? <Scanner result = {getBarcodeData}/> : <button className = 'd-flex btn btn-outline-primary' onClick={() => {setScannerVisible(true)}}><FontAwesomeIcon icon={faBarcode}/></button>}
          </div>

          <div className='col-md-6 col-12'>
            <div className='col-12'>
              <div className='col-12'>
                {listWarehouseStocks && <Autocomplete
                  size='small'
                  className='mb-2'
                  value={selectProductStock?.productCode}
                  onChange={(event, value) => {
                    setSelectProductStock(value)
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  freeSolo
                  filterSelectedOptions
                  options={listWarehouseStocks}
                  renderInput={(params) => <TextField {...params} label="Product" autoFocus ref={productFocusRef}/>}
                  getOptionLabel = {(params) => `${params?.productCode?.productCode + ' | '+ params.productCode?.product?.brand?.name + ' ' + params.productCode?.product?.name + ' | '+ settings?.currencyStart + ' ' + params?.productCode?.cost?.toFixed(2)}`}
                  noOptionsText = "No Product Found"
                />}
              </div>

              <div className='col-12'>
                <Text
                  label="Qty"
                  type="Number"
                  value = {quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                />
              </div>

              <div className='col-12'>
                <Text
                  label="Final Price"
                  type="Number"
                  value = {itemPrice}
                  onChange={(e) => setItemPrice(Number(e.target.value))}
                  end = {settings.currencyEnd}
                />
              </div>

              <div className=''>
                  <dd className='fw-bold'>Price / Qty : {selectProductStock && new Intl.NumberFormat().format((itemPrice / quantity)) + ' ' + settings?.currencyEnd}</dd>
              </div>

              <div className='col-12 d-grid'>
                {selectProductStock ? <button style={{maxHeight: '40px'}} className='btn btn-primary' type='submit'><FontAwesomeIcon icon={faCartPlus}/></button> : <button  style={{maxHeight: '40px'}} className='btn btn-dark' type='submit' disabled><FontAwesomeIcon icon={faCartPlus}/></button>}
              </div>

            </div>
          </div>
        </form>

        {/*PRODUCTS ADDED TABLE*/}
        <div className='row mb-3'>
          <div className='col-12'>
            <MUITable
              id = {(row) => row.productStock?._id}
              pageSize = {5}
              columns = {[
                { field: 'brand', headerName: 'Brand', width: 120, renderCell: (i) => i.row?.productStock?.productCode?.product?.brand?.name, filterable: false, sortable: false},
                { field: 'name', headerName: 'Item', width: 170, renderCell: (i) => i.row?.productStock?.productCode?.product?.name, filterable: false, sortable: false},
                { field: 'quantity', headerName: 'Qty', width: 90, filterable: false, sortable: false},
                { field: 'unitCost', headerName: 'Unit Cost', width: 120, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.itemPrice / i.row.quantity)}`,  filterable: false, sortable: false},
                { field: 'itemPrice', headerName: 'Final Price', width: 100, filterable: false, sortable: false, renderCell: (i) => `${settings?.currencyStart} ${i.row.itemPrice ? new Intl.NumberFormat().format(i.row?.itemPrice) : 0.00}`},
                { field: 'warehouse', headerName: 'Ware House', width: 120, filterable: false, sortable: false, renderCell: (i) => i.row.productStock?.wareHouse?.name},
                { field: 'action', headerName: 'Action', width: 90,  filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Clear fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setItems(items?.filter(c => c.productStock?._id !== i.row?.productStock?._id))
                      toast.success("Product Removed")
                      productFocusRef?.current?.firstChild?.focus();
                    }}
                  />
                </div>
                },
              ]}
              rows = {items}
            />
          </div>
        </div>
      </div>

      {/* COLUMN 2 */}
      <div className='col-xxl-4 col-12 d-flex justify-content-center align-items-start'>
        <Paper elevation={5} className='p-5' style={{minWidth: 400}}>
          <h4 className='text-center pb-3'>Purchase Summary</h4>
          <Text
            label="Invoice No"
            value = {invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
          
          {listSuppliers && <Autocomplete
            size='small'
            fullWidth
            className='mb-2'
            value={selectedSupplier?._id}
            onChange={(event, value) => {
              setSelectedSupplier(value)
            }}
            inputValue={inputSupplier}
            onInputChange={(event, newInputValue) => {
              setInputSupplier(newInputValue)
            }}
            options={listSuppliers}
            renderInput={(params) => <TextField {...params} label="Supplier"/>}
            getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
            noOptionsText = "No Supplier Found"
          />}
          <div className='d-flex justify-content-between'><p>Date : </p> <p>{purchaseDate}</p></div>
          <div className='d-flex justify-content-between'><p>No of Items : </p> <p>{items.length}</p></div>
          <div className='d-flex justify-content-between'><p>Sub Total : </p> <p>{items.length > 0 ? `${new Intl.NumberFormat().format(subTotal)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</p></div>

          <div>Invoice PDF File</div>
          <FileUpload submit = {getPDFData} fileType= "PDF"/>

          <hr style={{borderStyle: 'double'}}></hr>
          <div className='d-flex justify-content-between'><h5>Total : </h5> <h5>{items.length > 0 ? `${new Intl.NumberFormat().format(total)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</h5></div>
          {payMethod && <SelectInput
            label = "Payment Method"
            value = {pay}
            onChange = {(e) => {
              setPay(e.target.value)
            }}
            items = {
              payMethod && payMethod?.map((i) => (
                <MenuItem value={i}>{i.name}</MenuItem>
              ))
            }
          />}

          {(pay?._id === "63e31a93f029ceab39dcd281") &&
            <SelectInput
              label = "Account"
              value = {bank}
              onChange = {(e) => setBank(e.target.value)}
              items = {
                listBanks && listBanks?.map((i) => (
                  <MenuItem value={i._id}>{i.bank} | {i.accountNo}</MenuItem>
                ))
              }
            />
          }

          <div>
            <Button className='w-100 mt-2' size='large' color="success" variant="contained" onClick={orderSubmit} disabled = {invoiceNo && selectedSupplier && pay && items.length > 0 ? submitDisable : true}>Checkout</Button>
          </div>
        </Paper>
      </div>

    </div>
  )
}
