import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useGdate } from '../../../context/date'

import {Text} from '../../../components/Inputs/InputFields'

export default function CreateGovOrder(props) {
    const data = props?.data
    const customer = props.customer
    const status = props?.status

    const [gdate] = useGdate()

    const [date, setDate] = useState(gdate?.date)

    const [chqNo, setChqNo] = useState()
    const [chqDate, setChqDate] = useState(gdate?.date)
    const [description, setDescription] = useState(`G.O No: ${data?.map((i) => i?.govOrder)?.join(", ")}`)
    const [amount, setAmount] = useState(data && data?.reduce((a,v) => a = a + v?.amount, 0))

    const [submitDisable, setSubmitDisable] = useState(false)

    // const orderNumbers = data?.map((i) => i?.govOrder)?.join(", ")

    const submissionDatas = data?.map((i) => {
        switch (i?.status) {
            case "receive":
                return {_id: i?._id, status: 'submit', submitDate: date};
                break;
            case "submit":
                return {_id: i?._id, status: 'cheque', chequeDate: date};
                break;
        }
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.post(`/bulkgovOrder`, submissionDatas);
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                if (status === 'submit') {
                    const {data} = await Axios.post(`/cheque`, {date, status:'credit', chqCustomer: customer, chqNo, chqDate, amount: Number(amount)?.toFixed(2)})
                    if (data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        const data1 = await Axios.post(`/accdatabulk`, [
                            {date, type: 'credit', empStatus: {status: false}, detail: {description, customer, cheque: data?._id}, amount: Number(amount)?.toFixed(2), accounting : '64fda2c5c969962bfbcee3eb', refId : `govOrders-${customer}-${chqNo}-${amount}`},
                            {date, type: 'debit', empStatus: {status: false}, detail: {description, customer}, amount: Number(-amount)?.toFixed(2), accounting: '63e31abdf029ceab39dcd283', refId : `govOrders-${customer}-${chqNo}-${amount}`}
                          ])
                        if (data1?.data?.error) {
                            toast.error(data1?.data?.error)
                            setSubmitDisable(false)
                        } else {
                            toast.success("Government Order and Accounts Recorded");
                            window.location.reload()
                        }
                    }
                } else {
                    toast.success("Government Order Recorded");
                    window.location.reload()
                }
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center mb-3'>Add Government Order</h5>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Date"
                    type = "date"
                    value = {date}
                    onChange={(e) => setDate(e.target.value)}
                />

                {status === 'submit' &&
                    <div>
                        <Text
                            label="Cheque No"
                            value = {chqNo}
                            onChange={(e) => setChqNo(e.target.value)}
                        />
                        <Text
                            label="Cheque Date"
                            type = "date"
                            value = {chqDate}
                            onChange={(e) => setChqDate(e.target.value)}
                        />
                        <Text
                            label="Description"
                            value = {description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <Text
                            label="Amount"
                            type = "Number"
                            value = {amount}
                            onChange={(e) => setAmount(e.target.value)}
                            end = "LKR"
                        />
                    </div>
                }
            <div class="d-grid gap-2">
            <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                SUBMIT ORDER
            </button>
            </div>
        </form>
        </div>
    )
}
