import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import {Clear} from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'

import {useGdate} from '../../../context/date'

import MUITable from '../../../components/table/MUITable'
import {Text} from '../../../components/Inputs/InputFields'
import {TextField, Autocomplete} from '@mui/material';

export default function TankCheck() {
  const {id} = useParams()
  const [gdate] = useGdate()

  const [listTankCheck, setListTankCheck] = useState('')
  const [listStockLoad, setListStockLoad] = useState('')

  const [tank, setTank] = useState('')
  const [listPurchaseDetails, setListPurchaseDetails] = useState('')

  const [pumps, setPumps] = useState('')
  const [pumpsMaxRead, setPumpsMaxRead] = useState('')


  const [inputPump, setInputPump] = useState('')
  const [selectedPump, setSelectedPump] = useState('')
  const [previousReading, setPreviousReading] = useState('')
  const [reading, setReading] = useState('')
  const [litters, setLitters] = useState('')
  const [testing, setTesting] = useState('')

  const [pumpList, setPumpList] = useState('')

  const [totalLitters, setTotalLitters] = useState('')
  const [testLitters, setTestLitters] = useState('')
  const [loadLitters, setLoadLitters] = useState('')

  const [amount, setAmount] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadTank()
    loadPumps()
    loadTankCheck()
    loadStockLoad()
  },[])

  useEffect(() => {
    tank && loadPurchaseDetails()
  },[tank])

  useEffect(() => {
    listTankCheck && loadStockLoad()
    listTankCheck && loadMaxReading()
  },[listTankCheck])

  useEffect(() => {
    listStockLoad && setLoadLitters(listStockLoad?.reduce((a,v) => a = a + Number(v?.quantity), 0) ? listStockLoad?.reduce((a,v) => a = a + Number(v?.quantity), 0) : 0)
  },[listStockLoad])

  useEffect(() => {
    reading && setLitters(Number(reading - previousReading)?.toFixed(3))
  },[previousReading, reading])

  useEffect(() => {
    (pumpList?.length === pumps?.length) && listPurchaseDetails && FIFO(listPurchaseDetails)
    pumpList && setTotalLitters(pumpList?.reduce((a,v) => a = a + Number(v?.litters), 0) ? pumpList?.reduce((a,v) => a = a + Number(v?.litters), 0) : 0)
    pumpList && setTestLitters(pumpList?.reduce((a,v) => a = a + Number(v?.testing), 0) ? pumpList?.reduce((a,v) => a = a + Number(v?.testing), 0) : 0)
  },[pumpList])


  const loadTank = async() => {
    try {
      const {data} = await Axios.get(`/warehousestock/${id}`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setTank(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadPurchaseDetails = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines`, {productCode: tank?.productCode?._id})
        if (data?.error) {
            toast.error(data.error)
        } else {
          setListPurchaseDetails(data?.reverse())
        }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }
  
  const loadPumps = async() => {
      try {
          const {data} = await Axios.post(`/pumps`, {"wareHouseStock": id })
          if(data?.error){
              toast.error(data.error)
          } else {
            setPumps(data)
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const loadTankCheck = async() => {
    try {
        const {data} = await Axios.post(`/tank-checks`, {"wareHouseStock._id": id})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListTankCheck(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const dataDateFrom = (createdDate) => {
    if (createdDate) {
      return { createdAt: {$gt: moment(createdDate)}}
    } else {
      return {}
    }
  }

  const loadMaxReading = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/maxreads`, {$expr: {$gte: ["$createdAt", { $toDate: listTankCheck[0]?.createdAt }] }} )
      if (data?.error){
        toast.error(data.error)
      } else {
        setPumpsMaxRead(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadStockLoad = async() => {
    try {
      const {data} = await Axios.post(`/stockloads`, {"wareHouseStock._id": id, ...dataDateFrom(listTankCheck[0]?.createdAt) })
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListStockLoad(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const meterMark = async(e) => {
    e.preventDefault()
    try {
      await setPumpList([...pumpList, {pump: selectedPump, previousReading, reading, litters, testing}])
      setInputPump()
      setSelectedPump()
      setPreviousReading('')
      setReading('')
      setLitters('')
      setTesting('')
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const FIFO = async(productLines) => {
    // SBS - Stock Before Sales
    // SAS - Stock After Sales
    // PBSIndex - Purchase Index of Before Sales
    // PASIndex - Purchase Index of After Sales
    try {
      var SBS = tank?.stock
      var sumBSQty = 0
      var PBSindex = ''
      
      // FIND SBS Index
      for (var s=0; s<productLines?.length; s++) {
        sumBSQty += productLines[s]?.quantity
        if (sumBSQty >= SBS) {
          PBSindex = s
          SBS = sumBSQty - SBS
          break
        }
      }
      
      const arr = []
      var quantity = tank?.stock

      quantity > 0 && await productLines?.slice(0, PBSindex + 1)?.reverse()?.map((pl, index) => {
        const uc = (pl?.itemPrice / pl?.quantity)?.toFixed(2)
        // First Element of Array
        if(index === 0) {
          if ((pl?.quantity - SBS) >= quantity) {
            arr.push({uc, qty: quantity, check: "Same"})
          } else {
            arr.push({uc, qty: Number(pl?.quantity - SBS), check: `${index}`})
          }
        } else {
          // Balance Elements of Array
          arr.push({uc, qty: Number(pl?.quantity), check: `${index}`})
        }
      })

      arr && await setAmount(() => arr?.reduce((a,v) => a += (v?.uc * v?.qty), 0)?.toFixed(2))

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} = Axios.post(`/tank-check`, {date: gdate?.date, wareHouseStock: tank, pumpList, loadLitters})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.put(`/warehousestock/${tank?._id}`, {stock: 0})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const {data} = await Axios.post(`/accdatabulk`, [
            {date: gdate?.date, type: "debit", empStatus: {status: false}, detail: {description: `Tank Check: ${tank?.productCode?.product?.name} - ${new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format( loadLitters - (totalLitters - testLitters) )} Inventory loss`}, amount : -(amount), accounting: '63ecfbe55b36ecf83d9dd4a3'},
            {date: gdate?.date, type: "credit", empStatus: {status: false}, detail: {description: `Tank Check: ${tank?.productCode?.product?.name} - ${new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format( loadLitters - (totalLitters - testLitters) )} Inventory loss`}, amount, accounting: '64eec83c972323a31bc16e3d'}
          ])
          if (data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success("Tank Check Updated")
            window.location.reload()
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <h6 className='text-center'>Check Tank</h6>
      <h5 className='text-center'>{tank?.productCode?.product?.name}</h5>
      {pumps && pumpsMaxRead &&
        <div className='col-xxl-4 col-lg-5 col-md-8 col-sm-10 col-12 mt-3'>
          <Autocomplete
            className='mb-2'
            size='small'
            value={selectedPump?._id}
            onChange={(event, value) => {
              setSelectedPump(value)
              setReading(Number(pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead.find((i) => i?._id?.pump === value?._id)?.endReading : (listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id) ? listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id)?.reading : (pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead?.find((i) => i?._id?.pump === value?._id)?.previousReading : 0)) ))
              setTesting(Number(pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead.find((i) => i?._id?.pump === value?._id)?.testing : 0))
              setPreviousReading(listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id) ? listTankCheck[0]?.pumpList?.find((i) => i?.pump?._id === value?._id)?.reading : (pumpsMaxRead?.find((i) => i?._id?.pump === value?._id) ? pumpsMaxRead?.find((i) => i?._id?.pump === value?._id)?.previousReading : 0))
            }}
            inputValue={inputPump}
            onInputChange={(event, newInputValue) => {
              setInputPump(newInputValue);
            }}
            blurOnSelect
            options={pumps}
            renderInput={(params) => <TextField {...params} label="Pump"/>}
            getOptionLabel = {(params) => `${params.name}`}
            noOptionsText = "No Pump Found"
          />

          <Text
            label="Previous Reading"
            type = "Number"
            value = {previousReading}
            onChange={(e) => {
              setPreviousReading(e.target.value)
            }}
          />

          <Text
            label="Reading"
            type = "Number"
            value = {reading}
            onChange={(e) => {
              setReading(e.target.value)
            }}
          />

          <Text
            label="Test Litters"
            type = "Number"
            value = {testing}
            onChange={(e) => {
              setTesting(e.target.value)
            }}
            end = "L"
          />

          <div className='col-12 d-grid'>
            {selectedPump && reading ? <button style={{maxHeight: '40px'}} className='btn btn-primary' onClick={meterMark}>Mark</button> : <button  style={{maxHeight: '40px'}} className='btn btn-dark' type='submit' disabled>Mark</button>}
          </div>

        </div>
      }

        <div className='col-xxl-4 col-lg-5 col-md-8 col-sm-10 col-12 mt-3'>
          <div className='row'>
            <div className='col-5'>
              <dd className='fw-bold'>Details</dd>
              <dd>Fillup</dd>
              <dd>Test (-)</dd>
              <dd>Actual Fillup</dd>
            </div>

            <div className='col-4 text-end'>
              <dd className='fw-bold'>Litters</dd>
              <dd>{new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(totalLitters)}</dd>
              <dd>{new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(testLitters)}</dd>
              <dd>{new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(totalLitters - testLitters)}</dd>
            </div>

            <div className='col-3 text-end'>
              <dd className='fw-bold'>Load</dd>
              <dd>-</dd>
              <dd>-</dd>
              <dd>{new Intl.NumberFormat().format((totalLitters - testLitters) / tank?.productCode?.product?.type?.loadLiter)}</dd>

            </div>

            <hr/>

            <div className='col-5'>
              <dd className='fw-bold'>Actual Purchase</dd>
              <dd className='fw-bold' style={{color: 'green'}}>Differance</dd>
            </div>

            <div className='col-4 text-end'>
              <dd className='fw-bold'>{new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(loadLitters)}</dd>
              <dd className='fw-bold' style={{color: 'green'}}>{new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format( loadLitters - (totalLitters - testLitters) )}</dd>

            </div>

            <div className='col-3 text-end'>
              <dd className='fw-bold'>{new Intl.NumberFormat().format(loadLitters / tank?.productCode?.product?.type?.loadLiter)}</dd>
              <dd className='fw-bold' style={{color: 'green'}}>{new Intl.NumberFormat().format( (loadLitters - ((totalLitters - testLitters))) / tank?.productCode?.product?.type?.loadLiter)}</dd>
            </div>
          </div>

          {/* Submit Button */}
          <div className='pt-2 d-grid'>
            {<LoadingButton onClick={handleSubmit} loading={pumpList?.length === pumps?.length ? false : true} variant="contained" disabled={submitDisable}> <span>SUBMIT</span> </LoadingButton>}
          </div>
        </div>

        <div className='col-xl-10 col-12 mt-3'>
          {pumpList.length > 0 && <MUITable
            id = {(row) => row.pump._id} 
            pageSize = {4}
            columns = {[
              { field: 'pump', headerName: 'Pump', width: 180, renderCell: (i) => i.row?.pump?.name, filterable: false, sortable: false},
              { field: 'pReading', headerName: 'Previous Reading', width: 150, renderCell: (i) => new Intl.NumberFormat().format(i.row?.previousReading),  filterable: false, sortable: false},
              { field: 'cReading', headerName: 'Current Reading', width: 150, renderCell: (i) => new Intl.NumberFormat().format(i.row?.reading),  filterable: false, sortable: false},
              { field: 'litters', headerName: 'Litters', width: 120, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(i.row?.litters),  filterable: false, sortable: false},
              { field: 'testing', headerName: 'Testing L', width: 120, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(i.row?.testing),  filterable: false, sortable: false},
              { field: 'action', headerName: 'Action', width: 90,  filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
              <div className='d-flex gap-2'>
                <Clear fontSize='large' className='p-2 shadow rounded-circle'
                  onClick={() => {
                    setPumpList(pumpList.filter(c => c?.pump?._id !== i.row.pump?._id))
                    toast.success("Pump Reading Removed")
                  }}
                />
              </div>
              },
            ]}
            rows = {pumpList?.length > 0 ? pumpList : 0}
          />}
        </div>

    </div>
  )
}
