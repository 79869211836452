import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';

import { useGdate } from '../../context/date'
import { useSettings } from '../../context/settings';

import MUITable from '../../components/table/MUITable';
import LoadingButton from '@mui/lab/LoadingButton'

import { Text } from '../../components/Inputs/InputFields';

export default function EmpAccountsSummary() {
    const {id}  = useParams();

    const [settings] = useSettings()
    const [gdate] = useGdate()
    const navigate = useNavigate();
    
    const [totalAccounts, setTotalAccounts] = useState()
    const [fuelUpDetails, setFuelUpDetails] = useState('')

    const [employee] = useState(id)
    const [date, setDate] = useState(gdate?.date)
    const [cash, setCash] = useState('0')
    const [bank, setBank] = useState('0')
    const [bill, setBill] = useState('0')
    const [drawings, setDrawings] = useState('0')
    const [expenses, setExpenses] = useState('0')

    const [entryVisibility, setEntryVisibility] = useState(false)
    const [allEntires, setAllEntires] = useState()

    const [type, setType] = useState('')
    const [missExpenses, setMissExpenses] = useState(0)

    const [rowData, setRowData] = useState('')

    const [subtn, setSubtn] = useState(true)
    const [exists, setExists] = useState('')

    //Load Day Pump & Load Account Data
    useEffect(() => {
        loadDayPump()
        loadAccountsData()
    },[])

    //Update on Date Effect
    useEffect(() => {
        loadDayPump()
        loadAccountsData()
        loadDayEmployee()
        loadfuelUpFilter()
    }, [date])

    useEffect(() => {
        entryVisibility && loadAllEntries()
    }, [date && entryVisibility])

    //Calculate Total Accounts related to Employee
    useEffect(() => {
        setTotalAccounts(Number(cash) + Number(bank) + Number(bill) + Number(drawings) + Number(expenses) + Number(missExpenses))
    }, [cash, bank, bill, drawings, expenses, missExpenses])

    //Submit Status Check
    useEffect(() => {
        rowData.length > 0 && (((rowData?.reduce((a,v) =>  a = a + v.inCome , 0 )?.toFixed(2)) === totalAccounts?.toFixed(2)) ? setSubtn(false) : setSubtn(true) )
    }, [rowData, totalAccounts])

 

    //Check MissExpense account Type Credit / Debit
    useEffect(() => {
        setType(() => 
        missExpenses >= 0 ? "credit" : "debit"
      )
    },[missExpenses])

    const loadDayEmployee = async() => {
        try {
            const {data} = await Axios.post(`/dayemployees/filter`, {employee, date})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setExists(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadAccountsData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filteremployeeaccount`, {employee, date})
            if(data?.error){
                toast.log(data.error)
            } else {
                await setCash(data.find((i) => i._id.accounting === '64952f578188d2c8b9c26603') ? `${data.find((i) => i._id.accounting === '64952f578188d2c8b9c26603').total?.toFixed(2)}` : '0.00' )
                await setBank(data.find((i) => i._id.accounting === '63e31a93f029ceab39dcd281') ? `${data.find((i) => i._id.accounting === '63e31a93f029ceab39dcd281').total?.toFixed(2)}` : '0.00' )
                await setBill(data.find((i) => i._id.accounting === '63e31abdf029ceab39dcd283') ? `${data.find((i) => i._id.accounting === '63e31abdf029ceab39dcd283').total?.toFixed(2)}` : '0.00' )
                await setDrawings(data.find((i) => i._id.accounting === '63e31af4f029ceab39dcd28f') ? `${(-data.find((i) => i._id.accounting === '63e31af4f029ceab39dcd28f').total)?.toFixed(2)}` : '0.00' )
                await setExpenses(data.find((i) => i._id.accounting === '63e31b29f029ceab39dcd291') ? `${data.find((i) => i._id.accounting === '63e31b29f029ceab39dcd291').total?.toFixed(2)}` : '0.00' )
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadAllEntries = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`, {"empStatus.employee": employee, date})
            if(data?.error){
                toast.log(data.error)
            } else {
                setAllEntires(data?.filter((i) => i?.accounting?._id !== '63eb82f1661aeb9f06368651'))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadfuelUpFilter = async() => {
        try {
         const {data} = await Axios.post(`/fillupsfilterbyemployee`, {date})
         if (data?.error) {
            toast.error(data.error)
         } else {
            setFuelUpDetails(data.find((i) => i?._id?.employee === id) ? data.find((i) => i?._id?.employee === id) : '')
         }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")    
        }
    }

    const loadDayPump = async() => {
        try {
            const {data} = await Axios.post(`/daypumps/byemployee`, {employee, date})
            if (data?.error){
                toast.error(data.error)
            } else {
                setRowData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const checkMissExpense = (missExpense) => {
        if (missExpense !== 0) {
            return ([
                {date, type : type, empStatus: {status: true, employee}, detail:{description: "Miscellaneous Expenses"}, amount: Number(missExpense).toFixed(2) , accounting: "63e31b29f029ceab39dcd291"},
                {date, type : "credit", empStatus: {status: true, employee}, detail:{description: "Fuel Day Sales By Employee"}, amount: Number(totalAccounts).toFixed(2) , accounting: "63eb82f1661aeb9f06368651"}
            ])
        } else {
            return ([
                {date, type : "credit", empStatus: {status: true, employee}, detail:{description: "Fuel Day Sales By Employee"}, amount: Number(totalAccounts).toFixed(2) , accounting: "63eb82f1661aeb9f06368651"}
            ])
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubtn(true)
            const {data} = await Axios.post(`/accdataBulk`, checkMissExpense(missExpenses))
            if (data?.error) {
                toast.error(data?.error)
                setSubtn(false)
            } else {
                const {data} = await Axios.post(`/dayemployee`, {employee, date, pumpList: rowData, cash, bank, bill, drawings, expenses: String( (Number(expenses) + Number(missExpenses)).toFixed(2) )})
                if (data?.error) {
                    toast.error(data.error)
                    setSubtn(false)
                } else {
                    toast.success("Employee Day Accounts Saved")
                    navigate('/dashboard/user/employee/card')
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }

    }

  return (
    <div className='p-4 shadow' style={{borderRadius: '5px', backdropFilter: 'blur(10px)'}}>

        <div className='row'>
            {/* Top Level 1st Column */}
            <div className='col-lg-8 col-12'>
                {/* Date */}
                <div className='col-12 mt-2'>
                    <Text
                        label="Date"
                        type = 'date'
                        value = {date}
                        onChange={(e) => setDate(e.target.value)}
                        readOnly = 'true'
                    />
                </div>

                {/* Accounts Details */}
                <div className='row mt-2'>

                    {/* 1st Column */}
                    <div className='col-md-6 col-12'>
                        <Text
                            label="Cash" 
                            value = {cash}
                            readOnly = "true"
                            start = {settings?.currencyStart}
                        />

                        <Text
                            label="Bank" 
                            value = {bank}
                            readOnly = "true"
                            start = {settings?.currencyStart}
                        />

                        <Text
                            label="Debts / Bill" 
                            value = {bill}
                            readOnly = "true"
                            start = {settings?.currencyStart}
                        />

                    </div>
            
                    {/* 2nd Column */}
                    <div className='col-md-6 col-12'>
                        <Text
                            label="Drawings" 
                            value = {drawings}
                            readOnly = "true"
                            start = {settings?.currencyStart}
                        />

                        <Text
                            label="Expenses" 
                            value = {expenses}
                            readOnly = 'true'
                            start = {settings?.currencyStart}
                        />
                        
                        <Text
                            label="Miscellaneous Expenses" 
                            value = {missExpenses}
                            type = "number"
                            onChange={(e) => setMissExpenses(e.target.value)}
                            start = {settings?.currencyStart}
                        />
                    </div>

                {/* 3rd Row Pump Details Table*/}
                <div className='col-12 mt-2'>
                    {rowData && 
                        <MUITable
                            id = {(row) => row._id}
                            pageSize = {5}
                            exportDisabled = {true}
                            columns = {[
                                { field: 'name', headerName: 'Name', width: 130, renderCell: (params) => params.row.pump.name, filterable: false, sortable: false},
                                { field: 'startReading', headerName: 'Start Reading', width: 150, renderCell: (params) => `${(new Intl.NumberFormat().format(params.row.startReading))}`, filterable: false, sortable: false},
                                { field: 'endReading', headerName: 'End Reading', width: 150, renderCell: (params) => `${new Intl.NumberFormat().format(params.row.endReading)}`, filterable: false, sortable: false},
                                { field: 'soldQuantity', headerName: 'Sold L', width: 120, renderCell: (params) => `${new Intl.NumberFormat().format(params.row.soldQuantity)} L`, filterable: false, sortable: false},
                                { field: 'testing', headerName: 'Test L', width: 90, renderCell: (params) => `${new Intl.NumberFormat().format(params.row.testing)} L`, filterable: false, sortable: false},
                                { field: 'other', headerName: 'Other L', width: 90, renderCell: (params) => `${new Intl.NumberFormat().format(params.row.expenseL + params.row.drawingsL)} L`, filterable: false, sortable: false},
                                { field: 'inCome', headerName: 'In Come', width: 150, renderCell: (params) => (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(params.row.inCome)), filterable: false, sortable: false}
                            ]}
                            rows = {rowData}
                        />
                    }
                </div>

                {/* 4rd Row All Enties Details Table*/}
                {entryVisibility ?
                    <div className='col-12 mt-2'>
                        {allEntires && 
                            <MUITable
                                id = {(row) => row._id}
                                pageSize = {5}
                                exportDisabled = {true}
                                columns = {[
                                    { field: 'accounting', headerName: 'Accounts', width: 170, renderCell: (params) => params.row.accounting?.name?.toUpperCase(), valueGetter: (params) => params.row.accounting?.name},
                                    { field: 'description', headerName: 'Description', width: 300, renderCell: (params) => params.row.detail?.description, filterable: false, sortable: false},
                                    { field: 'amount', headerName: 'Amount', width: 150, renderCell: (params) => (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(params.row.amount)), filterable: false, sortable: false}
                                ]}
                                rows = {allEntires}
                            />
                        }
                    </div>
                    :
                    <div className='pt-3'> <button className='btn btn-outline-primary' onClick={() => setEntryVisibility(true)}>View All Entries</button> </div>
                }

            </div>

            </div>

            {/* Top Level 2nd Column */}
            <div className='col-lg-4 col-12 align-self-center p-3' style={{minHeight : "100%"}}>
                <div className='border p-3'>
                    <div className='d-flex justify-content-between'>
                        <dd>Daily Sales : </dd>
                        <dd>{rowData ? `${new Intl.NumberFormat().format(rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ))} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</dd>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <dd>Fill Up Amount : </dd>
                        <dd>{fuelUpDetails ? (new Intl.NumberFormat().format(fuelUpDetails?.amount) + ' ' + settings?.currencyEnd) : `0.00 ${settings?.currencyEnd}`}</dd>
                    </div>
                    <div className='d-flex justify-content-between fw-bold'>
                        <dd>Sales Differance : </dd>
                        <dd style={ rowData && fuelUpDetails && (rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ) - fuelUpDetails?.amount) >= 0 ? {color: 'greenyellow'} : {color: 'red'}}>{fuelUpDetails && rowData ? ( new Intl.NumberFormat().format(rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ) - fuelUpDetails?.amount)  + ' ' + settings?.currencyEnd ) : `0.00 ${settings?.currencyEnd}`}</dd>
                    </div>
                    <hr></hr>
                    <div className='d-flex justify-content-between'>
                        <dd>Total Points : </dd>
                        <dd>{fuelUpDetails ? new Intl.NumberFormat().format(fuelUpDetails?.points) : `0.00`}</dd>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <dd>Total Redeem : </dd>
                        <dd>{fuelUpDetails ? new Intl.NumberFormat().format(fuelUpDetails?.redeem): '0.00'}</dd>
                    </div>

                </div>

                <div className='mt-3'>
                    {rowData && <h6 className='row justify-content-center text-center' style={(rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ).toFixed(2) - totalAccounts.toFixed(2)) ? {color: 'greenyellow'} : {color: 'red'} }>Accounts Differance: <br/> {new Intl.NumberFormat().format((rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ).toFixed(2) - totalAccounts.toFixed(2)))} {settings?.currencyEnd}</h6>}
                </div>

                <div className='row justify-content-center text-center'>
                    <h6 className='row justify-content-center text-center'>Accounts Deposits</h6>
                    <h6 className='row justify-content-center fw-bold'>{totalAccounts ? `${new Intl.NumberFormat().format(totalAccounts)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</h6>
                </div>

                <div className='row justify-content-center text-center'>
                    <h6 className='row justify-content-center text-center'>Pump List Income</h6>
                    <h6 className='row justify-content-center fw-bold'>{rowData ? `${new Intl.NumberFormat().format(rowData?.reduce((a,v) =>  a = a + v.inCome , 0 ))} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</h6>
                </div>

                {/* Submit Button */}
                <div className='p-2 d-grid'>
                    {exists?.length > 0 ? <LoadingButton variant="contained" disabled> <span>Already Saved</span> </LoadingButton> : <LoadingButton onClick={handleSubmit} loading={subtn} variant="contained"> <span>SUBMIT</span> </LoadingButton>}
                </div>

            </div>


        </div>
    </div>
  )
}
