import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { Text, SelectInput } from '../../components/Inputs/InputFields';
import {MenuItem} from '@mui/material/'

import {Money, AccountBalance, AccountBalanceWallet, Lock, Receipt, CreditCard, PanTool, Payments} from '@mui/icons-material/';


export default function CreateAccounts(props) {
    const accountsData = props.data;

    const [listAccountsCategory, setlistAccountsCategory] = useState('')
    const iconList = [{id: 'money', icon: <Money/>}, {id: 'locker', icon: <Lock/>}, {id: 'bank', icon: <AccountBalance/>}, {id: 'card', icon: <CreditCard/>}, {id: 'receipt', icon: <Receipt/>}, {id: 'drawings', icon: <PanTool/>}, {id: 'payments', icon: <Payments/>}, {id: 'equity', icon: <AccountBalanceWallet/>}]


    const [name, setName] = useState(accountsData?.name)
    const [category, setCategory] = useState('')
    const [description, setDescription] = useState(accountsData?.description)
    const [icon, setIcon] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadAccountsCategories()
        setIcon(accountsData?.icon)
    },[])

    const loadAccountsCategories = async() => {
        try {
            const {data} = await Axios.get(`/acccategories`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                await setlistAccountsCategory(data)
                setCategory(accountsData?.category?._id)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/accounting/${accountsData?._id}`, {name, category, description, icon})
            if(data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                toast.success("Accounts Updated Successfully")
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center text-capitalize'>Bank Accounts</h5>
            <form onSubmit={handleSubmit}>

                <Text
                    label="Account Name" 
                    value = {name}
                    onChange={(e) => setName(e.target.value)}
                />

                <SelectInput
                    label = "Account Type"
                    value = {category}
                    onChange = {(e) => setCategory(e.target.value)}
                    items = {
                        listAccountsCategory && listAccountsCategory?.map((i) => (
                        <MenuItem value={i._id}>{i.name}</MenuItem>
                        ))
                    }
                />

                <Text
                    label="Description" 
                    value = {description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <SelectInput
                    label = "Icon"
                    value = {icon}
                    onChange = {(e) => setIcon(e.target.value)}
                    items = {
                        iconList?.map((i) => (
                        <MenuItem value={i.id}>{i.icon} | {i.id?.toUpperCase()}</MenuItem>
                        ))
                    }
                />

                <div className="d-grid gap-2">
                <button className='btn btn-primary mt-3' type='submit' disabled={submitDisable}>UPDATE ACCOUNTS</button>
                </div>

            </form>
        </div>
  )
}
