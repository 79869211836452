import React, {useRef, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../../context/settings';
import { useGdate } from '../../../context/date'
import { useDk } from '../../../context/dk'

import { Print } from '@mui/icons-material/'


import MUITable from '../../../components/table/MUITable'

export default function BankAccountsDetails() {
    const {id} = useParams()
    const [settings] = useSettings()
    const [gdate] = useGdate()
    const [dk, setDk] = useDk()

    const [allData, setAllData] = useState('')
    const [listAccData, setListAccData] = useState('')

    useEffect(() => {
        loadAccData()
      },[id])

    useEffect(() => {
        allData && filterDataByDate()
      },[allData, gdate?.reportStartDate, gdate?.reportEndDate])

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `${listAccData[0]?.detail?.bank?.bank} Bank Accounts - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        },
    })

    const bankAccounts = [
        { field: 'employee', headerName: 'Employee', width: 170, renderCell: (i) => i.row.empStatus?.employee?.name, valueGetter: (i) => i.row.empStatus?.employee?.name},
        { field: 'date', headerName: 'Date', width: 120},
        { field: 'cheque', headerName: 'Cheque Date', width: 120, renderCell: (i) => (i.row.detail?.cheque !== 'null' && i.row.detail?.cheque), valueGetter: (i) => i.row.detail?.cheque},
        { field: 'description', headerName: 'Description', width: 300, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description},
        { field: 'debit', headerName: 'Debit', width: 150, renderCell: (i) => (i.row?.amount >= 0 ? (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(Math.abs(i.row.amount))) : '' ), filterable: false},
        { field: 'credit', headerName: 'Credit', width: 150, renderCell: (i) => (i.row?.amount < 0 ? (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(Math.abs(i.row.amount))) : '' ), filterable: false},
        { field: 'effects', headerName: 'Effects', width: 150, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false}

    ]

    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`, {accounting: "63e31a93f029ceab39dcd281", "detail.bank": id})
            if(data?.error){
                toast.error(data.error)
            } else {
                var effects = 0
                await setAllData(data?.map((i) => {
                    effects += i?.amount
                    return {...i, effects: effects}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const filterDataByDate = async() => {
        try {
            setListAccData(allData?.filter((i) => i?.date >= gdate?.reportStartDate && i?.date <= gdate?.reportEndDate))
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

  return (
    <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='row mb-2'>
                    <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 mb-2'>
                        <button className = 'btn btn-warning w-25' onClick={() => {printData()}}><Print/></button>
                    </div>
                </div>

                <div ref= {componentRef} className='pt-3'>
                    {listAccData && 
                        <MUITable
                            title = {listAccData && (listAccData[0]?.detail?.bank?.bank + ' - ' + listAccData[0]?.detail?.bank?.accountNo) + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                            id = {(row) => row._id}
                            columns = {bankAccounts}
                            rows = {listAccData}
                        />
                    }
                </div>
            </div>
            
            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total B/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'credit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>Total C/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'debit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <h5>Balance</h5>
                    <h5 style={(listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )) >=0 ? {color: '#39ff14'} : {color: '#FF0000'}}>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h5>
                </div>
            </div>
        </div>
  )
}
