import React, {useRef, useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import { useReactToPrint } from 'react-to-print'

import { useAuth } from '../../context/auth'
import { useSettings } from '../../context/settings'
import { useDk } from '../../context/dk'

import {SwitchInput} from '../../components/Inputs/InputFields'
import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

import { Add, Edit, Receipt, DeleteForever, Print, Check, Clear } from '@mui/icons-material/'

import CreateSupplier from './CreateSupplier'
import UpdateSupplier from './UpdateSupplier'

import AccountsFrom from '../accounts/DoubleEntires/AccountsForm'

export default function ListSuppliers() {

  const [auth] = useAuth()
  const [settings] = useSettings()
  const [dk, setDk] = useDk()

  const navigate = useNavigate()

  const [listSuppliers, setListSuppliers] = useState('')
  const [listBalanceDetails, setListBalanceDetails] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [visibleCredit, setVisibleCredit] = useState(false)
  const [visibleDebit, setVisibleDebit] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  const [checked, setChecked] = useState(true)

  useEffect(() => {
      loadSuppliers()
      loadBalanceDetails()
  }, [])
  

  const loadSuppliers = async() => {
      try {
          const {data} = await Axios.get('/suppliers')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListSuppliers(data)
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const loadBalanceDetails = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/suppliertotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '64acdf13722d07654e219f89' } ] }, "detail.supplier" : { $exists: true} })
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListBalanceDetails(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleDeleteSupplier = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/supplier/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('supplier Successfully Deleted')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  //Print Function
  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `Bank Accounts`,
      onBeforeGetContent: async() => {
          await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
          const data = localStorage.getItem("darkmode");
          if (data) {
              const parsed = JSON.parse(data);
              setDk({...dk, darkmode: parsed});
          }
      },
  })

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            <button className = 'btn btn-warning w-50' onClick={() => {printData()}}><Print/></button>
          </div>

          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex justify-content-end gap-2 mb-2'>
            <button className = 'btn btn-outline-success w-50' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
            <button className = 'btn btn-outline-danger w-50' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>  
          </div>
        </div>

        <div ref={componentRef}>
          <div className='d-flex justify-content-end'>
            <SwitchInput
              endLabel = "Active"
              checked = {checked}
              color = 'primary'
              onChange = {(e) => {
                setChecked(preVal => !preVal)
              }}
            />
          </div>
          {listSuppliers && listBalanceDetails &&
            <MUITable
              title = "List of Suppliers"
              id = {(row) => row._id}
              columns = {[
                  { field: 'name', headerName: 'supplier Name', width: 200, hide: true},
                  { field: 'nic', headerName: 'NIC No', width: 125, hide: true },
                  { field: 'company', headerName: 'Company', width: 200},
                  { field: 'address', headerName: 'Address', width: 300},
                  { field: 'phoneNumber', headerName: 'Phone No', width: 125},
                  { field: 'active', headerName: 'Active', width: 100, renderCell: (i) => i.row.active ? <Check color='success' /> : <Clear color='error'/>, filterable: false, sortable: false},
                  { field: 'balance', headerName: 'Payable Balance', width: 170, renderCell: (i) => `${settings?.currencyStart + ' ' + new Intl.NumberFormat().format(listBalanceDetails?.find((c) => c?._id?._id === i.row._id) ? listBalanceDetails?.find((c) => c?._id?._id === i.row._id)?.total : 0) }`, filterable: false, sortable: false},
                  { field: 'totalBusiness', headerName: 'Total Credit Business', width: 170, renderCell: (i) => `${settings?.currencyStart + ' ' + new Intl.NumberFormat().format(listBalanceDetails?.find((c) => c?._id?._id === i.row._id) ? listBalanceDetails?.find((c) => c?._id?._id === i.row._id)?.sumCreditAmount : 0) }`, filterable: false, sortable: false},
                  { field: 'action', headerName: 'Action', width: 120, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                  <div className='d-flex gap-2'>
                    <Receipt fontSize='large' className='p-2 shadow rounded-circle'
                      onClick={(e) => {
                        navigate(`/dashboard/user/supplier-accounts/${i?.row?._id}`)
                      }}
                    />
                    <Edit fontSize='large' className='p-2 shadow rounded-circle'
                      onClick={() => {
                        setVisible(true)
                        setSelected(i.row)
                      }}
                    />
                    <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                      onClick={() => {
                        setVisible1(true)
                        setSelected1(i.row)
                      }}
                    />
                  </div>
                  },
              ]}
              rows = {checked ? listSuppliers?.filter((f) => f?.active === true) : listSuppliers}
            hiddenFields = {{nic: false, address: false}}

            />
          }
        </div>

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateSupplier/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateSupplier supplier = {selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between gap-2'>
                <p>Are You sure want to? On deletation selected Customer data will be erased</p>
                <div>
                  <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteSupplier(selected1._id)
                    }}>Delete
                  </button>
                </div>
              </div>
              :
              <div>
                <p className='text-center'>You don't have Permission to Delete</p>
                <p className='text-center fs-4'>Login as Admin</p>
              </div>
              
          }
        />

        <ModalBasic
          width = '80%'
          open = {visibleCredit}
          onClose = {()=> {
          setVisibleCredit(false)
          }}
          content = {<AccountsFrom data= {{inputAccounting2: '64acdf13722d07654e219f89'}}/>}
        />

        <ModalBasic
          width = '80%'
          open = {visibleDebit}
          onClose = {()=> {
          setVisibleDebit(false)
          }}
          content = {<AccountsFrom data= {{inputAccounting: '64acdf13722d07654e219f89'}}/>}
        />
      </div>
    </div>
    )
}
