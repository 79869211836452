import React, {useRef,useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import { useReactToPrint } from 'react-to-print'

import { useSettings } from '../../../context/settings'
import { useAuth } from '../../../context/auth'
import { useDk } from '../../../context/dk'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit, Receipt, Print} from '@mui/icons-material/'

import CreateBankAccount from './CreateBankAccount'
import UpdateBankAccount from './UpdateBankAccount'

import AccountsForm from '../DoubleEntires/AccountsForm'

export default function ListBankAccounts() {

    const navigate = useNavigate()
     const [settings] = useSettings()
     const [auth] = useAuth()
     const [dk, setDk] = useDk()

    const [listBanks, setListBanks] = useState('')
    const [listBalanceDetails, setListBalanceDetails] = useState('')

    //Model
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [visibleCredit, setVisibleCredit] = useState(false)
    const [visibleDebit, setVisibleDebit] = useState(false)

    useEffect(() => {
        loadBankAccounts()
        loadBalanceDetails()
    }, [])

    const loadBankAccounts = async() => {
        try {
            const {data} = await Axios.get('/bank-accounts')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListBanks(data)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    const loadBalanceDetails = async() => {
        try {
        const {data} = await Axios.post(`/accdatas/banktotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31a93f029ceab39dcd281' } ] }, "detail.bank" : { $exists: true} })
          if (data?.error) {
              toast.error(data.error)
          } else {
            console.log(data)
            setListBalanceDetails(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    //Print Function
    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Bank Accounts`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        },
    })

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='row justify-content-between mb-2'>
                    <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
                        <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
                        <button className = 'btn btn-warning w-50' onClick={() => {printData()}}><Print/></button>
                    </div>

                    <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex justify-content-end gap-2 mb-2'>
                        <button className = 'btn btn-outline-success w-50' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
                        <button className = 'btn btn-outline-danger w-50' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>  
                    </div>
                </div>

                <div ref={componentRef}>
                    {listBanks && listBalanceDetails &&
                        <MUITable
                            title = "All Banks Accounts"
                            id = {(row) => row._id}
                            columns = {[
                                { field: 'bank', headerName: 'Bank', width: 150},
                                { field: 'accountName', headerName: 'Account Name', width: 350},
                                { field: 'accountType', headerName: 'Acc Type', width: 120},
                                { field: 'accountNo', headerName: 'Account No', width: 200},
                                { field: 'branch', headerName: 'Branch', width: 150},
                                { field: 'balance', headerName: 'Balance', width: 180, renderCell: (i) => `${settings?.currencyStart + ' ' + new Intl.NumberFormat().format(listBalanceDetails?.find((c) => c?._id?._id === i.row._id) ? listBalanceDetails?.find((c) => c?._id?._id === i.row._id)?.total : 0) }`, filterable: false, sortable: false},
                                { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                                <div className='d-flex gap-2'>
                                    <Receipt fontSize='large' className='p-2 shadow rounded-circle'
                                        onClick={(e) => {
                                            navigate(`/dashboard/user/bank-accounts/${i?.row?._id}`)
                                        }}
                                    />

                                    <Edit fontSize='large' className='p-2 shadow rounded-circle'
                                        onClick={() => {
                                            setVisible(true)
                                            setSelected(i.row)
                                        }}
                                    />
                                </div>
                                },
                            ]}
                            rows = {listBanks}
                        />
                    }
                </div>

                <ModalBasic
                    open = {visibleAdd}
                    onClose = {()=> {
                        setVisibleAdd(false)
                    }}
                    content = {auth?.employee?.role === 0 ? <CreateBankAccount/> : <div className='text-center'>You don't have Permission</div>}
                />

                <ModalBasic
                    open = {visible}
                    onClose = {()=> {
                        setVisible(false)
                    }}
                    content = {<UpdateBankAccount bank = {selected}/>}
                />

                <ModalBasic
                    width = '80%'
                    open = {visibleDebit}
                    onClose = {()=> {
                    setVisibleDebit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting: '63e31a93f029ceab39dcd281'}}/>}
                />

                <ModalBasic
                    width = '80%'
                    open = {visibleCredit}
                    onClose = {()=> {
                    setVisibleCredit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting2: '63e31a93f029ceab39dcd281'}}/>}
                    
                />
            </div>
        </div>
        )
}
