import React, {useEffect, useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../../components/Inputs/InputFields'

export default function UpdateAttendance(props) {
    const attendanceData = props.data;

    const [value, setValue] = useState(
        attendanceData?.payment?.type === 'hours' && attendanceData?.hours ||
        attendanceData?.payment?.type === 'day' && attendanceData?.day ||
        attendanceData?.payment?.type === 'month' && attendanceData?.month
    )

    const [submitDisable, setSubmitDisable] = useState(false)

    const empData = (data) => {
        switch(data?.payment?.type) {
            case "hours":
                return {hours : value, salary: Number(attendanceData?.payment?.amount * value).toFixed(2)};
                break;
            case "day":
                return {day : value, salary: Number(attendanceData?.payment?.amount * value).toFixed(2)};
                break;
            case "month":
                return {month : value, salary: Number(attendanceData?.payment?.amount * value).toFixed(2)};
                break;
        }
    }
        

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/empattendance/${attendanceData._id}`, empData(attendanceData))
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                toast.success("Attendance Updated");
                window.location.reload()
            }
        } catch (err) {
            toast.error("Attendance Update Failed Try Again");
        }
    }
    
    return (
        <div className='p-3'>
            <h5 className='text-center mb-3'>Update Attendance - {attendanceData?.date}</h5>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Hr / Day / Month"
                    value = {value}
                    onChange={(e) => setValue(e.target.value)} 
                />
                <div class="d-grid gap-2">

                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE ATTENDANCE
                </button>
                </div>
            </form>
        </div>
        )
}
