import React, {useEffect, useState} from 'react'
import Axios from 'axios';
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { Send } from '@mui/icons-material/'
import {useSettings} from '../../context/settings'

export default function CustomerCredit() {
    const [settings] = useSettings()

    const [avMsg, setAvMsg] = useState(0)
    const [message, setMessage] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadData()
        loadaccData()
    },[])

    const loadData = async() => {
        try {
            const {data} = await Axios.post(`/messagelist`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                setAvMsg(data?.reduce((a,v) => a = a + (v.messageCount), 0) > 0 ? data?.reduce((a,v) => a = a + (v.messageCount), 0) : '0')
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadaccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/customertotal`, {"detail.customer" : { $exists: true}})
            if (data?.error) {
                toast.error(data.error)
            } else {
                setMessage(data?.filter((i) => i?.total > 0)?.map((i) => {
                    return {PhoneNumber : i._id[0]?.phoneNumber, 
                            SmsMessage : (
                                `${settings?.name?.toUpperCase()}` + `\n` + `\n` +
                                `Dear Customer,`  + `\n` +
                                `Pay Your outstanding bill amount ${new Intl.NumberFormat().format(i?.total)} ${settings?.currencyEnd}` + `\n` + `\n` +
                                (settings?.creditMsgPay ? 
                                    `Bank: ${settings?.creditMsgPay?.bank}` + `\n` +
                                    `ACC.Name: ${settings?.creditMsgPay?.accountName}` + `\n` +
                                    `ACC.No: ${settings?.creditMsgPay?.accountNo}` + `\n` +
                                    `Branch: ${settings?.creditMsgPay?.branch}`
                                    :
                                    ''
                                )
                                
                            )}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleSendMessage = async() => {
        try {
            await setSubmitDisable(true)
            if (avMsg > message?.length) {
                const {data} = await Axios.post(`/sendmessage`, message)
                if (data?.orderId) {
                    const smsCount = await Axios.post(`/message`, {messageCount: -(data.reportId.length), type: 'sent', listOfSent: message.map((i) => i?.PhoneNumber), date: moment(new Date()).format("YYYY-MM-DD")} )
                    if (smsCount?.data?.error) {
                        toast.success('contact Support Error Found!')
                        setSubmitDisable(false)
                    } else {
                        toast.success(`Message Sent to ${data.reportId.length} Customers`)
                        setSubmitDisable(false)
                    }
                } else {
                    toast.error('Message not sent to Any Customer')
                    setSubmitDisable(false)
                }
            } else {
                toast.error(`Recharge Your SMS Service`)
                setSubmitDisable(false)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

  return (
    <button className='btn btn-warning w-25' onClick={settings && message && handleSendMessage} disabled={submitDisable}><Send/></button>
  )
}
