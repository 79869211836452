import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectInput} from '../../components/Inputs/InputFields'
import { MenuItem } from '@mui/material';

export default function UpdatePump(props) {
    const pumpData = props.pump

    const [listTanks, setListTanks] = useState('');
    const [listBooths, setListBooths] = useState('');

    const [name, setName] = useState(pumpData?.name);
    const [wareHouseStock, setWareHouseStock] = useState(pumpData?.wareHouseStock?._id);
    const [booth, setBooth] = useState(pumpData?.booth?._id);

    const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadTanks();
    loadBooths();
  }, []);

  const loadTanks = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks/filterCategory`, {_id: "63e65efc2f840202604fbb7f"})
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListTanks(data);
      }
    } catch (err) {
      toast.error(err)
    }
  }

  const loadBooths = async() => {
    try {
      const {data} = await Axios.get(`/booths`);
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListBooths(data);
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.put(`/pump/${pumpData._id}`, {...pumpData, name, wareHouseStock, booth});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Pump sucessfully Updated");
        window.location.reload()
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Pump {name}</h4>
      <form onSubmit={handleSubmit}>
        <Text
            label="Pump Name"
            value = {name}
            onChange={(e) => setName(e.target.value)} 
        />

        {listTanks && <SelectInput
            label = "Tank"
            value = {wareHouseStock}
            onChange = {(e) => {
            setWareHouseStock(e.target.value)
            }}
            items = {
            listTanks && listTanks.map((i) => (
                <MenuItem value={i._id}>{i.productCode?.product?.name}</MenuItem>
            ))
            }
        />}

        {listBooths && <SelectInput
            label = "Booth"
            value = {booth}
            onChange = {(e) => {
            setBooth(e.target.value)
            }}
            items = {
            listBooths.map((i) => (
                <MenuItem value={i._id}>{i.name}</MenuItem>
            ))
            }
        />}

        <div class="d-grid gap-2">
            <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            UPDATE PUMP
            </button>
        </div>
      </form>
    </div>
  )
}
