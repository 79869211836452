import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectInput} from '../../components/Inputs/InputFields'
import { MenuItem } from '@mui/material'
import {Check, Clear} from '@mui/icons-material';

export default function UpdateCustomer(props) {
    const CustomerData = props.customer;

    const [name, setName] = useState(CustomerData?.name)
    const [type, setType] = useState(CustomerData?.type)
    const [nic, setNic] = useState(CustomerData?.nic)
    const [address, setAddress] = useState(CustomerData?.address)
    const [phoneNumber, setPhoneNumber] = useState(CustomerData?.phoneNumber)
    const [active, setActive] = useState(CustomerData?.active)

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/customer/${CustomerData?._id}`, { ...CustomerData, name, type, nic, address, phoneNumber, active});
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                toast.success("Customer Details sucessfully Updated");
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Customer : {name}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Customer Name"
                    value = {name}
                    onChange={(e) => setName(e.target.value)} 
                />

                <SelectInput
                    label = "Customer Type"
                    value = {type}
                    onChange = {(e) => setType(e.target.value)}
                    items = {
                    ['individual', 'government'].map((i) => (
                        <MenuItem className='text-capitalize' value={i}>{i}</MenuItem>
                    ))
                    }
                />

                <Text
                    label="NIC Number"
                    value = {nic}
                    onChange={(e) => setNic(e.target.value)}
                    end = {nic.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                />
                <Text
                    label="Address"
                    value = {address}
                    onChange={(e) => setAddress(e.target.value)} 
                />
                <Text
                    label="Phone Number"
                    type ="Number"
                    value = {phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)} 
                    end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                />
                <SelectInput
                    label = "Active"
                    value = {active}
                    defaultValue = {active}
                    onChange = {(e) => setActive(e.target.value)}
                    items = {
                        [{id: true, name: 'YES'}, {id: false, name: 'NO'}].map((i) => (
                            <MenuItem value={i?.id}>{i?.name}</MenuItem>
                        ))
                }
                />
            
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE CUSTOMER
                </button>
                </div>
            </form>
        </div>
    )
}
