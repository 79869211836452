import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'
import { useGdate } from '../../context/date'
import {useSettings} from '../../context/settings'

import {Check, DeleteForever} from '@mui/icons-material/'

import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

export default function ListDayEmployee() {
    const [auth] = useAuth()
    const [gdate] = useGdate()
    const [settings] = useSettings()

    const [listCashflowCheck, setListCashflowCheck] = useState('')

    //Model
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [listData, setListData] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)


    useEffect(() => {
        loadCashflowsCheck()
        loadData()
    }, [])

    const loadCashflowsCheck = async() => {
        try {
          const {data} = await Axios.post('/cashflows')
          if (data?.error) {
            toast.error(data?.error)
          } else {
            setListCashflowCheck(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something wernt wrong, Try Again!")
        }
    }
    
    const loadData = async() => {
        try {
            const {data} = await Axios.post(`/dayemployees`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }
 
    const handleDeleteDayEmployee = async(selected) => {
        try {
            try {
                await setSubmitDisable(true)
                const {data} = await Axios.post(`/accdatas/removemany`, {"empStatus.employee": selected?.employee?._id, "empStatus.status": true, date: selected?.date, $or: [ {accounting: '63e31b29f029ceab39dcd291'}, {accounting: '63eb82f1661aeb9f06368651'} ] })
                if (data?.error){
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    const {data} = await Axios.delete(`/dayemployee/${selected?._id}`)
                    if(data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        toast.success("Day Employee Records Deleted")
                        window.location.reload()
                    }
                }
            } catch (err) {
                console.log(err)
                toast.error("Something went wrong Try Again!")
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong Try Again!")
        }
    }

    const allAccounts = [
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'employee', headerName: 'Employee', width: 160, renderCell: (i) => `${i.row.employee?.name}`, valueGetter: (i) => `${i.row.employee?.name}`},
        { field: 'cash', headerName: 'Cash', width: 140, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.cash)}`, filterable: false,sortable: false},
        { field: 'bank', headerName: 'Bank / Card Pays', width: 140, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.bank)}`, filterable: false, sortable: false},
        { field: 'bill', headerName: 'Bill', width: 140, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.bill)}`, filterable: false, sortable: false},
        { field: 'drawings', headerName: 'Drawings', width: 125, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.drawings)}`, filterable: false, sortable: false},
        { field: 'expenses', headerName: 'Expenses', width: 125, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.expenses)}`, filterable: false, sortable: false},
        { field: 'pumpsList', headerName: 'No of Pumps', width: 120, renderCell: (i) => ` ${i.row?.pumpList?.length}`, filterable: false, sortable: false},
        { field: 'action', headerName: 'Action', width: 70, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
            <div className='d-flex gap-2'>
                {listCashflowCheck && listCashflowCheck?.find((c) => c?.date === i.row.date) ? <Check fontSize='large' className='p-2 shadow rounded-circle' color='success'/>
                :
                <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                    setVisible(true)
                    setSelected(i.row)
                    }}
                />}
            </div>
        },
    ]

    return (
        <div className='row justify-content-center'>
            {/* <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                <h5>Daily Employee Details</h5>
                <p>{gdate?.reportStartDate + ' - ' + gdate?.reportEndDate} </p>
            </div> */}
            <div className='col-12 mb-3'>
                {listData &&
                    <MUITable
                        title = {'Daily Employee Details | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                        id = {(row) => row._id}
                        columns = {allAccounts}
                        rows = {listData}
                    />
                }
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total Fuel Sales By Employees</h6>
                    <h6>{(new Intl.NumberFormat().format((listData && listData?.reduce((a,v) =>  a = a + v?.cash + v?.bank + v?.bill + v?.drawings + v?.expenses , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
            </div>

            <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {
                    auth?.employee?.role === 0 ?
                    <div>
                        <p>If Delete, Employee Sales, Expense Accounts will be Deleted?</p>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                                handleDeleteDayEmployee(selected)
                                }}>Delete
                            </button>
                        </div>
  
                    </div>
                    :
                    <div className='d-flex justify-content-center w-100'>
                        <p>You don't have Permission to Delete</p>
                    </div>
                    
                }
            />
        </div>
    )
}
