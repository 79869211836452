import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {useAuth} from '../../context/auth'

import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

import {Add, Edit, DeleteForever} from '@mui/icons-material/'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarcode} from '@fortawesome/free-solid-svg-icons'

import Category from './Category/Category'
import CreateProduct from './CreateProduct'
import UpdateProduct from './UpdateProduct'
import ListProductCode from './ProductCodes/ListProductCode'

export default function ListProducts() {

  const navigate = useNavigate()
  const [auth] = useAuth()

  const [listProducts, setListProducts] = useState('')

  //Model
  const [visibleAddProduct, setVisibleAddProduct] = useState(false)
  const [visibleAddCategory, setVisibleAddCategory] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visibleDelete, setVisibleDelete] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)


  useEffect(() => {
      loadProducts()
  }, [])

  const loadProducts = async() => {
      try {
          const {data} = await Axios.get('/products')
          if(data?.error){
              toast.error(data.error)
          } else {
            setListProducts(data.filter((i) => i?.category?._id !== '63e65efc2f840202604fbb7f'))
          }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  const handleDelete = async(selected) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post('/productcodes', {product: selected?._id})
      if(data?.error){
        toast.error(data.error)
        await setSubmitDisable(false)
      } else {
        if(data?.length > 0) {
          toast.error("Product Stock Added. So, can't Delete")
        } else {
          const {data} = await Axios.delete(`/product/${selected?._id}`)
          if(data?.error){
            toast.error(data.error)
            await setSubmitDisable(false)
          } else {
            toast.success("Product Deleted Successfully")
            window.location.reload()
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-lg-12 col-12 mb-3'>
        {auth?.employee?.role === 0 &&
          <div className='d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAddProduct(true)}}><Add/> PRODUCT</button>
            <button className = 'btn btn-outline-primary' onClick={() => {navigate(`/dashboard/user/admin/inventory/brands`)}}>BRAND</button>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAddCategory(true)}}><Add/> CATEGORY</button>
          </div>
        }
        {listProducts && 
          <MUITable
            title = "List of Products"
            id = {(row) => row._id}
            columns = {[
                { field: 'name', headerName: 'Name', width: 300},
                { field: 'category', headerName: 'Category', width: 180, renderCell: (i) => i.row.category?.name, valueGetter: (i) => i.row.category?.name},
                { field: 'brand', headerName: 'Brand', width: 180, renderCell: (i) => i.row.brand?.name, valueGetter: (i) => i.row.brand?.name},
                { field: 'unit', headerName: 'Unit', width: 100},
                { field: 'action', headerName: 'Action', width: 150, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                  <FontAwesomeIcon icon={faBarcode} className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible1(true)
                      setSelected1(i.row)
                    }}
                  />
                  <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisibleDelete(true)
                      setSelectedDelete(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listProducts}
          />
        }

        <ModalBasic
          open = {visibleAddProduct}
          onClose = {()=> {
          setVisibleAddProduct(false)
          }}
          content = {<CreateProduct/>}
        />

        <ModalBasic
          open = {visibleAddCategory}
          onClose = {()=> {
          setVisibleAddCategory(false)
          }}
          content = {<Category/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {auth?.employee?.role === 0 ? <UpdateProduct data={selected}/> :               
            <div className='d-flex justify-content-center w-100'>
              <p>You don't have Permission to Edit</p>
            </div>}
        />

        <ModalBasic
          open = {visibleDelete}
          onClose = {()=> {
          setVisibleDelete(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
            <div className='d-flex justify-content-between'>
              <p>Stock and Product Code details related to this product will be Deleted</p>
              <div className='d-flex align-items-center'>
                <button className = 'btn btn-danger' disabled={submitDisable} onClick={() => {
                    handleDelete(selectedDelete)
                  }}>Delete
                </button>
              </div>
            </div>
            :
            <div className='d-flex justify-content-center w-100'>
              <p>You don't have Permission to Delete</p>
            </div>}
        />

        <ModalBasic
          width = '600px'
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {<ListProductCode product = {selected1}/> }
          
        />
      </div>
    </div>
  )
}