import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {Print, Fullscreen} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper, Skeleton} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import { useGdate } from '../../../../context/date'
import { useDk } from '../../../../context/dk'

import IncomeStatement from '../IncomeStatements/IncomeStatement'

export default function FinancialStatement() {
  const navigate = useNavigate()

  const [gdate] = useGdate()
  const [dk, setDk] = useDk()

  const [fuelStockValue, setFuelStockValue] = useState('')
  const [inventoryStockValue, setInventoryStockValue] = useState('')

  const [assetsAccounts, setAssetsAccounts] = useState('')
  const [equityAccounts, setEquityAccounts] = useState('')
  const [liabilityAccounts, setLiabilityAccounts] = useState('')

  const [netProfit, setNetProfit] = useState('')

  const [totalAssets, setTotalAssets] = useState('')
  const [totalEquity, setTotalEquity] = useState('')
  const [totalELP, setTotalELP] = useState('')

  const [PrintIcon, setPrintIcon] = useState(true)

  useEffect(() => {
    loadAccounts()
  },[])

  //TOTAL ASSETS
  useEffect(() => {
    assetsAccounts && setTotalAssets( (assetsAccounts ? assetsAccounts?.reduce((a,v) => a + v?.total, 0) : 0) + fuelStockValue + inventoryStockValue)
  },[assetsAccounts, fuelStockValue, inventoryStockValue])

  //TOTAL EQUITY
  useEffect(() => {
    setTotalEquity((equityAccounts ? equityAccounts?.reduce((a,v) => a + v?.total, 0) : 0) + netProfit)
  }, [totalEquity, netProfit])

  //TOTAL ELP
  useEffect(() => {
    // liabilityAccounts && totalEquity && setTotalELP( ( liabilityAccounts?.reduce((a,v) => a + v?.total, 0) ? liabilityAccounts?.reduce((a,v) => a + v?.total, 0) : 0 ) + totalEquity)
    setTotalELP( ( liabilityAccounts ? liabilityAccounts?.reduce((a,v) => a + v?.total, 0) : 0 ) + totalEquity)
  },[liabilityAccounts, totalEquity, netProfit])

  const loadAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`)
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setAssetsAccounts(data?.filter((i) => i._id.accounting[0]?.category === '63dca26a06b9cdaae9c5c8f9') ? data?.filter((i) => i._id.accounting[0]?.category === '63dca26a06b9cdaae9c5c8f9') : '' )
        await setEquityAccounts(data?.filter((i) => i._id.accounting[0]?.category === '63dca29706b9cdaae9c5c902') ? data?.filter((i) => i._id.accounting[0]?.category === '63dca29706b9cdaae9c5c902') : '' )
        await setLiabilityAccounts(data?.filter((i) => i._id.accounting[0]?.category === '63dca28d06b9cdaae9c5c8ff') ? data?.filter((i) => i._id.accounting[0]?.category === '63dca28d06b9cdaae9c5c8ff') : '' )
        
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getIncomeStatementDetails = (data) => {
    setNetProfit(data?.netProfit)
    setFuelStockValue(data?.fuelStockValue)
    setInventoryStockValue(data?.inventoryStockValue)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const componentRef2 = useRef();
  const printData2 = useReactToPrint({
      content : () => componentRef2.current,
      documentTitle: `All Time Balance Sheet`,
      onBeforeGetContent: async() => {
        setPrintIcon(false)
        await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
        setPrintIcon(true)
        const data = localStorage.getItem("darkmode");
        if (data) {
            const parsed = JSON.parse(data);
            setDk({...dk, darkmode: parsed});
        }
      },
  })

  return (
    <div className='row justify-content-center'>
      <p className='text-center fs-5 mt-3' style={{fontFamily: 'TirantiSolidLET'}}>All Records upto {moment(new Date()).format("MMMM Do YYYY")}</p>

      {/* INCOME STATEMENT */}
      <div className='col-lg-6 col-12'>
        <IncomeStatement getIncomeStatementDetails = {getIncomeStatementDetails}/>
      </div>

      {/* BALANCE SHEET */}
      <div className='col-lg-6 col-12'>
        <div className='row justify-content-center mt-2' ref={componentRef2}>
          <div className='d-flex justify-content-center gap-2 align-items-baseline'>
            <h5>BALANCE SHEET</h5>
            {PrintIcon && <Print fontSize='small' onClick={() => {printData2()}}/>}
            {PrintIcon && <Fullscreen fontSize='small' onClick={() => {navigate('/dashboard/user/accounts/financialstatement-detail')}}/>}
          </div>
        
          <div style={{overflowX: 'auto'}}>
            <Table component={Paper} size='small'>
              <TableHead>
                <TableRow>
                    <StyledTableCell className='w-75'>Accounts Details</StyledTableCell>
                    <StyledTableCell className='w-75' align="right">Amount</StyledTableCell>
                    <StyledTableCell align="right">Amount</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* ASSETS */}
                <TableRow key='assets'>
                  <TableCell className='fw-bold'>ASSETS</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>

                <TableRow key='fuel-stocks' >
                  <TableCell>Fuel Stock Value</TableCell>
                  <TableCell align="right">{ fuelStockValue ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(fuelStockValue) : <Skeleton animation="wave"/>}</TableCell>
                  <TableCell className='fw-bold' align="right"></TableCell>
                </TableRow>

                <TableRow key='inventory-stock' >
                  <TableCell>Inventory Stock Value</TableCell>
                  <TableCell align="right">{inventoryStockValue ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventoryStockValue) : <Skeleton animation="wave"/>}</TableCell>
                  <TableCell className='fw-bold' align="right"></TableCell>
                </TableRow>

                {assetsAccounts && assetsAccounts?.map((i) =>
                    <TableRow key={i?._id?.accounting[0]?._id}>
                        <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                        <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                )}

                <TableRow key='assetsTotal' style={Math.floor(totalELP) === Math.floor(totalAssets) ? {backgroundColor: 'greenyellow'} : {backgroundColor: 'red'} }>
                    <TableCell className='fw-bold fs-6 text-dark text-end'>Total Assets</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell className='fw-bold fs-6 text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalAssets)}</TableCell>
                </TableRow>

                {/* LIABILITY */}
                <TableRow key='liability'>
                    <TableCell className='fw-bold'>LIABILITIES</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>

                {liabilityAccounts && liabilityAccounts?.map((i) =>
                  <TableRow key={i?._id?.accounting[0]?._id}>
                    <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                    <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                )}

                <TableRow key='liabilityTotal' style={{backgroundColor: 'grey'}}>
                  <TableCell className='fw-bold text-dark text-end'>Total Liabilities</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell className='fw-bold text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(liabilityAccounts && liabilityAccounts?.reduce((a,v) => a + v?.total, 0))}</TableCell>
                </TableRow>

                  
                {/* EQUITY */}
                <TableRow key='equity'>
                  <TableCell className='fw-bold'>EQUITY</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>

                <TableRow key='income'>
                  <TableCell>Income | income Statement</TableCell>
                  <TableCell align="right">{netProfit ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(netProfit) : <Skeleton animation="wave"/>}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>

                {equityAccounts && equityAccounts?.map((i) =>
                  <TableRow key={i?._id?.accounting[0]?._id}>
                    <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                    <TableCell align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0)}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                )}

                <TableRow key='equityTotal' style={{backgroundColor: 'grey'}}>
                  <TableCell className='fw-bold text-dark text-end'>Total Equity</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell className='fw-bold text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalEquity)}</TableCell>
                </TableRow>

                <TableRow key='equityLiabilityTotal' style={Math.floor(totalELP) === Math.floor(totalAssets) ? {backgroundColor: 'greenyellow'} : {backgroundColor: 'red'} }>
                  <TableCell className='fw-bold fs-6 text-dark text-end'>Total Equity + Liabilities</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell className='fw-bold fs-6 text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalELP)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}
