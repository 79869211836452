import React, {useState, useEffect} from 'react'
import { useAuth } from '../context/auth';
import { Outlet } from 'react-router-dom'
import Loading from './Loading'
import Axios from 'axios'

export default function PrivateRoute() {
    //contexts
    const [auth] = useAuth();

    //state
    const [ok, setOk] = useState(false);

    useEffect(() => {
      authCheck();
    }, [auth?.token]);
    

    const authCheck = async () => {
      const { data } = await Axios.get(`/auth-check`);
      if (data.ok) {
          setOk(true);
        } else {
          setOk(false);
        }     
    };
    
  return ok ? <Outlet/> : <Loading/>
}
