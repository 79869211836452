import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { MenuItem } from '@mui/material'
import { Text, SelectInput } from '../../components/Inputs/InputFields'

import {Check, Clear} from '@mui/icons-material';

export default function CreateCustomer() {

  const [name, setName] = useState("")
  const [type, setType] = useState('')
  const [nic, setNic] = useState('')
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
        const {data} = await Axios.post(`/customer`, {name, type, nic, address, phoneNumber});
        if(data?.error) {
          toast.error(data.error);
          setSubmitDisable(false)
        } else {
          toast.success("Customer sucessfully Created");
          window.location.reload()
        }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
      
    }
  }
  
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Add Customer</h4>
        <form onSubmit={handleSubmit}>
          <Text
            label="Name"
            value = {name}
            onChange={(e) => setName(e.target.value)}
            focus = 'true' 
          />

          <SelectInput
            label = "Customer Type"
            value = {type}
            onChange = {(e) => setType(e.target.value)}
            items = {
              ['individual', 'government'].map((i) => (
                <MenuItem className='text-capitalize' value={i}>{i}</MenuItem>
              ))
            }
          />

          <Text
            label="NIC Number"
            value = {nic}
            onChange={(e) => setNic(e.target.value)}
            end = {nic.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
          />

          <Text
            label="Address"
            value = {address}
            onChange={(e) => setAddress(e.target.value)} 
          />

          <Text
            label="Phone Number"
            type ="Number"
            value = {phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)} 
            end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
          />

          <div class="d-grid gap-2">
            <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
              ADD CUSTOMER
            </button>
          </div>
        </form>
    </div>
  )
}
