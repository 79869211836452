import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth';

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import { Add, Edit, DeleteForever } from '@mui/icons-material/'

import CreatePointsCustomer from './CreatePointsCustomer'
import UpdatePointsCustomer from './UpdatePointsCustomer'

export default function ListPointsCustomers() {
  const [auth] = useAuth();

  const [listCustomers, setListCustomers] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visibleDeleteModel, setVisibleDeleteModel] = useState(false)
  const [selectedDeleteModel, setSelectedDeleteModel] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadCustomers()
  }, [])
  
  const loadCustomers = async() => {
      try {
          const {data} = await Axios.get('/pointscustomers')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListCustomers(data)
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const handleDeleteCustomer = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/pc-vehicle/removemany`, {customer: id})
      if (data?.error) {
        toast.error(data?.error)
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.delete(`/pointscustomer/${id}`)
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          toast.success('Customer Successfully Deleted')
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-sm-6 col-12 d-flex gap-2 mb-2'>
              <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>
      </div>
        {listCustomers && 
          <MUITable
            title = "List of Points Customers"
            id = {(row) => row._id}
            columns = {[
                { field: 'name', headerName: 'Customer Name', width: 200},
                { field: 'nic', headerName: 'NIC No', width: 125},
                { field: 'phoneNumber', headerName: 'Phone No', width: 125},
                { field: 'address', headerName: 'Address', width: 250},
                { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                  <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisibleDeleteModel(true)
                      setSelectedDeleteModel(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listCustomers}
          />
        }

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreatePointsCustomer/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdatePointsCustomer customer = {selected}/>}
        />

        <ModalBasic
          open = {visibleDeleteModel}
          onClose = {()=> {
          setVisibleDeleteModel(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Vehicles of this customer will be deleted</p>
                <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteCustomer(selectedDeleteModel._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />
      </div>
    </div>
  )
}
