import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../context/auth'
import { useGdate } from '../../context/date'

import {Edit, AccountBalanceWallet, Person } from '@mui/icons-material/'

import {Verified} from '@mui/icons-material/'
import { Card } from 'antd'

import ModalBasic from '../../components/Modal/ModalBasic'

import CardBg from '../../Assets/images/employeeCard-bg.svg'
import EmpAccForm from '../accounts/EmpAccForm'

export default function EmployeeCard() {
  const [auth] = useAuth();
  const [gdate] = useGdate()

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)

  //Model
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [listPumpEmployee, setListPumpEmployee] = useState('')
  const [employeeAccEntries, setEmployeeAccEntries] = useState('')
  const [dayEmployees, setDayEmployees] = useState('')

  useEffect(() => {
    loadPumpEmployees()
    loadAccountsData()
    loadDayEmployees()
  }, [gdate?.date])

  const loadPumpEmployees = async() => {
    try {
      const {data} = await Axios.post('/daypumps/dayemployees', {date: gdate?.date } )
      if (data?.error){
        toast.error(data?.error)
      } else {
        setListPumpEmployee(data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")

    }
  }

  const loadAccountsData = async() => {
    try {
        const {data} = await Axios.post(`/accdatas/filteremployeeaccount`,{date: gdate?.date})
        if(data?.error){
            toast.log(data.error)
        } else {
          setEmployeeAccEntries(data?.filter((i) => i?._id?.accounting !== "63eb82f1661aeb9f06368651"))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadDayEmployees = async() => {
    try {
        const {data} = await Axios.post(`/dayemployees`, {date: gdate?.date})
        if(data?.error){
            toast.error(data.error)
        } else {
            setDayEmployees(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const dayEntriesBalance = (pumpEmployee) => {
    return (pumpEmployee?.total?.toFixed(2) - employeeAccEntries?.filter((e) => e?._id?.employee === pumpEmployee?._id?._id)?.reduce((a,v) => a = a + v?.total,0)?.toFixed(2) )
  }

  return (
    <div className='row shadow p-2'>
      {listPumpEmployee.length === 0 ?
        <div>
          <h6 className='text-center'>No Pumps Marked For Day You have Selected ({gdate?.date})</h6>
          <h5 className='text-center'>Mark the Pumps ! So, Employee will be shown</h5>
        </div> 
        : ''}
      {listPumpEmployee && listPumpEmployee?.map((i)=> (
        <div key={i._id?._id} className='col-xxl-3 col-lg-4 col-md-6 col-12 p-2'> 
          <Card
            hoverable
            className='align-items-center'
            style={{
              backgroundImage:  (`url(${CardBg})`),
              backgroundSize: 'cover'
            }}
            loading={loading}
          >
            <div className='position-relative'>
              <div className='position-absolute' style={{opacity: '0.5', bottom: '0%', left: '0%', zIndex: 'inherit' }}>
                <Person style={{fontSize: 100}}/>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='position-relative'>
                  <div className='d-flex gap-1'>
                    <Edit fontSize='large' className='p-2 shadow rounded-circle'
                      style={{backgroundColor: 'rgba(250,250,250,0.7)'}} 
                      onClick={()=> {
                        navigate(`/dashboard/user/employee/${i._id?._id}`)
                      }}
                    />
                    {dayEmployees && dayEmployees?.find((d) => d?.employee?._id === i?._id?._id) ? '' : <AccountBalanceWallet fontSize='large' className='p-2 shadow rounded-circle'
                      style={{backgroundColor: 'rgba(250,250,250,0.7)'}}
                      onClick={() => {
                        setVisible(true)
                        setSelected(i)
                      }}
                    />}
                  </div>
                </div>

                <div style={{width: '100%'}}>
                  <div className='text-end'>
                    <dd className='text-capitalize fw-bold' style={{color: "black"}}>Phone No <br></br>{i?._id?.phoneNumber?.slice(0,3) + ' ' + i?._id?.phoneNumber?.slice(3,6) + ' ' + i?._id?.phoneNumber?.slice(6,11)}</dd>
                    {dayEmployees && dayEmployees?.find((d) => d?.employee?._id === i?._id?._id) ? 
                      <Verified className='text-success mb-3' fontSize='large' alt= 'Marked'/> :
                      <div>
                        <dd className='text-uppercase fw-bold mb-0'>Entry Differance</dd>
                        {employeeAccEntries ?
                          <dd className='text-uppercase fs-5 fw-bold' style={{color: dayEntriesBalance(i) === 0 ? 'green' : 'red'}}>
                            {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(dayEntriesBalance(i))}
                          </dd>
                          :
                          "Loading..."
                        }
                      </div>
                    }
                  </div>
                </div>

              </div>

            </div>
            <div className='border border-dark p-2' style={{backgroundImage: 'linear-gradient(122deg, rgba(43,23,55,0.79) 0%, rgba(55,05,90,0.6) 50%, rgba(43,23,55,0.79) 100%)', backdropFilter: 'blur(3px)', borderRadius: '5px'}}>
              <div className='text-uppercase text-center justify-content-center fs-6 fw-bold' style={{color: "white"}}>{i?._id?.name}</div>
            </div>
          </Card>
        </div>
        ))}

      <ModalBasic
        open = {visible}
        onClose = {()=> {
          setVisible(false)
        }}
        content = {<EmpAccForm employee = {selected?._id} dayEntriesBalance = {employeeAccEntries && dayEntriesBalance(selected)} passDate = {gdate?.date}/>}
      />

    </div>
  )
}