import React from 'react'

export default function PageNotFound() {
    return(
      <div className='d-flex justify-content-center align-items-center'>
        <div className='col-sm-6 col-12 p-3'>
          <p className='text-center justify-content-center fs-2 fw-bold'>404 | Page Not Found</p>
        </div>
      </div>

  )
}
