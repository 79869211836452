import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

import {useSettings} from '../../../context/settings'
import { useGdate } from '../../../context/date'

import { SelectInput, Text } from '../../../components/Inputs/InputFields'
import {TextField, Autocomplete, Button, MenuItem, Paper} from '@mui/material';
import {Clear} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import Scanner from '../../../components/Scan-Qr/Scanner'
import ReturnsReceipt from './Receipts/ReturnsReceipt'
import ReturnsReceiptV2 from './Receipts/ReturnsReceiptV2'

export default function ReturnsForm() {
  const [settings] = useSettings()
  const [gdate] = useGdate()

  const [listOrders, setlistOrders] = useState('')
  const [listWareHouses, setListWareHouses] = useState('')

  const [state, setState] = useState(true)

  const [selectInvoiceVal, setSelectInvoiceVal] = useState('')
  const [invoiceInputValue, setInvoiceInputValue] = useState('')
  
  const [invoiceNo, setInvoiceNo] = useState(0)
  const [listProducts, setListProducts] = useState('')

  const [selectProductVal, setSelectProductVal] = useState('')
  const [productInputValue, setProductInputValue] = useState('')

  const [quantity, setQuantity] = useState()

  const [orderDate, setOrderDate] = useState('')
  const [billTotal, setBillTotal] = useState(0)

  const [items, setItems] = useState([])
  const [returnDate] = useState(gdate?.date)
  const [payment, setPayment] = useState("64952f578188d2c8b9c26603")
  const [returnValue, setReturnValue] = useState('')

  const [warehouse, setWarehouse] = useState('')

  const [visibleScanner, setVisibleScanner] = useState(false)
  const [visible, setVisible] = useState(false)

  const productFocusRef = useRef()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadOrders()
    loadWareHouses()
  },[])

  useEffect(() => {
    setItems('')
    selectInvoiceVal && loadProductLines()
    setInvoiceNo(selectInvoiceVal?.invoiceNo)
    setOrderDate(selectInvoiceVal?.orderDate)
    setBillTotal(selectInvoiceVal?.total)
    selectInvoiceVal?.payment?._id === "63e31abdf029ceab39dcd283" ? setPayment('63e31abdf029ceab39dcd283') : setPayment("64952f578188d2c8b9c26603")
  },[selectInvoiceVal])

  useEffect(() => {
    items && setReturnValue(items?.reduce((t,i) => t = t + Number((i.productLine?.productStock?.productCode?.price -  i?.productLine?.unitDiscount ) * i?.quantity), 0)?.toFixed(2))
  },[items])

  const loadWareHouses = async() => {
    try {
      const {data} = await Axios.get('/warehouses')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWareHouses(data)
        setWarehouse('63f48039710db4fab3a62abb')
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadOrders = async() => {
    try {
      const {data} = await Axios.get(`/orders`)
      if (data?.error){
        toast.error(data.error)
      } else {
        setlistOrders(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong!, Try Again")
    }
  }

  const loadProductLines = async() => {
    try {
      const {data} = await Axios.post(`/productlinebyorder`, {order: selectInvoiceVal._id})
      if(data?.error) {
        toast.error(data.error)
      } else {
        setListProducts(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong! Try Again")
    }
  } 

  const getBarcodeData = (data) => {
    setInvoiceInputValue(data?.result?.decodedText)
    if (selectInvoiceVal.invoiceNo !== data?.result?.decodedText) {
      listOrders && setSelectInvoiceVal((listOrders.find((i) => data?.result?.decodedText === i.invoiceNo)) ? (listOrders.find((i) => data?.result?.decodedText === i.invoiceNo)) : '')
    }
    setVisibleScanner(false)
    data?.result?.decodedText && setState(false)
  }

  const productAdd = (e) => {
    e.preventDefault()
    try {
      if(quantity <= selectProductVal?.quantity) {
        setItems([...items, {productLine: selectProductVal, quantity}])
        setQuantity('')
        setProductInputValue('')
        setSelectProductVal('')
        productFocusRef?.current?.firstChild?.focus();
      }
      else {
        toast.error(`${selectProductVal?.quantity + ' ' + selectProductVal?.productStock?.productCode?.product?.unit} Ordered`)
        setQuantity(1)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong!, Try Again")
    }
  }

  const orderSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/order-return`, {order: selectInvoiceVal?._id, returnDate, total: Number(returnValue), payment, items, warehouse})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const data1 = await Axios.post(`/accdata`, {date: moment(returnDate).format("YYYY-MM-DD"), type : "debit", empStatus: {status: false}, detail:{description: `Return Order No: ${invoiceNo}`}, amount: Number(-returnValue).toFixed(2) , accounting: "63eb82f1661aeb9f06368651"})
          const data2 = await Axios.post(`/accdata`, {date: moment(returnDate).format("YYYY-MM-DD"), type : "debit", empStatus: {status: false}, detail:{description: `Return Order No: ${invoiceNo}`, customer: selectInvoiceVal?.customer?._id, invoiceNo}, amount: Number(-returnValue).toFixed(2) , accounting: payment})
          if(data1?.data?.error || data2?.data?.error){
            toast.error(data1?.data?.error || data2?.data?.error)
            setSubmitDisable(false)
          } else {
            toast.success("Sales Returns Updated")
            setVisible(true)
          }
        }

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong!, Try Again")
    }
  }

  return (
    <div className='row'>
      <div className='col-xxl-8 col-12 p-2'>
        {/* FIND ORDER */}
        <div className='row mb-2'>
          <div className='col-md-10 col-9'>
            {state &&
            <Autocomplete
              size='small'
              value={selectInvoiceVal?._id}
              onChange={(event, value) => {
                setSelectInvoiceVal(value)
              }}
              inputValue={invoiceInputValue}
              onInputChange={(event, newInputValue) => {
                setInvoiceInputValue(newInputValue)
              }}
              options={listOrders}
              renderInput={(params) => <TextField {...params} label="Invoice No" autoFocus/>}
              getOptionLabel = {(params) => `${params?.invoiceNo}`}
              noOptionsText = "No Order Found"
            />}
          </div>

          <div className='col-md-2 col-3 d-grid'>          
            <Button style={{maxHeight: '40px'}} variant="outlined" color='inherit' onClick={() => {setVisibleScanner(true)}}>
              <FontAwesomeIcon icon={faBarcode}/>
            </Button>
          </div>
        </div>

        {/* RETURN PRODUCTS FORM */}
        {selectInvoiceVal && 
          <form className='row' onSubmit={productAdd}>
            <div className='col-md-6 col-12 mb-2'>
              <Autocomplete
                style={{minWidth: '50%'}}
                size='small'
                value={selectProductVal?.productCodeData}
                onChange={(event, value) => {
                  setSelectProductVal(value)
                  setQuantity(value?.quantity)
                }}
                inputValue={productInputValue}
                onInputChange={(event, newInputValue) => {
                  setProductInputValue(newInputValue)
                }}
                options={listProducts}
                renderInput={(params) => <TextField {...params} label="Product" autoFocus ref={productFocusRef}/>}
                getOptionLabel = {(params) => `${params?.productStock?.productCode?.productCode + ' | '+ params.productStock?.productCode?.product?.name + ' | '+ new Intl.NumberFormat().format(params?.quantity) + ' ' + params.productStock?.productCode?.product?.unit}`}
                noOptionsText = "No Product Found"
              />                
            </div>

            <div className='col-md-4 col-12'>
              <Text
                label="Quantity"
                type="Number"
                value = {quantity}
                defaultValue = {0}
                onChange={(e) => setQuantity(Number(e.target.value))}
              />
            </div>

            <div className='col-md-2 col-12 d-grid'>
              {selectProductVal && quantity ? <button className='btn btn-primary' style={{maxHeight: '40px'}} variant="contained" type='submit'>RETURN</button> : <button className='btn btn-dark' style={{maxHeight: '40px'}} variant="contained" type='submit' disabled>RETURN</button>}          
            </div>

          </form>}

        {/* RETUNED PRODUCTS TABLE */}
        <div className='col-12 mt-2'>
          <MUITable
            id = {(row) => row.productLine?._id}
            pageSize = {5}
            columns = {[
                { field: 'item', headerName: 'Item', width: 200, renderCell: (i) => i.row?.productLine?.productStock?.productCode?.product?.name, filterable: false, sortable: false},
                { field: 'quantity', headerName: 'Qty', width: 90, renderCell: (i) => new Intl.NumberFormat().format(i.row.quantity), filterable: false, sortable: false},
                { field: 'unitPrice', headerName: 'Unit Price', width: 150, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row?.productLine?.productStock?.productCode?.price)}`, filterable: false, sortable: false},
                { field: 'discount', headerName: 'Discount', width: 120, renderCell: (i) => `${settings?.currencyStart} ${i.row.productLine.unitDiscount ? new Intl.NumberFormat().format(i.row.productLine?.unitDiscount * i.row.quantity) : 0.00}`, filterable: false, sortable: false},
                { field: 'price', headerName: 'Price', width: 150, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format((i.row?.productLine?.productStock?.productCode?.price - i.row?.productLine?.unitDiscount ) * i.row?.quantity)}`, filterable: false, sortable: false},
                { field: 'action', headerName: 'Action', width: 150, filterable: false, sortable: false, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Clear fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setItems(items?.filter((c) => c?.productLine?._id !== i.row?.productLine?._id))
                      toast.success("Product Removed")
                      productFocusRef?.current?.firstChild?.focus();
                    }}
                  />
                </div>
                },
            ]}
            rows = {items}
          />
        </div>
      </div>

      {/* ORDER SUMMARY */}
      <div className='col-xxl-4 col-12 p-2 d-flex justify-content-center'>
        <Paper elevation={5} className='p-5' style={{minWidth: 400}}>
          <h4 className='text-center pb-3'>Sales Return Summary</h4>
          <div className='d-flex justify-content-between'><p>Invoice No : </p> <p>{invoiceNo}</p></div>
          <div className='d-flex justify-content-between'><p>Order Date : </p> <p>{orderDate}</p></div>
          <div className='d-flex justify-content-between'><p>Return Date : </p> <p>{moment(new Date()).format("YYYY-MM-DD hh:mm A")}</p></div>
          <div className='d-flex justify-content-between'><p>No of Item Returns : </p> <p>{items.length}</p></div>
          <div className='d-flex justify-content-between'><p>Billed Total : </p> <p>{selectInvoiceVal ? `${new Intl.NumberFormat().format(billTotal)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</p></div>
          <hr style={{borderStyle: 'double'}}></hr>
          <div className='d-flex justify-content-between'><h5>Returns Value : </h5> <h5>{items.length > 0 ? `${new Intl.NumberFormat().format(returnValue)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</h5></div>
          <div className='d-flex justify-content-between gap-3 mt-3'>
            <p>Returns To :</p>
            <SelectInput
              label = "Warehouse"
              value = {warehouse}
              onChange = {(e) => {
                setWarehouse(e.target.value)
              }}
              items = {
                listWareHouses && listWareHouses?.map((i) => (
                  <MenuItem value={i._id}>{i.name}</MenuItem>
                ))
              }
            />
          </div>
          <div>
            <Button className='w-100 mt-2' size='large' color="success" variant="contained" onClick={orderSubmit} disabled={selectInvoiceVal && items.length && warehouse ? submitDisable : true}>Checkout</Button>
          </div>
        </Paper>
      </div>

      <ModalBasic
        open = {visibleScanner}
        onClose = {()=> {
          setVisibleScanner(false)
        }}
        content = {
          <div className='p-3'>
            <Scanner result = {getBarcodeData}/>
          </div>
        }
      />

      <ModalBasic
        width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
        open = {visible}
        onClose = {()=> {
          window.location.reload()
          setVisible(false)
        }}
        content = { settings.billReceipt === 'invoice' ?
          <ReturnsReceiptV2 order = {{selectInvoiceVal, items, warehouse, returnDate, returnValue, payment}}/>
          :
          <ReturnsReceipt order = {{selectInvoiceVal, items, warehouse, returnDate, returnValue, payment}}/>
        }
      />
    </div>
  )
}
