import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../../components/Inputs/InputFields'

export default function UpdateBrand(props) {
    const brandData = props.data;

    const [name, setName] = useState(brandData?.name);

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/brand/${brandData._id}`, {...brandData,name});
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                toast.success("Brand Updated");
                window.location.reload()
            }
        } catch (err) {
            toast.error("Brand Update Failed Try Again");
        
        }
    }
    
    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Update {name}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Brand"
                    value = {name}
                    onChange={(e) => setName(e.target.value)} 
                />
    
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE BRAND
                </button>
                </div>
            </form>
        </div>
    )
}
