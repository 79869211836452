import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {Print} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper, Skeleton} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import { useGdate } from '../../../../context/date'
import { useDk } from '../../../../context/dk'

import IncomeStatementDetail from '../IncomeStatements/IncomeStatementDetail'

export default function FinancialStatementDetail() {

  const [gdate] = useGdate()
  const [dk, setDk] = useDk()

  const [fuelStockValue, setFuelStockValue] = useState('')
  const [inventoryStockValue, setInventoryStockValue] = useState('')

  const [assetsAccounts, setAssetsAccounts] = useState('')
  const [equityAccounts, setEquityAccounts] = useState('')
  const [liabilityAccounts, setLiabilityAccounts] = useState('')

  const [listEmployees, setListEmployees] = useState('')
  const [listCustomers, setListCustomers] = useState('')
  const [listSuppliers, setListSuppliers] = useState('')
  const [listCheque, setListCheque] = useState('')
  const [listBank, setListBank] = useState('')

  const [netProfit, setNetProfit] = useState('')

  const [totalAssets, setTotalAssets] = useState('')
  const [totalEquity, setTotalEquity] = useState('')
  const [totalELP, setTotalELP] = useState('')

  const [PrintIcon, setPrintIcon] = useState(true)

  useEffect(() => {
    loadAccounts()
    loadEmployeeArrears()
    loadCustomerBalance()
    loadChequeBalance()
    loadSupplierBalance()
    loadBankBalance()
  },[gdate])

  useEffect(() => {
    // assetsAccounts && setTotalAssets( (assetsAccounts?.reduce((a,v) => a + v?.total, 0) ? assetsAccounts?.reduce((a,v) => a + v?.total, 0) : '0') + (fuelStockValue ? fuelStockValue?.reduce((a,v) => a = a + v?.stockValue, 0) : 0) + inventoryStockValue)
    setTotalAssets( (assetsAccounts ? assetsAccounts?.reduce((a,v) => a + v?.total, 0) : '0') + (fuelStockValue ? fuelStockValue?.reduce((a,v) => a = a + v?.stockValue, 0) : 0) + inventoryStockValue)
  },[assetsAccounts, fuelStockValue, inventoryStockValue])

  useEffect(() => {
    setTotalEquity((equityAccounts ? equityAccounts?.reduce((a,v) => a + v?.total, 0) : 0) + netProfit)
  }, [totalEquity, netProfit])

  useEffect(() => {
    // liabilityAccounts && totalEquity && setTotalELP( ( liabilityAccounts?.reduce((a,v) => a + v?.total, 0) ? liabilityAccounts?.reduce((a,v) => a + v?.total, 0) : 0 ) + totalEquity)
    setTotalELP( ( liabilityAccounts ? liabilityAccounts?.reduce((a,v) => a + v?.total, 0) : 0 ) + totalEquity)
  },[liabilityAccounts, totalEquity, netProfit])

  const loadAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: {$lte: gdate?.reportEndDate}})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setAssetsAccounts(data?.filter((i) => i._id.accounting[0]?.category === '63dca26a06b9cdaae9c5c8f9') ? data?.filter((i) => i._id.accounting[0]?.category === '63dca26a06b9cdaae9c5c8f9') : '' )
        await setEquityAccounts(data?.filter((i) => i._id.accounting[0]?.category === '63dca29706b9cdaae9c5c902') ? data?.filter((i) => i._id.accounting[0]?.category === '63dca29706b9cdaae9c5c902') : '' )
        await setLiabilityAccounts(data?.filter((i) => i._id.accounting[0]?.category === '63dca28d06b9cdaae9c5c8ff') ? data?.filter((i) => i._id.accounting[0]?.category === '63dca28d06b9cdaae9c5c8ff') : '' )
        
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadEmployeeArrears = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/employeetotal`,  {  $expr : { $eq: [ '$accounting' , { $toObjectId: '6515831c1a89e4fc7010012a' } ] }, "empStatus.employee" : { $exists: true}, date: {$lte: gdate?.reportEndDate} } )
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListEmployees(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomerBalance = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/customertotal`,  {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31abdf029ceab39dcd283' } ] }, "detail.customer" : { $exists: true}, date: {$lte: gdate?.reportEndDate} } )
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListCustomers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadChequeBalance = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/chequetotal`,  {  $expr : { $eq: [ '$accounting' , { $toObjectId: '64fda2c5c969962bfbcee3eb' } ] }, "detail.customer" : { $exists: true}, date: {$lte: gdate?.reportEndDate} } )
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListCheque(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSupplierBalance = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/suppliertotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '64acdf13722d07654e219f89' } ] }, "detail.supplier" : { $exists: true}, date: {$lte: gdate?.reportEndDate} })
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBankBalance = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/banktotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '63e31a93f029ceab39dcd281' } ] }, "detail.bank" : { $exists: true}, date: {$lte: gdate?.reportEndDate} })
      if (data?.error) {
          toast.error(data.error)
      } else {
        setListBank(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getIncomeStatementDetails = (data) => {
    setNetProfit(data?.netProfit)
    setFuelStockValue(data?.fuelStockValue)
    setInventoryStockValue(data?.inventoryStockValue)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const componentRef2 = useRef();
  const printData2 = useReactToPrint({
      content : () => componentRef2.current,
      documentTitle: `Detailed Balance Sheet - Begin To ${gdate?.reportEndDate}`,
      onBeforeGetContent: async() => {
        setPrintIcon(false)
        await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
        setPrintIcon(true)
        const data = localStorage.getItem("darkmode");
        if (data) {
            const parsed = JSON.parse(data);
            setDk({...dk, darkmode: parsed});
        }
      },
  })

  return (
    <div className='row justify-content-center m-2' ref={componentRef2}>
      <p className='text-center fs-5 mb-2' style={{fontFamily: 'TirantiSolidLET'}}>{`Begin To ${moment(gdate?.reportEndDate).format("MMMM Do YYYY")}`}</p>
      {/* INCOME STATEMENT */}
      <div className='d-none'>
        <IncomeStatementDetail getIncomeStatementDetails = {getIncomeStatementDetails} balanceSheet = {true}/>
      </div>

      {/* BALANCE SHEET */}
      <div className='col-xl-10 col-12'>
        <div className='row justify-content-center'>
          <div className='d-flex justify-content-center gap-2 align-items-baseline'>
              <h5>BALANCE SHEET</h5>
              {PrintIcon && <Print fontSize='small' onClick={() => {printData2()}}/>}
          </div>
        
          <Table component={Paper} size='small'>
            <TableHead>
              <TableRow>
                  <StyledTableCell className='w-75'>Accounts Details</StyledTableCell>
                  <StyledTableCell className='w-75' align="right">Amount</StyledTableCell>
                  <StyledTableCell align="right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* ASSETS */}
              <TableRow key='assets'>
                <TableCell className='fw-bold'>ASSETS</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* Fuel Stocks */}
              <TableRow key='fuel-stocks' >
                <TableCell>Fuel Stocks</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold' align="right"></TableCell>
              </TableRow>

              {fuelStockValue && fuelStockValue?.map((i) =>
                  <TableRow key={i?.type}>
                      <TableCell className='d-flex justify-content-between text-capitalize'>{i?.type + ' | ' + new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(i?.stock ? i?.stock : 0)} <dt>{i?.stockValue ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.stockValue) : <Skeleton animation="wave"/>}</dt></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                  </TableRow>
              )}

              <TableRow key='fuel-stocks-value' >
                <TableCell className='text-center'>Total Fuel Stocks Value</TableCell>
                <TableCell align="right" className='border-bottom'>{ fuelStockValue ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(fuelStockValue?.reduce((a,v) => a = a + v?.stockValue, 0)) : <Skeleton animation="wave"/>}</TableCell>
                <TableCell className='fw-bold' align="right"></TableCell>
              </TableRow>

              {/* Inventory Stocks */}
              <TableRow key='inventory-stock' >
                <TableCell>Inventory Stock Value</TableCell>
                <TableCell align="right">{inventoryStockValue ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventoryStockValue) : <Skeleton animation="wave"/>}</TableCell>
                <TableCell className='fw-bold' align="right"></TableCell>
              </TableRow>

              {/* Assets Accounts */}
              {assetsAccounts && assetsAccounts?.filter((i) => i?._id?.accounting[0]?._id !== "63e31a93f029ceab39dcd281" && i?._id?.accounting[0]?._id !== "63e31abdf029ceab39dcd283" && i?._id?.accounting[0]?._id !== "64fda2c5c969962bfbcee3eb")?.map((i) =>
                  <TableRow key={i?._id?.accounting[0]?._id}>
                      <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                      <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                      <TableCell align="right"></TableCell>
                  </TableRow>
              )}

              {/* Bank */}
              <TableRow key='Bank'>
                <TableCell className='fw-bold'>Banks</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {listBank && listBank?.map((i) =>
                <TableRow key={i?._id?.bank}>
                  <TableCell className='d-flex justify-content-between'><li>{i?._id?.bank} | {i?._id?.accountNo}</li> <dt>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0)}</dt></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              <TableRow key='Bank-total'>
                <TableCell align='center' className='fw-bold'>Bank Total</TableCell>
                <TableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(listBank ? listBank?.reduce((a,v) => a = a + v?.total, 0) : 0)}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* Debts */}
              <TableRow key='Debts'>
                <TableCell className='fw-bold'>Debts</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {listCustomers && listCustomers?.filter((f) => f?.total !== 0)?.map((i) =>
                <TableRow key={i?._id?.name}>
                  <TableCell className='d-flex justify-content-between'><li>{i?._id?.name} | {i?._id?.phoneNumber ? i?._id?.phoneNumber : '-'}</li> <dt>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0)}</dt></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              <TableRow key='Debts-total'>
                <TableCell align='center' className='fw-bold'>Debts Total</TableCell>
                <TableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(listCustomers ? listCustomers?.reduce((a,v) => a = a + v?.total, 0) : 0)}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* Cheque */}
              <TableRow key='Cheque'>
                <TableCell className='fw-bold'>Cheques</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {listCheque && listCheque?.filter((f) => f?.total !== 0)?.map((i) =>
                <TableRow key={i?._id?.name}>
                  <TableCell className='d-flex justify-content-between'><li>{i?._id?.name} | {i?._id?.phoneNumber ? i?._id?.phoneNumber : '-'}</li> <dt>{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</dt></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              <TableRow key='cheque-total'>
                <TableCell align='center' className='fw-bold'>Cheque Total</TableCell>
                <TableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(listCheque ? listCheque?.reduce((a,v) => a = a + v?.total, 0) : 0)}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow key='assetsTotal' style={Math.floor(totalELP) === Math.floor(totalAssets) ? {backgroundColor: 'greenyellow'} : {backgroundColor: 'red'} }>
                  <TableCell className='fw-bold fs-6 text-dark text-end'>Total Assets</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell className='fw-bold fs-6 text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalAssets)}</TableCell>
              </TableRow>



              {/* LIABILITY */}
              <TableRow key='liability'>
                  <TableCell className='fw-bold'>LIABILITIES</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
              </TableRow>
              
              {/* Liability Accounts*/}
              {liabilityAccounts && liabilityAccounts?.filter((i) => i?._id?.accounting[0]?._id !== "64acdf13722d07654e219f89" && i?._id?.accounting[0]?._id !== "6515831c1a89e4fc7010012a")?.map((i) =>
                <TableRow key={i?._id?.accounting[0]?._id}>
                  <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                  <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              {/* Creditors */}
              <TableRow key='Creditors'>
                <TableCell className='fw-bold'>Creditors</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {listSuppliers && listSuppliers?.filter((f) => f?.total !== 0)?.map((i) =>
                <TableRow key={i?._id?.name}>
                  <TableCell  className='d-flex justify-content-between'><li>{i?._id?.name} | {i?._id?.phoneNumber ? i?._id?.phoneNumber : '-'}</li> <dt>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0)}</dt></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              <TableRow key='creditor-total'>
                <TableCell align='center' className='fw-bold'>Creditor Total</TableCell>
                <TableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(listSuppliers ? listSuppliers?.reduce((a,v) => a = a + v?.total, 0) : 0)}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* Salary Arrears */}
              <TableRow key='salary-arrears'>
                <TableCell className='fw-bold'>Salary Arrears</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {listEmployees && listEmployees?.filter((f) => f?.total !== 0)?.map((i) =>
                <TableRow key={i?._id?.name}>
                  <TableCell className='d-flex justify-content-between'><li>{i?._id?.name} | {i?._id?.phoneNumber ? i?._id?.phoneNumber : '-'}</li> <dt>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0)}</dt></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              <TableRow key='salary-arrears--total'>
                <TableCell align='center' className='fw-bold'>Salary Arrers Total</TableCell>
                <TableCell align="right" className='border-bottom'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(listEmployees ? listEmployees?.reduce((a,v) => a = a + v?.total, 0) : 0)}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow key='liabilityTotal' style={{backgroundColor: 'grey'}}>
                <TableCell className='fw-bold text-dark text-end'>Total Liabilities</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(liabilityAccounts && liabilityAccounts?.reduce((a,v) => a + v?.total, 0))}</TableCell>
              </TableRow>

                
              {/* EQUITY */}
              <TableRow key='equity'>
                <TableCell className='fw-bold'>EQUITY</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow key='income'>
                <TableCell>Income | income Statement</TableCell>
                <TableCell align="right">{netProfit ? new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(netProfit) : <Skeleton animation="wave"/>}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {equityAccounts && equityAccounts?.map((i) =>
                <TableRow key={i?._id?.accounting[0]?._id}>
                  <TableCell> {i?._id?.accounting[0]?.name?.toUpperCase() + ' | ' + i?._id?.accounting[0]?.description}</TableCell>
                  <TableCell align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0)}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              )}

              <TableRow key='equityTotal' style={{backgroundColor: 'grey'}}>
                <TableCell className='fw-bold text-dark text-end'>Total Equity</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalEquity)}</TableCell>
              </TableRow>

              <TableRow key='equityLiabilityTotal' style={Math.floor(totalELP) === Math.floor(totalAssets) ? {backgroundColor: 'greenyellow'} : {backgroundColor: 'red'} }>
                <TableCell className='fw-bold fs-6 text-dark text-end'>Total Equity + Liabilities</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold fs-6 text-dark text-end'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalELP)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
