import React, {useRef, useState, useEffect} from 'react'
import moment from 'moment'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../../../context/settings'
import { useDk } from '../../../../context/dk'

import {Print} from '@mui/icons-material'

import QrCodeGenerator from '../../../../components/Scan-Qr/QrCodeGenerator'
import {Divider} from '@mui/material'

export default function SalesReceipt(props) {
    const order = props.order

    const [settings] = useSettings();
    const [dk, setDk] = useDk();

    const [invoiceNo] = useState(order?.invoiceNo)
    const [noQuantity, setNoQuantity] = useState('')
    const [subTotal, setSubTotal] = useState('')
    const [discount, setDiscount] = useState('')

    const [printedDate, setPrintedDate] = useState(new Date())

    useEffect(() => {
        setSubTotal(order?.items?.reduce((t,i) => t = t + (i?.quantity * i?.productStock?.productCode?.price), 0))
        setDiscount(order?.items?.reduce((t,i) => t = t + Number(i?.unitDiscount * i?.quantity), 0))
        setNoQuantity(order?.items?.reduce((t,i) => t = t + Number(i.quantity), 0))
        order?.printedDate && setPrintedDate(order?.printedDate)
    },[order])

    const paymentMethod = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pay'}, {_id:'63e31abdf029ceab39dcd283', name: 'Credit'},]

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Sales Receipt ${order?.invoiceNo}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            toast.success('Print Success')
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        }
    })

  return (
    <div>
        <button className='btn btn-warning mb-2' onClick={printData}> <Print/></button>
        <div className='m-3' ref= {componentRef}>
            <h5 className='text-center fw-bold'>{settings?.name}</h5>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Address: {settings?.address}</dd>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Tel: {settings?.phoneNumber}</dd>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Email: {settings?.mail}</dd>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Printed On: {moment(printedDate).format("YYYY-MM-DD hh:mm A")}</dd>

            <h5 className='text-center fw-bold' >SALES RECEIPT</h5>
            
            <div>
                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>INVOICE NO: {order?.invoiceNo}</dd>
                    <dd style={{fontSize: '12px'}}>Payment: {(paymentMethod.find((i) => i._id === order?.payment) ? paymentMethod.find((i) => i._id === order?.payment).name : 'Unknown')}</dd>
                </div>
                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>CUSTOMER: {(order?.selectedCustomer ? order?.selectedCustomer?.name : 'Retail')}</dd>
                    <dd style={{fontSize: '12px'}}>No.Items: {(order?.items?.length ? order?.items?.length : 0)}</dd>
                </div>

                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>DATE: {order?.orderDate}</dd>
                    <dd style={{fontSize: '12px'}}>No.Qty: {new Intl.NumberFormat().format(noQuantity)}</dd>
                </div>
                
            </div>
            
            <div className='d-flex justify-content-between mb-0'>
                <p style={{fontSize: '12px'}}>ITEMS</p>
                <p style={{fontSize: '12px'}}>PRICE</p>
            </div>

            {order.items && order?.items.map((i) => 
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='lh-sm' style={{fontSize: '12px'}}>{i.productStock?.productCode?.product?.name} | {i.productStock?.productCode?.productCode} <br/> {new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(i.productStock?.productCode?.price)} * {new Intl.NumberFormat().format(i?.quantity)} - (DISC: {new Intl.NumberFormat().format(i.unitDiscount * i.quantity)})</p>
                    <p className='lh-sm' style={{fontSize: '12px'}}>{new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format((i?.quantity * (i.productStock?.productCode?.price - i.unitDiscount)))}</p> 
                </div>
            )}

            <hr className='border m-0'></hr>

            <hr className='border mt-0'></hr>

            <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm' style={{fontSize: '12px'}}>Sub Total:</p>
                <p className='lh-sm' style={{fontSize: '12px'}}>{order?.items?.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal)}` : '0.00'}</p> 
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm' style={{fontSize: '12px'}}>Total Discount:</p>
                <p className='lh-sm' style={{fontSize: '12px'}}>- ( {discount ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(discount)}` : '0.00'} )</p> 
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm fw-bold' style={{fontSize: '14px'}}>Total:</p>
                <p className='lh-sm fw-bold' style={{fontSize: '14px'}}>{order?.items?.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal - discount)}` : '0.00'}</p> 
            </div>

            {order?.payment === '64952f578188d2c8b9c26603' && <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm' style={{fontSize: '12px'}}>Cash Received:</p>
                <p className='lh-sm' style={{fontSize: '12px'}}>{order?.received ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(order?.received)}` : '0.00'}</p> 
            </div>}

            {order?.payment === '64952f578188d2c8b9c26603' && <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm' style={{fontSize: '12px'}}>Balance:</p>
                <p className='lh-sm' style={{fontSize: '12px'}}>{order?.received && order?.total ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(order?.received - order?.total)}` : '0.00'}</p> 
            </div>}

            <div className='d-flex justify-content-center p-1'>
                <QrCodeGenerator qrText = {String(invoiceNo)} size='100' hide='true'/>
            </div>


            <h6 className='text-center p-1'>Thanks, Come Again</h6>
            <Divider light>*** END ***</Divider>
        </div>
        
    </div>
  )
}
