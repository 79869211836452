import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"
import {Text} from '../../../components/Inputs/InputFields'


export default function UpdateFuelType(props) {
    const fuelData = props.data

    const [fuelType, setFuelType] = useState(fuelData?.fuelType)

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async() => {
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/fueltype/${fuelData?._id}`, {fuelType})
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                toast.success("Fuel Type Updated Sucessfully")
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Fuel Type - {fuelType}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                label="Fuel Type"
                value = {fuelType}
                onChange={(e) => setFuelType(e.target.value)}
                focus = 'true'
                />
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE FUEL TYPE
                </button>
                </div>
            </form>
        </div>
  )
}
