import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {Edit} from '@mui/icons-material/'
import {Card} from 'antd'

import ModalBasic from '../../components/Modal/ModalBasic'

import NozzleImg from '../../Assets/images/fuel-nozzle-icon.png'
import DayPump from './DayPump'

export default function PumpsCard() {
  const [loading, setLoading] = useState(true);

  //Model
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [pumpslist, setPumpslist] = useState('')
  const [loadMaxRead, setLoadMaxRead] = useState('')

  const [selectedMaxRead, setSelectedMaxRead] = useState(null)

  useEffect(() => {
    loadPumps();
    loadMaxReading();
  }, [])

  const loadPumps = async() => {
    try {
      const {data} = await Axios.post('/pumps')
      if (data?.error){
        toast.error(data?.error)
      } else {
        setPumpslist(data)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadMaxReading = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/maxreads`)
      if (data?.error){
        toast.error(data.error)
      } else {
        setLoadMaxRead(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
      <div className='row p-2 shadow' style={{borderRadius: '5px', backdropFilter: 'blur(10px)'}}>
        {pumpslist?.length === 0 ? <h2 className='col text-center'>There is no Pump to Show</h2> : ''}
        {pumpslist && pumpslist?.map((i)=> (
          <div key={i._id} className='col-xxl-3 col-xl-4 col-md-6 col-12 p-2'> 
            <Card
              hoverable
              className='align-items-center'
              style={ {backgroundImage: 'linear-gradient(19deg, #00a180 0%, #bf1fca 100%)'}}
              loading={loading}
            >
              <div className='position-relative'>
                <div className='position-absolute'>
                  <img
                    style={{opacity:0.4, height: '120px', zIndex: 'inherit'}}
                    alt="Nozzle"
                    src={NozzleImg}
                  />
                </div>

                <div className='d-flex justify-content-between'>
                  <div className='position-relative'>
                    <div className='d-flex gap-1'>
                      <Edit fontSize='large' className='p-2 shadow rounded-circle'
                        style={{backgroundColor: 'rgba(250,250,250,0.7)'}}
                        onClick={() => {
                          setVisible(true)
                          setSelected(i)
                          setSelectedMaxRead(loadMaxRead && loadMaxRead.find((m) => m._id.pump === i._id) ? loadMaxRead.find((m) => m._id.pump === i._id)?.endReading : '0' )
                        }}
                      />
                    </div>
                  </div>

                  <div style={{width: '100%'}}>
                    <div className='text-end'>
                        <dd className='text-capitalize fs-6' style={{color: "white"}}>Tank: <strong>{i?.wareHouseStock?.productCode?.product?.name}</strong></dd>
                        <dd className='text-capitalize fs-6' style={{color: "white"}}>Booth: <strong>{i?.booth?.name}</strong></dd>
                    </div>
                  </div>

                </div>

              </div>
              <div className='shadow' style={{position:'relative', backgroundImage: 'linear-gradient(122deg, rgba(70,70,70,0.5) 0%, rgba(200,200,200,0.5) 50%, rgba(70,70,70,0.5) 100%)', backdropFilter: 'blur(3px)', borderRadius: '5px', padding:1}}>
                <div className='text-uppercase text-center justify-content-center fs-5 fw-bold' style={{color: "white"}}>{i.name}</div>
              </div>
            </Card>
          </div>
          ))}
          
          <ModalBasic
            open = {visible}
            onClose = {()=> {
              setVisible(false)
            }}
            content = {<DayPump pump = {selected} maxRead = {selectedMaxRead}
            />}
          />
      </div>
  )
}