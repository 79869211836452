import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"
import {Text} from '../../../components/Inputs/InputFields'


export default function CreateFuelType() {

    const [fuelType, setFuelType] = useState()
    const [loadLiter, setLoadLiter] = useState()

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async() => {
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.post(`/fueltype`, {fuelType, loadLiter})
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                toast.success("Fuel Type Added Sucessfully")
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Create Fuel Type</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Fuel Type"
                    value = {fuelType}
                    onChange={(e) => setFuelType(e.target.value)}
                    focus = 'true'
                />
                <Text
                    label="A load Liters"
                    value = {loadLiter}
                    onChange={(e) => setLoadLiter(e.target.value)}
                />
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    CREATE FUEL TYPE
                </button>
                </div>
            </form>
        </div>
  )
}
