import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreateAccounts from './CreateAccounts'
import UpdateAccounts from './UpdateAccounts'

export default function ListAccounts() {

    const [listAccounts, setListAccounts] = useState('')

    //Model
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        loadAccounts()
    }, [])
    

    const loadAccounts = async() => {
        try {
            const {data} = await Axios.get('/accountings')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListAccounts(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-xxl-8 col-xl-10 col-lg-11 col-12 mb-3'>
                <div className='row justify-content-between mb-2'>
                    <div className='col-xl-4 col-md-6 col-12 d-flex gap-2 mb-2'>
                        <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
                    </div>
                </div>
                {listAccounts && 
                    <MUITable
                        title = "All Accounts Types"
                        id = {(row) => row._id}
                        columns = {[
                            { field: 'name', headerName: 'Accounts Name', width: 180, renderCell: (i) => i.row.name?.toUpperCase(), valueGetter: (i) => i.row.name },
                            { field: 'category', headerName: 'Type of Account', width: 150, renderCell: (i) => i.row?.category?.name?.toUpperCase(), valueGetter: (i) => i.row?.category?.name},
                            { field: 'description', headerName: 'Description', width: 300, filterable : false, sortable: false},
                            { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable:false, disableExport: true, renderCell: (i) => 
                            <div className='d-flex gap-2'>
                              <Edit fontSize='large' className='p-2 shadow rounded-circle'
                                onClick={() => {
                                    setVisible(true)
                                    setSelected(i?.row)
                                }}
                              />
                            </div>
                            },
                        ]}
                        rows = {listAccounts}
                    />
                }            
            </div>

            <ModalBasic
                open = {visibleAdd}
                onClose = {()=> {
                setVisibleAdd(false)
                }}
                content = {<CreateAccounts/>}
            />

            <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {<UpdateAccounts data={selected}/>}
            />
        </div>
    )
}
