import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../../../context/settings'

import MUITable from '../../../../components/table/MUITable'

import { Visibility} from '@mui/icons-material/'

// import SalesReceipt from '../Receipts/SalesReceipt';
// import SalesReceiptV2 from '../Receipts/SalesReceiptV2';

export default function ListPurchase() {
  const navigate = useNavigate()

  const [settings] = useSettings()

  const [listPurchase, setListPurchase] = useState('')

  useEffect(() => {
      loadPurchases()
  }, [])
  

  const loadPurchases = async() => {
      try {
          const {data} = await Axios.post('/purchases')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListPurchase(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        {listPurchase && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
              { field: 'invoiceNo', headerName: 'Invoice No', width: 120},
              { field: 'supplier', headerName: 'Supplier', width: 180, renderCell: (i) => i.row.supplier?.name, valueGetter: (i) => i.row.supplier?.name},
              { field: 'purchaseDate', headerName: 'Purchase Date', width: 150},
              { field: 'payment', headerName: 'Payment', width: 150, renderCell: (i) => i?.row?.payment?.name, valueGetter: (i) => i?.row?.payment?.name},
              { field: 'total', headerName: 'Total', width: 150, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i?.row?.total) ), valueGetter: (i) => i?.row?.total},
              { field: 'markedOn', headerName: 'Marked On', width: 200, renderCell: (i) => moment(i?.row?.updatedAt).format('YYYY-MM-DD hh:mm:ss A'), valueGetter: (i) => moment(i?.row?.updatedAt).format('YYYY-MM-DD hh:mm:ss A')},
              { field: 'action', headerName: 'Action', width: 100,filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      navigate(`/dashboard/user/inventory/purchase-report/${i.row._id}`)
                    }}
                  />
                </div>
              },
            ]}
            rows = {listPurchase}
          />
        }   
      </div>
    </div>
  )
}
