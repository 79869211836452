import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import {useSettings} from '../../../context/settings'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreateProductCode from './CreateProductCode'
import UpdateProductCode from './UpdateProductCode'

export default function ListProductCode(props) {
  const product = props.product

  const [settings] = useSettings() 

  const [listProductCode, setListProductCode] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadProductCodes()
  }, [])
  

  const loadProductCodes = async() => {
      try {
          const {data} = await Axios.post('/productcodes', {product: product?._id})
          if(data?.error){
              toast.error(data.error)
          } else {
            setListProductCode(data)
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-lg-12 col-12 mb-3'>
        <h4 className='text-center mb-3'>Product Codes  - {product?.name}</h4>
        <div className='d-flex gap-2 mb-2'>
          <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> ADD NEW</button>
        </div>
        {listProductCode && 
          <MUITable
            title = "List of Product Codes"
            id = {(row) => row._id}
            pageSize = {3}
            columns = {[
                { field: 'productCode', headerName: 'Product Code', width: 150},
                { field: 'cost', headerName: 'Cost', width: 120, renderCell: (i) => settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.cost), filterable: false, sortable: false},
                { field: 'price', headerName: 'Price', width: 120, renderCell: (i) => settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.price), filterable: false, sortable: false},
                { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listProductCode}
          />
        }

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateProductCode productData={product}/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateProductCode productCodeData={selected}/>}
        />

      </div>
    </div>
  )
}