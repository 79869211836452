import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import MUITable from '../../components/table/MUITable'

export default function MessageDetails() {
  
  const [listData, setListData] = useState('')
  const [avMsg, setAvMsg] = useState(0)

  const [textColor, setTextColor] = useState({color: 'red'})
  const [latestRecharge, setLatestRecharge] = useState('')

  useEffect(() => {
    loadData()
  },[])

  useEffect(() => {
    setTextColor(() => {
      switch (true) {
        case (avMsg < 11):
          return {color: '#FF0000'};
          break;
        case (avMsg < 21):
          return {color: 'yellow'};
          break;
        case (avMsg >= 21):
          return {color: '#39ff14'};
          break;
        default:
          return {color: 'grey'}
      }
    })

    setLatestRecharge(listData && listData?.findLast((i) => i?.type === 'recharge')?.date)

  }, [listData])

  const loadData = async() => {
    try {
      const {data} = await Axios.post(`/messagelist`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListData(data)
        setAvMsg(data?.reduce((a,v) => a = a + (v.messageCount), 0) > 0 ? data?.reduce((a,v) => a = a + (v.messageCount), 0) : '0')
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row'>
      <div className='col-xl-5 col-lg-6 col-md-5 col-12 pb-3'>
        <h5 className='text-center pb-3'>Message Service Details</h5>

        <div className='d-flex justify-content-between'>
          <dd>Total Ordered</dd>
          <dd>{listData && listData?.reduce((a,v) => a = a + (v.type === 'recharge' && v.messageCount), 0)}</dd>
        </div>

        <div className='d-flex justify-content-between'>
          <dd>Lastest Ordered Date</dd>
          <dd>{latestRecharge}</dd>
        </div>

        <div className='d-flex justify-content-between'>
          <dd>Total Sent</dd>
          <dd>{listData && Math.abs(listData?.reduce((a,v) => a = a + (v.type === 'sent' && v.messageCount), 0))}</dd>
        </div>

        <hr/>

        <div className='d-flex justify-content-between fs-5' style={textColor}>
          <dd>Available</dd>
          <dd>{avMsg && avMsg}</dd>
        </div>
      </div>

      <div className = "col-xl-7 col-lg-6 col-md-7 col-12 pb-3">
        {listData && 
          <MUITable
            id = {(row) => row._id}
            pageSize = {5}
            columns = {[
              { field: 'type', headerName: 'Type', width: 90},
              { field: 'messageCount', headerName: 'Count', width: 80},
              { field: 'createdAt', headerName: 'Sent Date', width: 170, renderCell: (i) => moment(i.row.createdAt).format("YYYY-MM-DD hh:mm: A"), filterable: false},
            ]}
            rows = {listData}
          />
        }
      </div>
    </div>
  )
}
