import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {AccountBalance, Money, AccountBalanceWallet, Lock, Receipt, CreditCard, PanTool, Payments} from '@mui/icons-material/';

export default function ShortReportCard(props) {
  const iconList = [{id: 'money', icon: <Money fontSize='medium'/>}, {id: 'locker', icon: <Lock fontSize='medium'/>}, {id: 'bank', icon: <AccountBalance fontSize='medium'/>}, {id: 'card', icon: <CreditCard fontSize='medium'/>}, {id: 'receipt', icon: <Receipt fontSize='medium'/>}, {id: 'drawings', icon: <PanTool fontSize='medium'/>}, {id: 'payments', icon: <Payments fontSize='medium'/>}, {id: 'equity', icon: <AccountBalanceWallet fontSize='medium'/>}]

  const amount = props.amount ? props.amount : 0
  const formatedAmount = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR', currencyDisplay: "code"}).format(amount)
  const amountArray = formatedAmount?.split(".")

  return (
    <Card key={props.key} className='col-xxl-2 col-lg-3 col-md-4 col-sm-6 col-12 m-1' style={{backgroundColor: props.color}}>
        <CardActionArea className='pt-3 pb-3 p-2' onClick= {props.onClick}>
            <div className='d-flex justify-content-center gap-2'>
                {props.icon && iconList.find((m) => m.id === props.icon)?.icon}
                <Typography variant="body">{props.accounts}</Typography>
            </div>

            <div className='d-flex align-items-baseline justify-content-center'>
                <Typography className='fs-6 fw-bold'>
                    {amountArray && amountArray[0]}
                </Typography>

                <Typography style={{fontSize: '12px'}}>
                    .{amountArray && amountArray[1]}
                </Typography>
            </div>
        </CardActionArea>
    </Card>
  );
}