import React from 'react'
import {Apartment, Person, Settings} from '@mui/icons-material/';

export default function Stepper(props) {
    const StepperBlock = {width: '50px', height: '50px', borderRadius: '50%', backgroundImage: "linear-gradient(300deg, rgba(131,58,180,1) 0%, rgba(230,12,12,1) 50%, rgba(255,153,0,1) 100%)"}
    const StepperLine = {height: '5px', backgroundImage: "linear-gradient(300deg, rgba(131,58,180,1) 0%, rgba(230,12,12,1) 50%, rgba(255,153,0,1) 100%)"}
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center' style={StepperBlock}>
                <Apartment className='text-light'/>
            </div>

            <hr className='w-25' style={StepperLine}/>

            <div className='d-flex justify-content-center align-items-center' style={props?.stepCount > 0 ? StepperBlock : {...StepperBlock, backgroundImage: "linear-gradient(300deg, rgba(100,100,100,1) 0%, rgba(100,100,100,1) 50%, rgba(100,100,100,1) 100%)"}}>
                <Settings className='text-light'/>
            </div>

            <hr className='w-25' style={props?.stepCount > 0 ? StepperLine : {...StepperLine, backgroundImage: "linear-gradient(300deg, rgba(100,100,100,1) 0%, rgba(100,100,100,1) 50%, rgba(100,100,100,1) 100%)"}}/>

            <div className='d-flex justify-content-center align-items-center' style={props?.stepCount > 1 ? StepperBlock : {...StepperBlock, backgroundImage: "linear-gradient(300deg, rgba(100,100,100,1) 0%, rgba(100,100,100,1) 50%, rgba(100,100,100,1) 100%)"}}>
                <Person className='text-light'/>
            </div>
        </div>
    )
}
