import React, {useRef, useState,useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../../context/settings';
import { useDk } from '../../../context/dk'

import { Print } from '@mui/icons-material/'

import MUITable from '../../../components/table/MUITable'

export default function ListTankCheck() {
    const {id} = useParams()
    const navigate = useNavigate()
    const [settings] = useSettings()
    const [dk, setDk] = useDk()

    const [listTankChecks, setListTankChecks] = useState('')
    const [pumpList, setPumpList] = useState('')

    useEffect(() => {
        loadPumps()
        loadTankCheck()
      },[id])

    
    const loadPumps = async() => {
        try {
            const {data} = await Axios.post('/pumps', {wareHouseStock: id})
            if (data?.error){
            toast.error(data?.error)
            } else {
                setPumpList(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }
      
    const loadTankCheck = async() => {
        try {
            const {data} = await Axios.post(`/tank-checks`, {"wareHouseStock._id": id})
            if(data?.error){
                toast.error(data.error)
            } else {
                await setListTankChecks(data?.reverse())
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Tank: ${listTankChecks[0]?.wareHouseStock?.productCode?.product?.name} Checks List`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        },
    })

    const pumps = pumpList && pumpList?.map((n) => {
        return {field: n?.name, headerName: n?.name + ' Reading', width: 130, renderCell: (i) => i.row.pumpList?.find((c) => (c?.pump?._id === n?._id))?.reading, filterable: false }
      })

    const columns = [
        { field: 'date', headerName: 'Date', width: 100},
        ...pumps,
        { field: 'fillUp', headerName: 'Total Fillup', width: 130, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.pumpList.reduce((a,v) => a = a + v?.litters, 0))} L`, filterable: false, sortable: false},
        { field: 'testing', headerName: 'Testing', width: 120, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.pumpList.reduce((a,v) => a = a + v?.testing, 0))} L`, filterable: false, sortable: false},
        { field: 'actualL', headerName: 'Actual L', width: 130, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.pumpList.reduce((a,v) => a = a + (v?.litters) - (v?.testing), 0))} L`, filterable: false, sortable: false},
        { field: 'actualLoad', headerName: 'Actual Load', width: 130, renderCell: (i) => new Intl.NumberFormat().format( i.row.pumpList.reduce((a,v) => a = a + (v?.litters) - (v?.testing), 0)/6600 ), filterable: false, sortable: false},
        { field: 'purchase Load', headerName: 'Purchase Load', width: 130, renderCell: (i) => new Intl.NumberFormat().format(i.row.loadLitters/6600), filterable: false, sortable: false},
        { field: 'load', headerName: 'Load L', width: 130, renderCell: (i) => `${i.row.loadLitters} L`, filterable: false, sortable: false},
        { field: 'different', headerName: 'Different', width: 130, renderCell: (i) => `${new Intl.NumberFormat().format((i.row.loadLitters) - i.row.pumpList.reduce((a,v) => a = a + (v?.litters) - (v?.testing), 0))} L`, filterable: false, sortable: false},
        { field: 'createdAt', headerName: 'Marked On', width: 180, renderCell: (i) => moment(i.row.createdAt).format("YYYY-MM-DD hh:mm A"), valueGetter: (i) => moment(i.row.createdAt).format("YYYY-MM-DD hh:mm A")},
    ]

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='row mb-2'>
                    <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
                        <button className = 'btn btn-primary w-50' onClick={() => navigate(`/dashboard/user/tank/tank-check/${id}`)}>Check Tank</button>
                        <button className = 'btn btn-warning w-25' onClick={() => {printData()}}><Print/></button>
                    </div>
                </div>

                <div ref= {componentRef}>
                    <h6 className='text-center'>Tank</h6>
                    <h5 className='text-center'>{listTankChecks[0]?.wareHouseStock?.productCode?.product?.name} Checks List</h5>
                    {listTankChecks && 
                        <MUITable
                            id = {(row) => row._id}
                            columns = {columns}
                            rows = {listTankChecks}
                        />
                    }
                </div>
            </div>
        </div>
    )
}
