import React, { useState, createContext, useContext, useEffect } from "react";

const MenuTypeContext = createContext();

const MenuTypeProvider = (props) => {
    const [menuType, setMenuType] = useState({
        value: '',
    });

    useEffect(() => {
        const data = localStorage.getItem("menuType");
        if (data) {
            const parsed = JSON.parse(data);
            setMenuType({...menuType, value: parsed});
        }
    }, []);

    return(
        <MenuTypeContext.Provider value={[menuType, setMenuType]}>
            {props.children}
        </MenuTypeContext.Provider>
    );
};

const useMenuType = () => useContext(MenuTypeContext);

export {MenuTypeProvider, useMenuType};