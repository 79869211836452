import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'

import {Tabs} from '@mui/material/'

import { useGdate } from '../../context/date'

import ShortReportCard from '../../components/Card/ShortReportCard'

export default function AllAcounts() {
    const navigate = useNavigate()
    const [gdate] = useGdate()

    const [value, setValue] = useState(0)

    const [listAccountings, setListAccountings] = useState('')
    const [allAccountsTotal, setAllAccountsTotal] = useState('')
    
    useEffect(() => {
      loadAccountings()
    },[])

    useEffect(() => {
        loadTotalAccounts()
    },[gdate?.reportStartDate, gdate?.reportEndDate])
    
    const loadAccountings = async() => {
        try {
          const {data} = await Axios.get(`/accountings`)
          if (data?.error) {
            toast.error("Data Load Failed")
          } else {
            await setListAccountings(data.filter((i) => i._id !== '63eb82f1661aeb9f06368651') ? data.filter((i) => i._id !== '63eb82f1661aeb9f06368651') : '' )
          }
        } catch (err) {
          console.log(err)
        }
    }

    const loadTotalAccounts = async() => {
      try {
        const {data} = await Axios.post(`/accdatas/filteraccounts`)
        if(data?.error) {
          toast.error(data.error)
        } else {
          setAllAccountsTotal(data)
        }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

    const colourSelection = (category) => {
      switch (category) {
        case '63dca26006b9cdaae9c5c8f6':
          return 'rgba(83, 145, 101, 0.75)'
          break;
        case '63dca26a06b9cdaae9c5c8f9':
          return 'rgba(175, 85, 140, 0.7)'
          break;
        case '63dca26f06b9cdaae9c5c8fc':
          return 'rgba(230, 140, 40, 0.8)'
          break;
        case '63dca28d06b9cdaae9c5c8ff':
          return 'rgba(240, 20, 40, 0.8)'
          break;
        case '63dca29706b9cdaae9c5c902':
          return 'rgba(45, 90, 130, 0.7)'
          break;
        default:
          return ''
          break;
      }
    }

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <div className='mt-3'>
        <p className='text-center fs-5 ' style={{fontFamily: 'TirantiSolidLET'}}>{gdate?.reportStartDate + ' To ' + gdate?.reportEndDate}</p>
        <div className='col-12'>
          <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile = {true}
          >
          {listAccountings && listAccountings?.map((i, index) => 
              <ShortReportCard
                key = {i?.name + index}
                icon = {i?.icon}
                accounts = {i?.name?.toUpperCase()}
                amount = {allAccountsTotal && allAccountsTotal?.find((r) => i?._id === r?._id?.accounting[0]?._id) ? allAccountsTotal?.find((r) => i?._id === r?._id?.accounting[0]?._id)?.total : '0'}
                color = {colourSelection(i?.category?._id)}
                onClick = {(e) => {
                    navigate(`/dashboard/user/accounts/${i?._id}`)
                }}
              />
          )}
          </Tabs>
        </div>
      </div>
    )
}
