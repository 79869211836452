import React, {useState, useEffect} from 'react'
import {Html5QrcodeScanner} from 'html5-qrcode'
import ScannerBeep from '../../Assets/Audio/ScannerBeep.mp3'

export default function Scanner(props) {
    var [scanner] = useState('')
    const [stateOfResult, setstateOfResult] = useState('')

    var sound = new Audio(ScannerBeep);

    useEffect(() => {
        if(!scanner) {
            function onScanSuccess (text,result) {
                props.result({text,result})
                stateOfResult !== text && sound.play();
                setstateOfResult(text)
            }
            scanner = new Html5QrcodeScanner("reader", { fps: 5, qrbox: {width: 250 , height: 150},  videoConstraints: {facingMode: { exact: "environment" }} })
            scanner.render(onScanSuccess);
        }
    }, [])

    return (
        <div className='p-2' id="reader"></div>
    )
}
