import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth';
import { useSettings } from '../../../context/settings';
import { useGdate } from '../../../context/date'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Check, DeleteForever} from '@mui/icons-material/'


import AccountsForm from './AccountsForm'

export default function AllDoubleEntries() {
    
    const [auth] = useAuth()
    const [settings] = useSettings()
    const [gdate] = useGdate()

    const [listCashflowCheck, setListCashflowCheck] = useState('')

    const [listAccData, setListAccData] = useState('')
    const [totalDebit, setTotalDebit] = useState('')
    const [totalCredit, setTotalCredit] = useState('')

    const [visibleDebit, setVisibleDebit] = useState(false)

    const [visible1, setVisible1] = useState(false)
    const [selected1, setSelected1] = useState(null)

    useEffect(() => {
        loadCashflowsCheck()
    },[])

    useEffect(() => {
        setTotalDebit(listAccData && listAccData?.reduce((a,v) =>  a = a + (Number(allAccountsDebitEntry(v))) , 0 ))
        setTotalCredit(listAccData && listAccData?.reduce((a,v) =>  a = a + (Number(allAccountsCreditEntry(v))) , 0 ))
    },[listAccData])

    useEffect(() => {
        loadAccData()
    },[gdate?.reportStartDate, gdate?.reportEndDate])

    const loadCashflowsCheck = async() => {
        try {
          const {data} = await Axios.post('/cashflows')
          if (data?.error) {
            toast.error(data?.error)
          } else {
            setListCashflowCheck(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something wernt wrong, Try Again!")
        }
    }

    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListAccData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccountsDebitEntry = (i) => {
        switch (true) {
            case ( (i?.accounting?.category?.type && i?.amount > 0) || (!i?.accounting?.category?.type && i?.amount < 0) ):
                return Math.abs(i.amount);
            default:
                return ''
        }
    }

    const allAccountsCreditEntry = (i) => {
        switch (true) {
            case ( (!i?.accounting?.category?.type && i?.amount > 0) || (i?.accounting?.category?.type && i?.amount < 0) ):
                return Math.abs(i.amount);
            default:
                return ''
        }
    }

    const handleDeleteAccounts = async(selected) => {
        try {
            const {data} = await Axios.post(`/accdatas/removemany`, {"refId": selected?.refId , "empStatus.status": false, date: selected?.date})
            if (data?.error) {
                toast.error(data?.error)
            } else {
                toast.success("Double Entries Deleted")
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccounts = [
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'accounts', headerName: 'Accounts', width: 180, renderCell: (i) => i.row.accounting?.name?.toUpperCase(), valueGetter: (i) => i.row.accounting?.name},
        { field: 'employee', headerName: 'Employee', width: 170, renderCell: (i) => i.row.empStatus?.employee?.name, valueGetter: (i) => i.row.empStatus?.employee?.name},
        { field: 'description', headerName: 'Description', width: 400, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description },
        { field: 'debit', headerName: 'Debit', width: 150, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), valueGetter: (i) => i.row.amount, filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 150, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ),valueGetter: (i) => i.row.amount, filterable: false, sortable: false},
        { field: 'delete', headerName: 'Delete', width: 125, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
            <div className='d-flex gap-2'>
                {listCashflowCheck?.find((c) => c?.date === i.row.date) ? <div className='text-success'>CASHFLOW <Check/></div> : 
                (i.row.refId?.startsWith("de-") ?
                    <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                        onClick={() => {
                        setVisible1(true)
                        setSelected1(i.row)
                        }}
                    />
                    :
                    <div>RESTRICTED</div>
                )}
            </div>
        }
    ]
    
    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='d-flex justify-content-end mb-2'>
                    <button className = 'btn btn-outline-primary' onClick={() => {setVisibleDebit(true)}}><Add/>Double Entry</button> 
                </div>
                
                {listCashflowCheck && listAccData && 
                    <MUITable
                        title = {"All Double Entries | " + gdate?.reportStartDate + ' To ' + gdate?.reportEndDate} 
                        id = {(row) => row._id}
                        columns = {allAccounts}
                        rows = {listAccData}
                    />
                }
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total B/F</h6>
                    <h6>{( new Intl.NumberFormat().format(totalDebit) ) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>Total C/F</h6>
                    <h6>{( new Intl.NumberFormat().format(totalCredit) ) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <h5></h5>
                    <h5 style={(totalCredit - totalDebit)  === 0 ? {color: '#39ff14'} : {color: '#FF0000'}}>{( new Intl.NumberFormat().format(Math.abs(totalCredit - totalDebit)) ) + ' ' + settings?.currencyEnd}</h5>
                </div>
            </div>

            <ModalBasic
                width = '80%'
                open = {visibleDebit}
                onClose = {()=> {
                setVisibleDebit(false)
                }}
                content = {<AccountsForm/>}
            />

            <ModalBasic
                open = {visible1}
                onClose = {()=> {
                setVisible1(false)
                }}
                content = {
                    auth?.employee?.role === 0 ?
                    <div className='d-flex justify-content-between gap-2'>
                        <div>
                            {selected1?.refId ? <dd>Make Sure. <br/> All Entires with similar Referance ID will be deleted</dd> : <dd>Only Double Entires can be deleted</dd>}
                        </div>
                        <div className='d-flex align-items-center'>
                            <button className = 'btn btn-outline-danger' disabled = {selected1?.refId ? false : true} onClick={() => {handleDeleteAccounts(selected1)}}>Delete</button>
                        </div>
                    </div>
                    :
                    <div className='d-flex justify-content-center w-100'>
                        <p>You don't have Permission to Delete</p>
                    </div>
                }
            />
        </div>
    )
}
