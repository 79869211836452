import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'

import {TextField, Autocomplete, MenuItem} from '@mui/material';
import { Text, SelectInput} from '../../../components/Inputs/InputFields';

import {DepData3000, DepData5000} from '../../../Data/data'

export default function DepCheck(props) {
  const tankData = props?.warehouseStock

  const navigate = useNavigate()

  const [gdate] = useGdate()

  const [selectDepList, setSelectDepList] = useState('')
  const [depData, setDepData] = useState([])
  const [product] = useState(tankData?.productCode?.product?._id)
  const [stock, setStock] = useState(tankData?.stock?.toFixed(3))
  const [dep, setDep] = useState('')
  const [date, setDate] = useState(gdate?.date)

  const [inputValue, setInputValue] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    switch(tankData?.productCode?.product?.capacity || selectDepList){
      case 3000:
        setDepData(DepData3000)
        break;
      case 5000:
        setDepData(DepData5000)
        break;
      default:
        setDepData(DepData3000)
    }
  }, [product, selectDepList])
  

  const handleSubmit = async(e) => {
    e.preventDefault();
    {
      try {
        await setSubmitDisable(true)
        const {data} = await Axios.post(`/product-depth`, {product, dep: dep?.dep , volume: dep?.volume, stock, date})
        if (data?.error){
          toast.error(data?.error)
          setSubmitDisable(false)
        } else {
          toast.success(`Depth ${dep?.dep} | ${dep?.volume} L  Marked`)
          window.location.reload()
        }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
    }

  }

  return (
    <div className='p-3'>
      {tankData && <div className='d-flex justify-content-center align-items-baseline gap-2'>
        <h4>Tank - {tankData.productCode?.product?.name}</h4>
        <dd>{tankData.productCode?.product?.capacity}G</dd>
      </div>}

      <h5 className='text-center'>Depth Check</h5>

      {tankData ? <form onSubmit={handleSubmit}>
        <Text
          label="Date"
          type = 'date'
          value = {date}
          onChange={(e) => setDate(e.target.value)}
          readOnly = 'true'
        />

        {depData && <Autocomplete
          className = 'mb-2'
          size='small'
          value={dep?.dep}
          onChange={(event, value) => {
            setDep(value)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={depData}
          renderInput={(params) => <TextField {...params} label="Depth"/>}
          getOptionLabel = {(params) => `${params.dep} | ${params.volume} L`}
          noOptionsText = "No Depth Found"
        />}

        <Text
          label="Current Stock"
          type = 'Number'
          readOnly = 'true'
          value = {stock}
          onChange={(e) => setStock(e.target.value)}
        />

        <div className='d-flex justify-content-between'>
          Depth Differance
          <dd style={(dep?.volume - stock) > 0 ? {color:'greenyellow'} : {color:'red'}}>{(dep?.volume - stock)?.toFixed(3)}</dd>
        </div>

        <div className="d-grid gap-2">
          <button className='btn btn-primary mt-3' disabled={submitDisable}>{props.btntxt || "SUBMIT"}</button>
          <button className='btn btn-success mt-3' onClick={()=> {
            navigate(`/dashboard/user/tank/depthlist/${tankData?.productCode?.product?._id}`)
          }}>DEPTH MARK LIST</button>
        </div>

      </form>
      :
      <div className='row justify-content-center'>
        <div className='col-xl-4 col-md-6 col-12'>
          <SelectInput
            label = "Select Capacity"
            value = {selectDepList}
            onChange = {(e) => {
              setSelectDepList(e.target.value)
            }}
            items = {
              [3000, 5000].map((i) => (
                <MenuItem value={i}>{i} G</MenuItem>
              ))
            }
          />

          {selectDepList && depData && <Autocomplete
            className = 'mb-2'
            size='small'
            value={dep?.dep}
            onChange={(event, value) => {
              setDep(value)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={depData}
            renderInput={(params) => <TextField {...params} label="Depth"/>}
            getOptionLabel = {(params) => `${params.dep} | ${params.volume} L`}
            noOptionsText = "No Depth Found"
          />}

          <div className='d-flex justify-content-between fs-5'>
            Volume Liters
            <dd>{dep?.volume} L</dd>
          </div>

        </div>
      </div>}
    </div>
  )
}
