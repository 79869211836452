import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useGdate } from '../../../context/date'
import {useSettings} from '../../../context/settings'

import {Autocomplete, TextField, MenuItem} from '@mui/material/'
import { Text, SelectInput } from '../../../components/Inputs/InputFields'

export default function StockManage() {

  const [gdate] = useGdate()
  const [settings] = useSettings()

  const [listProductCodes, setListProductCodes] = useState()
  const [listStock, setListStock] = useState()
  const [noAdjustment, setNoAdjustment] = useState('')

  const [type, setType] = useState('exchange')
  const [inputProductCode, setInputProductCode] = useState('')
  const [selectedProductCode, setSelectedProductCode] = useState('')
  const [wareHouseStock, setWareHouseStock] = useState()
  const [productStock, setProductStock] = useState('')
  const [updateStock, setUpdateStock] = useState('')

  const [date, setDate] = useState(gdate?.date)
  const [amount, setAmount] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadProductCodeList()
  },[])

  useEffect(() => {
    loadStocksList(selectedProductCode)
  },[selectedProductCode])

  useEffect(() => {
    setWareHouseStock()
    loadAdjustment()
  },[type])

  const loadProductCodeList = async() => {
    try {
      const {data} = await Axios.post('/productcodes')
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setListProductCodes(data.filter((i) => i.product?.category?._id !== "63e65efc2f840202604fbb7f"))
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }


  const loadStocksList = async(selectedProductCode) => {
    try {
        const {data} = await Axios.post('/warehousestocks/filterbyproduct', {id : selectedProductCode?._id})
        if (data?.error) {
            toast.error(data.error)
        } else {
            await setListStock(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAdjustment = async() => {
    try {
      const {data} = await Axios.get('/stock-adjustments')
      if(data?.error){
        toast.error(data.error)
      } else {
        setNoAdjustment(data?.length)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }


  const handleAdjustment = async(e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/stock-adjustment`, {refNo: `${noAdjustment + 1}`, type, date, wareHouseStock: productStock, eWareHouseStock: wareHouseStock, quantity: updateStock, amount})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        if (type === 'exchange') {
          const {data} = await Axios.post(`/warehousestock/exchange`, [
            {_id: productStock._id, stock: Number(updateStock).toFixed(3)},
            {_id: wareHouseStock?._id, stock: Number(-updateStock).toFixed(3)}
          ]);
          if(data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success("Product Stock Adjusted")
            window.location.reload()
          }
        } else {
          const {data} = await Axios.post(`/accdatabulk`, [
            {date, type: `${updateStock >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail: {description: `Ref.No : ${noAdjustment + 1} - Adjustment of ${updateStock} ${productStock?.productCode?.product?.unit}`}, amount : `${updateStock >= 0 ? Number(amount)?.toFixed(2) : Number(-amount)?.toFixed(2)}`, accounting: '63ecfbe55b36ecf83d9dd4a3'},
            {date, type: `${updateStock < 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail: {description: `Ref.No : ${noAdjustment + 1} - Adjustment of ${updateStock} ${productStock?.productCode?.product?.unit}`}, amount : `${updateStock < 0 ? Number(amount)?.toFixed(2) : Number(-amount)?.toFixed(2)}`, accounting: '64eec83c972323a31bc16e3d'}
          ])
          if (data?.error) {
            toast.error(data?.error)
            setSubmitDisable(false)
          } else {
            const {data} = await Axios.put(`/warehousestock/${productStock._id}`, { $inc: { 'stock': +(Number(updateStock).toFixed(3)) } })
            if(data?.error) {
              toast.error(data.error)
              setSubmitDisable(false)
            } else {
              toast.success("Product Stock Adjusted")
              window.location.reload()
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center text-capitalize'>Stock Adjustment Form</h5>

      <form onSubmit={handleAdjustment}>
        <Text
          label = "Date"
          type = "date"
          value = {date}
          onChange={(e) => {
            setDate(e.target.value)
          }}
        />

        <SelectInput
          label = "Type"
          value = {type}
          onChange = {(e) => {
            setType(e.target.value)
          }}
          items = {
            ['exchange', 'adjustment']?.map((i) => (
              <MenuItem className='text-capitalize' value={i}>{i}</MenuItem>
            ))
          }
        />

        {listProductCodes && <Autocomplete
            size='small'
            className='mb-2'
            value={selectedProductCode?._id}
            onChange={(event, value) => {
            setSelectedProductCode(value)
            }}
            inputValue={inputProductCode}
            onInputChange={(event, newInputValue) => {
            setInputProductCode(newInputValue)
            }}
            freeSolo
            options={listProductCodes}
            renderInput={(params) => <TextField {...params} label="Product Codes | Brand | Product" autoFocus/>}
            getOptionLabel = {(params) => `${params?.productCode + ' | '+ params.product?.brand?.name + ' ' + params.product?.name}`}
            noOptionsText = "No Product Code Found"
        />}

        {listStock?.length > 0 && type === 'exchange' && <SelectInput
          label = "From Warehouse"
          value = {wareHouseStock}
          onChange = {(e) => {
            setWareHouseStock(e.target.value)
          }}
          items = {
            listStock && listStock?.map((i) => (
              <MenuItem className='text-capitalize' value={i}>{i.wareHouse?.name + ' | ' + new Intl.NumberFormat().format(i?.stock) + ' ' + i.productCode?.product?.unit }</MenuItem>
            ))
          }
        />}

        {listStock?.length > 0 && <SelectInput
            label = "To / Warehouse"
            value = {productStock}
            onChange = {(e) => {
                setProductStock(e.target.value)
            }}
            items = {
                listStock && listStock?.map((i) => (
                <MenuItem className='text-capitalize' value={i}>{i.wareHouse?.name + ' | ' + new Intl.NumberFormat().format(i?.stock) + ' ' + i.productCode?.product?.unit }</MenuItem>
                ))
            }
        />}

        {wareHouseStock && productStock && wareHouseStock?._id === productStock?._id ? <dd className='text-center text-danger'>You Selected Same Warehouse</dd> : ''}

        <Text
          label = {`Stock in ${productStock.productCode?.product.unit ? productStock.productCode?.product.unit : ''}`}
          type = "Number"
          value = {updateStock}
          onChange={(e) => {
            setUpdateStock(e.target.value)
            setAmount(Math.abs(productStock?.productCode?.cost * e.target.value)?.toFixed(2))
          }}
        />

        {type === 'adjustment' && <Text
          label = "Value"
          type = "Number"
          value = {amount}
          onChange={(e) => {
            setAmount(e.target.value)
          }}
          end = {settings?.currencyEnd}
        />}

        <div className='d-flex justify-content-between p-2'>
          <p>Stock After Update</p>
          <p>{new Intl.NumberFormat().format((productStock?.stock + Number(updateStock))) + ' ' + productStock.productCode?.product?.unit}</p>
        </div>

        <div className="d-grid gap-2">
            <button className='btn btn-primary mt-3' disabled={wareHouseStock?._id !== productStock?._id && (type === 'exchange' ? (wareHouseStock?.stock >= updateStock ? true : false) : true) && updateStock && amount ? submitDisable : true}>{type === 'exchange' ? "EXCHANGE" : "ADJUSTMENT"}</button>
        </div>
      </form>
  </div>
  )
}
