import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth';
import {useSettings} from '../../../context/settings'
import { useGdate } from '../../../context/date'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, DeleteForever} from '@mui/icons-material/'

import StockManage from './StockManage';

export default function ListStockAdjustment() {

    const [auth] = useAuth()
    const [settings] = useSettings()
    const [gdate] = useGdate()

    const [listStockAdjustments, setListStockAdjustments] = useState('')
    //Model
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [visible1, setVisible1] = useState(false)
    const [selected1, setSelected1] = useState(null)

    const [date, setDate] = useState(gdate?.date)

    const [submitDisable, setSubmitDisable] = useState(false)


    useEffect(() => {
        loadAdjustment()
    }, [])
  
    const loadAdjustment = async() => {
        try {
            const {data} = await Axios.post('/stock-adjustments')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListStockAdjustments(data)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    const handleDeleteAdjustment = async(selected) => {
        try {
             await setSubmitDisable(true)
            const {data} = await Axios.delete(`/stock-adjustment/${selected._id}`)
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                if (selected?.type === 'exchange') {
                    const {data} = await Axios.post(`/warehousestock/exchange`, [
                        {_id: selected?.wareHouseStock._id, stock: Number(-selected?.quantity).toFixed(3)},
                        {_id: selected?.eWareHouseStock?._id, stock: Number(selected?.quantity).toFixed(3)}
                    ]);
                    if(data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        toast.success("Exchanged Deleted")
                        window.location.reload()
                    }
                } else {
                    const {data} = await Axios.post(`/accdatabulk`, [
                        {date, type: `${selected?.quantity < 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail: {description: `Ref.No : ${selected?.refNo} - Adjustment of ${selected?.quantity} ${selected?.wareHouseStock?.productCode?.product?.unit} Deleted`}, amount : `${selected?.quantity < 0 ? Number(selected?.amount)?.toFixed(2) : Number(-selected?.amount)?.toFixed(2)}`, accounting: '63ecfbe55b36ecf83d9dd4a3'},
                        {date, type: `${selected?.quantity >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail: {description: `Ref.No : ${selected?.refNo} - Adjustment of ${selected?.quantity} ${selected?.wareHouseStock?.productCode?.product?.unit} Deleted`}, amount : `${selected?.quantity >= 0 ? Number(selected?.amount)?.toFixed(2) : Number(-selected?.amount)?.toFixed(2)}`, accounting: '64eec83c972323a31bc16e3d'}
                    ])
                    if (data?.error) {
                        toast.error(data?.error)
                        setSubmitDisable(false)
                    } else {
                        const {data} = await Axios.put(`/warehousestock/${selected?.wareHouseStock._id}`, { $inc: { 'stock': -(Number(selected?.quantity).toFixed(3)) } })
                        if(data?.error) {
                            toast.error(data.error)
                            setSubmitDisable(false)
                        } else {
                            toast.success("Adjustment Deleted")
                            window.location.reload()
                        }
                    }
                }
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
        }
    }

    //FIFO Cost manage
    // Use only for - Stock adjustment

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='d-flex gap-2 mb-2'>
                    <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAdd(true)}}><Add/> ADJUST</button>
                </div>
                {listStockAdjustments && 
                <MUITable
                    title = "List of Stock Adjustments"
                    id = {(row) => row._id}
                    columns = {[
                        { field: 'productCode', headerName: 'Product Code', width: 150, renderCell: (i) => i.row.wareHouseStock?.productCode?.productCode, valueGetter: (i) => i.row.wareHouseStock?.productCode?.productCode},
                        { field: 'productName', headerName: 'Product', width: 170, renderCell: (i) => i.row.wareHouseStock?.productCode?.product?.name, valueGetter: (i) => i.row.wareHouseStock?.productCode?.product?.name},
                        { field: 'date', headerName: 'Date', width: 100},
                        { field: 'type', headerName: 'Type', width: 125, renderCell: (i) => <div className='text-uppercase'>{i.row.type}</div>, valueGetter: (i) => i.row.type},
                        { field: 'wareHouse', headerName: 'Ware House', width: 125, renderCell: (i) => i.row.wareHouseStock?.wareHouse?.name, valueGetter: (i) => i.row.wareHouseStock?.wareHouse?.name},
                        { field: 'transferFrom', headerName: 'Transfer From', width: 125, renderCell: (i) => i.row.eWareHouseStock?.wareHouse?.name, valueGetter: (i) => i.row.eWareHouseStock?.wareHouse?.name},
                        { field: 'quantity', headerName: 'Quantity', width: 100, renderCell: (i) => i.row.quantity + " " + i.row.wareHouseStock?.productCode?.product?.unit, filterable: false, sortable: false},
                        { field: 'amount', headerName: 'Value', width: 130, renderCell: (i) => (settings.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.amount)), filterable: false, sortable: false},
                        { field: 'createdAt', headerName: 'Updated On', width: 170, renderCell: (i) => moment(i.row.createdAt).format("YYYY-MM-DD hh:mm A"), filterable: false, sortable: false},
                        { field: 'action', headerName: 'Action', width: 80,filterable: false, sortable: false, renderCell: (i) => 
                        <div className='d-flex gap-2'>
                        <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                            onClick={() => {
                            setVisible1(true)
                            setSelected1(i.row)
                            }}
                        />
                        </div>
                        },
                    ]}
                    rows = {listStockAdjustments}
                />}

                <ModalBasic
                open = {visible1}
                onClose = {()=> {
                setVisible1(false)
                }}
                content = {
                    auth?.employee?.role === 0 ?
                    <div className='d-flex justify-content-between'>
                        <p>Are You sure want to?</p>
                        <button className = 'btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                            handleDeleteAdjustment(selected1)
                        }}>Delete
                        </button>
                    </div>
                    :
                    <div className='d-flex justify-content-center w-100'>
                        <p>You don't have Permission to Delete</p>
                    </div>
                }
                />

                <ModalBasic
                    open = {visibleAdd}
                    onClose = {()=> {
                    setVisibleAdd(false)
                    }}
                    content = {<StockManage/>}
                />
            </div>
        </div>
    )
}
