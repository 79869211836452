import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth';

import {SwitchInput} from '../../components/Inputs/InputFields'
import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

import {Add, Edit, DeleteForever, Receipt, Check, Clear} from '@mui/icons-material/'

import CreateEmployee from './CreateEmployee'
import UpdateEmployee from './UpdateEmployee'

import AccountsForm from '../accounts/DoubleEntires/AccountsForm'

export default function ListEmployee() {

  const navigate = useNavigate()

  const [auth] = useAuth()

  const [listEmployee, setListEmployee] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [visibleCredit, setVisibleCredit] = useState(false)
  const [visibleDebit, setVisibleDebit] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  const [checked, setChecked] = useState(true)

  useEffect(() => {
      loadEmployee()
  }, [])
  

  const loadEmployee = async() => {
      try {
        const {data} = await Axios.post('/employees/filter', {role: { $gte: auth?.employee?.role } })
        if(data?.error){
            toast.error(data.error)
        } else {
            setListEmployee(data)
        }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  const handleDeleteEmployee = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} =  await Axios.delete(`/employee/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success("Employee Deleted Successfully")
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> ADD NEW</button>
          </div>

          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex justify-content-end gap-2 mb-2'>
            <button className = 'btn btn-outline-danger w-50' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
            <button className = 'btn btn-outline-success w-50' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>  
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <SwitchInput
            endLabel = "Active"
            checked = {checked}
            color = 'primary'
            onChange = {(e) => {
              setChecked(preVal => !preVal)
            }}
          />
        </div>
        {listEmployee && 
          <MUITable
            title = "List of Employees"
            id = {(row) => row._id}
            columns = {[
                { field: 'nic', headerName: 'NIC', width: 125},
                { field: 'name', headerName: 'Name', width: 180},
                { field: 'email', headerName: 'Email', width: 220},
                { field: 'phoneNumber', headerName: 'Phone Number', width: 120},
                { field: 'role', headerName: 'Role', width: 120, renderCell: (params) => {
                switch(params.row.role) {
                  case 0:
                    return "Admin"
                  case 1:
                    return "User"
                  case 2:
                    return "Employee"
                  default:
                    return "Employee"
                }}},
                { field: 'designation', headerName: 'Designation', width: 125},
                { field: 'active', headerName: 'Active', width: 100, renderCell: (i) => i.row.active ? <Check color='success' /> : <Clear color='error'/>, filterable: false, sortable: false},
                { field: 'address', headerName: 'Address', width: 300},
                { field: 'action', headerName: 'Action', width: 150, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Receipt fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={(e) => {
                      navigate(`/dashboard/user/employee-accounts/${i?.row?._id}`)
                    }}
                  />
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                  {/* <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible1(true)
                      setSelected1(i.row)
                    }}
                  /> */}
                </div>
                },
            ]}
            rows = {checked ? listEmployee?.filter((f) => f?.active === true) : listEmployee}
          />
        }

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateEmployee/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateEmployee employee = {selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between gap-2'>
                <p>Are You sure want to? On deletation selected Customer data will be erased</p>
                <div>
                  <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                      handleDeleteEmployee(selected1._id)
                    }}>Delete
                  </button>
                </div>
              </div>
              :
              <div>
                <p className='text-center'>You don't have Permission to Delete</p>
                <p className='text-center fs-4'>Login as Admin</p>
              </div>
              
          }
        />

        <ModalBasic
          width = '80%'
          open = {visibleDebit}
          onClose = {()=> {
          setVisibleDebit(false)
          }}
          content = {<AccountsForm data= {{inputAccounting: '64cb525518dca625f12ce5bf'}}/>}
        />

        <ModalBasic
          width = '80%'
          open = {visibleCredit}
          onClose = {()=> {
          setVisibleCredit(false)
          }}
          content = {<AccountsForm data= {{inputAccounting2: '64cb525518dca625f12ce5bf'}}/>}
        />
      </div>
    </div>
  )
}
