import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectInput} from '../../components/Inputs/InputFields'
import { MenuItem } from '@mui/material';

export default function CreatePump() {

  const [listTanks, setListTanks] = useState('')
  const [listBooths, setListBooths] = useState('')

  const [name, setName] = useState('')
  const [wareHouseStock, setWareHouseStock] = useState('')
  const [booth, setBooth] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadData();
    loadBooths();
  }, []);

  const loadData = async() => {
    try {
      const {data} = await Axios.post(`/warehousestocks/filtercategory`, {_id: "63e65efc2f840202604fbb7f"})
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListTanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBooths = async() => {
    try {
      const {data} = await Axios.get(`/booths`);
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListBooths(data);
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/pump`, {name, wareHouseStock, booth});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Pump sucessfully Created");
        window.location.reload()
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Pump</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Pump Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />

        <SelectInput
          label = "Tank"
          value = {wareHouseStock}
          onChange = {(e) => {
            setWareHouseStock(e.target.value)
          }}
          items = {
            listTanks && listTanks?.map((i) => (
              <MenuItem value={i._id}>{i.productCode?.product?.name}</MenuItem>
            ))
          }
        />
        <p className='text-center'>Tanks stocks will be recorded with main Warehouse</p>

        <SelectInput
          label = "Booth"
          value = {booth}
          onChange = {(e) => {
            setBooth(e.target.value)
          }}
          items = {
            listBooths && listBooths?.map((i) => (
              <MenuItem value={i._id}>{i.name}</MenuItem>
            ))
          }
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            CREATE PUMP
          </button>
        </div>
      </form>
    </div>
  )
}
