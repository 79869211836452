import React from 'react'
import { FormControl, InputLabel, MenuItem, Select, Switch, TextField, InputAdornment, Stack, Typography, Checkbox } from '@mui/material/'

export function Text(props) {
    const InputProps = {
        readOnly: (props.readOnly === 'true' ? true : false),
        startAdornment: (props.start && <InputAdornment position="start">{props.start}</InputAdornment>),
        endAdornment: (props.end && <InputAdornment position="end">{props.end}</InputAdornment>)
    }
  return (
    <TextField
      key = {props.label}
      className = 'mb-2'
      label= {props.label} 
      fullWidth
      size = {props.size ? props.size : 'small'} 
      defaultValue = {props.defaultValue}
      value = {props.value}
      onChange = {props.onChange}
      type = {props.type}
      autoFocus = {props.focus}
      InputProps={InputProps}
    />
  )
}

export  function SelectInput(props) {
  return (
    <FormControl className = {props?.className || 'mb-2'} fullWidth size="small">
      <InputLabel id="select-small">{props.label}</InputLabel>
      <Select
        key = {props.label}
        labelId = "simple-select-label"
        id = "simple-select"
        fullWidth
        label = {props.label}
        size = 'small'
        value = {props.value}
        defaultValue = {props.defaultValue}
        onChange = {props.onChange}
      >
        <MenuItem kay = 'none' value="">
          <em>None</em>
        </MenuItem>
        {props.items}
      </Select>
    </FormControl>
  )
}

export function SwitchInput(props) {
  return (
    <Stack key={props.startLabel + props.endLabel} className='justify-content-center mb-2' direction="row" alignItems="center">
      <Typography>{props.startLabel}</Typography>
      <Switch checked={props.checked} onChange={props.onChange} color = {props.color ? props.color : 'default'}/>
      <Typography>{props.endLabel}</Typography>
    </Stack>
  )
}





