import React, {useEffect, useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import {useAuth} from '../../../context/auth'
import {useSettings} from '../../../context/settings'

import { Text } from '../../../components/Inputs/InputFields'
import {TextField, Autocomplete} from '@mui/material';

import Scanner from '../../../components/Scan-Qr/Scanner'

export default function CreateFuelUps() {
    const [auth] = useAuth()
    const [settings] = useSettings();

    const [listVehicles, setListVehicles] = useState('')
    const [availablePoints, setavailablePoints] = useState('')
    const [totalDayAmount, setTotalDayAmount] = useState('')


    const [vehicle, setVehicle] = useState('')
    const [amount, setAmount] = useState('')
    const [points, setPoints] = useState('0')
    const [employee] = useState(auth?.employee?._id)
    const [date] = useState(moment(new Date()).format("YYYY-MM-DD"))

    const [phoneNumber, setPhoneNumber] = useState('')
    const [redeem, setRedeem] = useState('0')

    const [vehicleInput, setvehicleInput] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadvehicles()
    }, [])

    useEffect(() => {
        (vehicle ? loadAvailablePoints() : setavailablePoints('0.00'))
        setPoints('0')
        setPhoneNumber(auth?.employee?.role === 0 ? vehicle?.customer?.phoneNumber : '')
    }, [vehicle])

    useEffect(() => {
        if (vehicle?.vehicleNo !== vehicleInput) {
            listVehicles && setVehicle(listVehicles?.find((i) => i.vehicleNo === vehicleInput) ? listVehicles?.find((i) => i.vehicleNo === vehicleInput) : '')
        }
    }, [vehicleInput])

    useEffect(() => {
        vehicle && setPoints(
            ( totalDayAmount >= vehicle?.pointsCategory?.maxAmount ? '0'
            :
            ((Number(amount) + totalDayAmount) > vehicle?.pointsCategory?.maxAmount ? ((vehicle?.pointsCategory?.maxAmount - totalDayAmount) * vehicle?.pointsCategory?.pointsRate)
            :
            amount * vehicle?.pointsCategory?.pointsRate
            )
            )
        )
    }, [amount])

    const loadvehicles = async() => {
        try {
            const {data} = await Axios.get('/pc-vehicles')
            if(data?.error){
                toast.error(data.error)
            } else {
                setListVehicles(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const getBarcodeData = (data) => {
        setvehicleInput(data.result.decodedText)
    }

    const loadAvailablePoints = async() => {
        try {
            const {data} = await Axios.post('/fillups', {vehicle: vehicle?._id})
            if(data?.error){
                toast.error(data.error)
            } else {
                setavailablePoints((data.reduce((t,i) => t = t + (i?.points - i?.redeem), 0))?.toFixed(2))
                setTotalDayAmount(( (data?.filter((i) => moment(i.createdAt).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD"))).reduce((t,i) => t = t + Number(i?.amount), 0) ))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }
    
    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            if (redeem <= Number(availablePoints)) {
                if ((redeem <= Number(amount))) {
                    const {data} = await Axios.post('/fillup', {vehicle: vehicle?._id, amount, points, redeem: Number(redeem)?.toFixed(2), employee, date})
                    if (data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        toast.success("Fill Up Updated Sucessfully")
                        window.location.reload()
                    }
                } else {
                    toast.error(`Redeem Points Should be less than Amount`)
                    setSubmitDisable(false)
                }
            } else {
                toast.error(`Available Points is ${availablePoints}`)
                setRedeem(availablePoints)
                setSubmitDisable(false)
            }
        } catch (err) {
            console.log(err)
            toast.error('Something went wrong')
        }
    }

    return (
        <div className='p-3'>
            <h4 className='text-center'>New fill up</h4>
            <div className='mb-2'>
                <Scanner result = {getBarcodeData}/>
            </div>
            
            <form onSubmit={handleSubmit}>
                {listVehicles && <Autocomplete
                    size='small'
                    className='mb-2'
                    value={vehicle?._id}
                    onChange={(event, value) => {
                        setVehicle(value)
                    }}
                    inputValue={vehicleInput}
                    onInputChange={(event, newInputValue) => {
                        setvehicleInput(newInputValue)
                    }}
                    freeSolo
                    filterSelectedOptions
                    options={listVehicles}
                    renderInput={(params) => <TextField {...params} label="Vehicle" autoFocus />}
                    getOptionLabel = {(params) => `${params?.vehicleNo}`}
                    noOptionsText = "No vehicle Found"
                />}

                <Text
                    label="Amount"
                    type="Number"
                    value = {amount}
                    onChange={(e) => {
                        setAmount(e.target.value)
                    }}
                    end = {settings?.currencyEnd}
                />

                <div className='d-flex justify-content-between pt-2'>
                    <dd> Available Points :</dd>
                    <dd>{availablePoints ? Number(availablePoints)?.toFixed(2) : '0.00'}</dd>
                </div>

                <div className='d-flex justify-content-between'>
                    <dd> Maximum Points / Day :</dd>
                    <dd>{vehicle ? Number(vehicle.pointsCategory.maxAmount * vehicle.pointsCategory.pointsRate)?.toFixed(2) : '0.00'}</dd>
                </div>

                <div className='d-flex justify-content-between'>
                    <dd> Points For Fill Up :</dd>
                    <dd>{amount ? Number(points)?.toFixed(2) : '0.00'}</dd>
                </div>

                <Text
                    label="Phone Number"
                    value = {phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />

                {phoneNumber && phoneNumber === vehicle?.customer?.phoneNumber ?
                    <Text
                        label="Redeem Points"
                        type="Number"
                        value = {redeem}
                        onChange={(e) => setRedeem(e.target.value)}
                    />
                :
                <p className='text-center'>Enter Phone No for Redeem Points</p>}

                <div className='d-flex justify-content-between'>
                    <p> Amount to be Paid :</p>
                    <p>{amount ? Number(amount - redeem)?.toFixed(2) : '0.00'}</p>
                </div>

                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    RECORD FILLUP
                </button>
                </div>
            </form>
    </div>
    )
}
