import React, {useEffect, useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../components/Inputs/InputFields'
import {TextField, Autocomplete} from '@mui/material';

export default function CreateTank() {

  const [typeitems, setTypeitems] = useState('')

  const [name, setName] = useState("")
  const [category] = useState('63e65efc2f840202604fbb7f')
  const [type, setType] = useState('')
  const [unit] = useState('L')
  const [capacity, setCapacity] = useState('')

  const [inputValue, setInputValue] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadFuelTypes()
  },[])

  const loadFuelTypes = async() => {
    try {
      const {data} = await Axios.get(`/fueltypes`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setTypeitems(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/product`, {name, category, type, unit, capacity});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        const {_id} = data;
        try {
          const data1 = await Axios.post('/productcode', {productCode: _id, product: _id, cost: '0', price: '0'})
          if(data1?.data?.error) {
            toast.error(data1.data.error)
            setSubmitDisable(false)
          } else {
            const data2 = await Axios.post(`/warehousestock`, {wareHouse: '63f48039710db4fab3a62abb', productCode: data1?.data?._id})
            if (data2?.data?.error) {
              toast.error(data2.data.error)
              setSubmitDisable(false)
            } else {
              toast.success('Tank Created Sucessfully')
              window.location.reload()   
            }
          }
        } catch (err) {
          toast.error("System error, Try Again!")
          setSubmitDisable(false)
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Tank Creation Failed Try Again");
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Tank</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Tank Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />

        {typeitems && <Autocomplete
          className='mb-2'
          style={{minWidth: '50%'}}
          size='small'
          value={type?._id}
          onChange={(event, value) => {
            setType(value)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          options={typeitems}
          renderInput={(params) => <TextField {...params} label="Type"/>}
          getOptionLabel = {(params) => params?.fuelType}
          noOptionsText = "No Fuel Type Found"
        />}

        <Text
          label="Capacity in Gallon"
          type="Number"
          value = {capacity}
          onChange={(e) => setCapacity(e.target.value)} 
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            CREATE TANK
          </button>
        </div>
      </form>
    </div>
  )
}
