import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"
import {Text} from '../../../components/Inputs/InputFields'


export default function CreatePointsCategory() {

  const [type, setType] = useState('')
  const [pointsRate, setPointsRate] = useState('')
  const [maxAmount, setMaxAmount] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async() => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/pointscategory`, {type, pointsRate: Number(pointsRate / 100), maxAmount})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success("Points Category Added Sucessfully")
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Points Category</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Type"
          value = {type}
          onChange={(e) => setType(e.target.value)}
          focus = 'true'
        />
        <Text
          label="Points %"
          value = {pointsRate}
          type = "Number"
          onChange={(e) => setPointsRate(e.target.value)} 
        />
        <dd>Rs.100 = {pointsRate * (1)}</dd>
        <Text
          label="Maximum Amount"
          value = {maxAmount}
          type = "Number"
          onChange={(e) => setMaxAmount(e.target.value)} 
        />
        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            CREATE POINTS CATEGORY
          </button>
        </div>
      </form>
    </div>
  )
}
