import React, { useState, createContext, useContext, useEffect } from "react";
import Axios from 'axios'

const SettingsContext = createContext();

const SettingsProvider = (props) => {
    const [settings, setSettings] = useState({
        name: '',
        phoneNumber: '',
        address: '',
        mail: '',
        currencyStart: '',
        currencyEnd: '',
        billReceipt: '',
        creditMsgPay : ''
    });

    useEffect(() => {
        loadSettings()
    }, []);

    const loadSettings = async() => {
        try {
            const {data} = await Axios.get('/settings/6440fc36e4c208d8ead76411')
            if(data?.error){
                console.log(data?.error)
            } else {
                setSettings(data)
            }
        } catch (error) {
            console.log("Settings => " + error)
        }
    }

    return(
        <SettingsContext.Provider value={[settings, setSettings]}>
            {props.children}
        </SettingsContext.Provider>
    );
};

const useSettings = () => useContext(SettingsContext);

export {SettingsProvider, useSettings};