import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { Autocomplete, TextField } from '@mui/material';

export default function UpdateVehicle(props) {
  const vehicleData = props.data

  const [listCustomers, setListCustomers] = useState('')
  const [listPointsCategories, setListPointsCategories] = useState('')
  //Model

  const [customerInput, setCustomerInput] = useState('')
  const [pointsCategoryInput, setPointsCategoryInput] = useState('')

  const [customer, setCustomer] = useState(vehicleData?.customer?._id)
  const [pointsCategory, setPointsCategory] = useState(vehicleData?.pointsCategory?._id)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadCustomers()
      loadPointsCategories()
  }, [])
  

  const loadCustomers = async() => {
      try {
          const {data} = await Axios.get('/customers')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListCustomers(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadPointsCategories = async() => {
    try {
        const {data} = await Axios.get('/pointscategories')
        if(data?.error){
            toast.error(data.error)
        } else {
            setListPointsCategories(data)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} =  await Axios.put(`/pc-vehicle/${vehicleData?._id}`, {customer, pointsCategory})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`Vehicle ${vehicleData?.vehicleNo} Updated Sucessfully`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong, Try Again!')
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Vehicle - {vehicleData?.vehicleNo}</h4>
      <form onSubmit={handleSubmit}>
        {listCustomers && <Autocomplete
          size='small'
          className='mb-2'
          value={customer?._id}
          onChange={(event, value) => {
            setCustomer(value)
          }}
          inputValue={customerInput}
          onInputChange={(event, newInputValue) => {
            setCustomerInput(newInputValue)
          }}
          options={listCustomers}
          renderInput={(params) => <TextField {...params} label="Customer"/>}
          getOptionLabel = {(params) => `${params?.phoneNumber + ' | '+ params?.name}`}
          noOptionsText = "No Customer Found"
        />}

        {listPointsCategories && <Autocomplete
          size='small'
          className='mb-2'
          value={pointsCategory?._id}
          onChange={(event, value) => {
            setPointsCategory(value)
          }}
          inputValue={pointsCategoryInput}
          onInputChange={(event, newInputValue) => {
            setPointsCategoryInput(newInputValue)
          }}
          options={listPointsCategories}
          renderInput={(params) => <TextField {...params} label="Points Category"/>}
          getOptionLabel = {(params) => `${params?.type}`}
          noOptionsText = "No Points Category Found"
        />}
        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            UPDATE VEHICLE
          </button>
        </div>
      </form>
    </div>
    )
}
