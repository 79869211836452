import React, {useRef,useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import { useReactToPrint } from 'react-to-print'

import { useGdate } from '../../../context/date'
import { useDk } from '../../../context/dk'

import MUITable from '../../../components/table/MUITable'

import {CallReceived, CallMade, Check, Warning, Print} from '@mui/icons-material/'


export default function ListCheques() {

    const [gdate] = useGdate()
    const [dk, setDk] = useDk()

    const [ListCheques, setListCheques] = useState('')

    useEffect(() => {
        loadCheques()
    }, [])

    const loadCheques = async() => {
        try {
            const {data} = await Axios.post('/cheques', {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListCheques(data)
            }
        } catch (err) {
            toast.error("Something went wrong, Try Again!")
            console.log(err)
        }
    }

    //Print Function
    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Bank Accounts`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        },
    })

    return (
        <div className='row justify-content-center'>
            <div className='row col-12 mb-3 justify-content-center'>
                <div>
                    <button className = 'btn btn-warning' onClick={() => {printData()}}><Print/></button>
                </div>

                <div className='col-xxl-10 col-xl-11 col-12 p-3' ref={componentRef}>
                    {/* <h5 className='text-center'>All Received / Given Cheques</h5>
                    <dd className='text-center'>{gdate?.reportStartDate} To {gdate?.reportEndDate}</dd> */}
                    {ListCheques && 
                        <MUITable
                            title = {'All Received / Given Cheques | ' + gdate?.reportStartDate + " To " + gdate?.reportEndDate}
                            id = {(row) => row._id}
                            columns = {[
                                { field: 'type', headerName: 'Type', width: 80, filterable: false, sortable: false, renderCell : (i) => i.row.status === "credit" ? <CallReceived color='success'/> : <CallMade color='error'/>},
                                { field: 'date', headerName: 'Date', width: 100},
                                { field: 'customer', headerName: 'Customer', width: 220, renderCell : (i) => i.row.chqCustomer?.name, valueGetter : (i) => i.row.customer?.name},
                                { field: 'chqNo', headerName: 'Cheque No', width: 120},
                                { field: 'chqDate', headerName: 'Cheque Date', width: 100},
                                { field: 'deposit', headerName: 'Release', width: 80, filterable: false, sortable: false, renderCell : (i) => i.row.chqDeposit ? <Check color='success'/> : <Warning color='warning'/>},
                                { field: 'amount', headerName: 'Amount', width: 150, renderCell : (i) => new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i.row.amount), valueGetter : (i) => i.row.amount},
                            ]}
                            rows = {ListCheques}
                        />
                    }
                </div>
            </div>
        </div>
        )
}
