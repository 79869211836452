import React, {useEffect, useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Check, Clear} from '@mui/icons-material';
import {Tabs, Tab, MenuItem} from '@mui/material/'
import {Text, SelectInput} from '../components/Inputs/InputFields'

import VersionLog from './VersionLog'
import FileUpload from '../components/FIleUpload/FileUpload'
import MessageDetails from './Message/MessageDetails'

import { Image } from 'antd';
import POS from '../Assets/images/POS.png'
import Invoice from '../Assets/images/Invoice.png'
import InvoiceNoName from '../Assets/images/invoice-noName.png'

export default function Settings() {
    const [value, setValue] = useState(0)

    const [listBank, setListBank] = useState('')

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [mail, setMail] = useState('')
    const [logo, setLogo] = useState()

    const [currencyStart, setCurrencyStart] = useState('')
    const [currencyEnd, setCurrencyEnd] = useState('')

    const [nameOnReceipt, setNameOnReceipt] = useState(false)
    const [billReceipt, setBillReceipt] = useState('')

    const [creditMsgPay, setCreditMsgPay] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadBank()
        loadData()
    },[])


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadData = async() => {
        try {
            const {data} = await Axios.get(`/settings/6440fc36e4c208d8ead76411`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                await setId(data?._id)
                await setName(data?.name)
                await setPhoneNumber(data?.phoneNumber)
                await setAddress(data?.address)
                await setMail(data?.mail)
                await setLogo(data?.logo)
                await setCurrencyStart(data?.currencyStart)
                await setCurrencyEnd(data?.currencyEnd)
                await setNameOnReceipt(data?.nameOnReceipt)
                await setBillReceipt(data?.billReceipt)
                await setCreditMsgPay(data?.creditMsgPay?._id)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadBank = async() => {
        try {
            const {data} = await Axios.get(`/bank-accounts`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                setListBank(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const getImageData = (data) => {
        if(data?.file) {
          setLogo(data)
        } else {
          setLogo()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/settings/${id}`, {name, phoneNumber, address, mail, logo, currencyStart, currencyEnd, nameOnReceipt, billReceipt, creditMsgPay: creditMsgPay?.toString()});
            if(data?.error) {
                toast.error(data.error);
                setSubmitDisable(false)
            } else {
                toast.success("System Data Updated");
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-12'>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile = 'true'
                >
                    <Tab label="System" />
                    <Tab label="Currency" />
                    <Tab label="Receipt" />
                    <Tab label="Message" />
                    <Tab label="Version" />

                </Tabs>
            </div>

            <form className='row justify-content-center' onSubmit={handleSubmit} >
                {value === 0 && 
                <div className='row justify-content-center'>
                    <div className='col-xl-4 col-md-6 col-12 p-3'>
                        <Text
                            label="Business Name"
                            value = {name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Text
                            label="Phone Number"
                            type ="Number"
                            value = {phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)} 
                            end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
                        />
                        <Text
                            label="Address"
                            value = {address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                        <Text
                            label="Mail Address"
                            type="email"
                            value = {mail}
                            onChange={(e) => setMail(e.target.value)}
                            end = {mail.trim() ? (mail.includes('@') ? <Check color='success' /> : <Clear color='error'/>) : ''}
                        />
                    </div>

                    <div className='col-xl-4 col-md-6 col-12 p-3'>
                        <FileUpload submit = {getImageData} fileType= "Image" hidePreview = {true}/>
                        {logo?.file ? 
                            <div className='col-12'>
                                <img
                                    className='w-100'
                                    src={logo?.file}
                                    alt='Logo Load Failed Reload'
                                />
                                <button className='btn btn-danger mt-2 w-100' onClick={() => {setLogo('')}}>REMOVE LOGO</button>
                            </div>
                            :
                            <p className='text-center fs-6'>Set Organization Logo</p>
                        }
                    </div>
                </div>}

                {value === 1 && <div className='col-xl-4 col-md-6 col-12 p-3'>
                    <Text
                        label="Currency Symbol"
                        value = {currencyStart}
                        end = {' 123,456.00'}
                        onChange={(e) => setCurrencyStart(e.target.value)}
                    />
                    <Text
                        label="Currency Symbol"
                        value = {currencyEnd}
                        start = {' 123,456.00'}
                        onChange={(e) => setCurrencyEnd(e.target.value)}
                    />
                </div>}

                {value === 2 && <div className='col-xl-4 col-md-6 col-12 p-3'>
                    <SelectInput
                        label = "Bill Receipt"
                        value = {billReceipt}
                        onChange = {(e) => {
                            setBillReceipt(e.target.value)
                        }}
                        items = {
                            ['invoice', 'pos'].map((i) => (
                                <MenuItem value={i}>{i?.toUpperCase()}</MenuItem>
                            ))
                        }
                    />
                    {billReceipt === 'invoice' && <SelectInput
                        label = "Name on Receipt"
                        value = {nameOnReceipt}
                        onChange = {(e) => {
                            setNameOnReceipt(e.target.value)
                        }}
                        items = {
                            [{item:'TRUE', value: true}, {item:'FALSE', value: false}].map((i) => (
                                <MenuItem value={i?.value}>{i?.item}</MenuItem>
                            ))
                        }
                    />}
                    <div className='row justify-content-center p-3'>
                        {billReceipt === 'invoice' ?
                            <Image
                                width={'100%'}
                                src={nameOnReceipt ?  Invoice : InvoiceNoName}
                                alt='Bill-Receipt'
                            />
                            :
                            <Image
                                width={'70%'}
                                src={POS}
                                alt='Bill-Receipt'
                            />
                        }
                    </div>
                </div>}

                {value === 3 && <div className='row justify-content-center col-12 p-3'>
                    <div className='col-xl-8 col-12'>
                        <MessageDetails/>
                    </div>

                    <div className='col-xl-4 col-md-6 col-12'>
                        <SelectInput
                            label = "Payment Bank"
                            value = {creditMsgPay}
                            onChange = {(e) => {
                                setCreditMsgPay(e.target.value)
                            }}
                            items = {
                                listBank && listBank.map((i) => (
                                    <MenuItem value={i?._id}>{i?.bank?.toUpperCase()  + ' | ' +  i?.branch + ' | ' + i?.accountNo}</MenuItem>
                                ))
                            }
                        />
                    </div>
                </div>}

                {value === 4 && <div className='col-12 p-3'>
                    <VersionLog/>
                </div>}
       
            </form>

            <div class="d-flex justify-content-center">
                <button className = 'btn btn-primary' type = 'submit' onClick={handleSubmit}  disabled={submitDisable}>
                    UPDATE SETTINGS
                </button>
            </div>
        </div>
    )
}
