import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useAuth } from '../../../context/auth'
import { useGdate } from '../../../context/date'
import {useSettings} from '../../../context/settings'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, DeleteForever} from '@mui/icons-material/'

import CreateFuelUps from './CreateFuelUps'

export default function ListFuelUps() {
  const [auth] = useAuth();
  const [gdate] = useGdate();
  const [settings] = useSettings();

  const [listFuelUps, setListFuelUps] = useState('')


  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [selectedDelete, setSelectedDelete] = useState('')
  const [visibleDelete, setVisibleDelete] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadFuelUps()
  }, [])
  

  const loadFuelUps = async() => {
      try {
          if(auth?.employee?.role <= 1) {
            const {data} = await Axios.post('/fillups', {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListFuelUps(data)
            }
          } else {
            const {data} = await Axios.post('/fillups', {employee: auth?.employee?._id, date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListFuelUps(data)
            }
          }
      } catch (err) {
        console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }
  
  const handleDeletefuelUp = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/fillup/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('Fill Up Records Deleted Successfully')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }
  
  return (
    <div className='row justify-content-center'>
      <div className='col-lg-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
            <div className='col-xxl-3 col-xl-4 col-md-6 col-12 d-flex gap-2 mb-2'>
                <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            </div>
        </div>

        <div className='d-flex justify-content-center gap-2 align-items-baseline'>
          <h5>Fillup Details</h5>
          <p>{gdate?.reportStartDate + ' - ' + gdate?.reportEndDate} </p>
        </div>

        {listFuelUps && 
          <MUITable
            title = {'Fillup Details | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
            id = {(row) => row._id}
            columns = {[
              { field: 'vehicle', headerName: 'Vehicle', width: 120, renderCell: (i) => i.row?.vehicle?.vehicleNo, valueGetter: (i) => i.row?.vehicle?.vehicleNo},
              { field: 'amount', headerName: 'Amount', width: 125, renderCell: (i) => `${settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.amount)}`, filterable: false, sortable: false},
              { field: 'points', headerName: 'Points', width: 100, renderCell: (i) => new Intl.NumberFormat().format(i.row?.points), filterable: false, sortable: false},
              { field: 'redeem', headerName: 'Redeem', width: 100, renderCell: (i) => new Intl.NumberFormat().format(i.row?.redeem), filterable: false, sortable: false},
              { field: 'paid', headerName: 'Paid', width: 125, renderCell: (i) => `${settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.amount - i.row.redeem)}`, filterable: false, sortable: false},
              { field: 'employee', headerName: 'Employee', width: 150, renderCell: (i) => i.row?.employee?.name, valueGetter: (i) => i.row?.employee?.name},
              { field: 'date', headerName: 'Date', width: 180, renderCell: (i) => moment(i.row?.createdAt).format("YYYY-MM-DD hh:mm A"), filterable: false, sortable: false},
              { field: 'action', headerName: 'Action', width: 80, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
              <div className='d-flex gap-2'>
                <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                  onClick={() => {
                    setVisibleDelete(true)
                    setSelectedDelete(i.row)
                  }}
                />
              </div>
              },
            ]}
            rows = {listFuelUps}
          />
        }

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateFuelUps/>}
        />

        <ModalBasic
          open = {visibleDelete}
          onClose = {()=> {
          setVisibleDelete(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className = 'btn btn-danger' disabled={submitDisable} onClick={() => {
                    handleDeletefuelUp(selectedDelete._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />
      </div>
    </div>
  )
}
