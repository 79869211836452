import React, {useState} from 'react'
import { NavLink } from 'react-router-dom';

import { useAuth } from '../../../context/auth';

import {Menu} from 'antd';

import { Propane, LocalGasStation, ArrowCircleRight, FormatColorFill} from '@mui/icons-material/';

export default function MenuGas() {

  const [auth] = useAuth();

  const [current, setCurrent] = useState('');

  const empItems = [
    //Pumps
    {
      label: 'Pumps',
      key: 'pump',
      icon: <ArrowCircleRight/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/pump/card">Mark Pump</NavLink>),
          key: 'pump-card',
        },
      ]
    },

    //Fill Up
    {
      label: 'Fill Up',
      key: 'fillup',
      icon: <FormatColorFill/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/fillup/fill">Mark FillUp</NavLink>),
          key: 'fill',
        },
      ]
    },
    
];

  const userItems = [
    //Tanks
    {
      label: 'Tanks',
      key: 'tank',
      icon: <Propane/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/card">Tank Stocks</NavLink>),
          key: 'tank-card',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/find-depth">Find Depth</NavLink>),
          key: 'find-depth',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/all-depths">All Depths</NavLink>),
          key: 'all-depths',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/all-loads">All Loads</NavLink>),
          key: 'all-loads',
        },
      ]
    },


    //Pumps
    {
      label: 'Pumps',
      key: 'pump',
      icon: <ArrowCircleRight/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/pump/card">Mark Pump</NavLink>),
          key: 'pump-card',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/pump/dayPump">All Day Pumps</NavLink>),
          key: 'accounts-dayPump',
        },
      ]
    },

    //Fill Up
    {
      label: 'Fill Up',
      key: 'fillup',
      icon: <FormatColorFill/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/fillup/fill">Mark Fillup</NavLink>),
          key: 'fill',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/fillup/pointscustomer">All Customers</NavLink>),
          key: 'points-customer',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/fillup/vehicles">All Vehicles</NavLink>),
          key: 'vehicles',
        },
      ]
    },
  ];

  const adminItems = [
    //Tanks
    {
      label: 'Tanks',
      key: 'tank',
      icon: <Propane/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/card">Tank Stocks</NavLink>),
          key: 'tank-card',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/find-depth">Find Depth</NavLink>),
          key: 'find-depth',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/all-depths">All Depths</NavLink>),
          key: 'all-depths',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/tank/all-loads">All Loads</NavLink>),
          key: 'all-loads',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/tank/List">All Tanks</NavLink>),
          key: 'tank-List',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/fueltype">All Fuel Types</NavLink>),
          key: 'fuel-type',
        },
      ]
    },

    //Booths
    {
      label: 'Booths',
      key: 'booth',
      icon: <LocalGasStation/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/booth/List">All Booths</NavLink>),
          key: 'booth-List',
        },
      ]
    },

    //Pumps
    {
      label: 'Pumps',
      key: 'pump',
      icon: <ArrowCircleRight/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/pump/card">Mark Pump</NavLink>),
          key: 'pump-card',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/pump/dayPump">All Day Pumps</NavLink>),
          key: 'accounts-dayPump',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/pump/List">All Pumps</NavLink>),
          key: 'pump-List',
        },
      ]
    },

    //Fill Up
    {
      label: 'Fill Up',
      key: 'fillup',
      icon: <FormatColorFill/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/fillup/fill">Mark Fillup</NavLink>),
          key: 'fill',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/fillup/pointscustomer">All Customers</NavLink>),
          key: 'points-customer',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/fillup/vehicles">All Vehicles</NavLink>),
          key: 'vehicles',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/fillup/pointsCategory">All Points Category</NavLink>),
          key: 'points-category',
        }
      ]
    },
  ];

  const sb = () => {
    switch (true) {
      case (auth?.employee?.role === 0):
        return adminItems
      case (auth?.employee?.role === 1):
        return userItems
        case (auth?.employee?.role === 2):
          return empItems
    }
  }

  return (
    <Menu theme={'dark'}  selectedKeys={current} onClick={(e) => setCurrent(e.key)} mode="inline" items={sb()} />
  )
}
