import React, {useState} from 'react'
import { NavLink } from 'react-router-dom';

import { useAuth } from '../../../context/auth';
import { useDk } from '../../../context/dk';

import {Menu} from 'antd';

import {Inventory, MoveDown, ShoppingBag, RemoveShoppingCart, ShoppingCartCheckout, ProductionQuantityLimits, Warehouse} from '@mui/icons-material/';

export default function SideBarMenuPOS() {

  const [auth,] = useAuth();

  const [current, setCurrent] = useState('');

  const empItems = [
    {
      label: 'Sales',
      icon: <ShoppingCartCheckout/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/inventory/sale">Add Sales</NavLink>),
          key: 'add-sale',
        },
      ]
    },
    {
      label: 'Sales Return',
      icon: <ProductionQuantityLimits/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/inventory/sale-return">Add Sales Return</NavLink>),
          key: 'add-sale-return',
        },
      ]
    },
  ];

  const userItems = [
    {
      label: 'Sales',
      icon: <ShoppingCartCheckout/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/inventory/sale">Add Sales</NavLink>),
          key: 'add-sale',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/sales">All Sales</NavLink>),
          key: 'sales',
        },
      ]
    },
    {
      label: 'Sales Return',
      icon: <ProductionQuantityLimits/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/inventory/sale-return">Add Sales Return</NavLink>),
          key: 'add-sale-return',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/sale-returns">All Sales Returns</NavLink>),
          key: 'sale-returns',
        },
      ]
    },
    {
      label: 'Purchases',
      icon: <ShoppingBag/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchase">Add Purchase</NavLink>),
          key: 'add-purchase',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchases">All Purchases</NavLink>),
          key: 'purchases',
        },
      ]
    },
    {
      label: 'Purchase Returns',
      icon: <RemoveShoppingCart/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchase-return">Add Return</NavLink>),
          key: 'add-purchase-return',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchase-returns">All Returns</NavLink>),
          key: 'purchase-returns',
        },
      ]
    },
    {
      label: 'Stocks',
      icon: <MoveDown/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/stocks">All Stocks</NavLink>),
          key: 'stocks',
        },
      ]
    },
  ];

  const adminItems = [
    {
      label: 'Sales',
      icon: <ShoppingCartCheckout/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/inventory/sale">Add Sales</NavLink>),
          key: 'add-sale',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/sales">All Sales</NavLink>),
          key: 'sales',
        },
      ]
    },
    {
      label: 'Sales Return',
      icon: <ProductionQuantityLimits/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/inventory/sale-return">Add Sales Return</NavLink>),
          key: 'add-sale-return',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/sale-returns">All Sales Returns</NavLink>),
          key: 'sale-returns',
        },
      ]
    },
    {
      label: 'Purchases',
      icon: <ShoppingBag/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchase">Add Purchase</NavLink>),
          key: 'add-purchase',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchases">All Purchases</NavLink>),
          key: 'purchases',
        },
      ]
    },
    {
      label: 'Purchase Returns',
      icon: <RemoveShoppingCart/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchase-return">Add Return</NavLink>),
          key: 'add-purchase-return',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/purchase-returns">All Returns</NavLink>),
          key: 'purchase-returns',
        },
      ]
    },
    {
      label: 'Products',
      icon: <Inventory/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/inventory/list">All Products</NavLink>),
          key: 'products',
        },
      ]
    },
    {
      label: 'Stocks',
      icon: <MoveDown/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/inventory/stocks">All Stocks</NavLink>),
          key: 'stocks',
        },
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/inventory/stock-adjustments">All Adjustments</NavLink>),
          key: 'adjustments',
        },
      ]
    },
    {
      label: 'Warehouses',
      icon: <Warehouse/>,
      children: [
        {
          label: (<NavLink className='text-decoration-none' aria-current="page" to="dashboard/user/admin/inventory/warehouse">All Warehouses</NavLink>),
          key: 'warehouses',
        },
      ]
    },
  ];


  const sb = () => {
    switch (true) {
      case (auth?.employee?.role === 0):
        return adminItems
      case (auth?.employee?.role === 1):
        return userItems
        case (auth?.employee?.role === 2):
          return empItems
    }
  }

  return (
    <Menu theme={'dark'}  selectedKeys={current} onClick={(e) => setCurrent(e.key)} mode="inline" items={sb()} />
  )
}
