import React, {useRef, useState, useEffect} from 'react'
import moment from 'moment'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../../../context/settings'
import { useDk } from '../../../../context/dk'

import {Print} from '@mui/icons-material'

import {Divider} from '@mui/material'

export default function SalesReceipt(props) {
    const order = props.order

    const [settings] = useSettings();
    const [dk, setDk] = useDk();

    const [noQuantity, setNoQuantity] = useState('')
    const [subTotal, setSubTotal] = useState('')
    const [discount, setDiscount] = useState('')

    const [printedDate, setPrintedDate] = useState(new Date())
    const [creditNotificationAccounts, setCreditNotificationAccounts] = useState(order?.selectInvoiceVal?.payment?._id)

    useEffect(() => {
        setSubTotal(order?.items?.reduce((t,i) => t = t + (i?.quantity * i.productLine?.productStock?.productCode?.price), 0))
        setDiscount(order?.items?.reduce((t,i) => t = t + Number(i.productLine?.unitDiscount * i?.quantity), 0))
        setNoQuantity(order?.items?.reduce((t,i) => t = t + Number(i.quantity), 0))
        order?.printedDate && setPrintedDate(order?.printedDate)
        order?.orderPayment && setCreditNotificationAccounts(order?.orderPayment)
    },[order])

    const paymentMethod = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pay'}, {_id:'63e31abdf029ceab39dcd283', name: 'Credit'},]

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Return Receipt ${order?.selectInvoiceVal?.invoiceNo}`,
        onBeforeGetContent: () => {
            setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            toast.success('Print Success')
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        }
    })

  return (
    <div>
        <button className='btn btn-warning mb-2' onClick={printData}><Print/></button>
        <div className='m-3' ref= {componentRef}>
            <h5 className='text-center fw-bold'>{settings?.name}</h5>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Address: {settings?.address}</dd>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Tel: {settings?.phoneNumber}</dd>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Email: {settings?.mail}</dd>
            <dd className='text-center lh-sm' style={{fontSize: '12px'}}>Printed On: {moment(printedDate).format("YYYY-MM-DD hh:mm A")}</dd>


            <h5 className='text-center fw-bold' >RETURNS RECEIPT</h5>
            
            <div>
                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>{"INVOICE NO: " + order?.selectInvoiceVal?.invoiceNo}</dd>
                    <dd style={{fontSize: '12px'}}>{'Payment: ' + (paymentMethod.find((i) => i._id === order?.payment) ? paymentMethod.find((i) => i._id === order?.payment).name : 'Unknown')}</dd>
                </div>
                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>{'CUSTOMER: ' + (order?.selectedCustomer ? order?.selectedCustomer?.name : 'Retail')}</dd>
                    <dd style={{fontSize: '12px'}}>{"No.Items: " + order?.items?.length}</dd>
                </div>

                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>{'DATE: ' + order?.returnDate}</dd>
                    <dd style={{fontSize: '12px'}}>{"No.Qty: " + new Intl.NumberFormat().format(noQuantity)}</dd>
                </div>

                <div className='d-flex justify-content-between'>
                    <dd style={{fontSize: '12px'}}>{'ORDER DATE: ' + order?.selectInvoiceVal?.orderDate}</dd>
                </div>
                
            </div>
            
            <div className='d-flex justify-content-between mb-0'>
                <p style={{fontSize: '12px'}}>ITEMS</p>
                <p style={{fontSize: '12px'}}>PRICE</p>
            </div>

            {order?.items.map((i) => 
                <div className='d-flex justify-content-between align-items-center'>
                    <p className='lh-sm' style={{fontSize: '12px'}}>{i.productLine?.productStock?.productCode?.product?.name} | {i.productLine?.productStock?.productCode?.productCode} <br/> {new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(i.productLine?.productStock?.productCode?.price)} * {new Intl.NumberFormat().format(i?.quantity)} - (DISC: {new Intl.NumberFormat().format(i.productLine?.unitDiscount * i.quantity)})</p>
                    <p className='lh-sm' style={{fontSize: '12px'}}>{new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format((i.quantity * (i.productLine?.productStock?.productCode?.price - i.productLine?.unitDiscount)))}</p> 
                </div>
            )}
            <hr className='border m-0'></hr>
            <hr className='border mt-0'></hr>

            <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm' style={{fontSize: '12px'}}>Sub Total:</p>
                <p className='lh-sm' style={{fontSize: '12px'}}>{order?.items.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal)}` : '0.00'}</p> 
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm' style={{fontSize: '12px'}}>Total Discount:</p>
                <p className='lh-sm' style={{fontSize: '12px'}}>- ( {discount ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(discount)}` : '0.00'} )</p> 
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <p className='lh-sm fw-bold' style={{fontSize: '14px'}}>Total Returns:</p>
                <p className='lh-sm fw-bold' style={{fontSize: '14px'}}>{(order?.items.length > 0 && creditNotificationAccounts !== '63e31abdf029ceab39dcd283') ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal - discount)}` : '0.00'}</p> 
            </div>

            {creditNotificationAccounts === '63e31abdf029ceab39dcd283' && 
                <div>
                    <p className='text-center fw-bold text-danger' style={{fontSize: '16px'}}>CREDIT Sales returns will be <br></br> Debited from Customer Accounts</p>
                </div>
            }

            <h6 className='text-center p-1'>Thanks, Come Again</h6>
            <Divider light>*** END ***</Divider>
        </div>
        
    </div>
  )
}
