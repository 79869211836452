import React, {useEffect, useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useGdate } from '../../../context/date'

import { Autocomplete, TextField } from '@mui/material'
import {Text} from '../../../components/Inputs/InputFields'

export default function CreateGovOrder() {

    const [gdate] = useGdate()

    const [listGovCustomers, setListGovCustomers] = useState('')

    const [customer, setCustomer] = useState()
    const [govOrder, setGovOrder] = useState('')
    const [status] = useState('receive')
    const [receiveDate, setReceiveDate] = useState(gdate?.date)
    const [amount, setAmount] = useState('')

    const [inputCustomer, setInputCustomer] = useState()

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadGovCustomers()
  },[])

  const loadGovCustomers = async() => {
    try {
        const {data} = await Axios.get('/customers')
        if (data?.error) {
            toast.error(data.error)
        } else {
            setListGovCustomers(data?.filter((i) => i?.type === 'government'))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/govOrder`, {customer, govOrder, status, receiveDate, amount});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Government Order Recorded");
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='p-3'>
        <h5 className='text-center mb-3'>Add Government Order</h5>
        <form onSubmit={handleSubmit}>
            {listGovCustomers && <Autocomplete
                size='small'
                fullWidth
                className='mb-2'
                value={customer?._id}
                onChange={(event, value) => {
                    setCustomer(value)
                }}
                inputValue={inputCustomer}
                onInputChange={(event, newInputValue) => {
                    setInputCustomer(newInputValue)
                }}
                options={listGovCustomers}
                renderInput={(params) => <TextField {...params} label="Customer"/>}
                getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
                noOptionsText = "No Government Customer Found"
            />}
            <Text
                label="Government Order No"
                value = {govOrder}
                onChange={(e) => setGovOrder(e.target.value)}
            />
            <Text
                label="Received Date"
                type = "date"
                value = {receiveDate}
                onChange={(e) => setReceiveDate(e.target.value)}
            />
            <Text
                label="Amount"
                type = "Number"
                value = {amount}
                onChange={(e) => setAmount(e.target.value)}
                end = "LKR"
            />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            SUBMIT ORDER
          </button>
        </div>
      </form>
    </div>
  )
}
