import React, { useState, createContext, useContext } from "react";

const SearchContext = createContext();

const SearchProvider = (props) => {
    const [values, setValues] = useState({
        keyword: null,
        data: [],
    });

    

    return(
        <SearchContext.Provider value={[values, setValues]}>
            {props.children}
        </SearchContext.Provider>
    );
};

const useSearch = () => useContext(SearchContext);

export {SearchProvider, useSearch};