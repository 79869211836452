import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../context/settings';
import { useGdate } from '../../context/date'

import MUITable from '../../components/table/MUITable'

export default function ListEmployeeAccounts() {
    const {id} = useParams();
    const [settings] = useSettings()
    const [gdate] = useGdate()

    const [listAccData, setListAccData] = useState('')

    useEffect(() => {
        loadAccData()
    }, [id, gdate?.reportStartDate, gdate?.reportEndDate])

    const EmployeeAccounts = [
        { field: 'date', headerName: 'Date', width: 120},
        { field: 'description', headerName: 'Description', width: 350, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description },
        { field: 'debit', headerName: 'Debit', width: 150, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 150, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false}
    ]
    
    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`, {"empStatus.employee": id, "empStatus.status": false, accounting: '6515831c1a89e4fc7010012a', date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListAccData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccountsDebitEntry = (row) => {
        switch (true) {
            case ( (row.accounting?.category?.type && row?.amount > 0) || (!row.accounting?.category?.type && row?.amount < 0) ):
                return Math.abs(row.amount);
        }
    }

    const allAccountsCreditEntry = (row) => {
        switch (true) {
            case ( (!row.accounting?.category?.type && row?.amount > 0) || (row.accounting?.category?.type && row?.amount < 0) ):
                return Math.abs(row.amount);
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                {/* <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                    <h5>Employee {listAccData[0]?.empStatus?.employee?.name} Accounts</h5>
                    <p>{gdate?.reportStartDate} To {gdate?.reportEndDate}</p>
                </div> */}
                    
                {listAccData && 
                    <MUITable
                        title = {listAccData && 'Employee ' + listAccData[0]?.empStatus?.employee?.name + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                        id = {(row) => row._id}
                        columns = {EmployeeAccounts}
                        rows = {listAccData}
                    />
                }
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total C/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'credit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>Total B/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'debit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <h5>Salary Paid</h5>
                    <h5 style={(listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )) >=0 ? {color: '#39ff14'} : {color: '#FF0000'}}>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h5>
                </div>
            </div>
        </div>
    )
}
