import React, {useEffect} from 'react'
import {BrowserRouter} from "react-router-dom"
import { Toaster, toast } from 'react-hot-toast'
import moment from 'moment';

import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json';
import Loading from './routes/Loading';

import { ConfigProvider } from 'antd';
import { Layout } from 'antd';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { useDk } from './context/dk';
import { useGdate } from './context/date';
import { useSettings } from './context/settings';

import TopMenu from './components/Topbar/TopMenu'
import RoutesPage from './routes/RoutesPage';

import Setup from './pages/Setup'


export default function App() {
  const { version } = packageFile;
  const isProduction = process.env.NODE_ENV === 'production';

  const [settings] = useSettings()
  const [dk] = useDk()
  const [gdate] = useGdate()

  const MUITheme = createTheme({
    palette: {
      mode: `${dk.darkmode ? 'dark' : 'light'}`,
    },
  });

  useEffect(() => {
    window.addEventListener("offline", (event) => {
      toast.loading("Connection lost.");
    });

    window.addEventListener("online", (event) => {
      toast.dismiss()
      toast.success("Connection Restored.");
    }); 
  },[])

  return (
    // <CacheBuster
    //   currentVersion={version}
    //   isEnabled={isProduction} //If false, the library is disabled.
    //   isVerboseMode={true} //If true, the library writes verbose logs to console.
    //   loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    //   metaFileDirectory={'../public/'} //If public assets are hosted somewhere other than root on your server.
    // >
      <BrowserRouter>
        <Toaster/>
        {settings ? <ConfigProvider>       
          <ThemeProvider theme={MUITheme}>
            <CssBaseline/>
            <Layout style={{minHeight: '100vh'}}>
              <Layout className="site-layout" style={{backgroundColor: dk.darkmode ? '#121212' : 'rgba(255, 255, 250, 1'}}>
                {/* TopMenu Implemented */}
                <TopMenu/>
                {gdate && !gdate.cashFlowStatus && <div className='text-center p-1 bg-danger'>{moment(gdate?.date).format("YYYY-MM-DD")} CashFlow Need to be Verified <a className='text-light fw-bold' href="/dashboard/user/accounts/daily-cashflow">CLICK HERE</a></div>}
                {/* Content */}
                {<RoutesPage/>}

                {/* FOOTER */}
                <div className='p-3 text-center' style={{
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                  }}>
                Copyrights © {new Date().getFullYear() !== 2023 ? '2023 - ' + new Date().getFullYear() : 2023} <b><a className='text-decoration-none' href='https://bido.lk' target='_blank'>Bido (pvt) Ltd.</a></b> All rights reserved. Designed By <b><a className='text-decoration-none' href='/developer'>Shadhir Shaz</a></b>

                </div>

              </Layout>
            </Layout>
          </ThemeProvider>
        </ConfigProvider>
        :
        <Setup/>
        }
      </BrowserRouter>
    // </CacheBuster>
  )
}

