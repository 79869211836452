import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import moment from 'moment';

export default function DateFilter(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                color='inherit'
                onClick={handleClick}
            >
                Date Filters
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                      maxHeight: 120
                    },
                  }}
          
            >
                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().startOf('week').format('YYYY-MM-DD'), date2: moment().endOf('week').format('YYYY-MM-DD')})
                    handleClose()
                }}>This Week
                </MenuItem>

                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().subtract(7, 'day').startOf('week').format('YYYY-MM-DD'), date2: moment().subtract(7, 'day').endOf('week').format('YYYY-MM-DD')})
                    handleClose()
                }}>Last Week
                </MenuItem>

                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().subtract(6, 'day').format('YYYY-MM-DD'), date2: moment().format('YYYY-MM-DD')})
                    handleClose()
                }}>Last 7 Days</MenuItem>

                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().startOf('month').format('YYYY-MM-DD'), date2: moment().endOf('month').format('YYYY-MM-DD')})
                    handleClose()
                }}>This Month</MenuItem>

                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), date2: moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')})
                    handleClose()
                }}>Last Month</MenuItem>

                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().startOf('year').format('YYYY-MM-DD'), date2: moment().endOf('year').format('YYYY-MM-DD')})
                    handleClose()
                }}>This Year</MenuItem>

                <MenuItem onClick={(e) => {
                    props.onSubmit({date: moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'), date2: moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD')})
                    handleClose()
                }}>Last Year</MenuItem>
            </Menu>
        </div>
    );
}