import React, {useRef, useState, useEffect} from 'react'
import moment from 'moment'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../../../context/settings'
import { useDk } from '../../../../context/dk'

import {Print} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import QrCodeGenerator from '../../../../components/Scan-Qr/QrCodeGenerator'
import {Divider} from '@mui/material';

import CpetcoLogoImg from '../../../../Assets/images/ceypetco_logo.png'

export default function SalesReceiptV2(props) {
    const order = props.order

    const [settings] = useSettings();
    const [dk, setDk] = useDk();

    const [invoiceNo] = useState(order?.invoiceNo)
    const [noQuantity, setNoQuantity] = useState('')
    const [subTotal, setSubTotal] = useState('')
    const [discount, setDiscount] = useState('')

    const [printedDate, setPrintedDate] = useState(new Date())

    useEffect(() => {
        setSubTotal(order?.items?.reduce((t,i) => t = t + (i?.quantity * i?.productStock?.productCode?.price), 0))
        setDiscount(order?.items?.reduce((t,i) => t = t + Number(i?.unitDiscount * i?.quantity), 0))
        setNoQuantity(order?.items?.reduce((t,i) => t = t + Number(i.quantity), 0))
        order?.printedDate && setPrintedDate(order?.printedDate)
    },[order])


    const paymentMethod = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pay'}, {_id:'63e31abdf029ceab39dcd283', name: 'Credit'},]

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
    }));

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Sales Receipt ${order?.invoiceNo}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        }
    })

    return (
        <div className='row justify-content-center'>
            <div>
                <button className='btn btn-warning' onClick={printData}><Print/></button>
            </div>
            
            <div className='row justify-content-center p-5' style={{width: 900}} ref= {componentRef}>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex gap-2'>
                        <img
                            height = '60px' 
                            src={settings?.logo ? settings?.logo?.file : CpetcoLogoImg}
                            alt='Logo'
                        />
                        <h4 style={{fontFamily: 'TirantiSolidLET'}}>{settings?.nameOnReceipt ? settings?.name : ''}</h4>    
                    </div>
                    <div>
                        <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Address: {settings?.address}</dd>
                        <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Telephone No: {settings?.phoneNumber}</dd>
                        <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Email: {settings?.mail}</dd>
                    </div>
                </div>
                
                <hr></hr>

                <dd className='text-end lh-sm' style={{fontSize: '14px'}}>Printed On: {moment(printedDate).format("YYYY-MM-DD hh:mm A")}</dd>

                <h4 className='text-center fw-bold' >SALES RECEIPT</h4>
                
                <div className='d-flex justify-content-center mb-2'>
                    <div className='row justify-content-between'>
                        <dd style={{fontSize: '12px'}}>INVOICE NO: {order?.invoiceNo}</dd>
                        <dd style={{fontSize: '12px'}}>CUSTOMER: {order?.selectedCustomer ? order?.selectedCustomer?.name : 'Retail'}</dd>
                        <dd style={{fontSize: '12px'}}>DATE: {order?.orderDate}</dd>
                        
                    </div>

                    <div className='row text-end'>
                        <dd style={{fontSize: '12px'}}>Payment: {(paymentMethod.find((i) => i._id === order?.payment) ? paymentMethod.find((i) => i._id === order?.payment).name : 'Unknown')}</dd>
                        <dd style={{fontSize: '12px'}}>No.Items: {(order?.items?.length ? order?.items?.length : 0)}</dd>
                        <dd style={{fontSize: '12px'}}>No.Qty: {new Intl.NumberFormat().format(noQuantity)}</dd>
                    </div>
                    
                </div>

                <div className='w-100'>
                    <div>
                        {order?.items && 
                            <Table className='w-100' size='small' component={Paper}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{fontSize: '12px'}}>#</StyledTableCell>
                                        <StyledTableCell style={{fontSize: '12px'}}>Product Code</StyledTableCell>
                                        <StyledTableCell style={{fontSize: '12px'}}>Product</StyledTableCell>
                                        <StyledTableCell style={{fontSize: '12px'}}>Unit Price</StyledTableCell>
                                        <StyledTableCell style={{fontSize: '12px'}}>Qty</StyledTableCell>
                                        <StyledTableCell style={{fontSize: '12px'}}>Discount</StyledTableCell>
                                        <StyledTableCell style={{fontSize: '12px'}}>Price</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {order?.items.map((i,index) =>
                                        <TableRow key={index}>
                                            <TableCell style={{fontSize: '12px'}}>{index+1}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{i?.productStock?.productCode?.productCode}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{i?.productStock?.productCode?.product?.name}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.productStock?.productCode?.price)}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{new Intl.NumberFormat().format(i?.quantity) + ' ' + i?.productStock?.productCode?.product?.unit}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.unitDiscount * i?.quantity)}</TableCell>
                                            <TableCell style={{fontSize: '12px'}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format((i?.quantity * (i?.productStock?.productCode?.price - i?.unitDiscount)))}</TableCell>
                                        </TableRow> 
                                    )}
                                </TableBody>
                            </Table>
                        }
                    </div>

                    <div className='row m-3 pt-3'>
                        <div className='col-md-6 col-12'>
                            <div className='d-flex justify-content-center'>
                                <QrCodeGenerator qrText = {String(invoiceNo)} size='125' hide='true'/>
                            </div>
                        </div>

                        <div className='col-md-6 col-12'>
                            <div className='row'>
                                <div className='d-flex justify-content-between'>
                                    <p className='lh-sm' style={{fontSize: '14px'}}>Sub Total:</p>
                                    <p className='lh-sm' style={{fontSize: '14px'}}>{order?.items?.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal)}` : '0.00'}</p> 
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <p className='lh-sm' style={{fontSize: '14px'}}>Total Discount:</p>
                                    <p className='lh-sm' style={{fontSize: '14px'}}>( {discount ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(discount)}` : '0.00'} )</p> 
                                </div>

                                <div className='d-flex justify-content-between'>
                                    <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>Total:</p>
                                    <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>{order?.items?.length > 0 ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(subTotal - discount)}` : '0.00'}</p> 
                                </div>

                                {order?.payment === '64952f578188d2c8b9c26603' && <div className='d-flex justify-content-between'>
                                    <p className='lh-sm' style={{fontSize: '12px'}}>Cash Received:</p>
                                    <p className='lh-sm' style={{fontSize: '12px'}}>{order?.received ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(order?.received)}` : '0.00'}</p> 
                                </div>}

                                {order?.payment === '64952f578188d2c8b9c26603' && <div className='d-flex justify-content-between'>
                                    <p className='lh-sm' style={{fontSize: '12px'}}>Balance:</p>
                                    <p className='lh-sm' style={{fontSize: '12px'}}>{order?.received && order?.total ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(order?.received - order?.total)}` : '0.00'}</p> 
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <h6 className='text-center p-1'>Thanks, Come Again</h6>
                <Divider light>*** END ***</Divider>
            </div>
            
        </div>
    )
}
