import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'
import { useGdate } from '../../context/date'
import {useSettings} from '../../context/settings'

import {DeleteForever, Check, Error} from '@mui/icons-material/'

import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

export default function ListDayPump() {
    const [auth] = useAuth()
    const [gdate] = useGdate()
    const [settings] = useSettings()

    const [listCashflowCheck, setListCashflowCheck] = useState('')

    //Model
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [listData, setListData] = useState('')

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadCashflowsCheck()
        loadData()
    },[gdate])

    const loadCashflowsCheck = async() => {
        try {
          const {data} = await Axios.post('/cashflows')
          if (data?.error) {
            toast.error(data?.error)
          } else {
            setListCashflowCheck(data)
          }
        } catch (err) {
          console.log(err)
          toast.error("Something wernt wrong, Try Again!")
        }
    }
    
    const loadData = async() => {
        try {
            const {data} = await Axios.post(`/daypumps`, {date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if(data?.error){
                toast.error(data.error)
            } else {
                setListData(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccounts = [
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'pump', headerName: 'Pump', width: 125, renderCell: (i) => `${i.row.pump?.name}`, valueGetter: (i) => `${i.row.pump?.name}`},
        { field: 'employee', headerName: 'Employee', width: 150, renderCell: (i) => `${i.row.employee?.name}`, valueGetter: (i) => `${i.row.employee?.name}`},
        { field: 'price', headerName: 'Price', width: 80, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.price)}`, filterable: false, sortable: false},
        { field: 'startReading', headerName: 'Start Reading', width: 130, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.startReading)}`, filterable: false, sortable: false},
        { field: 'endReading', headerName: 'End Reading', width: 130, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.endReading)}`, filterable: false, sortable: false},
        { field: 'testing', headerName: 'Test L', width: 90, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.testing)} L` , filterable: false, sortable: false},
        { field: 'testRefill', headerName: 'T.Refill', width: 70, renderCell: (i) => i.row.testing !== 0 ? (i.row?.testRefill ? <Check color='success'/> : <Error color='warning'/>): '-' , filterable: false, sortable: false},
        { field: 'expenseL', headerName: 'Expense L', width: 90, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.expenseL)} L`, filterable: false, sortable: false},
        { field: 'drawingsL', headerName: 'Drawings L', width: 90, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.drawingsL)} L`, filterable: false, sortable: false},
        { field: 'soldQuantity', headerName: 'Sold L', width: 120, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.soldQuantity)} L`, filterable: false, sortable: false},
        { field: 'amount', headerName: 'Amount', width: 150, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(Math.abs(i.row.inCome))}`, filterable: false, sortable: false},
        { field: 'action', headerName: 'Action', width: 70, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
            <div className='d-flex gap-2'>
                {listCashflowCheck && listCashflowCheck?.find((c) => c?.date === i.row.date) ? <Check fontSize='large' className='p-2 shadow rounded-circle' color='success'/>
                :
                <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                    setVisible(true)
                    setSelected(i.row)
                    }}
                />}
            </div>
        },
    ]

    const handleDeleteDayPump = async(selected) => {
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/warehousestock/${selected?.pump?.wareHouseStock?._id}`, {
                $inc: { 'stock':  +(Number(selected?.soldQuantity) + Number(selected?.expenseL) + Number(selected?.drawingsL) +  Number(selected?.testRefill === true ? 0 : selected?.testing)), 'sold': -(Number(selected?.soldQuantity) + Number(selected?.expenseL) + Number(selected?.drawingsL) +  Number(selected?.testRefill === true ? 0 : selected?.testing))}
            });
            if(data?.error){
              toast.error(data.error)
              setSubmitDisable(false)
            } else {
                try {
                    const {data} = await Axios.delete(`/daypump/${selected._id}`)
                    if(data?.error) {
                        toast.error(data.error)
                        setSubmitDisable(false)
                    } else {
                        try {
                            const deleteExpenseDrawings = await Axios.post(`/accdatas/removemany`, {refId: `dp-${selected?._id}-${selected?.date}-${selected?.employee?._id}`, "empStatus.status": false, date: selected?.date})
                            const deleteAccDatas = await Axios.post(`/accdatas/removemany`, {"empStatus.employee": selected.employee._id, "empStatus.status": true, date: selected?.date})
                            const deleteDayEmp = await Axios.post(`/dayemployees/filterdelete`, {employee: selected?.employee?._id, date: selected?.date})
                            if (deleteExpenseDrawings?.data?.error || deleteAccDatas?.data?.error || deleteDayEmp?.data?.error){
                                toast.error(deleteExpenseDrawings?.data?.error || deleteAccDatas?.data?.error || deleteDayEmp?.data?.error)
                                setSubmitDisable(false)
                            } else {
                                toast.success("Day Pump Related Records Deleted")
                                window.location.reload()
                            }
                        } catch (err) {
                            console.log(err)
                            toast.error("Something went wrong Try Again!")
                            setSubmitDisable(false)
                        }
                    }
                } catch (err) {
                    console.log(err)
                    toast.error("Something went wrong Try Again!")
                    setSubmitDisable(false)
                }
            }
          } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
          }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-12 mb-3'>
                <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                    <h5>Daily Pump Details</h5>
                    <p>{gdate?.reportStartDate + ' - ' + gdate?.reportEndDate} </p>
                </div>

                {listData && 
                    <MUITable
                        title = {'Daily Pump Details | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                        id = {(row) => row._id}
                        columns = {allAccounts}
                        rows = {listData}
                    />
                }
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total Fuel Sales By Pumps</h6>
                    <h6>{(new Intl.NumberFormat().format((listData && listData?.reduce((a,v) =>  a = a + v?.inCome , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
            </div>

            <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {
                    auth?.employee?.role === 0 ?
                    <div>
                        <p>If Delete, Employee Accounts related to Pump will be Deleted?</p>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                                handleDeleteDayPump(selected)
                                console.log(selected)
                                }}>Delete
                            </button>
                        </div>
  
                    </div>
                    :
                    <div className='d-flex justify-content-center w-100'>
                        <p>You don't have Permission to Delete</p>
                    </div>
                    
                }
            />
        </div>
    )
}
