import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../components/Inputs/InputFields'

export default function UpdateBooth(props) {
    const boothData = props.booth;

    const [name, setName] = useState(boothData?.name);
    const [noOfPump, setNoOfPump] = useState(boothData?.noOfPump);

    const [submitDisable, setSubmitDisable] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        await setSubmitDisable(true)
        const {data} = await Axios.put(`/booth/${boothData._id}`, {...boothData,name,noOfPump,});
        if(data?.error) {
            toast.error(data.error);
            setSubmitDisable(false)
        } else {
            toast.success("Booth sucessfully Updated");
            window.location.reload()
        }
        } catch (err) {
        toast.error("Booth Update Failed Try Again");
        
        }
    }
    
    return (
        <div className='p-3'>
            <h4 className='text-center mb-3'>Update {name}</h4>
            <form onSubmit={handleSubmit}>
                <Text
                    label="Booth Name"
                    value = {name}
                    onChange={(e) => setName(e.target.value)} 
                />
                <Text
                    label="No of Pumps"
                    value = {noOfPump}
                    type = "Number"
                    onChange={(e) => setNoOfPump(e.target.value)} 
                />
            
                <div class="d-grid gap-2">
                <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
                    UPDATE BOOTH
                </button>
                </div>
            </form>
        </div>
    )
}
