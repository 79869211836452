import React,{useState} from 'react'
import {toast} from 'react-hot-toast'

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

import {VisibilityOff} from '@mui/icons-material'

import PDFViewer from '../Viewers/PDFViewer'
import ImageViewer from '../Viewers/ImageViewer'

export default function FileUpload(props) {
    // for onchange event
    const [hidePreview, setHidePreview] = useState(props?.hidePreview ? props?.hidePreview : false)
    const [file, setFile] = useState()
    const [type, setType] = useState()
    const [fileError, setFileError]=useState();

    const fileTypeSelection = (uploadType) => {
        switch (uploadType) {
            case 'PDF':
                return ['application/pdf'];
                break;
            case 'Image':
                return ['image/png', 'image/jpg', 'image/jpeg']
                break;
            default:
                return ['application/pdf'];
        }
    }

    // onchange event
    const supportFileTypes = fileTypeSelection(props.fileType)
        const handleFileChange = async(e) => {
            await setFile()
            await setType()
            const selectedFile = e.target.files[0]
            if (selectedFile) {
                if(selectedFile && supportFileTypes.includes(selectedFile.type)){
                    let reader = new FileReader();
                    reader.readAsDataURL(selectedFile)
                    reader.onloadend = async (e) => {
                        await setFile(e.target.result)
                        await setType(selectedFile.type)

                        // this submit results goes to File Requested Components
                        props?.submit({file: e.target.result, type: selectedFile.type})
                        setFileError('');
                    }
                }
                else{
                    setFileError(`Select Valid ${props.fileType ? props.fileType : ''} Type`);
                }
            }
            else{
                setFile()
                setType()

                // this submit results goes to File Requested Components
                props?.submit()
                toast.error('Selected File Removed');
            }

        }

    return (
        <form>
            <div className='d-flex justify-content-between'>
                <input type="file" className='form-control mb-2' required onChange={handleFileChange}/>
                {!hidePreview && <div>
                    {file ? <div>
                    {type === "application/pdf" && <PDFViewer data={file}/>}
                    {['image/png', 'image/jpg', 'image/jpeg'].find((a) => a === type) && <ImageViewer data={file}/>}
                    </div>
                    :
                    <div> <VisibilityOff fontSize='large' className='p-2 shadow rounded-circle'/> </div>
                    }
                </div>}
            </div>
            {fileError && <div className='text-danger mb-2'>{fileError}</div>}

        </form>
    )
}
