import React, {useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../../../context/settings'

import MUITable from '../../../../components/table/MUITable'
import ModalBasic from '../../../../components/Modal/ModalBasic'

import {Edit, Visibility} from '@mui/icons-material/'

import UpdatePurchaseReurn from './UpdatePurchaseReurn'

export default function ListPurchaseReturns() {
  const navigate = useNavigate()

  const [settings] = useSettings()

  const [listPurchaseReturns, setListPurchaseReturns] = useState('')

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadPurchases()
  }, [])
  
  const loadPurchases = async() => {
      try {
          const {data} = await Axios.post('/purchasereturns')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListPurchaseReturns(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        {listPurchaseReturns && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
              { field: 'invoiceNo', headerName: 'Invoice No', width: 140, renderCell: (i) => i.row.purchase?.invoiceNo, valueGetter: (i) => i.row.purchase?.invoiceNo},
              { field: 'supplier', headerName: 'Supplier', width: 180, renderCell: (i) => i.row.purchase?.supplier?.name, valueGetter: (i) => i.row.purchase?.supplier?.name},
              { field: 'purchaseDate', headerName: 'Purchase Date', width: 120, renderCell: (i) => i.row.purchase.purchaseDate, valueGetter: (i) => i.row.purchase.purchaseDate},
              { field: 'returnDate', headerName: 'Return Date', width: 120, renderCell: (i) => i.row.returnDate, valueGetter: (i) => i.row.returnDate},
              { field: 'refundStatus', headerName: 'Refund Status', width: 115, renderCell: (i) => {
                switch (i.row.paymentStatus) {
                  case "received":
                    return <div className='w-100 p-2' style={{color: 'green'}}>{i.row.paymentStatus?.toUpperCase()}</div>
                  case "panding":
                    return <div className='w-100 p-2' style={{color: 'orange'}}>{i.row.paymentStatus?.toUpperCase()}</div>
                  case 'unknown':
                    return <div className='w-100 p-2' style={{color: 'red'}}>{i.row.paymentStatus?.toUpperCase()}</div>
              }}},
              { field: 'refund', headerName: 'Refund', width: 125, renderCell: (i) => i?.row?.payment?.name?.toUpperCase(), valueGetter: (i) => i?.row?.payment?.name},
              { field: 'total', headerName: 'Total', width: 150, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i?.row?.total) ), valueGetter: (i) => i?.row?.total},
              { field: 'markedOn', headerName: 'Marked On', width: 180, renderCell: (i) => moment(i?.row?.updatedAt).format('YYYY-MM-DD hh:mm A'), valueGetter: (i) => moment(i?.row?.updatedAt).format('YYYY-MM-DD hh:mm:ss A')},
              { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      navigate(`/dashboard/user/inventory/purchase-return-report/${i.row._id}`)
                    }}
                  />
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                </div>
              },
            ]}
            rows = {listPurchaseReturns}
          />
        }   
      </div>

      <ModalBasic
        open = {visible}
        onClose = {()=> {
        setVisible(false)
        }}
        content = {<UpdatePurchaseReurn data = {selected}/>}
      />

    </div>
    )
}
