import React, {useRef,useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useSettings } from '../../context/settings';
import { useGdate } from '../../context/date'
import { useDk } from '../../context/dk'

import MUITable from '../../components/table/MUITable'

export default function ListSupplierAccounts() {
    const {id} = useParams()
    const [settings] = useSettings()
    const [gdate] = useGdate()
    const [dk, setDk] = useDk()

    const [allData, setAllData] = useState('')
    const [listAccData, setListAccData] = useState('')

    useEffect(() => {
        loadAccData()
      },[id])

    useEffect(() => {
        allData && filterDataByDate()
      },[allData, gdate?.reportStartDate, gdate?.reportEndDate])

    //Print Function
    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Supplier ${listAccData[0]?.detail?.supplier?.name} Accounts - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        },
    })

    //CSV Function
    const jsonToCsvConvert = {
        data: listAccData,
        filename: `Supplier ${listAccData[0]?.detail?.supplier?.name} Accounts - ${gdate?.reportStartDate} - ${gdate?.reportEndDate}`,
        delimiter: ',',
        headers: ['date', "description", "amount"]
    }

    const SupplierAccounts = [
        { field: 'date', headerName: 'Date', width: 120},
        { field: 'description', headerName: 'Description', width: 350, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description },
        { field: 'debit', headerName: 'Debit', width: 150, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 150, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'effects', headerName: 'Effects', width: 150, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false, sortable: false}
    ]
    
    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`, {accounting: "64acdf13722d07654e219f89", "detail.supplier": id})
            if(data?.error){
                toast.error(data.error)
            } else {
                var effects = 0
                await setAllData(data?.map((i) => {
                    effects += i?.amount
                    return {...i, effects: effects}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const filterDataByDate = async() => {
        try {
            setListAccData(allData?.filter((i) => i?.date >= gdate?.reportStartDate && i?.date <= gdate?.reportEndDate))
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccountsDebitEntry = (row) => {
        switch (true) {
            case ( (row.accounting?.category?.type && row?.amount > 0) || (!row.accounting?.category?.type && row?.amount < 0) ):
                return Math.abs(row.amount);
        }
    }

    const allAccountsCreditEntry = (row) => {
        switch (true) {
            case ( (!row.accounting?.category?.type && row?.amount > 0) || (row.accounting?.category?.type && row?.amount < 0) ):
                return Math.abs(row.amount);
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-12 m-3'>
                <div className='row mb-2'>
                    <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
                        <button className = 'btn btn-warning w-50' onClick={() => {printData()}}>Print</button>
                        {/* <button className = 'btn btn-success w-50' onClick={() => {csvExport(jsonToCsvConvert)}}>CSV</button>  */}
                    </div>
                </div>
                
                <div ref= {componentRef} className='pt-3'>
                    {/* <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                        <h5>Supplier {listAccData[0]?.detail?.supplier?.name} Accounts</h5>
                        <p>{gdate?.reportStartDate} To {gdate?.reportEndDate}</p>
                    </div> */}
                    {listAccData && 
                        <MUITable
                            title = {listAccData && 'Supplier ' + listAccData[0]?.detail?.supplier?.name + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                            id = {(row) => row._id}
                            columns = {SupplierAccounts}
                            rows = {listAccData}
                        />
                    }
                </div>
            </div>

            <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total C/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'credit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>Total B/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'debit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <h5>Payables</h5>
                    <h5 style={(listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )) <=0 ? {color: '#39ff14'} : {color: '#FF0000'}}>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h5>
                </div>
            </div>
        </div>
    )
}
