import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../../context/auth';
import { useSettings } from '../../../../context/settings'

import MUITable from '../../../../components/table/MUITable'
import ModalBasic from '../../../../components/Modal/ModalBasic'

import {Edit, Visibility} from '@mui/icons-material/'

import SalesReceipt from '../Receipts/SalesReceipt';
import SalesReceiptV2 from '../Receipts/SalesReceiptV2';

export default function ListOrders() {

  const [auth] = useAuth()
  const [settings] = useSettings()

  const [listOrders, setListOrders] = useState('')

  const [items, setItems] = useState('')
  //Model
  const [visibleDetails, setVisibleDetails] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState('')

  useEffect(() => {
      loadOrders()
  }, [])
  

  const loadOrders = async() => {
      try {
          const {data} = await Axios.get('/orders')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListOrders(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadItems = async(id) => {
    try {
      const {data} = await Axios.post(`/productlines`, {order: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setItems(data)
        setVisibleDetails(true)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='d-flex gap-2 mb-2'>
        </div>
        {listOrders && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
                { field: 'invoiceNo', headerName: 'Invoice No', width: 120},
                { field: 'orderDate', headerName: 'Order Date', width: 120},
                { field: 'type', headerName: 'Cus.Type', width: 100, renderCell: (i) => i.row.customer ? i.row.customer?.type?.slice(0,3)?.toUpperCase() : "RETAIL", valueGetter: (i) => i.row.customer ? i.row.customer?.type : "retail"},
                { field: 'payment', headerName: 'Payment', width: 160, renderCell: (i) => i.row.payment?.name?.toUpperCase(), valueGetter: (i) => i.row.payment?.name},
                { field: 'total', headerName: 'Total', width: 160, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.total) ), valueGetter: (i) => i?.row?.total},
                { field: 'comment', headerName: 'Comments', width: 150},
                { field: 'govOrder', headerName: 'Gov.Order', width: 120, renderCell: (i) => i.row.govOrder?.govOrder, valueGetter: (i) => i.row.govOrder?.govOrder},
                { field: 'createdAt', headerName: 'Order Created', width: 150, renderCell: (i) => moment(i.row.createdAt).format('YYYY-MM-DD HH:MM'), filterable: false, sortable: false},
                { field: 'update', headerName: 'Updates', width: 150, renderCell: (i) => i.row.updatedAt !== i.row.createdAt ? moment(i.row.updatedAt).format('YYYY-MM-DD HH:MM') : '-', filterable: false, sortable: false},
                { field: 'action', headerName: 'Action', width: 80, filterable: false, sortable: false, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      loadItems(i.row?._id)
                      setSelectedDetails(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listOrders}
          />
        }
        <ModalBasic
          width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
          open = {visibleDetails}
          onClose = {()=> {
          setVisibleDetails(false)
          }}
          content = {settings.billReceipt === 'invoice' ?
            <SalesReceiptV2 order = {{invoiceNo: selectedDetails?.invoiceNo, selectedCustomer: selectedDetails?.customer, orderDate: selectedDetails?.orderDate, items, total: selectedDetails?.total, payment: selectedDetails?.payment?._id, received: selectedDetails?.received, printedDate: selectedDetails?.createdAt}}/>
            :
            <SalesReceipt order = {{invoiceNo: selectedDetails?.invoiceNo, selectedCustomer: selectedDetails?.customer, orderDate: selectedDetails?.orderDate, items, total: selectedDetails?.total, payment: selectedDetails?.payment?._id, received: selectedDetails?.received, printedDate: selectedDetails?.createdAt}}/>
          }
        />
      </div>
    </div>
    )
}
