import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../context/settings';

import { Text, SelectInput} from '../../components/Inputs/InputFields';
import {TextField, Autocomplete, MenuItem} from '@mui/material/'

export default function EmpAccForm(props) {
  const empData = props.employee
  const empAccounts = [{_id:'64952f578188d2c8b9c26603', name: 'Cash'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank / Card Pays'}, {_id:'63e31abdf029ceab39dcd283', name: 'Debts / Bill'}, {_id:'63e31af4f029ceab39dcd28f', name: 'Drawings'}, {_id:'63e31b29f029ceab39dcd291', name: 'Expense'}]

  const [settings] = useSettings()

  const [listAccounts] = useState(empAccounts)
  const [listBanks, setListBanks] = useState('')
  const [listCustomers, setListCustomers] = useState('')
  const [listFuelTypes, setListFuelTypes] = useState('')
  const [listGovOrders, setListGovOrders] = useState('')

  const [inputCustomer, setInputCustomer] = useState('')
  const [inputGovOrder, setInputGovOrder] = useState('')

  const [employee] = useState(empData._id)
  
  const [date, setDate] = useState(props?.passDate)

  const [accounting, setAccounting] = useState('')
  const [type, setType] = useState('')
  const [description, setDescription] = useState('')
  const [customer, setCustomer] = useState()
  const [bank, setBank] = useState()
  const [billNo, setBillNo] = useState()
  const [comment, setComment] = useState()
  const [fuelType, setFuelType] = useState()
  const [liter, setLiter] = useState()
  const [govOrder, setGovOrder] = useState()

  const [amount, setAmount] = useState('')
  const [status, setStatus] = useState(false)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadBanks()
  }, [])

  useEffect(() => {
    accounting === "63e31abdf029ceab39dcd283" && loadCustomers()
    accounting === "63e31abdf029ceab39dcd283" && loadFuelTypes()
  }, [accounting])

  useEffect(() => {
    setStatus(true)
  }, [empData])

  useEffect(() => {
    setType('')
    setDescription('')
    setInputCustomer('')
    setCustomer()
    setBank()
    setBillNo()
    setComment()
    setFuelType()
    setLiter()
    setAmount('')
  }, [accounting])

  useEffect(() => {
    setInputGovOrder('')
    setGovOrder()
  },[customer])

  useEffect(() => {
    setType(() => 
      amount >= 0 ? "credit" : "debit"
    )
  },[amount])

  const loadBanks = async() => {
    try {
      const {data} = await Axios.get(`/bank-accounts`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomers = async() => {
    try {
      const {data} = await Axios.get(`/customers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListCustomers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadFuelTypes = async() => {
    try {
      const {data} = await Axios.get(`/fueltypes`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListFuelTypes(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadGovOrders = async(id) => {
    try {
      const {data} = await Axios.post(`/govorders`, {customer: id, status: 'receive'})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListGovOrders(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (accounting === '63e31abdf029ceab39dcd283' && (!billNo || !comment || !fuelType)) {
        toast.error('Bill, comment & Fuel Type Required')
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/accdata`, {date, type: `${(accounting === '63e31af4f029ceab39dcd28f') ? (amount >= 0 ? 'debit' : 'credit') : type}`, empStatus: {status, employee}, detail: {description, bank, customer, billNo, comment, fuel: {fuelType, liter}, govOrder}, amount : `${Number( (accounting === '63e31af4f029ceab39dcd28f') ? (amount >= 0 ? (-amount) : amount) : amount )?.toFixed(2)}` , accounting})
        if(data?.error){
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          toast.success("Accounts Data Saved Sucessfully")
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    };
  }

  return (
    <div className='p-3'>
      <h5 className='text-center text-capitalize'>Accounts - {empData?.name}</h5>
      <h6 className='text-center text-capitalize'>Differance Amount - {new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(props?.dayEntriesBalance)}</h6>
      <form onSubmit={handleSubmit}>
        <Text
          label="Date"
          type = 'date'
          value = {date}
          onChange={(e) => setDate(e.target.value)}
          readOnly = 'true'
        />

        <SelectInput
          label = "Accounts"
          value = {accounting}
          onChange = {(e) => setAccounting(e.target.value)}
          items = {
            listAccounts && listAccounts?.map((i) => (
              <MenuItem value={i._id}>{i.name}</MenuItem>
            ))
          }
        />

        {accounting === "63e31a93f029ceab39dcd281" &&
          <SelectInput
            label = "Bank"
            value = {bank}
            onChange = {(e) => setBank(e.target.value)}
            items = {
              listBanks && listBanks?.map((i) => (
                <MenuItem value={i?._id}>{i?.bank} | {i?.accountNo}</MenuItem>
              ))
            }
          />
        }

        {accounting &&
          (accounting === "63e31abdf029ceab39dcd283" ?
            <div>
              <Text
                label="Bill No" 
                value = {billNo}
                onChange={(e) => {
                  setBillNo(e.target.value)
                  setDescription('Fuel Credit - Bill No ' + e.target.value)
                }}
              />
              {listCustomers && <Autocomplete
                size='small'
                fullWidth
                className='mb-2'
                value={customer?._id}
                onChange={(event, value) => {
                  setCustomer(value)
                  value?.type === 'government' && loadGovOrders(value?._id)
                }}
                inputValue={inputCustomer}
                onInputChange={(event, newInputValue) => {
                  setInputCustomer(newInputValue)
                }}
                options={listCustomers}
                renderInput={(params) => <TextField {...params} label="Customer"/>}
                getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
                noOptionsText = "No Customer Found"
              />}
              
              <button type="button" className="btn btn-link p-0 mb-3" onClick={() => window.open(`/dashboard/user/customer/List`,'_blank')}>Add Customer</button>

              <Text
                label="Comment (Vehicle No / Name)" 
                value = {comment}
                onChange={(e) => setComment(e.target.value)}
              />

                {listFuelTypes && <SelectInput
                  label = "Fuel Type (opt.)"
                  value = {fuelType}
                  onChange = {(e) => setFuelType(e.target.value)}
                  items = {
                    listFuelTypes && listFuelTypes?.map((i) => (
                      <MenuItem value={i?._id}>{i?.fuelType}</MenuItem>
                    ))
                  }
                />}

                <Text
                  label = "Liters (opt.)"
                  value = {liter}
                  type = "Number"
                  onChange={(e) => setLiter(e.target.value)}
                  end = "L"
                />

              {customer?.type === 'government' && listGovOrders && <Autocomplete
                size='small'
                fullWidth
                className='mb-2'
                value={govOrder?._id}
                onChange={(event, value) => {
                  value ? setGovOrder(value) : setGovOrder()
                }}
                inputValue={inputGovOrder}
                onInputChange={(event, newInputValue) => {
                  setInputGovOrder(newInputValue)
                }}
                options={listGovOrders}
                renderInput={(params) => <TextField {...params} label="Government Order (Opt.)"/>}
                getOptionLabel = {(params) => `${params.govOrder + ' | '+ params.receiveDate}`}
                noOptionsText = "Govenment Order not found"
              />}

            </div>
            :
            <Text
              label = "Description (opt.)"
              value = {description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )
        }

        <Text
          label="Amount" 
          value = {amount}
          type = "number"
          onChange={(e) => setAmount(e.target.value)}
          start = {settings?.currencyStart}
        />

        <div className="d-grid gap-2">
          <button className='btn btn-primary mt-3' type='submit' disabled={submitDisable}>Submit</button>
        </div>

      </form>
    </div>
  )
}
