import React from 'react'
import {Routes, Route} from "react-router-dom"
import { Layout } from 'antd'

//Roles Permission Access Levels
import PrivateRoute from '../routes/PrivateRoute'
import UserRoute from './UserRoute'
import AdminRoute from '../routes/AdminRoute'

//All Level Access
import Start from '../pages/Start'
import LoginPage from '../pages/auth/Login' //No SB Option
import Developer from '../pages/Developer' //No SB Option
import PageNotFound from '../pages/PageNotFound' //No SB Option

//EMPLOYEE Level Access
import Home from '../pages/Home'
import Profile from '../pages/Employee/Profile' //No SB Option

import PumpsCard from '../pages/pump/PumpsCard'

import ListFuelUps from '../pages/FillUps/FuelUps/ListFuelUps'
//POS
import SalesForm from '../pages/Inventory/Sales/SalesForm'
import ReturnsForm from '../pages/Inventory/Sales/ReturnsForm'


//USER Level Access
import Dashboard from '../pages/Dashboard/Dashboard'
import GasDashboard from '../pages/GasDashboard'

import EmployeeCard from '../pages/Employee/EmployeeCard'
import EmpAccountsSummary from '../pages/Employee/EmpAccountsSummary' //No SB Option
import ListEmployee from '../pages/Employee/ListEmployee'
import ListDayEmployee from '../pages/Employee/ListDayEmployee'
import Attendance from '../pages/Employee/Attendances/Attendance'
import EmpAttendanceDetails from '../pages/Employee/Attendances/EmpAttendanceDetails' //No SB Option

import ListCustomers from '../pages/Customer/ListCustomers'
import CustomerOrders from '../pages/Customer/GovOrders/CustomerOrders'
import ListGovOrders from '../pages/Customer/GovOrders/ListGovOrder'

import TankCard from '../pages/Tank/TankCard'
import TankStockForm from '../pages/Tank/StockUpdateForm' //No SB Option
import FindDepth from '../pages/Tank/Depths/DepCheck'
import AllLoads from '../pages/Tank/AllLoads'
import AllDepths from '../pages/Tank/Depths/AllDepths'
import ListDep from '../pages/Tank/Depths/ListDep' //No SB Option
import ListTankCheck from '../pages/Tank/TankChecks/ListTankCheck' //No SB Option
import TankCheck from '../pages/Tank/TankChecks/TankCheck' //No SB Option

import ListDayPump from '../pages/pump/ListdayPump'
import FuelSales from '../pages/pump/FuelSales'

import PointsCustomers from '../pages/FillUps/PointsCustomer/ListPointsCustomers'
import ListPCVehicles from '../pages/FillUps/Vehicles/ListVehicles'
//POS
import ListOrders from '../pages/Inventory/Sales/Orders/ListOrders'
import ListOrderReturns from '../pages/Inventory/Sales/OrderReturns/ListOrderReturns'
import PurchaseForm from '../pages/Inventory/Purchase/PurchaseForm'
import ListPurchase from '../pages/Inventory/Purchase/Purchases/ListPurchase'
import PurchaseDetails from '../pages/Inventory/Purchase/Purchases/PurchaseDetails' //No SB Option
import PurchaseReturnForm from '../pages/Inventory/Purchase/PurchaseReturnForm'
import ListPurchaseReturns from '../pages/Inventory/Purchase/PurchaseReturns/ListPurchaseReturns'
import PurchaseReturnDetails from '../pages/Inventory/Purchase/PurchaseReturns/PurchaseReturnDetails' //No SB Option
import ListStock from '../pages/Inventory/ListStock'

import ListEmployeeAccounts from '../pages/accounts/ListEmployeeAccounts' //No SB Option
import ListCustomerAccounts from '../pages/accounts/ListCustomerAccounts' //No SB Option
import AccountsDetails from '../pages/accounts/DoubleEntires/AccountsDetails' //No SB Option
import AccountsForm from '../pages/accounts/DoubleEntires/AccountsForm'
import AllDoubleEntries from '../pages/accounts/DoubleEntires/AllDoubleEntries' //No SB Option
import DailyCashFlow from '../pages/accounts/CashFlow/DailyCashFlow'
import ListBankAccounts from '../pages/accounts/Bank/ListBankAccounts'
import BankAccountsDetails from '../pages/accounts/Bank/BankAccountsDetails' //No SB Option
import ListCheques from '../pages/accounts/Cheques/ListCheques'
import IncomeStatementDetail from '../pages/accounts/FinancialYear/IncomeStatements/IncomeStatementDetail' //No SB Option
import FinancialStatementDetail from '../pages/accounts/FinancialYear/FinancialStatements/FinancialStatementDetail' //No SB Option


//ADMIN Level Access
import ListSuppliers from '../pages/Supplier/ListSuppliers'

import ListTank from '../pages/Tank/ListTank'
import ListFuelType from '../pages/Tank/FuelType/ListFuelType'

import ListBooth from '../pages/Booth/ListBooth'

import ListPump from '../pages/pump/ListPump'

import ListPointsCategories from '../pages/FillUps/PointsCategory/ListPointsCategories'
//POS
import ListBrands from '../pages/Inventory/Brand/ListBrand' //No SB Option
import ListProducts from '../pages/Inventory/ListProducts'
import ListStockAdjustment from '../pages/Inventory/StockAdjustment/ListStockAdjustment'
import WareHouse from '../pages/Inventory/WareHouse/WareHouse'

import ListSupplierAccounts from '../pages/accounts/ListSupplierAccounts' //No SB Option
import ListAccounts from '../pages/accounts/ListAccounts'

import Settings from '../pages/Settings'

export default function RoutesPage() {

  const {Content} = Layout;
      
  return (
    <Content
      style={{
        height: '100%',
      }}
    >
      <div style={{padding: 24, height: '100%'}}>
        <Routes>
            <Route path="/" element={<Start/>}></Route>
            <Route path="/login" element={<LoginPage/>}></Route>
            <Route path="/developer" element={<Developer/>}></Route>
            <Route path="*" element={<PageNotFound/>} replace></Route>

            {/* EMPLOYEE ACCESS */}
            <Route path="/dashboard" element={<PrivateRoute/>}>
              <Route path="profile" element={<Profile/>}></Route>
              <Route path="" element={<Home/>}></Route>

              <Route path="pump/card" element={<PumpsCard/>}></Route>

              <Route path="fillup/fill" element={<ListFuelUps/>}></Route>

              <Route path="inventory/sale" element={<SalesForm/>}></Route>
              <Route path="inventory/sale-return" element={<ReturnsForm/>}></Route>
            </Route>

            {/* USER ACCESS  */}
            <Route path="/dashboard/user" element={<UserRoute/>}>
              <Route path="" element={<Dashboard/>}></Route>
              <Route path="gas-dashboard" element={<GasDashboard/>}></Route>


              <Route path="employee/card" element={<EmployeeCard/>}></Route>
              <Route path="employee/:id" element={<EmpAccountsSummary/>}></Route>
              <Route path="employee/list" element={<ListEmployee/>}></Route>
              <Route path="employee/listdayemployee" element={<ListDayEmployee/>}></Route>
              <Route path="employee/attendance" element={<Attendance/>}></Route>
              <Route path="employee/attendance/:id" element={<EmpAttendanceDetails/>}></Route>

              <Route path="customer/list" element={<ListCustomers/>}></Route>
              <Route path="customer-orders/:id" element={<CustomerOrders/>}></Route>
              <Route path="customers/list-govorders" element={<ListGovOrders/>}></Route>

              <Route path="supplier/list" element={<ListSuppliers/>}></Route>

              <Route path="tank/card" element={<TankCard/>}></Route>
              <Route path="tank/purchase/:id" element={<TankStockForm/>}></Route>
              <Route path="tank/find-depth" element={<FindDepth/>}></Route>
              <Route path="tank/all-loads" element={<AllLoads/>}></Route>
              <Route path="tank/all-depths" element={<AllDepths/>}></Route>
              <Route path="tank/depthlist/:id" element={<ListDep/>}></Route>
              <Route path="tank/List-tank-check/:id" element={<ListTankCheck/>}></Route>
              <Route path="tank/tank-check/:id" element={<TankCheck/>}></Route>

              <Route path="pump/dayPump" element={<ListDayPump/>}></Route>
              <Route path="pump/fuel-sales" element={<FuelSales/>}></Route>

              <Route path="fillup/pointscustomer" element={<PointsCustomers/>}></Route>
              <Route path="fillup/vehicles" element={<ListPCVehicles/>}></Route>

              <Route path="inventory/sales" element={<ListOrders/>}></Route>
              <Route path="inventory/sale-returns" element={<ListOrderReturns/>}></Route>
              <Route path="inventory/purchase" element={<PurchaseForm/>}></Route>
              <Route path="inventory/purchases" element={<ListPurchase/>}></Route>
              <Route path="inventory/purchase-report/:id" element={<PurchaseDetails/>}></Route>
              <Route path="inventory/purchase-return" element={<PurchaseReturnForm/>}></Route>
              <Route path="inventory/purchase-returns" element={<ListPurchaseReturns/>}></Route>
              <Route path="inventory/purchase-return-report/:id" element={<PurchaseReturnDetails/>}></Route>
              <Route path="inventory/stocks" element={<ListStock/>}></Route>

              <Route path="employee-accounts/:id" element={<ListEmployeeAccounts/>}></Route>
              <Route path="customer-accounts/:id" element={<ListCustomerAccounts/>}></Route>
              <Route path="supplier-accounts/:id" element={<ListSupplierAccounts/>}></Route>
              <Route path="accounts/:id" element={<AccountsDetails/>}></Route>
              <Route path="accounts/entry" element={<AccountsForm/>}></Route>
              <Route path="accounts/alldoubleentries" element={<AllDoubleEntries/>}></Route>
              <Route path="accounts/daily-cashflow" element={<DailyCashFlow/>}></Route>
              <Route path="accounts/bank-accounts" element={<ListBankAccounts/>}></Route>
              <Route path="bank-accounts/:id" element={<BankAccountsDetails/>}></Route>
              <Route path="accounts/cheques" element={<ListCheques/>}></Route>
              <Route path="accounts/incomeStatement-Detail" element={<IncomeStatementDetail/>}></Route>
              <Route path="accounts/financialStatement-Detail" element={<FinancialStatementDetail/>}></Route>

            </Route>

            {/* ADMIN ACCESS  */}
            <Route path="/dashboard/user/admin" element={<AdminRoute/>}>

              <Route path="tank/list" element={<ListTank/>}></Route>
              <Route path="fueltype" element={<ListFuelType/>}></Route>

              <Route path="booth/list" element={<ListBooth/>}></Route>
              <Route path="pump/list" element={<ListPump/>}></Route>
              
              <Route path="inventory/list" element={<ListProducts/>}></Route>
              <Route path="inventory/brands" element={<ListBrands/>}></Route>
              <Route path="inventory/stock-adjustments" element={<ListStockAdjustment/>}></Route>
              <Route path="inventory/warehouse" element={<WareHouse/>}></Route>

              <Route path="fillup/pointsCategory" element={<ListPointsCategories/>}></Route>

              <Route path="accounts/list" element={<ListAccounts/>}></Route>

              <Route path="settings" element={<Settings/>}></Route>
            </Route>
        </Routes>
      </div>
    </Content>
  )
}
