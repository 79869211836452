import React, {useState} from 'react';
import { DataGrid, GridToolbarContainer, useGridApiContext, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExportContainer, GridCsvExportMenuItem, GridPrintExportMenuItem } from '@mui/x-data-grid';

export default function MUITable(props) {
  const [pageSize, setPageSize] = useState(props?.pageSize || 100);
  const [tableHeight, setTableHeight] = useState(pageSize === 100 && (window.screen.height * 0.6))
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(props?.hiddenFields);

  const columnGroupingModel = [
    {
      groupId: 'unknown',
      headerName: props.title,
      headerAlign: 'center',
      children: props?.columns?.map((i) => { return {field: i?.field}}),
    },
  ];

  const CustomToolbar = () =>
    <GridToolbarContainer>
      <GridToolbarColumnsButton/>
      <GridToolbarFilterButton/>
      <GridToolbarDensitySelector/>
      <GridToolbarExport/>
    </GridToolbarContainer>

  const GridToolbarExport = ({ csvOptions, printOptions, ...other }) => {
    const apiRef = useGridApiContext();

    const print = async () => {
      const oldTitle = document.title;
      document.title = props.title;
      await apiRef.current.exportDataAsPrint({ hideToolbar: true });
      setTimeout(() => {document.title = oldTitle}, 5000)
    };

    return (
      <GridToolbarExportContainer {...other}>
        <GridCsvExportMenuItem options={{
          fileName: props.title,
          includeColumnGroupsHeaders: false
        }} />
        <GridPrintExportMenuItem onClick={print} options={{
          hideToolbar: true,
        }}/>
      </GridToolbarExportContainer>
  )};

  return (
    <DataGrid
      className = {props?.className} 
      style={props?.style}
      sx={{height: tableHeight, fontSize: props?.fontSize, width: '100%', bgcolor: props?.bgcolor || 'background.paper', boxShadow: 3, borderColor: 'primary.light',
        '& .MuiDataGrid-columnHeader': {
          color: 'warning.main',
        },
        '@media print': {
          '.MuiDataGrid-main': { color: 'rgba(0, 0, 0, 0.8)' },
        },
      }}
      rows={props?.rows}
      columns={props?.columns}
      getRowId = {props?.id}
      autoHeight = {tableHeight ? false : true}
      columnHeaderHeight = {props?.headerSize || 50}
      rowHeight = {props?.rowSize || 40}
      hideFooter = {props?.hideFooter || false}

      {...props?.rows}
      initialState={{
        ...props?.rows,
        pagination: { paginationModel: { pageSize } },
      }}
      pageSizeOptions={[5, 10, 25, 50, 100]}

      slots={props.exportDisabled ? '' : { toolbar: CustomToolbar }}
      localeText={{
        toolbarDensity: 'Size',
        toolbarDensityLabel: 'Size',
        toolbarDensityCompact: 'Small',
        toolbarDensityStandard: 'Medium',
        toolbarDensityComfortable: 'Large',
      }}

      checkboxSelection = {props?.checkbox || false}
      onRowSelectionModelChange={(RowSelection) => {
        const selectedIDs = new Set(RowSelection);
        const selectedRowData = props.rows.filter((row) =>
          selectedIDs.has(row._id)
        );
        props?.rowSelection && props?.rowSelection(selectedRowData)
      }}

      experimentalFeatures={{ columnGrouping: props.title ? true : false }}
      columnGroupingModel={columnGroupingModel}

      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        setColumnVisibilityModel(newModel)
      }
    />
  );
}