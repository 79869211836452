import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import MUITable from '../../components/table/MUITable'

export default function StockList(props) {
    const tank = props.data

    const [listLoads, setListLoads] = useState('')
    //Model

    useEffect(() => {
        loadStockLoad()
    }, [tank])
    

  const loadStockLoad = async() => {
      try {
          const {data} = await Axios.post(`/stockloads`, {"wareHouseStock._id": tank?._id})
          if(data?.error){
              toast.error(data.error)
          } else {
            setListLoads(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }
  
  return (
    <div className='row justify-content-center mt-5'>
      <h5 className='text-center text-capitalize mb-2'>Purchase Stocks List</h5>
      <div className='col-lg-10 col-12'>
        {listLoads && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
                { field: 'date', headerName: 'Date', width: 120, renderCell: (i) => i.row.purchase?.purchaseDate, valueGetter: (i) => i.row.purchase?.purchaseDate},
                { field: 'invoiceNo', headerName: 'Invoice No', width: 150, renderCell: (i) => i.row.purchase?.invoiceNo, valueGetter: (i) => i.row.purchase?.invoiceNo},
                { field: 'quantity', headerName: 'Litters', width: 120, renderCell: (i) => `${new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(i.row.quantity)}`, filterable: false, sortable: false},
                { field: 'load', headerName: 'Load', width: 120, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.quantity/ i.row.wareHouseStock?.productCode?.product?.type?.loadLiter)}`, filterable: false, sortable: false},
                { field: 'markedOn', headerName: 'Marked On', width: 180, renderCell: (i) => moment(i.row.createdAt).format("YYYY-MM-DD hh:mm: A")},
            ]}
            rows = {listLoads}
          />
        }
      </div>
    </div>
    )
}
