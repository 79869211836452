import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text, SelectInput} from '../../components/Inputs/InputFields'
import {TextField, MenuItem, Autocomplete} from '@mui/material';

export default function CreateProduct() {

  const [listCategory, setListCategory] = useState('')
  const [listBrand, setListBrand] = useState('')

  const [name, setName] = useState("")
  const [description, setDescription] = useState('')
  const [category, setCategory] = useState('')
  const [brand, setBrand] = useState('')
  const [unit, setUnit] = useState('')

  const [inputCategory, setInputCategory] = useState('')
  const [inputBrand, setInputBrand] = useState('')

  const listUnit = ['L', 'QTY', 'Kg']

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadCategories()
    loadBrands()
  }, [])

  const loadCategories = async() => {
    try {
      const {data} = await Axios.get('/categories')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListCategory(data.filter((i) => i._id !== '63e65efc2f840202604fbb7f')) 
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBrands = async() => {
    try {
      const {data} = await Axios.get('/brands')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListBrand(data) 
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const productObject = (i) => {
    try {
      if (i) {
        return {name, description, brand, category, unit}
      } else {
        return {name, description, category, unit}
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/product`, productObject(brand));
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success('Product Added')
        window.location.reload()
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Add Product</h4>
      
      <form onSubmit={handleSubmit}>
        <Text
          label="Product Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />

        {listCategory && <Autocomplete
          className='mb-2'
          style={{minWidth: '50%'}}
          size='small'
          value={category?._id}
          onChange={(event, value) => {
              setCategory(value)
          }}
          inputValue={inputCategory}
          onInputChange={(event, newInputValue) => {
              setInputCategory(newInputValue)
          }}
          options={listCategory}
          renderInput={(params) => <TextField {...params} label="Category"/>}
          getOptionLabel = {(params) => `${params.name}`}
          noOptionsText = "No Category Found"
        />}

        {listBrand && <Autocomplete
          className='mb-2'
          style={{minWidth: '50%'}}
          size='small'
          value={brand?._id}
          onChange={(event, value) => {
              setBrand(value)
          }}
          inputValue={inputBrand}
          onInputChange={(event, newInputValue) => {
              setInputBrand(newInputValue)
          }}
          options={listBrand}
          renderInput={(params) => <TextField {...params} label="Brand"/>}
          getOptionLabel = {(params) => `${params.name}`}
          noOptionsText = "No Brand Found"
        />}

        <Text
          label="Description"
          value = {description}
          onChange={(e) => setDescription(e.target.value)} 
        />

        <SelectInput
          label = "Unit"
          value = {unit}
          onChange = {(e) => {
            setUnit(e.target.value)
          }}
          items = {
            listUnit && listUnit?.map((i) => (
              <MenuItem value={i}>{i}</MenuItem>
            ))
          }
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            ADD PRODUCT
          </button>
        </div>
      </form>
    </div>
  )
}
