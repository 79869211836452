import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreateFuelType from './CreateFuelType'
import UpdateFuelType from './UpdateFuelType'

export default function ListFuelType() {

  const [listFuelType, setListFuelType] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
      loadFuelType()
  }, [])
  
  const loadFuelType = async() => {
      try {
          const {data} = await Axios.get('/fueltypes')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListFuelType(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-6 col-lg-8 col-md-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
            <div className='col-sm-6 col-12 d-flex gap-2 mb-2'>
              <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
            </div>
        </div>
        {listFuelType && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
                { field: 'fuelType', headerName: 'FuelType', width: 200},
                { field: 'loadLiter', headerName: 'Load Liters', width: 200, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'unit', unit: 'liter'}).format(i.row.loadLiter)},
            ]}
            rows = {listFuelType}
          />
        }
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateFuelType/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateFuelType data = {selected}/>}
        />

      </div>
    </div>
  )
}
