import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"
import {Text} from '../../../components/Inputs/InputFields'

export default function UpdatePointsCategory(props) {
  const pointsData = props?.data
  const [type, setType] = useState(pointsData?.type)
  const [pointsRate, setPointsRate] = useState(pointsData?.pointsRate)
  const [maxAmount, setMaxAmount] = useState(pointsData?.maxAmount)

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async() => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.put(`/pointscategory/${pointsData?._id}`, {type, pointsRate: Number( pointsRate / 100), maxAmount})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success("Points Category Updated Sucessfully")
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }
  
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Points Category - {pointsData?.type}</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Type"
          value = {type}
          onChange={(e) => setType(e.target.value)}
          focus = 'true'
        />
        <Text
          label="Points %"
          value = {pointsRate}
          type = "Number"
          onChange={(e) => setPointsRate(e.target.value)} 
        />
        <Text
          label="Maximum Redeem"
          value = {maxAmount}
          type = "Number"
          onChange={(e) => setMaxAmount(e.target.value)} 
        />
        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            UPDATE POINTS CATEGORY
          </button>
        </div>
      </form>
    </div>
  )
}
