import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { Autocomplete, TextField } from '@mui/material';
import {Text} from '../../../components/Inputs/InputFields'
import {Check, Clear} from '@mui/icons-material'

export default function CreateVehicle() {


  const [listCustomers, setListCustomers] = useState('')
  const [listPointsCategories, setListPointsCategories] = useState('')
  //Model

  const [pointsCategoryInput, setPointsCategoryInput] = useState('')

  const [phoneNumber, setPhoneNumber] = useState('')
  const [customer, setCustomer] = useState('')

  const [vehicleNo, setVehicleNo] = useState('')
  const [pointsCategory, setPointsCategory] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadCustomers()
      loadPointsCategories()
  }, [])
  

  const loadCustomers = async() => {
      try {
          const {data} = await Axios.get('/pointscustomers')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListCustomers(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadPointsCategories = async() => {
    try {
        const {data} = await Axios.get('/pointscategories')
        if(data?.error){
            toast.error(data.error)
        } else {
            setListPointsCategories(data)
        }
    } catch (err) {
        toast.error("Something went wrong, Try Again!")
        console.log(err)
    }
  }


  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (phoneNumber?.trim()) {
        if (customer?._id) {
          const {data} =  await Axios.post(`/pc-vehicle`, {customer: customer?._id, vehicleNo, pointsCategory})
          if (data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success("Vehicle Added")
            window.location.reload()
          }
        } else {
          const {data} = await Axios.post(`/pointscustomer`, {phoneNumber});
          if (data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            const _id = await data._id
            const data2 =  await Axios.post(`/pc-vehicle`, {customer: _id, vehicleNo, pointsCategory})
            if (data2?.data.error) {
              toast.error(data2?.data.error)
              setSubmitDisable(false)
            } else {
              toast.success("Vehicle Added")
              window.location.reload()
            }
          }
        }
      } else {
        toast.error("Phone Number Required")
        setSubmitDisable(false)
      }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong, Try Again!')
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Add Vehicle</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Customer phone No"
          value = {phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value)
            setCustomer(listCustomers && listCustomers?.find((i) => i?.phoneNumber === e.target.value))
          }}
          end = {phoneNumber.trim() > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <dd>{customer && ('Customer Found: ' + customer?.name)}</dd>

        <Text
          label="Vehicle No"
          value = {vehicleNo}
          onChange={(e) => setVehicleNo(e.target.value)}
        />

        {listPointsCategories && <Autocomplete
          size='small'
          className='mb-2'
          value={pointsCategory?._id}
          onChange={(event, value) => {
            setPointsCategory(value)
          }}
          inputValue={pointsCategoryInput}
          onInputChange={(event, newInputValue) => {
            setPointsCategoryInput(newInputValue)
          }}
          options={listPointsCategories}
          renderInput={(params) => <TextField {...params} label="Points Category"/>}
          getOptionLabel = {(params) => `${params?.type}`}
          noOptionsText = "No Points Category Found"
        />}

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            ADD VEHICLE
          </button>
        </div>
      </form>
    </div>
    )
}
