import React, {useEffect, useState} from 'react'
import ReactEcharts from "echarts-for-react";

export default function BarChart(props) { 
  const [listData] = useState(props?.data)

  const option = {
    legend: {
      padding: 20
    },
    backgroundColor: {
      image: 'rgba(255,255,255,0.1)'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
        left: '5%',
        right: '5%',
        bottom: '5%',
        containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        data: listData.map((i) => i?.productCode?.product?.name)
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Liters',
        scale: 'value',
      }
    ],
    series: [
      {
        name: 'Stock',
        type: 'bar',
        sclae: 3,
        data: listData.map((i) => Math.round(i?.stock)),
        emphasis: {
          focus: 'series',
        },
      },
      {
        name: '1 Load',
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        data: listData.map((i) => 6600),
      },
      {
        name: '2 Load',
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        data: listData.map((i) => 13200),
      },
      {
        name: '3 Load',
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        data: listData.map((i) => 19800),
      },
    ]
  };

  return (
    <div>
      {listData && <ReactEcharts style={{minHeight: (window.screen.height * 0.6), width: '100%'}} className='echarts-for-echarts' option={option} theme="dark" lazyUpdate={true}/>}
    </div>
      
  )
}
