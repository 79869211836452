import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

import {Warehouse,Visibility, Print} from '@mui/icons-material/'

import { useDk } from '../../context/dk'

export default function ListStock() {

  const [dk, setDk] = useDk()

  const [listBrand, setListBrand] = useState('')
  const [listProducts, setListProducts] = useState('')
  const [listWareHouseStocks, setListWareHouseStocks] = useState('')

  const [listPurchaseDetails, setListPurchaseDetails] = useState('')

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    loadBrands()
    loadProducts()
    loadWareHouseStocks()
    loadPurchaseDetails()
  }, [])

  const loadBrands = async() => {
    try {
        const {data} = await Axios.get(`/brands`)
        if(data?.error){
            toast.error(data.error)
        } else {
          setListBrand(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }
  
  const loadProducts = async() => {
      try {
          const {data} = await Axios.post(`/warehousestocks/group-byproductcode`)
          if(data?.error){
              toast.error(data.error)
          } else {
            setListProducts(data)
          }
      } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const loadWareHouseStocks= async() => {
    try {
        const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: {"$ne" : '63e65efc2f840202604fbb7f'}})
        if(data?.error){
            toast.error(data.error)
        } else {
          setListWareHouseStocks(data)
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }

  const loadPurchaseDetails = async() => {
    try {
        const {data} = await Axios.post(`/purchaseproductlines`)
        if (data?.error) {
            toast.error(data.error)
        } else {
          setListPurchaseDetails(data?.filter((i) => i?.productCode?.product?.category?._id !== '63e65efc2f840202604fbb7f')?.reverse())
        }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }

  const stockBgColor = (stock) => {
    switch (true) {
      case stock === 0:
        return {backgroundColor: 'red', color: 'black'}
        break;
      case stock <= 20:
        return {backgroundColor: 'orange', color: 'black'}
        break;
      case stock <= 50:
        return {backgroundColor: 'yellow', color: 'black'}
        break;
    }
  }

  const FIFO = (selectedProductCode) => {
    try {
      const purchaseList = listPurchaseDetails && listPurchaseDetails?.filter((i) => i?.productCode?._id === selectedProductCode)
      const totalStock = listWareHouseStocks && listWareHouseStocks?.filter((i) => i?.productCode?._id === selectedProductCode)?.reduce((a,v) => a = a + v?.stock, 0)
      var sumQty = 0
      const arr = []
      
      for (var i=0; i<purchaseList?.length; i++) {
        sumQty += purchaseList[i]?.quantity
        if (sumQty >= totalStock) {
          arr.push({unitCost: Number(purchaseList[i]?.itemPrice / purchaseList[i]?.quantity)?.toFixed(2), qty: ((purchaseList[i]?.quantity + totalStock) - sumQty)})
          break
        } else {
          arr.push({unitCost: Number(purchaseList[i]?.itemPrice / purchaseList[i]?.quantity)?.toFixed(2), qty: purchaseList[i]?.quantity})
        }
      }
    
      return(arr);

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `All Stocks`,
      onBeforeGetContent: async() => {
        await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
          const data = localStorage.getItem("darkmode");
          if (data) {
              const parsed = JSON.parse(data);
              setDk({...dk, darkmode: parsed});
          }
      },
  })

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='d-flex mb-2'>
          <button className = 'btn btn-warning' onClick={() => {printData()}}><Print/></button>
        </div>
        <div ref= {componentRef}>
          <h5 className='text-center'>All Stocks of Products</h5>
          {listProducts && listBrand && listBrand?.map((b) =>
            <div className='mb-3'>
              <h5 className='text-center'>{b?.name}</h5>
              <MUITable
                title = {b?.name + ' Brand Products'}
                id = {(row) => row._id}
                pageSize = {10}
                headerSize = {40}
                rowSize = {40}
                fontSize = {14}
                columns = {[
                    { field: 'productcode', headerName: 'Product Code', width: 140, renderCell: (i) => `${i.row?.productCode?.productCode}`, valueGetter: (i) => `${i.row?.productCode?.productCode}`},
                    { field: 'product', headerName: 'Product', width: 300, renderCell: (i) => `${i.row?.productCode?.product?.name}`, valueGetter: (i) => `${i.row?.productCode?.product?.name}`},
                    { field: 'totalStock', headerName: 'Total Stock', headerAlign:'center', width: 120, renderCell: (i) => <div className='text-center p-2 w-100 fw-bold' style={stockBgColor(i.row.totalStock)}>{new Intl.NumberFormat().format(i.row.totalStock) + ' ' + i.row?.productCode?.product?.unit}</div>, valueGetter: (i) => i.row?.totalStock},
                    { field: 'totalSold', headerName: 'Total Sold', width: 120, renderCell: (i) => (new Intl.NumberFormat().format(i.row.totalSold) + ' ' + i.row?.productCode?.product?.unit), valueGetter: (i) => i.row?.totalSold},
                    { field: 'unitCost', headerName: 'Avg Unit Cost', width: 130, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'currency', currency: "LKR"}).format(FIFO(i.row?.productCode?._id)?.reduce((a,v) => a = a + (v?.unitCost * v?.qty), 0) / i.row?.totalStock), filterable: false, sortable: false},
                    { field: 'unitPrice', headerName: 'Unit Price', width: 130, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'currency', currency: "LKR"}).format(i.row.productCode?.price), valueGetter: (i) => i.row.productCode?.price},
                    { field: 'unitProfit', headerName: 'Avg Unit Profit', width: 130, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'currency', currency: "LKR"}).format(i.row.productCode?.price - (FIFO(i.row?.productCode?._id)?.reduce((a,v) => a = a + (v?.unitCost * v?.qty), 0) / i.row?.totalStock)), filterable: false, sortable: false},
                    { field: 'stockValue', headerName: 'Stock Value', width: 150, renderCell: (i) => new Intl.NumberFormat('en-US', {style: 'currency', currency: "LKR"}).format(FIFO(i.row?.productCode?._id)?.reduce((a,v) => a = a + (v?.unitCost * v?.qty), 0)), filterable: false, sortable: false},
                    { field: 'action', headerName: <Warehouse/>, width: 70, filterable: false, sortable: false, renderCell: (i) => 
                    <div className='d-flex gap-2'>
                      <Visibility fontSize='small'
                        onClick={() => {
                          setVisible(true)
                          setSelected(listWareHouseStocks && listWareHouseStocks?.filter((w) => w?.productCode?._id === i.row?.productCode?._id))
                        }}
                      />
                    </div>
                    },
                ]}
                rows = {listProducts && listProducts?.filter((i) => i?.productCode?.product?.brand?._id === b?._id)}
              />
            </div>
          )}
        </div>
      </div>

      <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {selected && 
          <MUITable
            id = {(row) => row._id}
            headerSize = {40}
            rowSize = {40}
            fontSize = {14}
            hideFooter = {true}
            columns = {[
                { field: 'warehouse', headerName: 'Warehouse', width: 125, renderCell: (i) => `${i.row.wareHouse?.name}`, valueGetter: (i) => `${i.row.wareHouse?.name}`},
                { field: 'stock', headerName: 'Stock', width: 100, renderCell: (i) => (new Intl.NumberFormat().format(i.row.stock) + ' ' + i.row.productCode?.product?.unit), valueGetter: (i) => i.row.stock},
                { field: 'sold', headerName: 'Sold', width: 100, renderCell: (i) => (new Intl.NumberFormat().format(i.row.sold) + ' ' + i.row.productCode?.product?.unit), valueGetter: (i) => i.row.sold},
            ]}
            rows = {selected}
          />}
        />
    </div>
  )
}
