import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useSettings } from '../../../context/settings';
import { useGdate } from '../../../context/date'

import { Text, SwitchInput } from '../../../components/Inputs/InputFields';
import {TextField, Autocomplete} from '@mui/material/'

export default function AccountsForm(props) {
  const accData = props.data
  const [gdate] = useGdate()
  const [settings] = useSettings()

  const [listAccountings, setListAccountings] = useState('')
  const [accDataLength, setAccDataLength] = useState('')
  const [listBanks, setListBanks] = useState('')
  const [listCustomers, setListCustomers] = useState('')
  const [listSuppliers, setListSuppliers] = useState('')
  const [listEmployeeSalaryArrears, setListEmployeeSalaryArrears] = useState('')
  const [listCheques, setListCheques] = useState('')

  const [date, setDate] = useState(gdate?.date)
  const [enteredAmount, setEnteredAmount] = useState('')
  
  //Accounts form 1
  const [inputAccounting, setInputAccounting] = useState('')
  const [accounting, setAccounting] = useState('')
  const [type, setType] = useState('')
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')

  const [inputBank, setInputBank] = useState('')
  const [bank, setBank] = useState()
  const [inputCustomer, setInputCustomer] = useState('')
  const [customer, setCustomer] = useState()
  const [inputSupplier, setInputSupplier] = useState('')
  const [supplier, setSupplier] = useState()
  const [inputEmployee, setInputEmployee] = useState('')
  const [employee, setEmployee] = useState()

  const [chqDate, setChqDate] = useState(gdate?.date)
  const [chqNo, setChqNo] = useState()


  //Accounts form 2
  const [inputAccounting2, setInputAccounting2] = useState('')
  const [accounting2, setAccounting2] = useState('')
  const [type2, setType2] = useState('')
  const [amount2, setAmount2] = useState('')
  const [description2, setDescription2] = useState('')

  const [inputBank2, setInputBank2] = useState('')
  const [bank2, setBank2] = useState()
  const [checked2, setChecked2] = useState(false)
  const [inputCustomer2, setInputCustomer2] = useState('')
  const [customer2, setCustomer2] = useState()
  const [inputSupplier2, setInputSupplier2] = useState('')
  const [supplier2, setSupplier2] = useState()
  const [inputEmployee2, setInputEmployee2] = useState('')
  const [employee2, setEmployee2] = useState()

  const [cheque2, setCheque2] = useState()
  const [chqDate2, setChqDate2] = useState(gdate?.date)
  const [chqNo2, setChqNo2] = useState()

  const [listCashflowCheck, setListCashflowCheck] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    loadCashflowsCheck()
    loadAccounts()
    loadAccLength()
    loadBanks()
    loadEmployeeArrears()
    loadCustomers()
    loadSuppliers()
    loadCheques()
  }, [])

  useEffect(() => {
    listAccountings && setAccounting(listAccountings?.find((i) => i?._id === accData?.inputAccounting) && listAccountings?.find((i) => i?._id === accData?.inputAccounting))
    listAccountings && setAccounting2(listAccountings?.find((i) => i?._id === accData?.inputAccounting2) && listAccountings?.find((i) => i?._id === accData?.inputAccounting2))
    
  }, [listAccountings])

  useEffect(() => {
    setInputCustomer('')
    setCustomer()

    setInputSupplier('')
    setSupplier()

    setInputEmployee('')
    setEmployee()

  }, [accounting])

  useEffect(() => {
    setInputCustomer2('')
    setCustomer2()

    setInputSupplier2('')
    setSupplier2()

    setInputEmployee2('')
    setEmployee2()

  }, [accounting2])

  useEffect(() => {
    setChqDate2()
    setChqNo2()
  }, [checked2])

  useEffect(() => {
    if (accounting?.category?.type) {
      setAmount(enteredAmount) 
      setType('credit')
    } else {
      setAmount(-enteredAmount) 
      setType('debit')
    }

    if (!accounting2?.category?.type) {
      setAmount2(enteredAmount) 
      setType2('credit')
    } else {
      setAmount2(-enteredAmount) 
      setType2('debit')
    }
  },[enteredAmount, accounting, accounting2])

  const loadCashflowsCheck = async() => {
    try {
      const {data} = await Axios.post('/cashflows')
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListCashflowCheck(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  const loadAccounts = async() => {
    try {
      const {data} = await Axios.get(`/accountings`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListAccountings(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAccLength = async() => {
    try {
      const {data} = await Axios.get(`/accdata-count`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setAccDataLength(data + 1)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.get(`/bank-accounts`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListBanks(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadEmployeeArrears = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/employeetotal`, {  $expr : { $eq: [ '$accounting' , { $toObjectId: '6515831c1a89e4fc7010012a' } ] }, "empStatus.status": false, "empStatus.employee" : { $exists: true} })
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListEmployeeSalaryArrears(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCustomers = async() => {
    try {
      const {data} = await Axios.get(`/customers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListCustomers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSuppliers = async() => {
    try {
      const {data} = await Axios.get(`/suppliers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadCheques = async() => {
    try {
      const {data} = await Axios.post(`/cheques`, {status: 'credit', chqDeposit: false})
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListCheques(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const doubleEntryData = ({cheque, cheque2}) => {
    return [
      {date, type, empStatus: {status: false, employee}, detail: {description, customer, bank, supplier, cheque: cheque}, amount: Number(amount)?.toFixed(2), accounting, refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-${accDataLength}-${enteredAmount}`},
      {date, type: type2, empStatus: {status: false, employee: employee2}, detail: {description: description2, customer: customer2, bank: bank2, supplier: supplier2, cheque: cheque2}, amount: Number(amount2)?.toFixed(2), accounting: accounting2, refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-${accDataLength}-${enteredAmount}`}
    ]
  }

  const handleCheque = async() => {
    if (accounting?._id === '64fda2c5c969962bfbcee3eb') {
      const {data} = await Axios.post(`/cheque`, {date, status:'credit', chqCustomer:customer, chqNo, chqDate, amount: Number(enteredAmount)?.toFixed(2)})
      if (data?.error) {
        toast.error("Cannot update Cheque Details")
      } else {
        return({cheque: data})
      }
    } 
    else if (accounting2?._id === '64fda2c5c969962bfbcee3eb' ) {
      const {data} = await Axios.put(`/cheque/${cheque2?._id}`, {chqDeposit: true})
      if (data?.error) {
        toast.error("Cannot update Cheque Details")
      } else {
        return({cheque2: data})

      }
    }
    else if (accounting2?._id === '63e31a93f029ceab39dcd281' && checked2) {
      const {data} = await Axios.post(`/cheque`, {date, status:'debit', chqBank: bank2, chqNo: chqNo2, chqDate: chqDate2, chqDeposit: true, amount: Number(enteredAmount)?.toFixed(2)})
      if (data?.error) {
        toast.error("Cannot update Cheque Details")
      } else {
        return({cheque: null, cheque2: data})
      }
    } else {
      return({})
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      if (listCashflowCheck?.find((i) => i?.date === date)) {
        toast.error(`Already ${date} Cashflow Verified`)
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.post(`/accdatabulk`, doubleEntryData(await handleCheque()))
        if(data?.error){
          toast.error(data?.error)
          setSubmitDisable(false)
        } else {
          toast.success("Accounts Data Saved Sucessfully")
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center mb-3'>ACCOUNTS DOUBLE ENTRY</h5>
      <form onSubmit={handleSubmit}>
        <div>
          {/* Accounts Form 1 */}
          <div className='row justify-content-center'>
              {/* ACCOUNTS */}
              <div className='col-xl-3 col-lg-6 col-md-8 col-12'>
                {listAccountings && !accData?.inputAccounting && <Autocomplete
                  size='small'
                  fullWidth
                  className='mb-2'
                  value={accounting?._id}
                  onChange={(event, value) => {
                    setAccounting(value)
                  }}
                  inputValue={inputAccounting}
                  onInputChange={(event, newInputValue) => {
                    setInputAccounting(newInputValue)
                  }}
                  options={listAccountings}
                  renderInput={(params) => <TextField {...params} label="Accounts" autoFocus/>}
                  getOptionLabel = {(i) => `${i.name?.toUpperCase() + ' | Type: '+ i.category?.name}`}
                  noOptionsText = "No Accounts Found"
                />}
                {listAccountings && accData?.inputAccounting && <h6 className='text-center' >{accounting?.name?.toUpperCase() + ' | ' + accounting?.category?.name}</h6>}
              </div>

              {/* TABLE */}
              <div className='row mb-3 border-top'>
                <div className='col-lg-6 col-11 p-3 border-end'>
                  <dd>{accounting?.category?.type ? 'Increase' : 'Decrease'}</dd>

                  <div className='row'>
                    <div className='col-md-8 col-12'>
                      {/* BANK */}
                      {listBanks && accounting?._id === '63e31a93f029ceab39dcd281' &&
                        <div>
                          <Autocomplete
                            size='small'
                            fullWidth
                            className='mb-2'
                            value={bank?._id}
                            onChange={(event, value) => {
                              setBank(value)
                            }}
                            inputValue={inputBank}
                            onInputChange={(event, newInputValue) => {
                              setInputBank(newInputValue)
                            }}
                            options={listBanks}
                            renderInput={(params) => <TextField {...params} label="Bank"/>}
                            getOptionLabel = {(params) => `${params.bank + ' | '+ params.accountNo}`}
                            noOptionsText = "No Bank Found"
                          />
                        </div>
                      }
                      
                      {/* CHEQUE  */}
                      {listCustomers && accounting?._id === '64fda2c5c969962bfbcee3eb' &&
                        <div>
                          <Autocomplete
                            size='small'
                            fullWidth
                            className='mb-2'
                            value={customer?._id}
                            onChange={(event, value) => {
                              setCustomer(value)
                            }}
                            inputValue={inputCustomer}
                            onInputChange={(event, newInputValue) => {
                              setInputCustomer(newInputValue)
                            }}
                            options={listCustomers}
                            renderInput={(params) => <TextField {...params} label="Customer"/>}
                            getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
                            noOptionsText = "No Customer Found"
                          />

                          <Text
                            label = "Cheque Date"
                            type = "Date"
                            value = {chqDate}
                            onChange={(e) => setChqDate(e.target.value)}
                          />

                          <Text
                            label = "Cheque No"
                            value = {chqNo}
                            onChange={(e) => setChqNo(e.target.value)}
                          />
                        </div>
                      }

                      {/* DEBTS */}
                      {listCustomers && accounting?._id === '63e31abdf029ceab39dcd283' && <Autocomplete
                        size='small'
                        fullWidth
                        className='mb-2'
                        value={customer?._id}
                        onChange={(event, value) => {
                          setCustomer(value)
                        }}
                        inputValue={inputCustomer}
                        onInputChange={(event, newInputValue) => {
                          setInputCustomer(newInputValue)
                        }}
                        options={listCustomers}
                        renderInput={(params) => <TextField {...params} label="Customer"/>}
                        getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
                        noOptionsText = "No Customer Found"
                      />}

                      {/* SUPPLIERS */}
                      {listSuppliers && accounting?._id === '64acdf13722d07654e219f89' && <Autocomplete
                        size='small'
                        fullWidth
                        className='mb-2'
                        value={supplier?._id}
                        onChange={(event, value) => {
                          setSupplier(value)
                        }}
                        inputValue={inputSupplier}
                        onInputChange={(event, newInputValue) => {
                          setInputSupplier(newInputValue)
                        }}
                        options={listSuppliers}
                        renderInput={(params) => <TextField {...params} label="Supplier"/>}
                        getOptionLabel = {(params) => `${params.name + ' | '+ params.company}`}
                        noOptionsText = "No Supplier Found"
                      />}

                      {/* SALARY*/}
                      {listEmployeeSalaryArrears && accounting?._id === '64cb525518dca625f12ce5bf' && 
                        <p>Pay Employee Arrears By Select SALARY ARREARS Accounts</p>
                      }

                      {/* SALARY ARREARS*/}
                      {listEmployeeSalaryArrears && accounting?._id === '6515831c1a89e4fc7010012a' && 
                        <Autocomplete
                          size='small'
                          fullWidth
                          className='mb-2'
                          value={employee?._id?._id}
                          onChange={(event, value) => {
                            setEmployee(value?._id)
                            setEnteredAmount(value?.total)
                            setDescription(`Salary Arrears Paid`)
                          }}
                          inputValue={inputEmployee}
                          onInputChange={(event, newInputValue) => {
                            setInputEmployee(newInputValue)
                          }}
                          options={listEmployeeSalaryArrears}
                          renderInput={(params) => <TextField {...params} label="Employee"/>}
                          getOptionLabel = {(params) => `${params._id.name + ' | '+ settings?.currencyStart + ' ' + params.total}`}
                          noOptionsText = "No Employee with Salary Arrears"
                        />
                      }

                      <Text
                        label = "Description"
                        value = {description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className='col-md-4 col-12'>
                      <p className='text-end fs-5'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(amount ? amount : '0.00')}</p>
                    </div>
                  </div>

                </div>
              </div>
          </div>

          {/* Accounts Form 2 */}
          <div className='row justify-content-center'>
            {/* ACCOUNTS */}
            <div className='col-xl-3 col-lg-4 col-md-6 col-12 mt-5'>
              {listAccountings && !accData?.inputAccounting2 && <Autocomplete
                size='small'
                fullWidth
                className='mb-2'
                value={accounting2?._id}
                onChange={(event, value) => {
                  setAccounting2(value)
                }}
                inputValue={inputAccounting2}
                onInputChange={(event, newInputValue) => {
                  setInputAccounting2(newInputValue)
                }}
                options={listAccountings}
                renderInput={(params) => <TextField {...params} label="Accounts"/>}
                getOptionLabel = {(i) => `${i.name?.toUpperCase() + ' | Type: '+ i.category?.name}`}
                noOptionsText = "No Accounts Found"
              />}
              {listAccountings && accData?.inputAccounting2 && <h6 className='text-center' >{accounting2?.name?.toUpperCase() + ' | ' + accounting2?.category?.name}</h6>}
            </div>

            {/* TABLE */}
            <div className='row mb-3 border-top'>
              <div className='col-lg-6 col-1'></div>

              <div className='col-lg-6 col-11 p-3 border-start'>
                <dd>{!accounting2?.category?.type ? 'Increase' : 'Decrease'}</dd>

                <div className='row'>
                  <div className='col-md-8 col-12'>
                    {/* BANK */}
                    {listBanks && accounting2?._id === '63e31a93f029ceab39dcd281' &&
                      <div>
                        <Autocomplete
                          size='small'
                          fullWidth
                          className='mb-2'
                          value={bank2?._id}
                          onChange={(event, value) => {
                            setBank2(value)
                          }}
                          inputValue={inputBank2}
                          onInputChange={(event, newInputValue) => {
                            setInputBank2(newInputValue)
                          }}
                          options={listBanks}
                          renderInput={(params) => <TextField {...params} label="Bank"/>}
                          getOptionLabel = {(params) => `${params.bank + ' | '+ params.accountNo}`}
                          noOptionsText = "No Bank Found"
                        />

                        <SwitchInput
                          endLabel = "Cheque"
                          checked = {checked2}
                          onChange = {(e) => {
                            setChecked2(preVal => !preVal)
                          }}
                        /> 

                        {checked2 && 
                          <div>
                            <Text
                              label = "Cheque Date"
                              type = "Date"
                              value = {chqDate2}
                              onChange={(e) => setChqDate2(e.target.value)}
                            />

                            <Text
                              label = "Cheque No"
                              value = {chqNo2}
                              onChange={(e) => setChqNo2(e.target.value)}
                            />
                          </div>
                        }
                      </div>
                    }

                    {/* CHEQUE DEPOSIT */}
                    {listCheques && accounting2?._id === '64fda2c5c969962bfbcee3eb' && <Autocomplete
                      size='small'
                      fullWidth
                      className='mb-2'
                      value={cheque2?._id}
                      onChange={(event, value) => {
                        setCheque2(value)
                        setCustomer2(value?.chqCustomer?._id)
                        setEnteredAmount(value?.amount)
                      }}
                      inputValue={inputCustomer2}
                      onInputChange={(event, newInputValue) => {
                        setInputCustomer2(newInputValue)
                      }}
                      options={listCheques}
                      renderInput={(params) => <TextField {...params} label="Cheque Date | Cheque No | Customer Name | Amount"/>}
                      getOptionLabel = {(params) => `${params.chqDate + ' | '+ params.chqNo + ' | '+ params.chqCustomer?.name + ' | '+ new Intl.NumberFormat().format(params.amount)}`}
                      noOptionsText = "No Cheques Found"
                    />}

                    {/* DEBTS */}
                    {listCustomers && accounting2?._id === '63e31abdf029ceab39dcd283' && <Autocomplete
                      size='small'
                      fullWidth
                      className='mb-2'
                      value={customer2?._id}
                      onChange={(event, value) => {
                        setCustomer2(value)
                      }}
                      inputValue={inputCustomer2}
                      onInputChange={(event, newInputValue) => {
                        setInputCustomer2(newInputValue)
                      }}
                      options={listCustomers}
                      renderInput={(params) => <TextField {...params} label="Customer"/>}
                      getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
                      noOptionsText = "No Supplier Found"
                    />}

                    {/* SUPPLIERS */}
                    {listSuppliers && accounting2?._id === '64acdf13722d07654e219f89' && <Autocomplete
                      size='small'
                      fullWidth
                      className='mb-2'
                      value={supplier2?._id}
                      onChange={(event, value) => {
                        setSupplier2(value)
                      }}
                      inputValue={inputSupplier2}
                      onInputChange={(event, newInputValue) => {
                        setInputSupplier2(newInputValue)
                      }}
                      options={listSuppliers}
                      renderInput={(params) => <TextField {...params} label="Supplier"/>}
                      getOptionLabel = {(params) => `${params.name + ' | '+ params.company}`}
                      noOptionsText = "No Customer Found"
                    />}

                    {/* SALARY*/}
                    {listEmployeeSalaryArrears && accounting2?._id === '64cb525518dca625f12ce5bf' && 
                      <p>Pay Employee Arrears By Select SALARY ARREARS Accounts</p>
                    }

                    {/* SALARY ARREARS*/}
                    {listEmployeeSalaryArrears && accounting2?._id === '6515831c1a89e4fc7010012a' && 
                      <Autocomplete
                        size='small'
                        fullWidth
                        className='mb-2'
                        value={employee2?._id?._id}
                        onChange={(event, value) => {
                          setEmployee2(value?._id)
                          setEnteredAmount(value?.total)
                          setDescription2(`Salary Arrears Added`)
                        }}
                        inputValue={inputEmployee2}
                        onInputChange={(event, newInputValue) => {
                          setInputEmployee2(newInputValue)
                        }}
                        options={listEmployeeSalaryArrears}
                        renderInput={(params) => <TextField {...params} label="Employee"/>}
                        getOptionLabel = {(params) => `${params._id.name + ' | '+ settings?.currencyStart + ' ' + params.total}`}
                        noOptionsText = "No Employee with Salary Arrears"
                      />
                    }

                    <Text
                      label = "Description"
                      value = {description2}
                      onChange={(e) => setDescription2(e.target.value)}
                    />
                  </div>

                  <div className='col-md-4 col-12'>
                    <p className='text-end fs-5'>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(amount2 ? amount2 : '0.00')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row justify-content-between border p-3'>
          <div className='col-lg-4 col-md-6 col-12'>
            <Text
              label="Amount" 
              value = {enteredAmount}
              type = "number"
              onChange={(e) => setEnteredAmount(e.target.value)}
              start = {settings?.currencyStart}
            />
          </div>

          <div className='col-lg-4 col-md-6 col-12'>
            <Text
              label="Date"
              type = 'date'
              value = {date}
              onChange={(e) => setDate(e.target.value)}
              readOnly = 'true'
            />
          </div>

          <div className="col-lg-2 col-md-12 col-12 d-grid gap-2">
            <button className='btn btn-primary' style={{maxHeight: '40px'}} type='submit' onClick={handleSubmit} disabled={submitDisable}>Submit</button>
          </div>
        </div>
      </form>
    </div>
  )
}
