import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useSettings } from '../../../context/settings'

import {Text} from '../../../components/Inputs/InputFields'

import Scanner from '../../../components/Scan-Qr/Scanner'

export default function CreateProductCode(props) {
  const productData = props.productData;

  const [settings] = useSettings()

  const [listWarehouses, setListWarehouses] = useState('')

  const [productCode, setProductCode] = useState('')
  const [product] = useState(productData?._id)
  const [cost, setCost] = useState('')
  const [price, setPrice] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  const getBarcodeData = (data) => {
    setProductCode(data?.text)

  }

  useEffect(() => {
    loadWareHouses()
  },[])

  const loadWareHouses = async() => {
    try {
      const {data} = await Axios.get('/warehouses')
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListWarehouses(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/productcode`, {productCode, product, cost, price});
      if(data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        const productCodeId = data._id;
        try {
            const array = [];
            listWarehouses.map((i) => {
            array.push({wareHouse: i._id, productCode: productCodeId})})

            const {data} = await Axios.post(`/warehousestockmany`, array)
            if(data?.error){
              toast.error(data.error)
              setSubmitDisable(false)
            } else {
              toast.success('Product Code Added')
              window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center mb-3'>Add Product Code - {productData?.name}</h5>
      <form onSubmit={handleSubmit}>
        <div className='mb-2'>
          <Scanner result = {getBarcodeData}/>
        </div>
        <Text
          label="Product Code"
          value = {productCode}
          onChange={(e) => setProductCode(e.target.value)}
        />

        <Text
          label="Cost"
          type="Number"
          value = {cost}
          onChange={(e) => setCost(e.target.value)}
          end={settings?.currencyEnd}
        />

        <Text
          label="Price"
          type="Number"
          value = {price}
          onChange={(e) => setPrice(e.target.value)}
          end={settings?.currencyEnd}
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            ADD PRODUCT CODE
          </button>
        </div>
      </form>
    </div>
  )
}
