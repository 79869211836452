import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit} from '@mui/icons-material/'

import CreatePointsCategory from './CreatePointsCategory'
import UpdatePointsCategory from './UpdatePointsCategory'

export default function ListPointsCategories() {

  const [listPointsCategories, setListPointsCategories] = useState('')

  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    loadPointsCategoryies()
  },[])

  const loadPointsCategoryies = async() => {
    try {
      const {data} = await Axios.get(`/pointscategories`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListPointsCategories(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xxl-6 col-xl-8 col-lg-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-md-6 col-sm-6 col-12 d-flex gap-2 mb-2'>
              <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>
        </div>
        {listPointsCategories && 
          <MUITable
            id = {(row) => row?._id}
            columns = {[
                { field: 'type', headerName: 'Type', width: 180},
                { field: 'pointsRate', headerName: 'Points %', width: 100, filterable: false},
                { field: 'maxAmount', headerName: 'Max Amount', width: 180, renderCell: (i) => new Intl.NumberFormat().format(i.row?.maxAmount), filterable: false, sortable: false},
                { field: 'action', headerName: 'Action', width: 100,filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listPointsCategories}
          />
        }
        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreatePointsCategory/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdatePointsCategory data = {selected}/>}
        />

      </div>
    </div>
  )
}
