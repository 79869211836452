import React, {useState, useEffect} from 'react'
import Axios from 'axios';
import toast from 'react-hot-toast';
import CategoryForm from './CategoryForm';
import ModalBasic from '../../../components/Modal/ModalBasic'

export default function Category() {
  
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [UpdateName, setUpdateName] = useState();

  const [submitDisable, setSubmitDisable] = useState(false)
  const [submitDisable2, setSubmitDisable2] = useState(false)

  useEffect(() => {
    LoadCategories();
  }, []);

  const LoadCategories = async() => {
    try {
      const {data} = await Axios.get("/categories");
      setCategory(data);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
    }
  }
  

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/category`, {name});
      if(data?.error){
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`${data?.name} Successfully Created`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Create Category Failed. Try Aain.")
    }
  }

  const handleUpdate = async(e) => {
    e.preventDefault();
    try {
      await setSubmitDisable2(true)
      const {data} = await Axios.put(`/category/${selected._id}`, {name: UpdateName});
      if(data?.error){
        toast.error(data.error)
        setSubmitDisable2(false)
      } else {
        toast.success(`Category ${UpdateName} updated Sucessfully`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }


  return (
    <div className='p-3'>
      <h3 className='row justify-content-center p-2'>Manage Category</h3>
      <CategoryForm
        handleSubmit = {handleSubmit}
        value = {name}
        onChange = {(e) => {setName(e.target.value)}}
        btnStatus = {submitDisable}
      />
      <hr/>

      <div className='col'>
        {category?.map((c) => (
          <button 
            className='btn btn-outline-primary m-3' 
            key={c._id} 
            onClick={()=> {
              setVisible(true)
              setSelected(c);
              setUpdateName(c.name)
              }}>
              {c.name}</button>))}
      </div>

      <ModalBasic
        open = {visible}
        onClose = {()=> {
          setVisible(false)
        }}
        content = {<CategoryForm
          title = "Update Category"
          handleSubmit = {handleUpdate}
          value = {UpdateName}
          onChange = {(e) => {setUpdateName(e.target.value)}}
          btntxt = "UPDATE"
         btnStatus = {submitDisable2}
        />}
      />
    </div>
  )
}
