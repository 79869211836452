import React, {useState, useEffect} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useAuth } from '../../context/auth'
import { useSettings } from '../../context/settings'

import {Check, Clear} from '@mui/icons-material'

import {Text, SelectInput} from '../../components/Inputs/InputFields'
import {MenuItem} from '@mui/material/'

export default function CreateEmployee() {
  const [auth] = useAuth()
  const [settings] = useSettings()

  const [rolesArray, setRolesArray] = useState([{id: 2, role:"Employee"}])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [nic, setNic] = useState("")
  const [role, setRole] = useState('')
  const [designation, setDesignation] = useState('')
  const [password, setPassword] = useState("")
  const [comparePassword, setComparePassword] = useState('')
  const [address, setAddress] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const [type, setType] = useState('')
  const [amount, setAmount] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
    setRolesArray(roles())
  },[auth?.employee])

  const roles = () => {
    switch (auth?.employee?.role) {
      case 0:
        return [{id: 0, role:"Admin"},{id: 1, role:"User"},{id: 2, role:"Employee"}]
        break
      default:
        return [{id: 2, role:"Employee"}]
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      if (password === comparePassword) {
        const {data} = await Axios.post(`/register`, {
          name, email, nic, role, designation, password, address, phoneNumber, payment: {type, amount: Number(amount)?.toFixed(2)}
        })
        if(data?.error) {
          toast.error(data.error);
          setSubmitDisable(false)
        } else {
          toast.success("Employee sucessfully Created");
          window.location.reload()
        }
      } else {
        toast.error("Password Miss Match")
        setSubmitDisable(false)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
      
    }
  }
  
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Employee</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true' 
        />
        <Text
          label="Email / Username"
          value = {email}
          onChange={(e) => setEmail(e.target.value)} 
        />

        <Text
          label="NIC Number"
          value = {nic}
          onChange={(e) => setNic(e.target.value)}
          end = {nic.length > 0 ? (nic.length === 10 || nic.length === 12 ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <SelectInput
          label = "Role"
          value = {role}
          onChange = {(e) => setRole(e.target.value)}
          items = {
            rolesArray && rolesArray?.map((i) => (
              <MenuItem value={i.id}>{i.role}</MenuItem>
            ))
          }
        />

        <Text
          label="Designation"
          value = {designation}
          onChange={(e) => setDesignation(e.target.value)}
        />

        <Text
          label="Password"
          type= "password"
          value = {password}
          onChange={(e) => setPassword(e.target.value)} 
        />

        <Text
          label="Confirm Password"
          type = "password"
          value = {comparePassword}
          onChange={(e) => setComparePassword(e.target.value)}
          end = {comparePassword.trim() > 0 ? (password === comparePassword ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <Text
          label="Address"
          value = {address}
          onChange={(e) => setAddress(e.target.value)} 
        />

        <Text
          label="Phone Number"
          type ="Number"
          value = {phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)} 
          end = {phoneNumber.length > 0 ? (phoneNumber.length === 10 ? <Check color='success' /> : <Clear color='error'/>) : ''}
        />

        <SelectInput
          label = "Payment Type"
          value = {type}
          onChange = {(e) => setType(e.target.value)}
          items = {
            ['hours', 'day', 'month']?.map((i) => (
              <MenuItem value={i}>{i}</MenuItem>
            ))
          }
        />

        <Text
          label="Payment"
          type= "Number"
          value = {amount}
          onChange={(e) => setAmount(e.target.value)}
          end = {settings?.currencyEnd}
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            REGISTER
          </button>
        </div>
        
      </form>
    </div>
  )
}
