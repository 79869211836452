import React, {useState} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { Text, SelectInput } from '../../../components/Inputs/InputFields';
import {MenuItem} from '@mui/material/'

export default function CreateAccount(props) {
    const bankData = props.bank

    const [bank, setBank] = useState(bankData?.bank)
    const [accountName, setAccountName] = useState(bankData?.accountName)
    const [accountType, setaccountType] = useState(bankData?.accountType)
    const [accountNo, setAccountNo] = useState(bankData?.accountNo)
    const [branch, setBranch] = useState(bankData?.branch)

    const [submitDisable, setSubmitDisable] = useState(false)

    const bankList = ['AMANA','COMMERCIAL', 'BOC', 'CARGILLS', 'CITI', 'COMMERCIAL', 'DFCC', 'HNB', 'NDB', 'NATIONS TRUST', 'PAN ASIA', 'PEOPLES', 'SAMPATH', 'SEYLAN', 'UNION']

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.put(`/bank-account/${bankData?._id}`, {bank, accountName, accountType, accountNo, branch})
            if(data?.error) {
                setSubmitDisable(false)
                toast.error(data.error)
            } else {
                toast.success("Bank Details Updated Successfully")
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='p-3'>
            <h5 className='text-center text-capitalize'>Bank Accounts</h5>
            <form onSubmit={handleSubmit}>

                <SelectInput
                    label = "Bank"
                    value = {bank}
                    onChange = {(e) => setBank(e.target.value)}
                    items = {
                        bankList && bankList?.map((i) => (
                        <MenuItem value={i}>{i}</MenuItem>
                        ))
                    }
                />

                <Text
                    label="Account Name" 
                    value = {accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                />

                <SelectInput
                    label = "Account Type"
                    value = {accountType}
                    onChange = {(e) => setaccountType(e.target.value)}
                    items = {
                        ['Savings', 'Current'].map((i) => (
                        <MenuItem value={i}>{i}</MenuItem>
                        ))
                    }
                />

                <Text
                    label="Account No" 
                    value = {accountNo}
                    onChange={(e) => setAccountNo(e.target.value)}
                />

                <Text
                    label="Branch" 
                    value = {branch}
                    onChange={(e) => setBranch(e.target.value)}
                />

                <div className="d-grid gap-2">
                <button className='btn btn-primary mt-3' type='submit' disabled={submitDisable}>UPDATE BANK</button>
                </div>

            </form>
        </div>
  )
}
