import React, {useState} from 'react'

import {Visibility} from '@mui/icons-material/'
import ModalBasic from '../Modal/ModalBasic'

export default function ImageViewer(props) {

    const [visible, setVisible] = useState(false)

    const [viewImage] = useState(props?.data)

        // Create new plugin instance

    return (
        <div>
            <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                onClick={() => 
                    setVisible(true)
                }
            />
            <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {
                    <div>
                        <h4 className='text-center'>View Image</h4>
                        <div  className='w-100'>
                            <img className='w-100' src={viewImage} alt="img"/>
                        </div>
                    </div>
                }
            />
        </div>
    )
}
