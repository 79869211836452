import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../../components/Inputs/InputFields'

export default function CreateWareHouse() {

  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const [description, setDescription] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/warehouse`, {name, location, description});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Warehouse sucessfully Created");
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
      
    }
  }
  
  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Create Warehouse</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Warehouse Name"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />
        <Text
          label="Location"
          value = {location}
          onChange={(e) => setLocation(e.target.value)} 
        />
        <Text
            label="Description"
            value = {description}
            onChange={(e) => setDescription(e.target.value)} 
        />
        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            CREATE WAREHOUSE
          </button>
        </div>
      </form>
    </div>
  )
}
