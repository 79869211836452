import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment';

import { useAuth } from '../../../context/auth'
import { useGdate } from '../../../context/date'
import { useSettings } from '../../../context/settings'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit, DeleteForever} from '@mui/icons-material/'

import UpdateAttendance from './UpdateAttendance';

import AccountsForm from '../../accounts/DoubleEntires/AccountsForm'

export default function EmpAttendanceDetails() {
    const {id} = useParams();

    const [auth] = useAuth();
    const [gdate] = useGdate();
    const [settings] = useSettings();

    const [listAttendance, setListAttendance] = useState('')
    const [salaryArrearsCredit, setSalaryArrearsCredit] = useState('')
    const [salaryArrearsDebit, setSalaryArrearsDebit] = useState('')
    const [salaryArrearsAccounts, setSalaryArrearsAccounts] = useState('')
    //Model
    const [visible, setVisible] = useState(false)
    const [selected, setSelected] = useState(null)

    const [visibleDelete, setVisibleDelete] = useState(false)
    const [selectedDelete, setSelectedDelete] = useState(null)

    const [visibleCredit, setVisibleCredit] = useState(false)
    const [visibleDebit, setVisibleDebit] = useState(false)

     const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadAttendance()
        loadSalary()
    },[])

    const loadAttendance = async() => {
        try {
            const {data} = await Axios.post(`/empattendances`, {employee: id, date: {$gte: gdate?.reportStartDate, $lte: gdate?.reportEndDate}})
            if (data?.error) {
                toast.error(data.error)
            } else {
                setListAttendance(data)
                console.log(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadSalary = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`,{"empStatus.status": false, "empStatus.employee": id, accounting: '6515831c1a89e4fc7010012a'})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setSalaryArrearsCredit(data?.filter((i) => i?.type === 'credit') ? data?.filter((i) => i?.type === 'credit').reduce((a,v) => a = a + v.amount, 0) : '0')
                setSalaryArrearsDebit(data?.filter((i) => i?.type === 'debit') ? data?.filter((i) => i?.type === 'debit')?.reduce((a,v) => a = a + v.amount, 0) : '0')
                setSalaryArrearsAccounts(data.reduce((a,v) => a = a + v.amount, 0) ? data.reduce((a,v) => a = a + v.amount, 0) : '0')
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const handleDeleteAttendance = async(selected) => {
        try {
            await setSubmitDisable(true)
            const {data} = await Axios.delete(`/empattendance/${selected?._id}`)
            if (data?.error) {
                toast.error(data.error)
                setSubmitDisable(false)
            } else {
                const {data} = await Axios.post(`/accdatas/removemany`, {"refId": `epa-${moment(selected?.date).format("YYYYMMDDHH")}-${selected?.employee?._id}-${selected?.payment?.amount}` , "empStatus.status": false, date: selected?.date})
                if (data?.error) {
                    toast.error(data?.error)
                    setSubmitDisable(false)
                } else {
                    toast.success('Attendance Deleted')
                    window.location.reload()
                }
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8 col-md-10 col-12 mb-3'>
                <div className='row justify-content-end mb-2'>
                    <div className='col-md-6 col-sm-8 col-12 d-flex justify-content-end gap-2 mb-2'>
                        <button className = 'btn btn-outline-danger w-50' onClick={() => {setVisibleDebit(true)}}><Add/> Debit</button> 
                        <button className = 'btn btn-outline-success w-50' onClick={() => {setVisibleCredit(true)}}><Add/> Credit</button>  
                    </div>
                    {/* <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                        <h4>{listAttendance[0]?.employee?.name}</h4>
                        <p>{gdate?.reportStartDate} - {gdate?.reportEndDate}</p>
                    </div> */}
                </div>
                {listAttendance && 
                <MUITable
                    title = {'Attendaces details of ' + listAttendance[0]?.employee?.name + ' | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                    id = {(row) => row._id}
                    columns = {[
                        { field: 'date', headerName: 'Date', width: 120},
                        { field: `${listAttendance[0]?.payment?.type}`, headerName: `WORK ${listAttendance[0]?.payment?.type ? listAttendance[0]?.payment?.type?.toUpperCase() : ''}`, width: 150, filterable: false, sortable: false},
                        { field: 'salary', headerName: 'Salary', width: 120, renderCell: (i) => settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.salary), filterable: false, sortable: false },
                        { field: 'action', headerName: 'Action', width: 100,filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                        <div className='d-flex gap-2'>
                            <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                                onClick={() => {
                                setVisibleDelete(true)
                                setSelectedDelete(i.row)
                                }}
                            />
                        </div>
                        },
                    ]}
                    rows = {listAttendance}
                />
                }

                <div className='d-flex justify-content-between pt-3'>
                    <dd>Total Salary</dd>
                    <dd>{new Intl.NumberFormat().format(salaryArrearsCredit ? salaryArrearsCredit : '0') + ' ' + settings?.currencyEnd}</dd>              
                </div>

                <div className='d-flex justify-content-between'>
                    <dd>Total Paid Salary</dd>
                    <dd>{new Intl.NumberFormat().format(salaryArrearsDebit ? salaryArrearsDebit : '0') + ' ' + settings?.currencyEnd}</dd>              
                </div>
                <hr/>
                <div className='d-flex justify-content-between'>
                    <dd className='fw-bold'>Total Salary Arrears</dd>
                    <dd className='fw-bold' style={{color: salaryArrearsAccounts > 0 ? 'red' : 'green'}}>{new Intl.NumberFormat().format(salaryArrearsAccounts ? salaryArrearsAccounts : '0') + ' ' + settings?.currencyEnd}</dd>              
                </div>

                <ModalBasic
                open = {visible}
                onClose = {()=> {
                setVisible(false)
                }}
                content = {<UpdateAttendance data = {selected}/>}
                />

                <ModalBasic
                    open = {visibleDelete}
                    onClose = {()=> {
                    setVisibleDelete(false)
                    }}
                    content = {
                        auth?.employee?.role === 0 ?
                        <div className='d-flex justify-content-between'>
                            <p>Are You sure want to?</p>
                            <button className = 'btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                                handleDeleteAttendance(selectedDelete)
                            }}>Delete
                            </button>
                        </div>
                        :
                        <div className='d-flex justify-content-center w-100'>
                            <p>You don't have Permission to Delete</p>
                        </div>
                    }
                />

                <ModalBasic
                    width = '80%'
                    open = {visibleDebit}
                    onClose = {()=> {
                    setVisibleDebit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting: '6515831c1a89e4fc7010012a'}}/>}
                />

                <ModalBasic
                    width = '80%'
                    open = {visibleCredit}
                    onClose = {()=> {
                    setVisibleCredit(false)
                    }}
                    content = {<AccountsForm data= {{inputAccounting2: '6515831c1a89e4fc7010012a'}}/>}
                />
            </div>
        </div>
    )
}
