import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../../context/settings';
import { useGdate } from '../../../context/date'

import MUITable from '../../../components/table/MUITable'

export default function ListAccounts() {
    const {id} = useParams();
    const [settings] = useSettings()
    const [gdate] = useGdate()

    const [allData, setAllData] = useState('')
    const [listAccData, setListAccData] = useState('')

    useEffect(() => {
        loadAccData()
      },[id])

    useEffect(() => {
        allData && filterDataByDate()
      },[allData, gdate?.reportStartDate, gdate?.reportEndDate])
    
    const loadAccData = async() => {
        try {
            const {data} = await Axios.post(`/accdatas/filter`, {accounting: id})
            if(data?.error){
                toast.error(data.error)
            } else {
                var effects = 0
                await setAllData(data?.map((i) => {
                    effects += i?.amount
                    return {...i, effects: effects}
                }))
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const filterDataByDate = async() => {
        try {
            setListAccData(allData?.filter((i) => i?.date >= gdate?.reportStartDate && i?.date <= gdate?.reportEndDate))
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const allAccounts = [
        { field: 'employee', headerName: 'Employee', width: 170, renderCell: (i) => i.row.empStatus?.employee?.name, valueGetter: (i) => i.row.empStatus?.employee?.name},
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'description', headerName: 'Description', width: 420, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description },
        { field: 'debit', headerName: 'Debit', width: 180, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), valueGetter: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 180, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), valueGetter: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'effects', headerName: 'Effects', width: 180, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false, sortable: false}
    ]

    const bankAccounts = [
        { field: 'employee', headerName: 'Employee', width: 170, renderCell: (i) => i.row.empStatus?.employee?.name, valueGetter: (i) => i.row.empStatus?.employee?.name},
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'bank', headerName: 'Bank', width: 150, renderCell: (i) => i.row.detail?.bank?.bank,  valueGetter: (i) => i.row.detail?.bank?.bank},
        { field: 'chqDate', headerName: 'Cheque Date', width: 100, renderCell: (i) => i.row.detail?.cheque?.chqDate , valueGetter: (i) => i.row.detail?.cheque?.chqDate},
        { field: 'chqNo', headerName: 'Cheque No', width: 150, renderCell: (i) => i.row.detail?.cheque?.chqNo , valueGetter: (i) => i.row.detail?.cheque?.chqNo},
        { field: 'accountNo', headerName: 'Account No', width: 150, renderCell: (i) => i.row.detail?.bank?.accountNo, valueGetter: (i) => i.row.detail?.bank?.accountNo},
        { field: 'description', headerName: 'Description', width: 300, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description},
        { field: 'debit', headerName: 'Debit', width: 140, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), valueGetter: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 140, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), valueGetter: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'effects', headerName: 'Effects', width: 140, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false, sortable: false}
    ]

    const chequeAccounts = [
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'customer', headerName: 'Customer', width: 180, renderCell: (i) => i.row.detail?.customer?.name , valueGetter: (i) => i.row.detail?.customer?.name},
        { field: 'chqDate', headerName: 'Cheque Date', width: 100, renderCell: (i) => i.row.detail?.cheque?.chqDate , valueGetter: (i) => i.row.detail?.cheque?.chqDate},
        { field: 'chqNo', headerName: 'Cheque No', width: 150, renderCell: (i) => i.row.detail?.cheque?.chqNo , valueGetter: (i) => i.row.detail?.cheque?.chqNo},
        { field: 'description', headerName: 'Description', width: 300, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description},
        { field: 'debit', headerName: 'Debit', width: 140, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), valueGetter: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 140, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), valueGetter: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'effects', headerName: 'Effects', width: 140, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false, sortable: false}
    ]

    const billAccounts = [
        { field: 'employee', headerName: 'Employee', width: 170, renderCell: (i) => i.row.empStatus?.employee?.name, valueGetter: (i) => i.row.empStatus?.employee?.name},
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'customer', headerName: 'Customer', width: 180, renderCell: (i) => i.row.detail?.customer?.name, valueGetter: (i) => i.row.detail?.customer?.name},
        { field: 'description', headerName: 'Description', width: 200, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description },
        { field: 'billNo', headerName: 'Bill No', width: 100, renderCell: (i) => i.row.detail?.billNo, valueGetter: (i) => i.row.detail?.billNo},
        { field: 'vehicleNo', headerName: 'Vehicle No', width: 100, renderCell: (i) => i.row.detail?.vehicleNo, valueGetter: (i) => i.row.detail?.vehicleNo},
        { field: 'government Order', headerName: 'Gov Order', width: 120, renderCell: (i) => i.row.detail?.order, valueGetter: (i) => i.row.detail?.order},
        { field: 'debit', headerName: 'Debit', width: 130, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), valueGetter: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 130, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), valueGetter: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'effects', headerName: 'Effects', width: 130, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false, sortable: false}
    ]

    const creditorAccounts = [
        { field: 'date', headerName: 'Date', width: 100},
        { field: 'supplier', headerName: 'Supplier', width: 180, renderCell: (i) => i.row.detail?.supplier?.name, valueGetter: (i) => i.row.detail?.supplier?.name},
        { field: 'company', headerName: 'Company', width: 220, renderCell: (i) => i.row.detail?.supplier?.company, valueGetter: (i) => i.row.detail?.supplier?.comany},
        { field: 'description', headerName: 'Description', width: 400, renderCell: (i) => i.row.detail?.description, valueGetter: (i) => i.row.detail?.description },
        { field: 'debit', headerName: 'Debit', width: 150, renderCell: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), valueGetter: (i) => ( allAccountsDebitEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsDebitEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'credit', headerName: 'Credit', width: 150, renderCell: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), valueGetter: (i) => ( allAccountsCreditEntry(i.row) && (settings?.currencyStart + ' ' + new Intl.NumberFormat().format( allAccountsCreditEntry(i.row) )) ), filterable: false, sortable: false},
        { field: 'effects', headerName: 'Effects', width: 150, renderCell: (i) => (settings?.currencyStart + ' ' + new Intl.NumberFormat().format(i.row.effects)), filterable: false, sortable: false}
    ]
    
    const selectedAccounts = (id) => {
        switch (id) {
            case '63e31abdf029ceab39dcd283':
                return billAccounts
                break
            case '63e31a93f029ceab39dcd281':
                return bankAccounts
                break
            case '64fda2c5c969962bfbcee3eb':
                return chequeAccounts
                break
            case '64acdf13722d07654e219f89':
                return creditorAccounts
                break
            default:
                return allAccounts
        }
    }

    const allAccountsDebitEntry = (row) => {
        switch (true) {
            case ( (row.accounting?.category?.type && row?.amount > 0) || (!row.accounting?.category?.type && row?.amount < 0) ):
                return Math.abs(row.amount);
        }
    }

    const allAccountsCreditEntry = (row) => {
        switch (true) {
            case ( (!row.accounting?.category?.type && row?.amount > 0) || (row.accounting?.category?.type && row?.amount < 0) ):
                return Math.abs(row.amount);
        }
    }

    return (
        <div className='row justify-content-center'>
            <div className='d-flex justify-content-center gap-2 align-items-baseline'>
                {/* <h5>{listAccData[0]?.accounting?.name?.toUpperCase()} Transactions</h5>
                <p>{gdate?.reportStartDate + ' - ' + gdate?.reportEndDate} </p> */}
            </div>
            <div className='col-12 mb-3'>
                {listAccData && 
                    <MUITable
                        title = {listAccData[0]?.accounting?.name?.toUpperCase() + ' Transactions | ' + gdate?.reportStartDate + ' - ' + gdate?.reportEndDate}
                        id = {(row) => row._id}
                        columns = {selectedAccounts(id)}
                        rows = {listAccData}
                        hiddenFields = {id === '63e31a93f029ceab39dcd281' && {chqDate: false, chqNo: false, employee: false}}
                    />
                }
            </div>

            {/* <div className='row'>
                <div className='d-flex justify-content-between'>
                    <h6>Total B/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'credit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <h6>Total C/F</h6>
                    <h6>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.type === 'debit' && v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h6>
                </div>
                <hr></hr>
                <div className='d-flex justify-content-between'>
                    <h5></h5>
                    <h5 style={(listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )) >=0 ? {color: '#39ff14'} : {color: '#FF0000'}}>{(new Intl.NumberFormat().format((listAccData && listAccData?.reduce((a,v) =>  a = a + (v?.amount) , 0 )))) + ' ' + settings?.currencyEnd}</h5>
                </div>
            </div> */}
        </div>
    )
}
