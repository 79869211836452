import React, {useState, useEffect} from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useGdate } from '../../context/date'
import {useSettings} from '../../context/settings'

import {Text, SwitchInput, SelectInput} from '../../components/Inputs/InputFields'
import {TextField, Autocomplete, MenuItem} from '@mui/material';

import {DepData3000, DepData5000} from '../../Data/data'
import FileUpload from '../../components/FIleUpload/FileUpload'

import StockLoadList from './StockLoadList'

export default function StockUpdateForm() {
  const {id} = useParams()

  const [gdate] = useGdate()
  const [settings] = useSettings()

  const [tank, setTank] = useState('')
  const [listBank, setListBank] = useState('')
  const [listSuppliers, setListSuppliers] = useState('')

  const [updateStock, setUpdateStock] = useState('')
  const [checked, setChecked] = useState(false)

  const [purchaseDate, setPurchaseDate] = useState(gdate?.date)
  const [invoiceNo, setInvoiceNo] = useState('')
  const [invoice, setInvoice] = useState()

  const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash', type: 'debit'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank', type: 'debit'}, {_id:'64acdf13722d07654e219f89', name: 'Credit', type: 'credit'}])
  const [pay, setPay] = useState('')

  const [bank, setBank] = useState()
  const [inputSupplier, setInputSupplier] = useState('')
  const [supplier, setSupplier] = useState()
  const [amount, setAmount] = useState('')

  const [depData, setDepData] = useState([])
  const [dep1, setDep1] = useState('')
  const [dep2, setDep2] = useState('')
  const [dif, setDif] = useState('')

  const [inputValue, setInputValue] = useState('')
  const [input2Value, setInput2Value] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)
  const [submitDisable2, setSubmitDisable2] = useState(false)


  useEffect(() => {
    loadTank()
    loadBanks()
    loadSuppliers()
  }, [])

  useEffect(() => {
    switch(tank?.productCode?.product?.capacity){
      case 3000:
        setDepData(DepData3000)
        break;
      case 5000:
        setDepData(DepData5000)
        break;
      default:
        setDepData(DepData3000)
    }
  }, [tank])

  useEffect(() => {
    setDif((Number(dep2?.volume) - Number(dep1?.volume)))
  }, [dep1, dep2])

  useEffect(() => {
    setUpdateStock('')
    setSupplier()
    setInvoiceNo('')
    setInvoice()
    setAmount('')
  },[checked])

  useEffect(() => {
    setBank()
  },[pay])

  const loadTank = async() => {
    try {
      const {data} = await Axios.get(`/warehousestock/${id}`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setTank(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadBanks = async() => {
    try {
      const {data} = await Axios.get(`/bank-accounts`)
      if (data?.error) {
        toast.error(data.error)
      } else {
        setListBank(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadSuppliers = async() => {
    try {
      const {data} = await Axios.get(`/Suppliers`)
      if(data?.error){
        toast.error(data?.error)
      } else {
        setListSuppliers(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const getPDFData = (data) => {
    if(data?.file) {
      setInvoice(data)
    } else {
      setInvoice()
    }
  }

  const handleUpdateStock = async() => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.put(`/warehousestock/${tank._id}`, { $inc: { 'stock': +(Number(updateStock)) } })
      if(data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`Stock ${updateStock}L updated`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const acc2 = (pay) => {
    if (pay?._id === '64acdf13722d07654e219f89') {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), type: `${amount >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail:{description: `${supplier?.name} : ${tank?.productCode?.product?.type} Purchase INV No: ${invoiceNo}`, supplier: supplier?._id}, amount: Number(amount).toFixed(2) , accounting: '64acdf13722d07654e219f89'}
    } else {
      return {date: moment(purchaseDate).format("YYYY-MM-DD"), type: `${amount >= 0 ? "debit" : "credit"}`, empStatus: {status: false}, detail:{description: `${supplier?.name} : ${tank?.productCode?.product?.type} Purchase INV No: ${invoiceNo}`, bank}, amount: `${amount >= 0 ? Number(-amount).toFixed(2) : Number(amount).toFixed(2)  }` , accounting: pay?._id}
    }
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      await setSubmitDisable2(true)
      if (checked) {
        const {data} = await Axios.post(`/createpurchase`, {invoiceNo, supplier: supplier, purchaseDate, items: [{productStock : tank, quantity: Number(updateStock)?.toFixed(3), itemPrice: Number(amount)?.toFixed(2) }], total: Number(amount)?.toFixed(2), payment: pay?._id, invoice, type: 'gas'})
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable2(false)
        } else {
          const stockData = await Axios.post(`/stockload`, {wareHouseStock: tank, purchase: data?.purchases?._id, quantity: Number(updateStock)?.toFixed(3), depth: dep2})
          if (stockData?.data?.error) {
            toast.error(stockData?.data.error)
            setSubmitDisable2(false)
          } else {
            const data1 = await Axios.post(`/accdata`, {date: moment(purchaseDate).format("YYYY-MM-DD"), type : `${amount >= 0 ? "credit" : "debit"}`, empStatus: {status: false}, detail:{description: `${supplier?.name} : ${tank?.productCode?.product?.type} Purchase INV No: ${invoiceNo}`}, amount: Number(amount).toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
            const data2 = await Axios.post(`/accdata`, acc2(pay))
            if(data1?.data?.error || data2?.data?.error){
              toast.error(data1?.data?.error || data2?.data?.error)
              setSubmitDisable2(false)
            } else {
              toast.success("Purchase Recorded")
              window.location.reload()
            }
          }
        }
      } else {
        toast.success(`Stock ${updateStock}L updated`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row p-3 justify-content-center'>
      <h5 className='text-center text-capitalize'>Purchase & Stock Form <br/>{tank?.productCode?.product?.name}</h5>

      <div className='col-lg-4 col-md-6 col-sm-8 col-12'>
        <SwitchInput
          endLabel = "Purchase"
          checked = {checked}
          onChange = {(e) => {
            setChecked(preVal => !preVal)
          }}
        />
      </div>

      {!checked && <form className='row justify-content-center' onSubmit={handleUpdateStock}>
        <div className='col-lg-4 col-md-6 col-sm-8 col-12'>
          <Text
            label="Stock Arrival"
            type = "Number"
            value = {updateStock}
            onChange={(e) => {
              setUpdateStock(e.target.value)
            }}
            end = "L"
          />

          <div className="d-grid gap-2">
            {<button className='btn btn-primary mt-3' disabled={submitDisable}>STOCK UPDATE</button>}
          </div>
        </div>

      </form>}

      {checked && <form className='row justify-content-center'>
        <div className='col-lg-4 col-md-8 col-12'>
          <Text
            label="Stock Arrival"
            type = "Number"
            value = {updateStock}
            onChange={(e) => {
              setUpdateStock(e.target.value)
              setAmount((e.target.value * tank?.productCode?.cost)?.toFixed(2))
            }}
            end = "L"
          />
        
          {depData &&
            <div>
              <Autocomplete
                className = 'mb-2'
                size='small'
                value={dep1?.volume}
                onChange={(event, value) => {
                  setDep1(value)
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={depData}
                renderInput={(params) => <TextField {...params} label="Before Load"/>}
                getOptionLabel = {(params) => `${params.dep} | ${params.volume} L`}
                noOptionsText = "No Depth Found"
              />
              <Autocomplete
                size='small'
                value={dep2?.volume}
                onChange={(event, value) => {
                  setDep2(value)
                }}
                inputValue={input2Value}
                onInputChange={(event, newInputValue) => {
                  setInput2Value(newInputValue);
                }}
                options={depData}
                renderInput={(params) => <TextField {...params} label="After Load"/>}
                getOptionLabel = {(params) => `${params.dep} | ${params.volume} L`}
                noOptionsText = "No Depth Found"
              />

              <div className='d-flex justify-content-between pt-2'>
                Litters as Entered <dd>{new Intl.NumberFormat().format(updateStock)} L</dd>
              </div>
              <div className='d-flex justify-content-between'>
                Litters on Depth <dd>{new Intl.NumberFormat().format(dif)} L</dd>
              </div>
              <hr></hr>
              <div className='d-flex justify-content-between pb-2'>
                Differance {(Math.abs(Number(dif - updateStock)) < 500 ? <dd style={{color: '#39ff14'}}>{new Intl.NumberFormat().format(dif - updateStock)} L</dd> : <dd style={{color: '#FF0000'}}>{new Intl.NumberFormat().format(dif - updateStock)} L</dd>)}
              </div>
            </div>
          }
        </div>

        <div className='col-lg-4 col-md-8 col-12'>
          <Text
            label="Purchase Date"
            type = 'date'
            value = {purchaseDate}
            onChange={(e) => setPurchaseDate(e.target.value)}
            readOnly = 'true'
          />

          {payMethod && <SelectInput
            label = "Payment Method"
            value = {pay}
            onChange = {(e) => {
              setPay(e.target.value)
            }}
            items = {
              payMethod && payMethod?.map((i) => (
                <MenuItem value={i}>{i.name}</MenuItem>
              ))
            }
          />}

          {pay?._id === '63e31a93f029ceab39dcd281' && <SelectInput
            label = "Bank"
            value = {bank}
            onChange = {(e) => {
              setBank(e.target.value)
            }}
            items = {
              listBank && listBank?.map((i) => (
                <MenuItem value={i?._id}>{i?.bank + ' | ' + i?.accountNo}</MenuItem>
              ))
            }
          />}

          <Text
            label="Amount" 
            value = {amount}
            type = "number"
            onChange={(e) => setAmount(e.target.value)}
            start = {settings?.currencyStart}
          />
        </div>

        <div className="col-lg-4 col-md-8 col-12">
          {listSuppliers &&
            <div>
              <Autocomplete
                size='small'
                fullWidth
                className='mb-2'
                value={supplier?._id}
                onChange={(event, value) => {
                  setSupplier(value)
                }}
                inputValue={inputSupplier}
                onInputChange={(event, newInputValue) => {
                  setInputSupplier(newInputValue)
                }}
                options={listSuppliers}
                renderInput={(params) => <TextField {...params} label="Supplier"/>}
                getOptionLabel = {(params) => `${params.name + ' | '+ params.phoneNumber}`}
                noOptionsText = "No Supplier Found"
              />
              <button type="button" className="btn btn-link p-0 mb-3" onClick={() => window.open(`/dashboard/user/admin/supplier/List`,'_blank')}>Add Supplier</button>
            </div>
          }

          <Text
            label="Invoice No"
            value = {invoiceNo}
            onChange={(e) => setInvoiceNo(e.target.value)} 
          />

          <FileUpload submit = {getPDFData} fileType= "PDF"/>

          <button className='btn btn-primary mt-3 w-100' onClick={handleSubmit} disabled={pay && invoiceNo && supplier ? submitDisable2 : true}>PURCHASE</button>
        </div>
      </form>}

      <StockLoadList data={tank}/>
    </div>
  )
}
