import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import {toast} from 'react-hot-toast'
import moment from 'moment'

import { useAuth } from '../../../context/auth';

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit, DeleteForever} from '@mui/icons-material/'

export default function ListDep() {
  const {id} = useParams()

  const [auth] = useAuth();

  const [ListDep, setListDep] = useState('')
  //Model

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadDepth()
  }, [])
    

  const loadDepth = async() => {
      try {
          const {data} = await Axios.post('/product-depths', {product: id})
          if(data?.error){
              toast.error(data.error)
          } else {
              setListDep(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const handleDeleteDep = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/product-depth/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('Depth Data Deleted')
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xl-8 col-lg-10 col-12 mb-3'>
        {ListDep && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
                { field: 'date', headerName: 'Date', width: 120},
                { field: 'dep', headerName: 'Depth', width: 100},
                { field: 'volume', headerName: 'Volume', width: 120, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.volume) + ' L'}`, filterable: false, sortable: false},
                { field: 'stock', headerName: 'Stock', width: 120, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.stock) + ' L'}`, filterable: false, sortable: false},
                { field: 'differance', headerName: 'Differance', width: 110, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.volume - i.row.stock) + ' L'}`, filterable: false, sortable: false},
                { field: 'markedOn', headerName: 'Marked On', width: 180, renderCell: (i) => moment(i.row.createdAt).format("YYYY-MM-DD hh:mm: A"), filterable: false, sortable: false},
                { field: 'action', headerName: 'Action', width: 75, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible1(true)
                      setSelected1(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {ListDep}
          />
        }

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between gap-2'>
                <p>Are You sure want to? On deletation selected Customer data will be erased</p>
                <div>
                  <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                      handleDeleteDep(selected1._id)
                    }}>Delete
                  </button>
                </div>
              </div>
              :
              <div>
                <p className='text-center'>You don't have Permission to Delete</p>
                <p className='text-center fs-4'>Login as Admin</p>
              </div>
          }
        />
      </div>
    </div>
    )
}
