import React, {useState, Fragment} from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import {Image} from 'antd';
import CpetcoLogoImg from '../../Assets/images/ceypetco_logo.png'

import {Menu as MenuIcon} from '@mui/icons-material/';

import { useMenuType } from '../../context/menu';

import { SelectInput} from '../Inputs/InputFields'
import {MenuItem} from '@mui/material';

import SidebarMenu from './Menu/Menu'
import MenuGas from './Menu/MenuGas'
import MenuPos from './Menu/MenuPOS'
import QuickLinks from './Menu/MenuQuickLinks'


export default function Sidebar2() {
  const [state, setState] = useState({
    left: false,
  });
  const [menuType, setMenuType] = useMenuType();
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const switchMenu = (menu) => {switch (menu?.value) {
    case "General":
      return <SidebarMenu/>;
    case "Gas":
      return <MenuGas/>;
    case "POS":
      return <MenuPos/>;
    case "Quick-Links":
      return <QuickLinks/>;
    default:
      return <SidebarMenu/>
  }}

  const list = (anchor) => (
    <Box sx={{bgcolor: 'rgb(0, 21, 41)', height: '100%', width: 250}}>
      <div className='sticky-top'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 50,
            margin: 8,
          }}
        >
          <Image
            height="100%"
            src={CpetcoLogoImg}
            alt='Logo'
          />
        </div>

        <SelectInput
          label = "Menu"
          value = {menuType?.value}
          onChange = {(e) => {
            setMenuType({...menuType, value : e.target.value})
            localStorage.setItem( "menuType", JSON.stringify(e.target.value));
          }}
          items = {
            ["General", "Gas", "POS", "Quick-Links"].map((i) => (
            <MenuItem value={i}>{i}</MenuItem>
            ))
          }
        />

        {switchMenu(menuType)}
      </div>
    </Box>

  );

  return (
    <div className='d-flex align-items-center'>
      {['left'].map((anchor) => (
        <Fragment key={anchor} >
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon style={{color: '#FFFFFF'}}/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}