import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../context/auth'
import {useSettings} from '../../context/settings'

import MUITable from '../../components/table/MUITable'
import ModalBasic from '../../components/Modal/ModalBasic'

import {Add, Edit, DeleteForever} from '@mui/icons-material/'

import CreateTank from './CreateTank'
import UpdateTank from './UpdateTank'

export default function ListTank() {
  const [auth] = useAuth()
  const [settings] = useSettings() 

  const [listTank, setListTank] = useState('')
  //Model
  const [visibleAdd, setVisibleAdd] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visible1, setVisible1] = useState(false)
  const [selected1, setSelected1] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadTank()
  }, [])
  

  const loadTank = async() => {
      try {
          const {data} = await Axios.post('/productcodes')
          if(data?.error){
              toast.error(data.error)
          } else {
            setListTank(data?.filter((i) => i?.product?.category?._id === "63e65efc2f840202604fbb7f"))
          }
      } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
      }
  }

  const handleDeleteTank = async(productCodeData) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/warehousestock/delete`, {productCode: productCodeData?._id})
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        const {data} = await Axios.delete(`/productcodes/${productCodeData?.product?._id}`)
        if (data?.error) {
          toast.error(data.error)
          setSubmitDisable(false)
        } else {
          const {data} = await Axios.delete(`/product/${productCodeData?.product?._id}`)
          if (data?.error) {
            toast.error(data.error)
            setSubmitDisable(false)
          } else {
            toast.success('Tank Successfully Deleted')
            window.location.reload()
          }
        }
      }

    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary w-50' onClick={() => {setVisibleAdd(true)}}><Add/> Add New</button>
          </div>
        </div>
        {listTank && 
          <MUITable
            title = "List of Tank Details"
            id = {(row) => row._id}
            columns = {[
                { field: 'name', headerName: 'Tank Name', width: 225, renderCell: (i) => `${i.row.product?.name}`, valueGetter: (i) => `${i.row.product?.name}`},
                { field: 'productCode', headerName: 'Product Code', width: 170},
                { field: 'type', headerName: 'Type', width: 170, renderCell: (i) => (i.row?.product?.type?.fuelType), valueGetter: (i) => (i.row.product?.type?.fuelType)},
                { field: 'capacity', headerName: 'Capacity (G)', width: 120, renderCell: (i) => `${new Intl.NumberFormat().format(i.row.product?.capacity)} G`, valueGetter: (i) => (i.row.product?.capacity)},
                { field: 'capacityL', headerName: 'Capacity (L)', width: 140, renderCell: (i) => `${new Intl.NumberFormat().format((i.row.product?.capacity * 4.855))} L`, valueGetter: (i) => (i.row.product?.capacity)},
                { field: 'cost', headerName: 'Cost', width: 120, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.cost)}`, filterable: false, sortable: false},
                { field: 'price', headerName: 'Price', width: 120, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format(i.row.price)}`, filterable: false, sortable: false},
                { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                   <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible1(true)
                      setSelected1(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listTank}
        />
        }

        <ModalBasic
          open = {visibleAdd}
          onClose = {()=> {
          setVisibleAdd(false)
          }}
          content = {<CreateTank/>}
        />

        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateTank tank= {selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className='btn btn-outline-danger'  disabled={submitDisable} onClick={() => {
                    handleDeleteTank(selected1)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
              
          }
        />
      </div>
    </div>
  )
}
