import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import LoadingGIF from "../Assets/images/loading.gif"

export default function Loading({path="login"}) {

    //state
    const [count, setCount] = useState(3);
    //hooks
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const interval = setInterval(() => {
        setCount((currentCount) => --currentCount);
      },3000);

      //cleanup
      return () => clearInterval(interval);
    }, [count]);

    
    count === 0 && navigate(`/${path}`, {
      state: location.pathname,
    });
    
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <img
        style={{width:500}}
        src={LoadingGIF}
        alt= 'Loading gif'
      />
      </div>
  )
}
