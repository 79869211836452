import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment'
import {toast} from 'react-hot-toast'

import { useSettings } from '../../../../context/settings'

import MUITable from '../../../../components/table/MUITable'
import ModalBasic from '../../../../components/Modal/ModalBasic'

import { Visibility} from '@mui/icons-material/'

import ReturnsReceipt from '../Receipts/ReturnsReceipt'
import ReturnsReceiptV2 from '../Receipts/ReturnsReceiptV2'

export default function ListOrderReturns() {

  const [settings] = useSettings()

  const [listOrders, setListOrders] = useState('')

  const [items, setItems] = useState('')

  //Model
  const [visibleDetails, setVisibleDetails] = useState(false)
  const [selectedDetails, setSelectedDetails] = useState('')

  useEffect(() => {
      loadOrders()
  }, [])
  
  const loadOrders = async() => {
      try {
          const {data} = await Axios.post('/order-returns')
          if(data?.error){
              toast.error(data.error)
          } else {
              setListOrders(data)
          }
      } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
      }
  }

  const loadItems = async(id) => {
    try {
      const {data} = await Axios.post(`/return-productlines`, {orderReturn: id})
      if (data?.error) {
        toast.error(data.error)
      } else {
        await setItems(data)
        setVisibleDetails(true)
      }
    } catch (err) {
      toast.error("Something went wrong, Try Again!")
      console.log(err)
    }
  }
  
  return (
    <div className='row justify-content-center'>
      <div className='col-12 mb-3'>
        {listOrders && 
          <MUITable
            id = {(row) => row._id}
            columns = {[
                { field: 'invoiceNo', headerName: 'Invoice No', width: 150, renderCell: (i) => i.row.order?.invoiceNo, valueGetter: (i) => i.row.order?.invoiceNo},
                { field: 'returnDate', headerName: 'Return Date', width: 180},
                { field: 'payment', headerName: 'Return Type', width: 150, renderCell: (i) => i?.row?.payment?.name, valueGetter: (i) => i?.row?.payment?.name},
                { field: 'total', headerName: 'Total', width: 180, renderCell: (i) => (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.row?.total)), valueGetter: (i) => i?.row?.total},
                { field: 'updatedAt', headerName: 'Last Updated', width: 200, renderCell: (i) => moment(i?.row?.updatedAt).format('YYYY-MM-DD hh:mm:ss A'), valueGetter: (i) => moment(i?.row?.updatedAt).format('YYYY-MM-DD hh:mm:ss A')},
                { field: 'action', headerName: 'Action', width: 150,filterable: false, sortable: false, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <Visibility fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      loadItems(i.row?._id)
                      setSelectedDetails(i.row)
                    }}
                  />
                  {/* <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                  <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible1(true)
                      setSelected1(i.row)
                    }}
                  /> */}
                </div>
                },
            ]}
            rows = {listOrders}
          />
        }
        <ModalBasic
          width = {settings.billReceipt === 'invoice' ? '1000px' : ''}
          open = {visibleDetails}
          onClose = {()=> {
          setVisibleDetails(false)
          }}
          content = {settings.billReceipt === 'invoice' ?
          <ReturnsReceiptV2 order = {{selectInvoiceVal: selectedDetails?.order, items: items, returnDate: selectedDetails?.returnDate, returnValue: selectedDetails?.total, printedDate: selectedDetails?.createdAt, payment: selectedDetails?.payment?._id}}/>
            :
            <ReturnsReceipt order = {{selectInvoiceVal: selectedDetails?.order, items: items, returnDate: selectedDetails?.returnDate, returnValue: selectedDetails?.total, printedDate: selectedDetails?.createdAt, payment: selectedDetails?.payment?._id}}/>
          }
        />

        {/* <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {<UpdateBooth booth = {selected}/>}
        />

        <ModalBasic
          open = {visible1}
          onClose = {()=> {
          setVisible1(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className = 'btn btn-outline-danger' onClick={() => {
                    deleteBooth(selected1._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        /> */}
      </div>
    </div>
  )
}
