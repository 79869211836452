import React, {useState, useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { useAuth } from '../../../context/auth'

import {Menu} from 'antd'

import {Home, Dashboard, Propane, LocalGasStation, Person, ShoppingCartCheckout, AccountBalanceWallet, Check, Receipt} from '@mui/icons-material/';

export default function SideBarQuickLinks() {

    const navigate = useNavigate()
    const [auth] = useAuth();

    const [current, setCurrent] = useState('');

    useEffect(() => {
        document.addEventListener('keydown', detectKeyDown, true)
    },[])

    const detectKeyDown = (e) => {
        const {ctrlKey, key} = e
        if(ctrlKey === true && key?.toLowerCase() === 'r') {
            e.preventDefault()
            navigate(`/dashboard/pump/card`)
        }
        if(ctrlKey === true && key?.toLowerCase() === 'e') {
            e.preventDefault()
            navigate(`/dashboard/user/tank/card`)
        }
        if(ctrlKey === true && key?.toLowerCase() === 'f') {
            e.preventDefault()
            navigate(`/dashboard/user/employee/card`)
        } 
        if(ctrlKey === true && key?.toLowerCase() === 's') {
            e.preventDefault()
            navigate(`/dashboard/inventory/sale`)
        } 
        if(ctrlKey === true && key?.toLowerCase() === 'd') {
            e.preventDefault()
            navigate(`/dashboard/user/accounts/entry`)
        } 
        if(ctrlKey === true && key?.toLowerCase() === 'a') {
            e.preventDefault()
            navigate(`/dashboard/user/employee/attendance`)
        } 
    }

    const empItems = [
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard">Home</NavLink>),
            key: 'ql-home',
            icon: <Home/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/pump/card">Mark Pump</NavLink>),
            key: 'ql-pump',
            icon: <LocalGasStation/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/inventory/sale">Add Sales</NavLink>),
            key: 'ql-sales',
            icon: <ShoppingCartCheckout/>
        }
    ];

    const userItems = [
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user">Dashboard</NavLink>),
            key: 'ql-dashboard',
            icon: <Dashboard/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/tank/card">Tank Stock</NavLink>),
            key: 'ql-tanks',
            icon: <Propane/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/pump/card">Mark Pump</NavLink>),
            key: 'ql-pump',
            icon: <LocalGasStation/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/employee/card">Fuel Accounts</NavLink>),
            key: 'ql-fuel-accounts',
            icon: <Person/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/inventory/sale">Add Sales</NavLink>),
            key: 'ql-sales',
            icon: <ShoppingCartCheckout/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/entry">Double Entry</NavLink>),
            key: 'ql-double-entries',
            icon: <AccountBalanceWallet/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/employee/attendance">Atteandance</NavLink>),
            key: 'ql-employee-attendance',
            icon: <Check/>
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/daily-cashflow">Daily Cash Flow</NavLink>),
            key: 'ql-daily-cashflow',
            icon: <Receipt/>
        },
    ];

    const adminItems = [
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user">Dashboard</NavLink>),
            key: 'ql-dashboard',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/pump/card">Mark Pump  <img width="16" height="16" src="https://img.icons8.com/material/24/ffffff/control.png" alt="control"/> + R</NavLink>),
            key: 'ql-pump',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/tank/card">Tank Stock  <img width="16" height="16" src="https://img.icons8.com/material/24/ffffff/control.png" alt="control"/> + E</NavLink>),
            key: 'ql-tanks',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/employee/card">Fuel Accounts  <img width="16" height="16" src="https://img.icons8.com/material/24/ffffff/control.png" alt="control"/> + F</NavLink>),
            key: 'ql-fuel-accounts',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/inventory/sale">Add Sales  <img width="16" height="16" src="https://img.icons8.com/material/24/ffffff/control.png" alt="control"/> + S</NavLink>),
            key: 'ql-sales',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/entry">Double Entry  <img width="16" height="16" src="https://img.icons8.com/material/24/ffffff/control.png" alt="control"/> + D</NavLink>),
            key: 'ql-double-entries',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/employee/attendance">Atteandance <img width="16" height="16" src="https://img.icons8.com/material/24/ffffff/control.png" alt="control"/> + A</NavLink>),
            key: 'ql-employee-attendance',
        },
        {
            label: (<NavLink className='text-decoration-none' aria-current="page" to="/dashboard/user/accounts/daily-cashflow">Daily Cash Flow</NavLink>),
            key: 'ql-daily-cashflow',
        },
    ];

    const sb = () => {
        switch (true) {
        case (auth?.employee?.role === 0):
            return adminItems
        case (auth?.employee?.role === 1):
            return userItems
            case (auth?.employee?.role === 2):
            return empItems
        }
    }

    return (
        <Menu theme={'dark'}  selectedKeys={current} onClick={(e) => setCurrent(e.key)} mode="inline" items={sb()} />
    )
}
