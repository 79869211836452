import React, {useState, useEffect, useRef} from 'react'
import Axios from 'axios'
import { toast } from 'react-hot-toast'
import moment from 'moment'

import {useSettings} from '../../../context/settings'
import { useGdate } from '../../../context/date'

import { SelectInput, Text } from '../../../components/Inputs/InputFields'
import {TextField, Autocomplete, Button, MenuItem, Paper} from '@mui/material';
import {Clear} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode } from '@fortawesome/free-solid-svg-icons'

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import Scanner from '../../../components/Scan-Qr/Scanner'

export default function PurchaseReturnForm() {
    const [settings] = useSettings()
    const [gdate] = useGdate()

    const [listBanks, setListBanks] = useState('')
    const [listPurchase, setlistPurchase] = useState('')

    const [state, setState] = useState(true)

    const [selectInvoiceVal, setSelectInvoiceVal] = useState('')
    const [invoiceInputValue, setInvoiceInputValue] = useState('')
    
    const [listProducts, setListProducts] = useState('')

    const [selectProductVal, setSelectProductVal] = useState('')
    const [productInputValue, setProductInputValue] = useState('')

    const [tank, setTank] = useState()
    const [quantity, setQuantity] = useState()
    const [returnPrice, setReturnPrice] = useState('')

    const [items, setItems] = useState([])
    const [returnDate] = useState(gdate?.date)
    const [paymentStatus, setPaymentStatus] = useState('')
    const [payment, setPayment] = useState()
    const [bank, setBank] = useState()
    const [returnValue, setReturnValue] = useState('')

    const [visibleScanner, setVisibleScanner] = useState(false)

    const [payMethod] = useState([{_id:'64952f578188d2c8b9c26603', name: 'Cash', type: 'credit'}, {_id:'63e31a93f029ceab39dcd281', name: 'Bank', type: 'credit'}, {_id:'64acdf13722d07654e219f89', name: 'Credit', type: 'debit'}])
    const productFocusRef = useRef()

    const [submitDisable, setSubmitDisable] = useState(false)

    useEffect(() => {
        loadBanks()
        loadPurchase()
    },[])

    useEffect(() => {
        setItems('')
        selectInvoiceVal && loadProductLines()
        selectInvoiceVal?.payment?._id === "63e31abdf029ceab39dcd283" ? setPayment(selectInvoiceVal?.payment?._id) : setPayment("64952f578188d2c8b9c26603")
    },[selectInvoiceVal])

    useEffect(() => {
        items && setReturnValue(items?.reduce((t,i) => t = t + Number(i?.returnPrice), 0)?.toFixed(2))
    },[items])

    useEffect(() => {
        setPayment()
    }, [paymentStatus])

    useEffect(() => {
        setBank()
    }, [payment])

    const loadBanks = async() => {
        try {
            const {data} = await Axios.get(`/bank-accounts`)
            if(data?.error){
                toast.error(data?.error)
            } else {
                setListBanks(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong, Try Again!")
        }
    }

    const loadPurchase = async() => {
        try {
            const {data} = await Axios.post(`/purchases`)
            if (data?.error){
                toast.error(data.error)
            } else {
                setlistPurchase(data)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong!, Try Again")
        }
    }

    const loadProductLines = async() => {
        try {
            const {data} = await Axios.post(`/purchaseproductlines`, {purchase: selectInvoiceVal._id})
            if(data?.error) {
                toast.error(data.error)
            } else {
                setListProducts(data)
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong! Try Again")
        }
    }

    const findTank = async() => {
        try {
          const {data} = await Axios.post('/warehousestocks/filtercategory', {_id: "63e65efc2f840202604fbb7f"})
          if (data?.error){
            toast.error(data?.error)
          } else {
            setTank(data?.find((i) => i?.productCode?._id === selectProductVal?.productCode?._id  && i?.wareHouse?._id === selectProductVal?.wareHouse?._id))
          }
        } catch (err) {
          console.log(err)
          toast.error("Something went wrong, Try Again!")
        }
    }

    const getBarcodeData = (data) => {
        setInvoiceInputValue(data?.result?.decodedText)
        if (selectInvoiceVal.invoiceNo !== data?.result?.decodedText) {
            listPurchase && setSelectInvoiceVal((listPurchase.find((i) => data?.result?.decodedText === i.invoiceNo)) ? (listPurchase.find((i) => data?.result?.decodedText === i.invoiceNo)) : '')
        }
        setVisibleScanner(false)
        data?.result?.decodedText && setState(false)
    }

    const productAdd = (e) => {
        e.preventDefault()
        try {
            if(selectInvoiceVal?.type === 'gas') {
                findTank()
            }
            if(quantity <= selectProductVal?.quantity) {
                setItems([...items, {productLine: selectProductVal, quantity, returnPrice}])
                setQuantity('')
                setReturnPrice('')
                setProductInputValue('')
                setSelectProductVal('')
                productFocusRef?.current?.firstChild?.focus();
            }
            else {
                toast.error(`${selectProductVal?.quantity + ' ' + selectProductVal?.productCode?.product?.unit} Ordered`)
                setQuantity(1)
            }
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong!, Try Again")
        }
    }

    const tankStockHandle = async() => {
        if (selectInvoiceVal?.type === 'gas') {
            const stockData = await Axios.post(`/stockload`, {wareHouseStock: tank, purchase: selectInvoiceVal?._id, quantity: Number(-items[0]?.quantity)?.toFixed(3)})
            if (stockData?.data?.error) {
                toast.error(stockData?.data.error)
                setSubmitDisable(false)
            } else {
                toast.success("Purchase Returns Updated")
                window.location.reload()
            }
        } else {
            toast.success("Purchase Returns Updated")
            window.location.reload()
        }
    }

    const PurchaseSubmit = async(e) => {
        e.preventDefault()
        try {
            await setSubmitDisable(true)
            if(paymentStatus === "received") {
                const {data} = await Axios.post(`/purchasereturn`, {purchase: selectInvoiceVal?._id, returnDate, items, total: Number(returnValue), payment: payment._id, paymentStatus})
                if (data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    const data1 = await Axios.post(`/accdata`, {date: moment(returnDate).format("YYYY-MM-DD"), type : "debit", empStatus: {status: false}, detail:{description: `Return Purchase No: ${selectInvoiceVal?.invoiceNo}`}, amount: Number(-returnValue)?.toFixed(2) , accounting: "63ecfbe55b36ecf83d9dd4a3"})
                    const data2 = await Axios.post(`/accdata`, {date: moment(returnDate).format("YYYY-MM-DD"), type : returnValue >= 0 ? payment?.type : (payment?.type === 'credit' ? 'debit' : 'credit'), empStatus: {status: false}, detail:{description: `Return Purchase No: ${selectInvoiceVal?.invoiceNo}`, supplier: selectInvoiceVal?.supplier?._id, bank}, amount: Number(returnValue >= 0 ? (payment?.type === 'credit' ? returnValue : -returnValue) : (payment?.type === 'credit' ? -returnValue : returnValue))?.toFixed(2) , accounting: payment})
                    if(data1?.data?.error || data2?.data?.error){
                        toast.error(data1?.data?.error || data2?.data?.error)
                        setSubmitDisable(false)
                    } else {
                        tankStockHandle()
                    }
                }
            } else {
                const {data} = await Axios.post(`/purchasereturn`, {purchase: selectInvoiceVal?._id, returnDate, items, total: Number(returnValue), paymentStatus})
                if (data?.error) {
                    toast.error(data.error)
                    setSubmitDisable(false)
                } else {
                    tankStockHandle()
                }
            }
        } catch (err) {
        console.log(err)
        toast.error("Something went wrong!, Try Again")
        }
    }

    return (
        <div className='row'>
            <div className='col-xxl-8 col-12 p-2'>
                {/* FIND ORDER */}
                <div className='row mb-2'>
                    <div className='col-md-10 col-9'>
                        {state &&
                        <Autocomplete
                        size='small'
                        value={selectInvoiceVal?._id}
                        onChange={(event, value) => {
                            setSelectInvoiceVal(value)
                        }}
                        inputValue={invoiceInputValue}
                        onInputChange={(event, newInputValue) => {
                            setInvoiceInputValue(newInputValue)
                        }}
                        options={listPurchase}
                        renderInput={(params) => <TextField {...params} label="Invoice No" autoFocus/>}
                        getOptionLabel = {(params) => `${params?.invoiceNo} | ${params?.purchaseDate}`}
                        noOptionsText = "No Order Found"
                        />}
                    </div>

                    <div className='col-md-2 col-3 d-grid'>          
                        <Button style={{maxHeight: '40px'}} variant="outlined" color='inherit' onClick={() => {setVisibleScanner(true)}}>
                        <FontAwesomeIcon icon={faBarcode}/>
                        </Button>
                    </div>
                </div>

                {/* RETURN PRODUCTS FORM */}
                {selectInvoiceVal && 
                <form className='row' onSubmit={productAdd}>
                    <div className='col-md-5 col-12 mb-2'>
                        <Autocomplete
                            style={{minWidth: '50%'}}
                            size='small'
                            value={selectProductVal?.productCodeData}
                            onChange={(event, value) => {
                                setSelectProductVal(value)
                            }}
                            inputValue={productInputValue}
                            onInputChange={(event, newInputValue) => {
                                setProductInputValue(newInputValue)
                            }}
                            options={listProducts}
                            renderInput={(params) => <TextField {...params} label="Product" autoFocus ref={productFocusRef}/>}
                            getOptionLabel = {(params) => `${params?.productCode?.productCode + ' | '+ params.productCode?.product?.name + ' | '+ params?.quantity + ' ' + params.productCode?.product?.unit}`}
                            noOptionsText = "No Product Found"
                        />                
                    </div>

                    <div className='col-md-2 col-sm-4 col-12'>
                        <Text
                            label="Quantity"
                            type="Number"
                            value = {quantity}
                            onChange={(e) => {
                                setQuantity(Number(e.target.value))
                                setReturnPrice(Number(e.target.value * (selectProductVal?.itemPrice / selectProductVal.quantity)?.toFixed(2)))
                            }}
                        />
                    </div>

                    <div className='col-md-3 col-sm-8 col-12'>
                        <Text
                            label="Value"
                            type="Number"
                            value = {returnPrice}
                            onChange={(e) => setReturnPrice(e.target.value)}
                            end = {settings?.currencyEnd}
                        />
                    </div>

                    <div className='col-md-2 col-12 d-grid'>
                        {selectProductVal && quantity ? <button className='btn btn-primary' style={{maxHeight: '40px'}} variant="contained" type='submit'>RETURN</button> : <button className='btn btn-dark' style={{maxHeight: '40px'}} variant="contained" type='submit' disabled>RETURN</button>}          
                    </div>
                </form>}

                {/* RETUNED PRODUCTS TABLE */}
                <div className='col-12 mt-2'>
                    <MUITable
                        id = {(row) => row.productLine?._id}
                        pageSize = {5}
                        columns = {[
                            { field: 'warehouse', headerName: 'Warehouse', width: 150, renderCell: (i) => i.row.productLine?.wareHouse?.name, filterable: false, sortable: false},
                            { field: 'item', headerName: 'Item', width: 180, renderCell: (i) => i.row.productLine?.productCode?.product?.name, filterable: false, sortable: false},
                            { field: 'quantity', headerName: 'Qty', width: 90, filterable: false, sortable: false},
                            { field: 'purchasePrice', headerName: 'Purchase Price', width: 150, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format( ((i.row.productLine?.itemPrice / i.row.productLine?.quantity) * i.row.quantity)?.toFixed(2) )}`, filterable: false, sortable: false},
                            { field: 'returnPrice', headerName: 'Return Price', width: 150, renderCell: (i) => `${settings?.currencyStart} ${new Intl.NumberFormat().format((i.row.returnPrice))}`, filterable: false, sortable: false},
                            { field: 'action', headerName: 'Action', width: 100, filterable: false, sortable: false, renderCell: (i) => 
                            <div className='d-flex gap-2'>
                            <Clear fontSize='large' className='p-2 shadow rounded-circle'
                                onClick={() => {
                                setItems(items?.filter((c) => c?.productLine?._id !== i.row?.productLine?._id))
                                toast.success("Product Removed")
                                productFocusRef?.current?.firstChild?.focus();
                                }}
                            />
                            </div>
                            },
                        ]}
                        rows = {items}
                    />
                </div>
            </div>

            {/* ORDER SUMMARY */}
            <div className='col-xxl-4 col-12 p-2 d-flex justify-content-center'>
                <Paper elevation={5} className='p-5' style={{minWidth: 400}}>
                    <h4 className='text-center pb-3'>Purchase Returns Summary</h4>
                    <div className='d-flex justify-content-between'><p>Invoice No : </p> <p>{selectInvoiceVal && selectInvoiceVal?.invoiceNo}</p></div>
                    <div className='d-flex justify-content-between'><p>Purchase Date : </p> <p>{selectInvoiceVal && selectInvoiceVal?.purchaseDate}</p></div>
                    <div className='d-flex justify-content-between'><p>Return Date : </p> <p>{returnDate}</p></div>
                    <div className='d-flex justify-content-between'><p>No of Item Returns : </p> <p>{items.length}</p></div>
                    <div className='d-flex justify-content-between'><p>Billed Total : </p> <p>{selectInvoiceVal ? `${new Intl.NumberFormat().format(selectInvoiceVal?.total)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</p></div>
                    <hr style={{borderStyle: 'double'}}></hr>
                    <div className='d-flex justify-content-between mb-2'><h5>Returns Value : </h5> <h5>{items.length > 0 ? `${new Intl.NumberFormat().format(returnValue)} ${settings?.currencyEnd}` : `0.00 ${settings?.currencyEnd}`}</h5></div>
                    <SelectInput
                        label = "Payment Status"
                        value = {paymentStatus}
                        onChange = {(e) => {
                            setPaymentStatus(e.target.value)
                        }}
                        items = {
                            ["received","panding","unknown"].map((i) => (
                            <MenuItem value={i}>{i}</MenuItem>
                            ))
                        }
                    />

                    {paymentStatus === "received" && payMethod && <SelectInput
                        label = "Payment Method"
                        value = {payment}
                        onChange = {(e) => {
                        setPayment(e.target.value)
                        }}
                        items = {
                        payMethod && payMethod?.map((i) => (
                            <MenuItem value={i}>{i?.name}</MenuItem>
                        ))
                        }
                    />}

                    {payment?._id === "63e31a93f029ceab39dcd281" &&
                        <SelectInput
                            label = "Bank"
                            value = {bank}
                            onChange = {(e) => setBank(e.target.value)}
                            items = {
                                listBanks && listBanks?.map((i) => (
                                <MenuItem value={i._id}>{i.bank} | {i.accountNo}</MenuItem>
                                ))
                            }
                        />
                    }

                    <div>
                        <Button className='w-100 mt-2' size='large' color="success" variant="contained" onClick={PurchaseSubmit} disabled={selectInvoiceVal && items.length && paymentStatus ? submitDisable : true}>Checkout</Button>
                    </div>
                </Paper>
            </div>

            <ModalBasic
                open = {visibleScanner}
                onClose = {()=> {
                setVisibleScanner(false)
                }}
                content = {
                <div className='p-3'>
                    <Scanner result = {getBarcodeData}/>
                </div>
                }
            />
            </div>
    )
}
