import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import { useSettings } from '../../../context/settings'

import {Text} from '../../../components/Inputs/InputFields'

import Scanner from '../../../components/Scan-Qr/Scanner'

export default function UpdateProductCode(props) {
  const productCodeData = props.productCodeData;

  const [settings] = useSettings()

  const [productCode, setProductCode] = useState(productCodeData?.productCode)
  const [cost, setCost] = useState(productCodeData?.cost)
  const [price, setPrice] = useState(productCodeData?.price)

  const [submitDisable, setSubmitDisable] = useState(false)

  const getBarcodeData = (data) => {
    setProductCode(data?.text)

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.put(`/productcode/update`, {...productCodeData, productCode, cost, price});
      if(data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success('Product Code Added Sucessfully')
        window.location.reload()
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h5 className='text-center mb-3'>Update Product Code</h5>
      <form onSubmit={handleSubmit}>
        <div className='mb-2'>
        <Scanner result = {getBarcodeData}/>
        </div>
        <Text
          label="Product Code"
          value = {productCode}
          onChange={(e) => setProductCode(e.target.value)}
        />

        <Text
          label="Cost"
          type="Number"
          value = {cost}
          onChange={(e) => setCost(e.target.value)}
          end={settings?.currencyEnd}
        />

        <Text
          label="Price"
          type="Number"
          value = {price}
          onChange={(e) => setPrice(e.target.value)}
          end={settings?.currencyEnd}
        />

        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            UPDATE PRODUCT CODE
          </button>
        </div>
      </form>
    </div>
  )
}
