import React, {useState,useEffect} from 'react'
import Axios from 'axios'
import {toast} from 'react-hot-toast'

import { useAuth } from '../../../context/auth';

import MUITable from '../../../components/table/MUITable'
import ModalBasic from '../../../components/Modal/ModalBasic'

import {Add, Edit, QrCode, DeleteForever} from '@mui/icons-material/'

import QrCodeGenerator from '../../../components/Scan-Qr/QrCodeGenerator'
import CreateVehicle from './CreateVehicle'
import UpdateVehicle from './UpdateVehicle';

export default function ListVehicles() {
  const [auth] = useAuth();

  const [listVehicles, setListVehicles] = useState('')
  const [listPoints, setListPoints] = useState('')
  //Model
  const [visibleAddVehicle, setVisibleAddVehicle] = useState(false)

  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(null)

  const [visibleDeleteModel, setVisibleDeleteModel] = useState(false)
  const [selectedDeleteModel, setSelectedDeleteModel] = useState(null)

  const [visibleQRModel, setVisibleQRModel] = useState(false)
  const [selectedQRModel, setSelectedQRModel] = useState(null)

  const [submitDisable, setSubmitDisable] = useState(false)

  useEffect(() => {
      loadVehicles()
      loadPointsByVehicle()
  }, [])
  

  const loadVehicles = async() => {
      try {
          const {data} = await Axios.get('/pc-vehicles')
          if(data?.error){
              toast.error(data.error)
          } else {
            setListVehicles(data)
          }
      } catch (err) {
        console.log(err)
          toast.error("Something went wrong, Try Again!")
      }
  }

  const loadPointsByVehicle = async() => {
    try {
        const {data} = await Axios.post('/fillups-pointbyvehicles')
        if(data?.error){
            toast.error(data.error)
        } else {
          setListPoints(data)
        }
    } catch (err) {
      console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
}

  const handleDeleteVehicle = async(id) => {
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.delete(`/pc-vehicle/${id}`)
      if (data?.error) {
        toast.error(data.error)
        setSubmitDisable(false)
      } else {
        toast.success(`Vehicle ${selectedDeleteModel?.vehicleNo} Deleted Successfully`)
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong, Try Again!')
    }
  }

  return (
    <div className='row justify-content-center'>
      <div className='col-xxl-8 col-xl-10 col-12 mb-3'>
        <div className='row justify-content-between mb-2'>
          <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 d-flex gap-2 mb-2'>
            <button className = 'btn btn-outline-primary' onClick={() => {setVisibleAddVehicle(true)}}><Add/> VEHICLE</button>
          </div>
        </div>
        {setListVehicles && listPoints &&
          <MUITable
            id = {(row) => row?._id}
            columns = {[
                { field: 'nic', headerName: 'Phone No', width: 120, renderCell: (i) => i.row.customer?.phoneNumber, valueGetter: (i) => i.row?.customer?.phoneNumber},
                { field: 'name', headerName: 'Customer Name', width: 180, renderCell: (i) => i.row.customer?.name, valueGetter: (i) => i.row?.customer?.name},
                { field: 'vehicleNo', headerName: 'Vehicle No', width: 120},
                { field: 'vehicleCategory', headerName: 'Category', width: 150, renderCell: (i) => i.row.pointsCategory?.type, valueGetter: (i) => i.row?.pointsCategory?.type},
                { field: 'availablePoints', headerName: 'Av Points', width: 100, renderCell: (i) => (listPoints.find((p) => p._id?.vehicle[0]?._id === i.row._id)?.points) - (listPoints.find((p) => p._id?.vehicle[0]?._id === i.row._id)?.redeem) },
                { field: 'action', headerName: 'Action', width: 125, filterable: false, sortable: false, disableExport: true, renderCell: (i) => 
                <div className='d-flex gap-2'>
                  <QrCode fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisibleQRModel(true)
                      setSelectedQRModel(i.row)
                    }}
                  />
                  <Edit fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisible(true)
                      setSelected(i.row)
                    }}
                  />
                  <DeleteForever fontSize='large' className='p-2 shadow rounded-circle'
                    onClick={() => {
                      setVisibleDeleteModel(true)
                      setSelectedDeleteModel(i.row)
                    }}
                  />
                </div>
                },
            ]}
            rows = {listVehicles}
          />
        }

        <ModalBasic
          open = {visibleAddVehicle}
          onClose = {()=> {
          setVisibleAddVehicle(false)
          }}
          content = {<CreateVehicle/>}
        />

        
        <ModalBasic
          open = {visible}
          onClose = {()=> {
          setVisible(false)
          }}
          content = {auth?.employee?.role <= 1 ? 
          <UpdateVehicle data={selected}/>
          : 
          <div className='d-flex justify-content-center w-100'>
            <p>You don't have Permission to Edit</p>
          </div>
        }
        />

        <ModalBasic
          open = {visibleQRModel}
          onClose = {()=> {
          setVisibleQRModel(false)
          }}
          content = {
            <div className='d-flex justify-content-center p-1'>
              <QrCodeGenerator qrText = {selectedQRModel?.vehicleNo}/>
            </div>
          }
        />

        <ModalBasic
          open = {visibleDeleteModel}
          onClose = {()=> {
          setVisibleDeleteModel(false)
          }}
          content = {
            auth?.employee?.role === 0 ?
              <div className='d-flex justify-content-between'>
                <p>Are You sure want to?</p>
                <button className='btn btn-outline-danger' disabled={submitDisable} onClick={() => {
                    handleDeleteVehicle(selectedDeleteModel._id)
                  }}>Delete
                </button>
              </div>
              :
              <div className='d-flex justify-content-center w-100'>
                <p>You don't have Permission to Delete</p>
              </div>
          }
        />

      </div>
    </div>
  )
}
