import React, {useRef, useState, useEffect} from 'react'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import { useDk } from '../../../../context/dk'

import {Print} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell'

export default function PurchaseReturnDetails() {
    const {id} = useParams()

    const [dk, setDk] = useDk();

    const [purchaseReturnDetails, setPurchaseReturnDetails] = useState('')

    useEffect(() => {
        loadPurchaseReturnDetails()
    },[])

    const loadPurchaseReturnDetails = async() => {
        try {
            const {data} = await Axios.get(`/purchasereturn/${id}`)
            if (data?.error) {
                toast.error(data.error)
            } else {
                setPurchaseReturnDetails(data)
                console.log(data)
            }
        } catch (err) {
          toast.error("Something went wrong, Try Again!")
          console.log(err)
        }
    }

    const paymentStatusColor = () => {
        try {
            switch (purchaseReturnDetails?.paymentStatus) {
                case 'received':
                    return {color: 'greenyellow'}
                case 'panding':
                    return {color: 'orange'}
                case 'unknown':
                    return {color: 'red'}
            }
        } catch (err) {
            console.log(err)
          toast.error("Something went wrong, Try Again!")

        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
    }));

    const componentRef = useRef();
    const printData = useReactToPrint({
        content : () => componentRef.current,
        documentTitle: `Purchase Return Report ${purchaseReturnDetails?.purchase?.supplier?.name} - ${purchaseReturnDetails?.purchase?.invoiceNo}`,
        onBeforeGetContent: async() => {
            await setDk({...dk, darkmode : false})
        },
        onAfterPrint: () => {
            const data = localStorage.getItem("darkmode");
            if (data) {
                const parsed = JSON.parse(data);
                setDk({...dk, darkmode: parsed});
            }
        }
    })

    return (
        <div className='row justify-content-center'>
            <div>
                <button className='btn btn-warning' onClick={printData}><Print/></button>    
            </div>

            <div className='row justify-content-center p-5' style={{width: 1000}} ref= {componentRef}>
                <h3 className='text-center'>Purchase Return Report</h3>

                <hr/>

                <div className='d-flex justify-content-between mb-2 w-100'>
                    <div className='d-flex gap-2' style={{width : 300}}>
                        <dd style={{fontSize: '14px'}}>SUPPLIER:</dd>
                        <div>
                            <dd style={{fontSize: '14px'}}>Name: {purchaseReturnDetails?.purchase?.supplier?.name}</dd>
                            <dd style={{fontSize: '14px'}}>Company: {purchaseReturnDetails?.purchase?.supplier?.company}</dd>
                            <dd style={{fontSize: '14px'}}>Address: {purchaseReturnDetails?.purchase?.supplier?.address}</dd>
                            <dd style={{fontSize: '14px'}}>Phone No: {purchaseReturnDetails?.purchase?.supplier?.phoneNumber}</dd>
                        </div>
                    </div>

                    <div className='row' style={{width : 300}}>
                        <dd style={{fontSize: '14px'}}>INVOICE NO: {purchaseReturnDetails?.purchase?.invoiceNo}</dd>
                        <dd style={{fontSize: '14px'}}>REFUND STATUS: <br/> <div style={paymentStatusColor()}>{purchaseReturnDetails?.paymentStatus?.toUpperCase()}</div></dd>
                        <dd style={{fontSize: '14px'}}>REFUND: {purchaseReturnDetails?.payment ? purchaseReturnDetails?.payment?.name?.toUpperCase() : "None"}</dd>
                    </div>

                    <div className='row text-end' style={{width : 300}}>
                        <dd style={{fontSize: '14px'}}>PURCHASE DATE: {purchaseReturnDetails?.purchase?.purchaseDate}</dd>
                        <dd style={{fontSize: '14px'}}>RETURN DATE: {purchaseReturnDetails?.returnDate}</dd>
                        <dd style={{fontSize: '14px'}}>CREATED ON: {moment(purchaseReturnDetails?.createdAt).format("YYYY-MM-DD hh:mm A")}</dd>
                        <dd style={{fontSize: '14px'}}>REFUND DATE: {purchaseReturnDetails?.payment && moment(purchaseReturnDetails?.updatedAt).format("YYYY-MM-DD hh:mm A")}</dd>
                    </div>
                </div>

                <div className='w-100'>
                    {purchaseReturnDetails?.items && 
                        <Table className='w-100' size='small' component={Paper}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell style={{fontSize: '12px'}}>#</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '12px'}}>Product Code</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '12px'}}>Product</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '12px'}}>Warehouse</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '12px'}}>Qty</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '12px'}}>Unit Price</StyledTableCell>
                                    <StyledTableCell style={{fontSize: '12px'}}>Item Price</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {purchaseReturnDetails?.items?.map((i,index) =>
                                    <TableRow key={index}>
                                        <TableCell style={{fontSize: '12px'}}>{index+1}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{i?.productLine?.productCode?.productCode}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{i?.productLine?.productCode?.product?.name}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{i?.productLine?.wareHouse?.name}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{i?.quantity + ' ' + i?.productLine?.productCode?.product?.unit}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.returnPrice / i?.quantity)}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.returnPrice)}</TableCell>
                                    </TableRow> 
                                )}
                            </TableBody>
                        </Table>
                    }

                    <div className='row m-3 pt-3 justify-content-end'>
                        <div className='col-md-4 col-sm-6 col-12'>
                            <div className='row'>
                                <div className='d-flex justify-content-between'>
                                    <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>Total:</p>
                                    <p className='lh-sm fw-bold' style={{fontSize: '16px'}}>{purchaseReturnDetails ? `${new Intl.NumberFormat("en-us", {currency: 'LKR', style: 'currency'}).format(purchaseReturnDetails?.total)}` : '0.00'}</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}
