import React, {useRef, useState, useEffect} from 'react'
import Axios from 'axios'
import moment from 'moment';
import {toast} from 'react-hot-toast'
import {useReactToPrint} from 'react-to-print'

import {Print, Verified} from '@mui/icons-material'
import { styled } from '@mui/material/styles';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

import {Text} from '../../../components/Inputs/InputFields'

import { useSettings } from '../../../context/settings';
import { useGdate } from '../../../context/date'
import { useDk } from '../../../context/dk'

export default function FinancialStatement() {

  const [settings] = useSettings()
  const [gdate, setGdate] = useGdate()
  const [dk, setDk] = useDk()

  const [fuelSales , setFuelSales] = useState('')
  const [inventorySales, setInventorySales] = useState('')
  const [inventoryReturns, setInventoryReturns] = useState('')
  const [totalSales, setTotalSales] = useState('')

  const [allAccounts, setAllAccounts] = useState('')
  const [totalNc, setTotalNc] = useState('')
  const [totalNegNc, setTotalNegNc] = useState('')

  const [bfCash, setBfCash] = useState('')
  const [bfCheque, setBfCheque] = useState('')

  const [cashAccounts, setCashAccounts] = useState('')
  const [chequeAccounts, setChequeAccounts] = useState('')

  const [cashTotalAccounts, setCashTotalAccounts] = useState('')
  const [chequeTotalAccounts, setChequeTotalAccounts] = useState('')

  const [listCashflowCheck, setListCashflowCheck] = useState('')
  const [amount, setAmount] = useState('')

  const [submitDisable, setSubmitDisable] = useState(false)
  const [submitDisable2, setSubmitDisable2] = useState(false)


  useEffect(() => {
    loadFuelSales()
    loadOrders()
    loadOrdersReturns()
    loadAccData()
    loadBFIncomeAccounts()
    loadIncomeAccounts()
    loadIncomeTotalAccounts()
    loadCashflowsCheck()
  },[])

  useEffect(() => {
    setTotalSales((fuelSales ? fuelSales?.reduce((a,v) => a = a + v?.total, 0) : 0) + inventorySales - inventoryReturns)
  },[fuelSales, inventorySales])

  useEffect(() => {
    setTotalNc(allAccounts && allAccounts?.reduce((a,v) => a + (allAccountsDebitEntry(v) ? allAccountsDebitEntry(v) : 0), 0))
    setTotalNegNc(allAccounts && allAccounts?.reduce((a,v) => a + (allAccountsCreditEntry(v) ? allAccountsCreditEntry(v) : 0), 0))
  },[allAccounts])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }));

  const loadFuelSales = async() => {
    try {
      const {data} = await Axios.post(`/daypumps/fuelsales-typepricecost`, {date: {$gte: gdate?.date, $lte: gdate?.date}})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setFuelSales(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadOrders = async() => {
    try {
      const {data} = await Axios.post(`/order-filter`, {orderDate: {$gte: gdate?.date, $lte: gdate?.date}})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setInventorySales(data?.reduce((a,v) => a = a + v?.total, 0) ? data?.reduce((a,v) => a = a + v?.total, 0) : 0)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadOrdersReturns = async() => {
    try {
      const {data} = await Axios.post(`/order-returns`, {returnDate: {$gte: gdate?.date, $lte: gdate?.date}})
      if(data?.error){
        toast.error(data.error)
      }
      else {
        setInventoryReturns(data?.reduce((a,v) => a = a + v?.total, 0) ? data?.reduce((a,v) => a = a + v?.total, 0) : 0)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadAccData = async() => {
    try {
        const {data} = await Axios.post(`/accdatas/filter`, {date: {$gte: gdate?.date, $lte: gdate?.date}})
        if(data?.error){
            toast.error(data.error)
        } else {
            setAllAccounts(data.filter((i) => i?.accounting?._id !== '64952f578188d2c8b9c26603' && i?.accounting?._id !== '63eb82f1661aeb9f06368651' && i?.accounting?._id !== '64fda2c5c969962bfbcee3eb' ? (i) => i?.accounting?._id !== '64952f578188d2c8b9c26603' && i?.accounting?._id !== '63eb82f1661aeb9f06368651' && i?.accounting?._id !== '64fda2c5c969962bfbcee3eb' : ''))
        }
    } catch (err) {
        console.log(err)
        toast.error("Something went wrong, Try Again!")
    }
  }
  
  const loadBFIncomeAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: {$lte: moment(gdate?.date).subtract(1, 'day').format('YYYY-MM-DD')}})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setBfCash(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603')?.total : 0 )
        await setBfCheque(data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb') ? data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb')?.total : 0 )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadIncomeAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: {$gte: gdate?.date, $lte: gdate?.date}})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setCashAccounts(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603')?.total : 0 )
        await setChequeAccounts(data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb') ? data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb')?.total : 0 )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  const loadIncomeTotalAccounts = async() => {
    try {
      const {data} = await Axios.post(`/accdatas/filteraccounts`, {date: {$lte: gdate?.date}})
      if(data?.error) {
        toast.error(data.error)
      } else {
        await setCashTotalAccounts(data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603') ? data.find((i) => i._id.accounting[0]?._id === '64952f578188d2c8b9c26603')?.total : 0 )
        await setChequeTotalAccounts(data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb') ? data.find((i) => i._id.accounting[0]?._id === '64fda2c5c969962bfbcee3eb')?.total : 0 )
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
    }
  }

  //Need to load Total Cash and Cheque account so can compare the amount with cash and cheque on last 2 rows

  const allAccountsDebitEntry = (i) => {
    switch (true) {
        case ( (i.accounting?.category?.type && i?.amount > 0) || (!i.accounting?.category?.type && i?.amount < 0) ):
            return Math.abs(i?.amount);
    }
  }

  const allAccountsCreditEntry = (i) => {
      switch (true) {
          case ( (!i.accounting?.category?.type && i?.amount > 0) || (i.accounting?.category?.type && i?.amount < 0) ):
              return Math.abs(i.amount);
      }
  }

  const componentRef = useRef();
  const printData = useReactToPrint({
      content : () => componentRef.current,
      documentTitle: `Daily Cash Flow Accounts - ${gdate?.date}`,
      onBeforeGetContent: async() => {
          await setDk({...dk, darkmode : false})
      },
      onAfterPrint: () => {
          const data = localStorage.getItem("darkmode");
          if (data) {
              const parsed = JSON.parse(data);
              setDk({...dk, darkmode: parsed});
          }
      },
  })

  //CASH ACCOUNTS INPUT AND VERIFY BALANCE
  const loadCashflowsCheck = async() => {
    try {
      const {data} = await Axios.post('/cashflows')
      if (data?.error) {
        toast.error(data?.error)
      } else {
        setListCashflowCheck(data)
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  const handleAccounts = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post('/accdatabulk', [
        {date: gdate?.date, type : `${amount > 0 ? 'credit' : 'debit'}`, empStatus: {status: false}, detail: {description: 'Miscellaneous income on Cashflow check'}, amount: Number(amount)?.toFixed(2), accounting: '6506bfcdd3328c7a7f56db8d', refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-cashflowCheck-${amount}`},
        {date: gdate?.date, type: `${amount > 0 ? 'credit' : 'debit'}`, empStatus: {status: false}, detail: {description: 'Miscellaneous income on Cashflow check'}, amount: Number(amount)?.toFixed(2), accounting: '64952f578188d2c8b9c26603' , refId : `de-${moment(new Date()).format("YYYYMMDDHHmmss")}-cashflowCheck-${amount}`}
      ])
      if (data?.error) {
        toast.error(data?.error)
        setSubmitDisable2(false)
      } else {
        toast.success("Miscellaneous Entry Added")
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  const loadCashFlowsToBeVerify = async() => {
    try {
        const {data} = await Axios.post(`/cashflows-verifiedlist`)
        if (data?.error) {
            console.log(data?.error)
        } else {
          setGdate({...gdate, date: data?.last ? moment(data?.date).add(1, 'day').format('YYYY-MM-DD') : data?.date, reportStartDate: moment(data?.date).add(1, 'day').startOf('month').format('YYYY-MM-DD'), reportEndDate: moment(data?.date).add(1, 'day').endOf('month').format('YYYY-MM-DD'), cashFlowStatus: data?.last})
          localStorage.setItem( "globalDates", JSON.stringify({...gdate, date: moment(gdate?.date).add(1, 'day').format('YYYY-MM-DD'), reportStartDate: moment(data?.date).add(1, 'day').startOf('month').format('YYYY-MM-DD'), reportEndDate: moment(data?.date).add(1, 'day').endOf('month').format('YYYY-MM-DD'), cashFlowStatus: data?.last}))
        }
    } catch (err) {
        console.log(err)
    }
  }

  const handleCashFlowVerify = async(e) => {
    e.preventDefault()
    try {
      await setSubmitDisable2(true)
      const {data} = await Axios.post('/cashflow', {date: gdate?.date, status: true, amount: Number(cashAccounts + chequeAccounts)?.toFixed(2)})
      if (data?.error) {
        toast.error(data?.error)
        setSubmitDisable(false)
      } else {
        await loadCashFlowsToBeVerify()
        toast.success("Cash Flow Checks Verified")
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something wernt wrong, Try Again!")
    }
  }

  return (
    <div>
      <div className='row mb-2'>
        <div className='col-xxl-3 col-xl-4 col-lg-5 col-sm-6 col-12 mb-2'>
          <button className = 'btn btn-warning w-50' onClick={() => {printData()}}><Print/></button> 
        </div>
      </div>

      {/* CASH FLOW STATEMENT */}
      <div className='row justify-content-center pt-3' ref= {componentRef}>
        <div className='d-flex justify-content-center gap-2 align-items-baseline'>
          <h4>DAILY CASH FLOW STATEMENT</h4>
          <p>{gdate?.date}</p>
        </div>
        
        <div className='col-xxl-8 col-lg-10'>
          <div style={{overflowX: 'auto'}}>
          <Table component={Paper} size='small'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Daily Cash Flow</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
                <StyledTableCell align="right">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
          
            <TableBody>

              {/* CASH BROUGHT FORWARD */}
              <TableRow key='cash-bf'>
                <TableCell className='text-end fw-bold'>CASH B/F</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash)}</TableCell>
              </TableRow>

              {/* CHEQUE BROUGHT FORWARD */}
              <TableRow key='cheque-bf'>
                <TableCell className='text-end fw-bold'>CHEQUE B/F</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCheque)}</TableCell>
              </TableRow>


              {/* FUEL SALES */}
              <TableRow key='fuel-sales'>
                <TableCell className='fw-bold'>FUEL SALES</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {fuelSales && fuelSales?.map((i) =>
                <TableRow key={i?._id?.type}>
                  <TableCell> {i?._id?.type + ' | ' + Number(i?.liter)?.toFixed(3) + ' L'}</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(i?.total ? i?.total : 0))}</TableCell>
                </TableRow>
              )}

              {/* INVENTORY SALES */}
              <TableRow key='inventory-sales'>
                <TableCell className='fw-bold'>INVENTORY SALES & RETURNS</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              <TableRow key='all-inventory-sales'>
                <TableCell>All Inventory Sales</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">{(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventorySales ? inventorySales : 0))}</TableCell>
              </TableRow>

              <TableRow key='all-inventory-returns'>
                <TableCell>All Inventory Returns</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">-({(new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(inventoryReturns ? inventoryReturns : 0))})</TableCell>
              </TableRow>

              {/* NONE CASH FLOWS ACCOUNTS */}
              <TableRow key='nonCashFlow'>
                <TableCell className='fw-bold'>NONE CASH FLOWS</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {allAccounts && allAccounts?.map((i) =>
                <TableRow key={i?._id}>
                  <TableCell> {i?.accounting?.name?.toUpperCase() + ' | ' + (i?.accounting?._id === '63e31abdf029ceab39dcd283' ? `${i?.detail?.customer?.name} : ` : '') + i?.detail?.description}</TableCell>
                  <TableCell align="right">{allAccountsDebitEntry(i) && (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(allAccountsDebitEntry(i)))}</TableCell>
                  <TableCell align="right">{allAccountsCreditEntry(i) && (new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(Math.abs(allAccountsCreditEntry(i))))}</TableCell>
                </TableRow>
              )}

              <TableRow key='noneCashFlowTotal' sx={{bgcolor: '#9e9e9e'}}>
                <TableCell className='text-end fw-bold text-dark'>TOTAL</TableCell>
                <TableCell className='fw-bold text-dark' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(totalNc) }</TableCell>
                <TableCell className='fw-bold text-dark' align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash + bfCheque + totalSales + totalNegNc) }</TableCell>
              </TableRow>

              <TableRow key='cash-cheque' style={Number(cashAccounts + chequeAccounts)?.toFixed(2) === Number(totalSales - (totalNc - totalNegNc))?.toFixed(2) ? {backgroundColor: 'greenyellow'} : {backgroundColor: 'red'}}>
                <TableCell className='text-center fw-bold' style={{fontSize: '16px', color: 'black'}}>{Number((totalSales + totalNegNc) - totalNc)?.toFixed(2) === Number(cashAccounts + chequeAccounts)?.toFixed(2) ? 'CASH + CHEQUE' : `ERROR! Missing Entry of ${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format( Math.abs((cashAccounts + chequeAccounts + totalNc) - (totalSales + totalNegNc)) ) }`}</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell className='fw-bold' style={{fontSize: '16px', color: 'black'}} align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format((bfCash + bfCheque + totalSales + totalNegNc) - totalNc) }</TableCell>
              </TableRow>

              {/* CASH */}
              <TableRow key='cash'>
                <TableCell className='fw-bold'>{Number(bfCash + cashAccounts)?.toFixed(2) === Number(cashTotalAccounts)?.toFixed(2) ? "CASH" : `ERROR CASH Should be ${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash + cashAccounts)}`}</TableCell>
                <TableCell align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCash + cashAccounts + Number(amount))}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

              {/* CHEQUE */}
              <TableRow key='cheque'>
                <TableCell className='fw-bold'>{Number(bfCheque + chequeAccounts)?.toFixed(2) === Number(chequeTotalAccounts)?.toFixed(2) ? "CHEQUE" : `ERROR CHEQUE Should be ${new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCheque + chequeAccounts)}`}</TableCell>
                <TableCell align="right">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'LKR'}).format(bfCheque + chequeAccounts)}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>

            </TableBody>
          </Table>
          </div>

          {listCashflowCheck && !listCashflowCheck?.find((i) => i?.date === gdate?.date) && 
          <div className='row mt-3'>
            <p className='text-center fs-5 col-xl-6 col-md-5'>Adjustments in Cash</p>
            <div className='col-xl-4 col-md-4 col-sm-8'>
              <Text
                label="Miscellaneous income +/-" 
                value = {amount}
                type = "number"
                onChange={(e) => setAmount(e.target.value)}
                start = {settings?.currencyStart}
              />
            </div>
            <div className='col-xl-2 col-md-3 col-sm-4'>
              <button className='btn btn-primary w-100' onClick={handleAccounts} disabled={submitDisable}>ADD ENTRY</button>
            </div>
          </div>}

          {listCashflowCheck && <div className='d-flex justify-content-end mt-3'>
            <button className='btn btn-success' onClick={handleCashFlowVerify} disabled= {submitDisable2 || listCashflowCheck?.find((i) => i?.date === gdate?.date) ? true : false}>{listCashflowCheck?.find((i) => i?.date === gdate?.date) ? <Verified/> : "VERIFY"}</button>
          </div>}
        </div>
      </div>
      
    </div>
  )
}
