import React, {useState} from 'react'
import Axios from "axios"
import {toast} from "react-hot-toast"

import {Text} from '../../../components/Inputs/InputFields'

export default function CreateBrand() {

  const [name, setName] = useState("");

  const [submitDisable, setSubmitDisable] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setSubmitDisable(true)
      const {data} = await Axios.post(`/brand`, {name});
      if(data?.error) {
        toast.error(data.error);
        setSubmitDisable(false)
      } else {
        toast.success("Brand Added");
        window.location.reload()
      }
    } catch (err) {
      console.log(err)
      toast.error("Something went wrong, Try Again!")
      
    }
  }

  return (
    <div className='p-3'>
      <h4 className='text-center mb-3'>Add Brand</h4>
      <form onSubmit={handleSubmit}>
        <Text
          label="Brand"
          value = {name}
          onChange={(e) => setName(e.target.value)}
          focus = 'true'
        />
        <div class="d-grid gap-2">
          <button className = 'btn btn-primary' type = 'submit' disabled={submitDisable}>
            ADD BRAND
          </button>
        </div>
      </form>
    </div>
  )
}
